import axios from "_utils/axios";

export function getOrganizationService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrganizationVenuesService() {
  return new Promise((resolve, reject) => {
    axios
      .get('userOrganization/venues?include_productions=1')
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateOrganizationService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${actions.id}`, actions.data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function createVenueService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${actions.orgId}/venues`, { venue: actions.venue })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateVenueService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${actions.orgId}/venues/${actions.venue._id}`, { venue: actions.venue })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteVenueService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${actions.orgId}/venues/${actions.venueId}`, { venue: actions.venue })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setSeatFeePlanService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${actions.orgId}/update-seat-fee`, { per_ticket_fee: actions.per_ticket_fee, sendEmail: actions.sendEmail, email: actions.email, message: actions.message })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function organizationList(phrase) {
  return new Promise((resolve, reject) => {
    axios
      .get('organizations', { 
        params: {
          phrase,
          fields: 'name timezone artix_id',
        }
       })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function savePaymentAchService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${actions.orgId}/updatePaymentAch`, { data: actions.paymentAch })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePaymentCheckMailingAddress(actions) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${actions.orgId}/updatePaymentAddress`, {
       data: actions.paymentAddress,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addChildOrganization(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/create-child-org`, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePaymentMethodService(actions) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${actions.orgId}/updatePaymentMethod`, {
        PaymentMethod: actions.PaymentMethod,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getSeriesWithUnpublishedSocialPostsService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/getSeriesWithUnpublishedSocialPosts`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPaymentMethodService(orgId, data={}) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/salesforce/getPaymentMethod`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAccountOwnerService(artixOrgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${artixOrgId}/salesforce/getAccountOwner`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteSeatingChartService(organizationId, seatingChartId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${organizationId}/seatingChart/${seatingChartId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function copySeatingChartService(organizationId, seatingChartId, seatingChartName) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${organizationId}/seatingChart/${seatingChartId}/copy`, {
        seatingChartName,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSeatingCharts(organizationId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${organizationId}/seatingChart`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function validateChartName(organizationId, name) {
  const checkNameRequest = 'organizations/' + organizationId + '/seatingChart/validate/' + name;
  return new Promise((resolve, reject) => {
    axios.get(checkNameRequest).then((response => {
      if (response.data === true) {
        throw new Error('Chart name already used');
      } else {
        resolve(response.data);
      }
    })).catch((error) => {
      reject(error);
    });
  });
};

export async function createSeatingChart(organizationId, productionId, name) {
  try {
    await validateChartName(organizationId, name);
    const newChart = {
      name: name,
      productionId,
      stage: {
        'text_transformString': '',
        'text_coords': [
          548.66667,
          40
        ],
        'box_transformString': '',
        'box_coords': [
          493.66667,
          20,
          150,
          40
        ],
        stage_matrix: {
          a: 1,
          b: 0,
          c: 0,
          d: 1,
          e: 0,
          f: 0
        }
      }            
    };

  const response = await axios
      .post('organizations/' + organizationId + '/seatingChart', newChart)
  return response.data;

  } catch (err) {
    throw err;
  }
}





export function generateACHFormService(orgId, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/generateACHForm`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePaymentAchStatusService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updatePaymentAchStatus`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getDashboardDataService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/getDashboardData`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEnterpriseDashboardDataService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/getEnterpriseDashboardData`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBuyersListFromEventService(eventId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`events/${eventId}/buyersList`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTicketAddendumFormService(orgId, data={}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/getTicketAddendumForm`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTicketAddendumStatusService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateTicketAddendumStatus`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePageTextService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updatePageText`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrganizationWebsiteService() {
  return new Promise((resolve, reject) => {
    axios
      .get(`org-website`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateOrganizationWebsiteService(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`org-website`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getProductionsService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/productions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getAllProductionsService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/all-productions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateShowContactFormService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateShowContactForm`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function saveCarouselImagesService({orgId, images}) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/uploadCarouselImages`, { images })
      .then((response) => {
        console.log('saveCarouselImagesService data', response.data)
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getCarouselImagesService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/carouselImages`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateCarouselImagesService(orgId, images) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateCarouselImages`, { images })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteCarouselImageService({orgId, images}) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/deleteCarouselImage`, { images })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateOrgBannerImageService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateOrgBannerImage`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function togglePageService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/togglePage`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateProductionDescriptionService(orgId, pslug, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/${pslug}/updateProductionDescription`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateProductionDisplayImageService(orgId, pslug, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/${pslug}/updateProductionDisplayImage`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateLogoService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateLogo`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function removeLogoService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/removeLogo`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updatePageColorService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updatePageColor`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function removeOrgBannerImageService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/removeOrgBannerImage`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function createReceivableInvoiceService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`transactions/create-receivable-invoice`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function contactEmailService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/salesforce/getContactEmail`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getRecentOrdersService(orgId, page, rowsPerPage) {
  let url = `organizations/${orgId}/getOrganizationRecentOrders`;
  if ((typeof page !== 'undefined' || page !== null) && rowsPerPage) {
    url += `?page=${page}&rowsPerPage=${rowsPerPage}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export function createTransactionService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`transactions`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function createPendingPaymentService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`transactions/create-pending-payment`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updateSocialUrlService(orgId, platform, url) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/updateSocialUrl`, { 
        platform,
        url
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTaxDeductibleFieldService(orgId, taxDeductible) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/updateTaxDeductibleField`, { tax_deductible: taxDeductible })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadQrCodeService (orgId, qrUrl, options) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/generateQrCode`, 
      { 
        qrUrl,
        options 
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
    });
  }

  
export function getStripeReadersListService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/getStripeReadersList`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteReaderService(readerId, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/deleteReader`, {
        reader_id: readerId
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBookingFeesService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/bookingFees`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createBookingFeeService(orgId, bookingFee) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/bookingFees`, {
        ...bookingFee
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateBookingFeeService(orgId, bookingFee) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/bookingFees/${bookingFee.id}`, {
        ...bookingFee
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteBookingFeeService(orgId, bookingFee) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${orgId}/bookingFees/${bookingFee.id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrgDetailsService(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${id}/details`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function sendTestEmailService(productionArtixId, email, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/send-test-email`, {
        productionArtixId,
        email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function updateOrgAttributesService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${data.orgId}/update-attributes`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function getOrganizationCssTemplatesService({orgId, page, rowsPerPage}) {
  let url = `organizations/${orgId}/css-templates`;
  if ((typeof page !== 'undefined' || page !== null) && rowsPerPage) {
    url += `?page=${page}&rowsPerPage=${rowsPerPage}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function saveOrganizationCssTemplateService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${data.orgId}/css-templates`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function updateOrganizationCssTemplateService(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${data.orgId}/css-templates`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteOrganizationCssTemplateService(data) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${data.orgId}/css-templates/${data.id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
