import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import LogRocket from 'logrocket';
import browserUpdate from 'browser-update';

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "_theme";
import Routes from "_routes/Routes";
import { useLocale } from "_contexts/LocaleContext";

import './app.css';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  if (process.env.NODE_ENV !== 'development') {
    LogRocket.init(process.env.NODE_ENV === "production" ? 'rwl5hk/onthestage-dashboard' : 'rwl5hk/react-dashboard-localtest');
  }
  const { calendarLocale } = useLocale();
  
  browserUpdate({required:{e:-4,f:-3,o:-3,s:-1,c:-3}, reminder: 0, reminderClosed: 24, insecure:true,api:2019.01 });
  const theme = useSelector((state) => state.themeReducer);
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | On The Stage Dashboard"
        defaultTitle="On The Stage Dashboard"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={calendarLocale}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
