import * as types from "_constants";
import {
  listProductions as actionListProductions
} from "_services/productionListService";

const mapDetailsToProductions = ({productions = [], productionDetails = []}) => {
  for (const production of productions) {
    const {
      artix_series_id
    } = production;
  
    const details = (productionDetails[0] || []).find((details) => details.SeriesId === artix_series_id);
    production.details = details;
  }
  return productions;
}

export function listLiveProductions(getSeriesAttributes) {
  return async (dispatch) => {
    return actionListProductions({
        current: true,
        getSeriesAttributes,
    }, true)
      .then((response) => {
        const productions = mapDetailsToProductions(response);
        dispatch({
          type: types.GET_LIVE_SUCCESS,
          payload: {
            live: productions,
          }          
        });
        dispatch({
          type: types.UPDATE_SIDEBAR_BADGE_LIVE,
          payload: {
            current: productions.length,
          }
        });  
      })
      .catch((error) => {
        dispatch({ type: types.GET_LIVE_FAILURE });
        throw error;
      });
  };
}

export function listDraftProductions(getSeriesAttributes) {
    return async (dispatch) => {
      return actionListProductions({
          status: 'draft',
          getSeriesAttributes,
      }, true)
        .then((response) => {
          const productions = mapDetailsToProductions(response);
          dispatch({
            type: types.GET_DRAFT_SUCCESS,
            payload: {
              draft: productions,
            }          
          });
          dispatch({
            type: types.UPDATE_SIDEBAR_BADGE_DRAFT,
            payload: {
              draft: productions.length,
            }
          });          
        })
        .catch((error) => {
          dispatch({ type: types.GET_DRAFT_FAILURE });
          throw error;
        });
    };
  }

  export function listLiveAndDraftProductions() {
    return async (dispatch) => {
      return actionListProductions({
          current: true,
          status: 'draft'
      })
        .then((response) => {
          const productions = mapDetailsToProductions(response);
          dispatch({
            type: types.GET_LIVE_AND_DRAFT_SUCCESS,
            payload: {
              live_and_draft: productions,
            }          
          }); 
        })
        .catch((error) => {
          dispatch({ type: types.GET_LIVE_FAILURE });
          throw error;
        });
    };
  }

  export function listPastProductions() {
    return async (dispatch) => {
      return actionListProductions({
          status: 'complete'
      })
        .then((response) => {
          const productions = mapDetailsToProductions(response);
          dispatch({
            type: types.GET_PAST_SUCCESS,
            payload: {
              past: productions,
            }          
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_PAST_FAILURE });
          throw error;
        });
    };
  }

  export function listAllProductions() {
    return async (dispatch) => {
      return actionListProductions({
      })
        .then((response) => {
          const productions = mapDetailsToProductions(response);
          dispatch({
            type: types.GET_ALL_SUCCESS,
            payload: {
              all: productions,
            }          
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_ALL_FAILURE });
          throw error;
        });
    };
  }

  export function listLiveEnterpriseProductions() {
    return async (dispatch) => {
      return actionListProductions({
          current: true,
          bulk_performance: true
      })
        .then((response) => {
          const productions = mapDetailsToProductions(response);
          dispatch({
            type: types.GET_LIVE_ENTERPRISE_SUCCESS,
            payload: {
              live_enterprise: productions,
            }          
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_LIVE_FAILURE });
          throw error;
        });
    };
  }