import * as types from "_constants";

const initialState = {
    affiliates: [],
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        affiliates: []
      };
    case types.AFFILIATES_GET_SUCCESS:
      return {
        ...state,
        affiliates: actions.payload.affiliates,
      };
    case types.UPDATE_AFFILIATE_SUCCESS:
      return {
        ...state,
      }
    case types.REMOVE_AFFILIATE_SUCCESS:
      return {
        ...state,
      };   
    default:
      return state;
  }
}
