import * as types from "_constants";


const initialState = {
  formData: {
    name: "",
    meetings: [],
    cast: [],
    capacity: 200,
    capacity_livestream: 0,
    dates: [],
    legal_artwork_use: false,
    is_jr_question: false,
    deliveryFormat: "Live",
    fee_style: "exclude",
    viewing_at_venue: true,
    is_livestream: false,
    is_virtual: false,
    event_type: "general",
    suggested_donation_enabled: false,
    allow_refunds: false,
    limit_tickets_in_order: false,    
    venue: null,
    license: {
      licensor: {}
    },
    _show: "",
    show: {},
    virtual_cast: [],
    virtual_tiers: [],
  },
  livestreamOptions: {
    livestream_type: '',
    virtual_capacity: 0,
    livestreamTiers: [{}]
  },
  organization: {
    venues: [],
  },
  limit_tickets_in_order: false,
  limit_tickets_in_order_num: {},
  successMessage: '',
  formErrorMessage: '',
  changeStatusResponse: '',
  has_virtual_program_link: false,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.RESET_FORM_DATA:
      return initialState;
    case types.SET_DELIVERY_FORMAT:
      return {
        ...state,
        deliveryFormat: actions.deliveryFormat,
      };

    case types.SET_VENUE_VISIBLE:
      return {
        ...state,
        venueVisible: actions.venueVisible,
      }

    case types.SET_LIVESTREAM_VISIBLE:
      return {
        ...state,
        livestreamVisible: actions.livestreamVisible,
      }

    case types.GET_NAMES_SUCCESS:
      return {
        ...state,
        nameList: actions.nameList,
      }


    case types.CREATE_VENUE_SUCCESS:
      console.log('created a vnenue, in production producer', actions.venue)
      const existingVenues = state.organization.venues;
      existingVenues.push(actions.venue);
      console.log('all venues', existingVenues);
      return {
        ...state,
        organization: {
          ...state.organization,
          venues: [...existingVenues],
        },
        formData: {
          ...state.formData,
          venue: actions.venue,
        }
      };
    // commeenting because it doesnt make sense that the first venue is pre selected
    case types.GET_ORG_DATA_SUCCESS:
      const venues = actions.organization?.venues || [];
      const organization = Object.assign({}, actions.organization, { venues });
  
      return {
        ...state,
        organization,
        // formData: {
        //   ...state.formData,
        //   venue,
        // }
      }

    case types.GET_LICENSORS_SUCCESS:
      return {
        ...state,
        licensors: actions.licensors,
      }

    case types.SET_FORM_DATA:
      return {
        ...state,
        formData: Object.assign({}, state.formData, actions.data),
      }

    case types.SET_LIVESTREAM_OPTIONS_DATA:
      return {
        ...state,
        livestreamOptions: Object.assign({}, state.livestreamOptions, actions.data),
      }

    case types.SET_ADDRESS:
      return {
        ...state,
        formData: {
          ...state.formData,
          venue: Object.assign({}, state.formData.venue, actions.data),
        }
      };

    case types.GET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        formData: Object.assign({}, state.formData, actions.basicInfo),
      };

    case types.SUBMIT_FORMDATA_SUCCESS:
      return {
        ...state,
        production: Object.assign({}, actions.production)
      };

    case types.CLEAR_FORM_ERROR_MESSAGE:
      return {
        ...state,
        formErrorMessage: '',
      };
      
    case types.SUBMIT_FORMDATA_FAILURE:
      return {
        ...state,
        formErrorMessage: actions.formErrorMessage,
      };
    case types.TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          suppress_campaigns: actions.suppress_campaigns,
        }
      };
    case types.UPDATE_SOCIAL_DISTANCING_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          social_distancing_seat_distance_x: actions.payload.social_distancing_seat_distance_x
        }
      };
    case types.GET_VIRTUAL_DRAFT_INFO_SUCCESS:
      return {
        ...state,
        formData: Object.assign({}, state.formData, actions.info),
      };
    
    case types.REMOVE_PEFORMANCE_DATE_FROM_DRAFT_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          dates: state.production.dates.filter((p, index) => {
            return index !== actions.payload.index;
          }),
        }
      };

    case types.ADD_PEFORMANCE_DATE_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          dates: actions.payload.dates,
        }
      };
    case types.GET_PRODUCTION_BY_ID_SUCCESS:
      return {
        ...state,
        production: actions.production
      };

    case types.UPDATE_PRODUCTION:
      return {
        ...state,
        production: {
          ...actions.production,
          //this stuff doesnt come back from an update
          events: state.production?.events || actions.production.events,
          artix_events: state.production?.artix_events || actions.production.artix_events,
          eventStats: state.production?.eventStats || actions.production.eventStats,
        }
      };

    case types.UPDATE_PRODUCTION_VALUE:
      return {
        ...state,
        production: {
          ...state.production,
          [actions.data.key]: actions.data.value,
        }
      }

    case types.UPDATE_PRODUCTION_STYLE:
      return {
        ...state,
        production: {
          ...state.production,
          style: Object.assign({}, state.production.style, actions.data)
        }
      };

    case types.TOGGLE_STANDBY_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          website_visible: actions.website_visible
        }
      };


    case types.DELETE_DRAFT_REQUEST_SUCCESS:
      return {
        ...state,
        production: null
      };

    case types.TOGGLE_TICKETS_PURCHASABLE_REQUEST_SUCCESS:
      return {
        ...state,
        production: null
      };
  

    // Website editor
    case types.GET_THEMES_SUCCESS:
      return {
        ...state,
        themes: actions.themes,
      };
    case types.UPLOAD_IMAGE_THEME_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          style: {
            homeBackgroundImage: actions.payload.image,
          }
        }
      };

    // Livestream
    case types.UPDATE_GEOFENCING_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          geofencing_enabled: actions.payload.geofencing_enabled,
          geofencing_rules: actions.payload.geofencing_rules,
        }
      }
      
    // Show details
    case types.PRODUCTION_API_REQUEST_SUCCESS:
      return {
        ...state,
        successMessage: actions.successMessage
      };
    case types.UPDATE_TICKETS_PER_ORDER_LIMIT_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          limit_tickets_in_order: actions.payload.limit_tickets_in_order,
          limit_tickets_in_order_num: actions.payload.limit_tickets_in_order_num
        }
      }
    case types.ADD_DRAFT_TIER_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          tiers: actions.payload.tiers,
        }
      }
    case types.DELETE_DRAFT_TIER_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          tiers: actions.payload.tiers,
        }
      }
    case types.UPDATE_SALES_TAX_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          sales_tax_percent: actions.payload.sales_tax_percent,
        }
      }
    case types.UPDATE_FEES_SALES_TAX_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          fees_sales_tax_percent: actions.payload.fees_sales_tax_percent,
        }
      }
    case types.UPDATE_ADDONS_SALES_TAX_REQUEST_SUCCESS:
      return {
        ...state,
        production: {
          ...state.production,
          addons_sales_tax_percent: actions.payload.addons_sales_tax_percent,
        }
      }
    case types.UPDATE_SHORT_CODE:
      return {
        ...state,
        production: {
          ...state.production,
          short_code: actions.value,
        }
      }
    case types.UPDATE_ORDER_CONFIRMATION_MESSAGE_SUCCESS:
      console.log('message update success payload', actions.payload)
      return {
        ...state,
        production: {
          ...state.production,
          order_confirmation_message: actions.payload.order_confirmation_message,
        }
      }      
    case types.CHANGE_EVENTS_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusResponse: actions.payload.changeStatusResponse,
      };
    default:
      return state;
  }
}
