import axios from "_utils/axios";

export function listProductions(params) {
  return new Promise((resolve, reject) => {
    axios
      .get("/productions", {
          params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


