import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import Sidebar from "_components/Sidebar";
import FundSidebar from "_components/FundSidebar";
import Header from "_components/AppBar";
import Footer from "_components/Footer";
import ErrorBoundary from "_components/ErrorBoundary";
import { getLoggedInStatus } from "_redux/actions/authActions";

import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  Link,
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Announcement = styled.div`
  padding: 10px;
  text-align: center;
  display: block;
  width: 100%;
  background-color: #bd1c1e;
  color: white;
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const history = useHistory();

  const user = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  const dashboardVariant = localStorage.getItem('defaultDashboard') || 'core';

  const cb = () => history.push("/auth/sign-in");
  const newPasswordCb = () => history.push("/auth/create-password");

  useEffect(() => {
    dispatch(getLoggedInStatus(cb, newPasswordCb));
  }, [dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {user && dashboardVariant === 'core' && (
          <>
            <Drawer>
              <Hidden mdUp implementation="js">
                <Sidebar
                  routes={routes}
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              </Hidden>
              <Hidden smDown implementation="css">
                <Sidebar
                  routes={routes}
                  PaperProps={{ style: { width: drawerWidth } }}
                />
              </Hidden>
            </Drawer>
            <AppContent>
              {/* <Announcement>Welcome to your shiny new dashboard!  <br/> Have questions? <Link style={{"textDecoration": "underline"}} color="inherit" target="_blank" rel="noreferrer" href="https://intercom.help/on-the-stage/en">Click here</Link> to find the answers in our support center. </Announcement> */}
              <Header user={user} onDrawerToggle={handleDrawerToggle} />
              <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
                {children}
              </MainContent>
              <Footer />
            </AppContent>
          </>
        )}

      {user && dashboardVariant === 'fund' && (
          <>
            <Drawer>
              <Hidden mdUp implementation="js">
                <FundSidebar
                  routes={routes}
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              </Hidden>
              <Hidden smDown implementation="css">
                <FundSidebar
                  routes={routes}
                  PaperProps={{ style: { width: drawerWidth } }}
                />
              </Hidden>
            </Drawer>
            <AppContent>
              <Header user={user} onDrawerToggle={handleDrawerToggle} />
              <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
                {children}
              </MainContent>
              <Footer />
            </AppContent>
          </>
        )}

      </Root>
  );
};

export default withWidth()(Dashboard);
