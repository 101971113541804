import React from 'react';
import { useSelector } from 'react-redux';
import OffersForm from './OffersForm';

function EditOffer({ isOpen, toggleModal, selectedOfferId, offersList }) {
  const { offers } = useSelector((state) => state.offersReducer);
  const selectedOfferToEdit = offers.find(
    (offer) => offer.id === selectedOfferId
  );

  const initialFormValues = {
    name: selectedOfferToEdit?.name,
    applies_to: selectedOfferToEdit?.applies_to,
    rules: selectedOfferToEdit?.rules,
    img_url: selectedOfferToEdit?.img_url,
    SeriesId: selectedOfferToEdit?.SeriesId,
    per_ticket: selectedOfferToEdit?.per_ticket,
  }

  return (
    <OffersForm
      key={`offers-form-${isOpen}`}
      isOpen={isOpen}
      toggleModal={toggleModal}
      initialFormValues={initialFormValues}
      isEditing={true}
      selectedOfferId={selectedOfferId}
      offersList={offersList}
    />
  );
}

export default EditOffer;
