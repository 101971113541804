import * as types from "_constants";

const initialState = {
  stripeConnectAccountId: null,
  status: false,
  capabilities: false,
  loaded: false
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_STRIPE_CONNECT_ACCOUNT:
      return {
        ...state,
        stripeConnectAccountId: actions.payload.stripeConnectAccountId,
        status: actions.payload.status,
        capabilities: actions.payload.capabilities,
        loaded: true
      };
    default:
      return state;
  }
}
