import * as types from "_constants";
import {
  listNotifications as actionListNotifications,
  dismissNotification as actionDismissNotification
} from "_services/notificationService";

export function listNotifications(filter = null) {
  return async (dispatch) => {
    dispatch({ type: types.NOTIFICATIONS_LIST_GET_REQUEST });
    return actionListNotifications(filter)
      .then((response) => {
        dispatch({
          type: types.NOTIFICATIONS_LIST_GET_SUCCESS,
          payload: {
            notifications: response,
          }          
        });
      })
      .catch((error) => {
        dispatch({ type: types.NOTIFICATIONS_LIST_GET_FAILURE });
        throw error;
      });
  };
}
export function dismissNotification(notificationId, filter = null) {
  return async (dispatch) => {
    dispatch({ type: types.NOTIFICATIONS_DISMISS_REQUEST });
    return actionDismissNotification(notificationId)
      .then((response) => {
        return actionListNotifications(filter)
        .then((response) => {
          dispatch({
            type: types.NOTIFICATIONS_LIST_GET_SUCCESS,
            payload: {
              notifications: response,
            }          
          });
          dispatch({
            type: types.SHOW_NOTIFICATION,
            payload: {
              message: 'Notification dismissed',
            }
          })
        })
      })
      .catch((error) => {
        const message = error.response?.data?.error || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}