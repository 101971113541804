import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Button as MuiButton,
  Link as MuiLink
} from "@material-ui/core";
import {
  Add as AddIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@material-ui/icons';
import { Spacer } from "_components/common";
import { spacing } from "@material-ui/system";
import { makeStyles } from "@material-ui/core/styles";
import { updatePlaybillValue } from "_redux/actions/playbillActions";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Link = styled(MuiLink)(spacing);
const Button = styled(MuiButton)(spacing);
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff"
  }
}));

export default function ActComponent({ actIdx=0, act, actsList, status, page, setNewAct, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (e, mode, fieldType, actIdx, sceneIdx, songIdx) => {
    if (mode === "new") {
      if (fieldType === 'acts') {
        const newActsArray = [...actsList]; // making a new array
        newActsArray[actIdx][e.target.name] = e.target.value // changing value in the new array
        setNewAct(
          newActsArray
        )
      } 
      else if (fieldType === 'scenes') {
        const newActsArray = [...actsList]; // making a new array
        newActsArray[actIdx].scenes[sceneIdx][e.target.name] = e.target.value // changing value in the new array
        setNewAct(
          newActsArray
        )
      } 
      else if (fieldType === 'songs') {
        const newActsArray = [...actsList]; // making a new array
        newActsArray[actIdx].scenes[sceneIdx].songs[songIdx][e.target.name] = e.target.value // changing value in the new array
        setNewAct(
          newActsArray
        )
      }
    } else if (mode === "update") {
      updateActs(e.target.name, e.target.value, fieldType, actIdx, sceneIdx, songIdx);
    }
  }

  const updateActs = (field, value, fieldType, actIdx, sceneIdx, songIdx) => {
    const updatedActs = actsList.map((act, index) => {

      if (fieldType === 'acts' && index === actIdx) {
        actsList[actIdx][field] = value
      } else if (fieldType === 'scenes') {
        actsList[actIdx].scenes[sceneIdx][field] = value 
      } else if (fieldType === 'songs') {
        actsList[actIdx].scenes[sceneIdx].songs[songIdx][field] = value 
      }
      return act;
    })
    dispatch(updatePlaybillValue({
      key: "acts",
      value: updatedActs,
      page: page
    }))
  }

  const handleAddNewSong = (status, actIdx, sceneIdx) => {
   if (status === "new") {
     const newArr = [...actsList]
     newArr[actIdx].scenes[sceneIdx].songs.push({
          song_name: "",
          cast_member: ""
      });
     setNewAct(newArr)
   } 
   else if (status === "update") {
     const newArr = [...actsList]
     newArr[actIdx].scenes[sceneIdx].songs.push({
         song_name: "",
         cast_member: ""
     });
 
     dispatch(updatePlaybillValue({ 
       key: "acts", 
       value: newArr, 
       page: page
     }));
   }
 }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} key={actIdx}>
          <Typography mb={2} ml={3}>{status === "update" ? actIdx + 1 + '. Act' : 'New Act'}</Typography>
            <TextField
              label="Act"
              placeholder="Type here"
              id="filled-hidden-label-small"
              variant="outlined"
              name="act_title"
              fullWidth
              size="small"
              value={act.act_title}
              onChange={(e) => handleChange(e, status, "acts", actIdx)}
              mb={2}
              ml={3}
              style={{ width: 520 }}
              className={classes.root}
            />
        </Grid>
        <Spacer my={6} />
      </Grid>

      <Typography mb={4} mt={4} ml={3}>Scene</Typography>

      <Grid container style={{ backgroundColor: '#ffffff', width: 520, borderRadius: 5, padding: 30, margin: '0px 10px 10px 10px' }} justify="center" spacing={3}>
        { actsList[actIdx].scenes.map((scene, sceneIdx) => (
          <>

          <Grid container spacing={0}>
              <Grid item xs={11}>
                <TextField
                  label="Scene"
                  placeholder="Type here"
                  id="filled-hidden-label-small"
                  variant="outlined"
                  name="scene_title"
                  fullWidth
                  size="small"
                  value={scene.scene_title}
                  onChange={(e) => handleChange(e, status, "scenes", actIdx, sceneIdx)}
                  mb={3}
                  className={classes.root}
                />
              </Grid>

            <Grid item xs={1}>
              <Button 
                align="left" 
                variant="outlined" 
                color="primary" 
                aria-label="delete-icon"
                onClick={() => props.handleRemoveScenes(sceneIdx, actIdx)}
                style={{ minWidth: '10px', width: '15px', height: '40px', marginLeft: '7px'}}
              > 
                <DeleteOutlinedIcon style={{ fontSize: '24px'}} />
              </Button>
            </Grid>
            <Spacer my={6} />
          
            { actsList[actIdx].scenes[sceneIdx].songs.map((song, songIdx) => (
            <>
              <Grid container key={songIdx} spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    label="Song"
                    placeholder="Type here"
                    id="filled-hidden-label-small"
                    variant="outlined"
                    name="song_name"
                    fullWidth
                    size="small"
                    value={song.song_name}
                    onChange={(e) => handleChange(e, status, "songs", actIdx, sceneIdx, songIdx)}
                    className={classes.root}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Cast Member"
                    placeholder="Type here"
                    id="filled-hidden-label-small"
                    variant="outlined"
                    name="cast_member"
                    fullWidth
                    size="small"
                    value={song.cast_member}
                    onChange={(e) => handleChange(e, status, "songs", actIdx, sceneIdx, songIdx)}
                    className={classes.root}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button 
                    align="left" 
                    variant="outlined" 
                    color="primary" 
                    aria-label="delete-icon"
                    onClick={() => props.handleRemoveSongs(songIdx, sceneIdx, actIdx)}
                    style={{ minWidth: '10px', width: '15px', height: '40px'}}
                  > 
                    <DeleteOutlinedIcon style={{ fontSize: '24px'}} />
                  </Button>
                </Grid>
              </Grid>

              <Spacer my={6} />
          
              </>
            // end of songs map
          ))} 

              <Grid container alignContent="center" alignItems="center">
                <Grid item>
                  <AddIcon fontSize="small" color="primary" />
                </Grid>
                <Grid item>
                  <Link component="button" variant="body2" underline="none" mt={2} onClick={() => handleAddNewSong(status, actIdx, sceneIdx)}>
                    <b>Add Song</b>
                  </Link>
                  <Spacer my={3} />
                </Grid>
              </Grid>

          </Grid>

        </>
        // end of scenes map
      ))} 

      </Grid> 
    {/* end of scenes container */}

    <Grid container justify="flex-start" direction="row" spacing={6}>
      <Grid item>
        <Button 
          align="left" 
          variant="outlined" 
          color="primary" 
          aria-label="add-icon"
          onClick={() => props.handleAddNewScene(actIdx)} 
          startIcon={<AddIcon fontSize="small" />}
          ml={3}
        >
          Add Scene
        </Button>
      </Grid>
    </Grid>

    <Spacer my={6} />

    <Grid container justify="flex-start" direction="row" spacing={2}>
      <Grid item>
        <Button 
          align="left" 
          variant="outlined" 
          color="primary" 
          aria-label="save-icon"
          onClick={() => props.handleSave()} 
          startIcon={<SaveOutlinedIcon fontSize="small" />}
          ml={3}
        >
          Save
        </Button>
      </Grid>
      <Grid item>
        <Button 
          align="left" 
          variant="outlined" 
          color="primary" 
          aria-label="delete-icon"
          onClick={() => props.handleRemove(actIdx)}
          startIcon={<DeleteOutlinedIcon fontSize="small" />}
        >
          Delete Act
        </Button>
      </Grid>
    </Grid>
    <Spacer my={6} />
  </>
  )
}
