import {useState, React, useEffect } from 'react';
import styled from "styled-components/macro";
import { useDispatch } from 'react-redux';
import { updatePlaybill } from '_redux/actions/playbillActions';
import { openNotification } from '_redux/actions/appActions';
import TooltipSwitch from "_components/common/TooltipSwitch";
import {
  Grid,
  Button,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import {
  Spacer,
  SaveButtonComponent,
} from "_components/common"

const MainContainer = styled.div`
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
`; //style={{ padding: 20, border: '1px solid #eee', borderRadius: 4 }}

const Title = styled.h1`
  font-family: DM Sans, "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -1px;
  color: #323232;
`;

const MainText = styled.p`
  white-space: pre-line
`

const pageCopies = [
{
  page_name: 'cover',
  copy: `This page will appear on the front of your program (the second page is the inside cover and is blank by default). Click on the Edit button below to upload a new image, or to change how the Production Name, Organization Name, Address, and Dates of the production appear on the cover. Click the “Show” toggle above to hide this section from your PDF version.\n
    To populate this with information from your show site, choose “Import From Website” after clicking the Actions button above. Importing will overwrite the image with the main show website image and the text with the information on the show website.`
}, {
  page_name: 'about',
  copy: `Click on the Edit button below to add or change the message to your audience about the organization and the show. Click the “Show” toggle above to hide this section from your PDF version.\n
    To populate this with information from your show site, choose “Import From Website” after clicking the Actions button above. Importing will overwrite the text in these sections with the text from each section of your About website tab (no images, video, or formatting will be imported).`
}, {
  page_name: 'scenes',
  copy: `Click on the Edit button below to create or edit the Act, Scene, and Song list for your production. Click the “Show” toggle above to hide this section from your PDF version.\n
    To create a new Act, click Add Act and Add Scene for each scene in that Act. If necessary, add songs and the corresponding cast members by clicking Add Song within the appropriate scene. After you have edited information within an Act, click the Save button at the bottom of that section to apply your edits, then press the main Save button to leave the edit screen.`
},{
  page_name: 'cast',
  copy: `Click on the Edit button below to add or change information about your cast members. Click the “Show” toggle above to hide this section from your PDF version.\n
    To populate this with information from your show site, choose “Import From Website” after clicking the Actions button above. Importing will overwrite the names, pictures, roles, and bios in these sections with the same information in your Cast website tab.`
}, {
  page_name: 'creative',
  copy: `Click on the Edit button below to add or change information about your creative. Click the “Show” toggle above to hide this section from your PDF version.\n
    To populate this with information from your show site, choose “Import From Website” after clicking the Actions button above. Importing will overwrite the names, pictures, roles, and bios in these sections with the same information in your Creative website tab. Information on the original creative team is not imported and must be entered manually here.`
}, {
  page_name: 'shop',
  copy: `This section is not editable from this screen. All information in this section must be imported from your show website after you set up Merchandise on the Shop tab. Click the “Show” toggle above to hide this section from your PDF version. The PDF version of this page will include a link and QR code to your website Shop tab.\n
    To populate this with information from your show site, choose “Import From Website” after clicking the Actions button above. Importing will overwrite the item images, names, and prices with the same information in your Shop website tab.`
}, {
  page_name: 'sponsors',
  copy: `Click on the Edit button below to create or edit the sponsor images for your production. Click the “Show” toggle above to hide this section from your PDF version.\n
    To add a new sponsor image, click the Edit button below and click Add Another, then upload an image for the sponsor. When you are done adding sponsor images, press the main Save button to leave the edit screen.`
}, {
  page_name: 'New Section',
  copy: `Click on the edit button below to add text and images to this section. Click the “Show” toggle above to hide this section from your PDF version.`
}];

export default function PlaybillRightColComponent({ production, playbillPages, playbillPage, showPreview, setShowPreview }) {
  const[isLoading, setIsLoading] = useState(false);
  const[sponsorsPageLayout, setSponsorsPageLayout] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    handleUpdatePlaybill(false);
  }, [sponsorsPageLayout]);

  const handleShowPageSwitch = async () => {
    try {

      const updatedPages = playbillPages.map((page, index) => {
        if (page.slug === playbillPage.slug) {
            page["show_page"] = !page['show_page'];
        }
        return page;
      })
      dispatch(updatePlaybill(production.id, { pages: updatedPages}));
    } catch (err) {
      dispatch(openNotification({
        message: err.response?.data || err.message || "Something went wrong!",
      }));
    }
  }

  const handleUpdatePlaybill = async (setPreview = true) => {
    setIsLoading(true);
    try {
      const updatedPages = playbillPages.map((page, index) => {
        if (page.slug === playbillPage.slug) {
          if(playbillPage.slug === 'sponsors' && sponsorsPageLayout !== '') {
            page.page_layout = sponsorsPageLayout;
          }
          setIsLoading(false);
          return playbillPage;
        } 

        setIsLoading(false);
        return page;
      })

      dispatch(updatePlaybill(production.id, { pages: updatedPages}));
      if(setPreview) {
        setShowPreview(true)
      } else {
        playbillPages = updatedPages;
      }
    } catch (err) {
      dispatch(openNotification({
        message: err.response?.data || err.message || "Something went wrong!",
      }));
    }
    setIsLoading(false);
  }

  const updateSponsorsLayout = async (value) => {
    setSponsorsPageLayout(value)
  }

  const layoutOptions = [
    { label: 'Full Image', value: 'full_image' },
    { label: '2 Images', value: '2_images' },
    { label: '4 Images', value: '4_images' },
    { label: '8 Images', value: '8_images' },
  ];

  const capitalizedSlug = playbillPage.slug.charAt(0).toUpperCase() + playbillPage.slug.slice(1)
  return (
      <div style={{padding: '20px'}}>
        <Grid container spacing={0} justify="space-evenly" alignItems="center">
          <Grid item>
            <Button disabled={showPreview} variant="contained" color="primary" size="large" onClick={() => setShowPreview(true)}>
              Preview
            </Button>
          </Grid>
        </Grid>

        <Spacer my={6} />

        <Grid container spacing={6} justify="space-between" alignItems="center">
          <Grid item>
            <Title>{ playbillPage ? capitalizedSlug : "" } </Title>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <TooltipSwitch 
                  checked={playbillPage.show_page} 
                  handleCheck={handleShowPageSwitch} 
                  name="show Showgram page"
                  placement="left"
                 />
              }
            />
          </Grid>
        </Grid>

        { pageCopies.map((page) => (
          <MainText mt={4} mb={4}>
            {page.page_name === playbillPage.slug && page.copy}
          </MainText>
        ))}
        <MainText mt={4} mb={4}>
          {playbillPage.slug === 'cover' || playbillPage.slug === 'about' || playbillPage.slug === 'cast' || playbillPage.slug === 'creative' || playbillPage.slug === 'scenes' || playbillPage.slug === 'sponsors' || playbillPage.slug === 'shop' ? 
            ("") : ("Click on the edit button below to add text and images to this section. Click the “Show” toggle above to hide this section from your PDF version.")
          }
        </MainText>

        {(playbillPage.slug === 'sponsors' && !showPreview) && (
          <>
          <Typography variant="h5">Select a Layout</Typography> 
          <Grid container spacing={2} my={3} style={{margin: '5px -8px' }}>
            {layoutOptions.map((option) => (
              <Grid item key={option.value} style={{marginRight: '10px'}}>
                <Button
                  variant={sponsorsPageLayout === option.value ? 'contained' : 'outlined'}
                  color={sponsorsPageLayout === option.value ? 'primary' : 'default'}
                  onClick={() => updateSponsorsLayout(option.value)}
                  style={{ backgroundColor: sponsorsPageLayout === option.value ? 'red' : 'initial' }}
                >
                  {option.label}
                </Button>
              </Grid>
            ))}
          </Grid>
          </>
        )} 
        {
          !playbillPage.disable_edit &&
            <Grid container spacing={3} justify="flex-start" alignItems="center">
            { showPreview ? (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={() => setShowPreview(false)}>
                  Edit
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                <SaveButtonComponent
                  onClick={() => handleUpdatePlaybill()}
                  label="Save"
                  isLoading={isLoading}
                  disabled={isLoading}
                />
                </Grid>
              </>
            )}
          </Grid>          
        }

      </div>
  )
};