import * as types from "_constants";

const initialState = {
  rows: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        rows: []
      };
    case types.GET_COMMUNICATION_EMAILS_REQUEST_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };
    case types.UPDATE_COMMUNICATION_EMAIL_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };
    case types.DELETE_COMMUNICATION_EMAIL_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };
    case types.CREATE_COMMUNICATION_EMAIL_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };
    default:
      return state;
  }
}
