import * as types from "_constants";

const initialState = {
  eventTierModifiers: [],
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.CREATE_EVENT_TIER_MODIFIER_SUCCESS:
      return {
        ...state,
        eventTierModifiers: [...state.eventTierModifiers, actions.payload.eventTierModifier],
      };
    case types.GET_EVENT_TIER_MODIFIERS_SUCCESS:
      return {
        ...state,
        eventTierModifiers: actions.payload.eventTierModifiers,
      };
    case types.UPDATE_EVENT_TIER_MODIFIER_SUCCESS: 
      const updatedEventTierModifiers = state.eventTierModifiers.map((eventTierModifier) => {
        if ((eventTierModifier.name === actions.payload.eventTierModifier.name) || (eventTierModifier.id === actions.payload.eventTierModifier.id)) {
          return actions.payload.eventTierModifier;
        }
        return eventTierModifier;
      })
      return {
        ...state,
        eventTierModifiers: [...updatedEventTierModifiers],
      }
    case types.DELETE_EVENT_TIER_MODIFIER_SUCCESS:
      const remainingTierModifiers = state.eventTierModifiers.filter(eventTierModifier => eventTierModifier.id !== actions.payload.eventTierModifierId);
      return {
        ...state,
        eventTierModifiers: remainingTierModifiers,
      };      
    default:
      return state;
  }
}
