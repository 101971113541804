// format: %s = symbol, %v = value
export const currencies = {
  "usd": {
    "name": "US Dollar",
    "code": 'usd',
    "symbol": "$",
    "format": "%s%v",
    "decimal": ".",
    "thousand": ",",
    "precision": 2,
    "recommendedFee": 2,
    "weekStartsOn": 0
  },
  "cad": {
    "name": "Canadian Dollar",
    "code": 'cad',
    "symbol": "$",
    "format": "%s%v",
    "decimal": ".",
    "thousand": ",",
    "precision": 2,
    "recommendedFee": 2,
    "weekStartsOn": 0
  },
  "eur": {
    "name": "Euro",
    "code": 'eur',
    "symbol": "€",
    "format": "%s%v",
    "decimal": ",",
    "thousand": ".",
    "precision": 2,
    "recommendedFee": 2,
    "weekStartsOn": 1
  },
  "gbp": {
    "name": "British Pound",
    "code": 'gbp',
    "symbol": "£",
    "format": "%s%v",
    "decimal": ".",
    "thousand": ",",
    "precision": 2,
    "recommendedFee": 2,
    "weekStartsOn": 1
  }
}