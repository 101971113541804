import * as types from "_constants";

const initialState = {
  customFlows: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_CUSTOM_FLOWS_SUCCESS:
      return {
        ...state,
        customFlows: actions.payload.data
      }
    case types.UPDATE_CUSTOM_FLOWS_SUCCESS:
      const updatedFlow = actions.payload.data;
      return {
        ...state,
        customFlows: state.customFlows.map((flow) =>
          flow.id === updatedFlow.id ? { ...flow, ...updatedFlow } : flow
        ),
      };      
    case types.SAVE_CUSTOM_FLOWS_SUCCESS:
      return {
        ...state,
        customFlows: [...state.customFlows, actions.payload.data]
      }
    default:
      return state;
  }
}

