import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  IconButton,
  Tooltip,
} from "@material-ui/core";

import {
  FileCopy as FileCopyIcon
} from "@material-ui/icons";

function CopyToClipBoard({ textToCopy, color }) {
  const [text, setText] = useState("Copy link to clipboard")

  const onCopyText = () => {
    setText("Copied!");
    setTimeout(() => {
      setText("Copy link to clipboard");
    }, 2000);
  };

  return (
    <CopyToClipboard text={textToCopy} onCopy={onCopyText}>
      <Tooltip title={text}>
        <IconButton color="inherit">
          <FileCopyIcon
            style={{
              color: color,
            }}
            fontSize="small"
            color={color}
          />
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  )
}

export default CopyToClipBoard;
