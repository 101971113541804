import React, { useEffect, useState } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { PDFDocument, degrees } from "pdf-lib";
import qz from "qz-tray";
import { useSelector } from "react-redux";
import axios from "_utils/axios";
import Tickets from "./Tickets";
import AllTickets from "./AllTickets";
import { Button, TextField } from "_components/common";
import { fromByteArray } from "base64-js";
import {
  FormControl,
  InputLabel,
  Radio,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import CircularProgressLoader from "_components/common/CircularProgressLoader";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PrintTickets = ({ order, tickets, sample = false }) => {
  const [printer, setPrinter] = useState(
    localStorage?.getItem?.("printer") ?? ""
  );
  const [pdfUrls, setPdfUrls] = useState({});
  const [printers, setPrinters] = useState(
    JSON.parse(localStorage?.getItem?.("printers")) ?? []
  );

  const defaultSettings = {
    size: { height: 5.5, width: 2 },
    units: "in",
    orientation: "portrait",
    altFontRendering: false,
    rotating: 1,
    interpolation: "nearest-neighbor",
    toolbar: false,
    maxProdNameLength: 50,
  };

  const loadSettings = () => {
    const savedSettings = localStorage.getItem("HardTicketSettings");
    return savedSettings ? JSON.parse(savedSettings) : defaultSettings;
  };

  const loadSignature = () => {
    qz.security.setCertificatePromise(function (resolve, reject) {
      fetch("/digital-certificate.txt", {
        cache: "no-store",
        headers: { "Content-Type": "text/plain" },
      }).then(async function (data) {
        if (data.ok) {
          const cert = await data.text();
          resolve(cert);
        } else {
          resolve(data);
        }
      });
    });

    qz.security.setSignatureAlgorithm("SHA512");
    //
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        axios
          .post(
            `/organizations/${organization.artix_id}/qz-certificate?requestToSign=` +
              toSign,
            {}
          )
          .then(function (response) {
            resolve(response.data);
          })
          .catch((err) => {
            console.log({ err });
            reject(err);
          });
      };
    });
  };

  const [settings, setSettings] = useState(loadSettings);

  useEffect(() => {
    localStorage.setItem("HardTicketSettings", JSON.stringify(settings));
  }, [settings]);

  const [showSettings, setShowSettings] = useState(false);
  const organization = useSelector(
    (state) => state.organizationReducer.organization
  );

  useEffect(() => {
    const isActive = qz?.websocket?.isActive();

    if (!isActive) {
      loadSignature();
      qz?.websocket
        ?.connect()
        ?.then(async () => {
          console.log("Connected to QZ Tray!");

          const printers = (await qz?.printers?.find()) ?? [];

          if (printers.length > 0) {
            setPrinters(printers);

            localStorage.setItem("printers", JSON.stringify(printers));
          }
        })
        .catch((err) => console.error("Error connecting to QZ Tray:", err));
    }
  }, []);

  const handleUpdateTicketStatus = async (ticketId) => {
    try {
      axios.put(`/organizations/${organization.artix_id}/ticket-print`, {
        id: ticketId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [type, setType] = useState("one-pdf");
  const [pdfUrl, setPdfUrl] = useState(null);

  async function rotatePdf(input) {
    try {
      let pdfBytes;

      if (typeof input === "string" && input.startsWith("data:")) {
        const base64Data = input.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        pdfBytes = new Uint8Array(byteNumbers);
      } else if (typeof input === "string") {
        const existingPdfBytes = await fetch(input).then((res) =>
          res.arrayBuffer()
        );
        pdfBytes = new Uint8Array(existingPdfBytes);
      } else {
        throw new Error("Invalid input type");
      }

      const pdfDoc = await PDFDocument.load(pdfBytes);

      const pages = pdfDoc.getPages();
      await rotatePagesRecursively(pages, 0, settings.rotating);

      async function rotatePagesRecursively(pages, index, rotationCount) {
        if (index >= pages.length) {
          return;
        }

        const totalRotation = degrees(90 * rotationCount);
        await pages[index].setRotation(totalRotation);

        await new Promise((resolve) => setTimeout(resolve, 100));

        await rotatePagesRecursively(pages, index + 1, rotationCount);
      }

      const rotatedPdfBytes = await pdfDoc.save();

      const base64String = fromByteArray(new Uint8Array(rotatedPdfBytes));

      return base64String;
    } catch (error) {
      console.error("Error rotating PDF: ", error);
    }
  }

  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  async function handlePdfRotation(url, rotationCount) {
    try {
      const rotatedPdfBase64 = await rotatePdf(url, rotationCount);
      const blob = base64ToBlob(rotatedPdfBase64, "application/pdf");
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error rotating PDF: ", error);
      return null;
    }
  }
  const [rotatedPdfUrl, setRotatedPdfUrl] = useState(null);
  useEffect(() => {
    async function fetchData() {
      if (pdfUrl) {
        const rotatedUrl = await handlePdfRotation(pdfUrl, 1); // rotate by 90 degrees
        setRotatedPdfUrl(rotatedUrl);
      }
    }
    fetchData();
  }, [pdfUrl]);

  const printPdf = async (base64PdfContent, tickets) => {

    if (!printer) {
      alert("Printer not found. Make sure QZ Tray is running.");
      return;
    }

    const config = qz.configs.create(printer, {
      size: settings.size,
      units: settings.units,
      orientation: settings.orientation,
      interpolation: settings.interpolation,
    });
    const data = [
      {
        type: "pixel",
        format: "pdf",
        flavor: "base64",
        data: base64PdfContent,
        options: { altFontRendering: settings.altFontRendering },
      },
    ];
    await qz
      .print(config, data)
      .then(() => {
        if (tickets.length > 0 && !sample) handleUpdateTicketStatus(tickets);
      })
      .catch((err) => console.error("Error printing:", err));
  };

  const printPatchTickets = async (order) => {
    for (const ticketId in pdfUrls) {
      if (pdfUrls.hasOwnProperty(ticketId)) {
        const rotatedPdfUrl = await rotatePdf(pdfUrls[ticketId]);
        await printPdf(rotatedPdfUrl, [
          {
            TicketId: +ticketId,
            OrderId: order,
          },
        ]);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
        }}
      >
        <>
          {(tickets && tickets.length > 0) ||
          (order && order.tickets.length > 0) ? (
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                bottom: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  width: "100%",
                  justifyContent: "end",
                  alignItems: "center",
                  columnGap: "30px",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => setShowSettings(!showSettings)}
                >
                  Settings
                </div>

                <FormControl
                  width={200}
                  style={{
                    width: "300px",
                  }}
                  variant="outlined"
                  my={2}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select a printer
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    onChange={(e) => {
                      setPrinter(e.target.value);
                      localStorage.setItem("printer", e.target.value);
                    }}
                    value={printer}
                    label="RSelect a printer"
                  >
                    <MenuItem value="">None</MenuItem>
                    {printers.map((printer) => (
                      <MenuItem key={printer} value={printer}>
                        {printer}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* <Grid item xs={12}>
                <RadioGroup
                  row
                  name="applies_to"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio value={"one-pdf"} name="one-pdf" color="primary" />
                    }
                    label="One PDF"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        value={"patch-pdf"}
                        name="patch-pdf"
                        color="primary"
                      />
                    }
                    label="Patch PDF"
                  />
                </RadioGroup>
              </Grid> */}

              {showSettings ? (
                <div
                  style={{
                    height: "4.25in",
                    width: "6in",
                    overflow: "hidden",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: 20,
                    }}
                  >
                    <TextField
                      type="number"
                      name="width"
                      label="Width"
                      width="33.3%"
                      value={settings.size.width}
                      variant="outlined"
                      onChange={(e) => {
                        setSettings({
                          ...settings,
                          size: {
                            ...settings.size,
                            width: e.target.value,
                          },
                        });
                      }}
                      my={3}
                    />

                    <TextField
                      type="number"
                      name="height"
                      label="Height"
                      width="33.3%"
                      value={settings.size.height}
                      variant="outlined"
                      onChange={(e) => {
                        setSettings({
                          ...settings,
                          size: {
                            ...settings.size,
                            height: e.target.value,
                          },
                        });
                      }}
                      my={3}
                    />
                    <FormControl
                      variant="outlined"
                      width="50%"
                      style={{
                        width: "33.3%",
                      }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select a units
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            units: e.target.value,
                          });
                        }}
                        value={settings.units}
                        label="RSelect a units"
                      >
                        <MenuItem value="cm">Cm</MenuItem>
                        <MenuItem value="in">In</MenuItem>
                        <MenuItem value="px">Px</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      columnGap: 20,
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      width="50%"
                      style={{
                        width: "33.3%",
                      }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Select a orientation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            orientation: e.target.value,
                          });
                        }}
                        value={settings.orientation}
                        label="RSelect a orientation"
                      >
                        <MenuItem value="null">null</MenuItem>
                        <MenuItem value="portrait">portrait</MenuItem>
                        <MenuItem value="landscape">landscape</MenuItem>
                        <MenuItem value="reverse-landscape">
                          reverse-landscape
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      width="50%"
                      style={{
                        width: "33.3%",
                      }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Rotating
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            rotating: e.target.value,
                          });
                        }}
                        value={settings.rotating}
                        label="RSelect a rotating"
                      >
                        <MenuItem value="0">0</MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      type="number"
                      name="maxProdNameLength"
                      label="Max prod name length"
                      width="33.3%"
                      value={settings.maxProdNameLength}
                      variant="outlined"
                      onChange={(e) => {
                        setSettings({
                          ...settings,
                          maxProdNameLength: e.target.value,
                        });
                      }}
                      my={3}
                    />

                    
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      columnGap: 20,
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      width="50%"
                      style={{
                        width: "33.3%",
                      }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Interpolation
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          setSettings({
                            ...settings,
                            interpolation: e.target.value,
                          });
                        }}
                        value={settings.interpolation}
                        label="Interpolation"
                      >
                        <MenuItem value="null">Null</MenuItem>
                        <MenuItem value="nearest-neighbor">
                          Nearest Neighbor
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings.toolbar}
                          onChange={(e) => {
                            setSettings({
                              ...settings,
                              toolbar: e.target.checked,
                            });
                          }}
                          name="toolbar"
                          color="primary"
                        />
                      }
                      label="PDF Toolbar"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={settings.altFontRendering}
                          onChange={(e) => {
                            setSettings({
                              ...settings,
                              altFontRendering: e.target.checked,
                            });
                          }}
                          name="altFontRendering"
                          color="primary"
                        />
                      }
                      label="Alt Font Rendering?"
                    />
                  </div>
                </div>
              ) : type === "one-pdf" ? (
                <div
                  style={{
                    height: "4.25in",
                    width: "6in",
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                >
                  <BlobProvider
                    document={<AllTickets tickets={tickets} order={order} maxProdNameLength={settings.maxProdNameLength} />}
                  >
                    {({ blob, url, loading, error }) => {
                      if (loading) {
                        return (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              border: "1px solid black",
                              backgroundColor: "gray",
                            }}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                width: "100%",
                                marginTop: "20px",
                              }}
                            >
                              <CircularProgressLoader />
                            </p>
                          </div>
                        );
                      }
                      if (error) {
                        console.error("Error generating PDF: ", error);
                        return <div>Error!</div>;
                      }

                      setPdfUrl(url);
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                          }}
                        >
                          <iframe
                            src={settings.toolbar ? url : `${url}#toolbar=0`}
                            style={{ height: "4.25in", width: "5.7in" }}
                            title="PDF"
                          />
                        </div>
                      );
                    }}
                  </BlobProvider>
                </div>
              ) : (
                <Tickets
                  order={order}
                  tickets={tickets}
                  setPdfUrls={setPdfUrls}
                  pdfUrls={pdfUrls}
                  maxProdNameLength={settings.maxProdNameLength}
                />
              )}
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  mt={2}
                  ml={2}
                  disabled={!printer && !showSettings}
                  onClick={async () => {
                    if (showSettings) {
                      setShowSettings(false);
                    } else {
                      if (type === "patch-pdf") {
                        await printPatchTickets(
                          order ? order?.id : tickets.Order?.id ?? null
                        );
                        setPdfUrl(null);
                      } else if (type === "one-pdf") {
                        const rotatedPdfUrl = await rotatePdf(pdfUrl);
                        if (order) {
                          await printPdf(
                            rotatedPdfUrl,
                            order?.tickets?.map((ticket, index) => {
                              return {
                                TicketId: ticket?.id ? +ticket.id : null,
                                OrderId: ticket?.OrderId
                                  ? ticket?.OrderId
                                  : ticket?.Order?.id
                                  ? ticket?.Order?.id
                                  : null,
                              };
                            })
                          );
                          setPdfUrl(null);
                        } else if (tickets) {
                          await printPdf(
                            rotatedPdfUrl,
                            tickets?.map((ticket, index) => {
                              return {
                                TicketId: ticket?.id ? +ticket.id : null,
                                OrderId: ticket?.OrderId
                                  ? ticket?.OrderId
                                  : ticket?.Order?.id
                                  ? ticket?.Order?.id
                                  : null,
                              };
                            })
                          );
                          setPdfUrl(null);
                        }
                      }
                    }
                  }}
                >
                  {showSettings ? "Close" : "Print"}
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                position: "absolute",
                top: "50%",
              }}
            >
              <h1>There are no tickets to print.</h1>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default PrintTickets;