import * as types from "_constants";

const initialState = {
  futureTransactions: [],
  futureTransactionsTotal: 0,
  currentTransactions: [],
  currentTransactionsTotal: 0,
  receivableTransactions: [],
  receivableTransactionsAmount: 0
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.LEDGER_GET_REQUEST:
      return {
        ...state,
        futureTransactions: [],
        futureTransactionsTotal: 0,
        currentTransactions: [],
        currentTransactionsTotal: 0,
        receivableTransactions: [],
        receivableTransactionsAmount: 0
      };
    case types.LEDGER_GET_SUCCESS:

      const transactions = actions.payload.rows;
      var today = new Date();
      const futureTransactions = [];
      let futureTransactionsTotal = 0;
      const currentTransactions = [];
      let currentTransactionsTotal = 0;
  
      const receivableTransactions = [];
      let receivableTransactionsAmount = 0;
      const invoicableTransactions = ['adjustment', 'invoice-merch', 'invoice-ticket', 'feature', 'invoice-cc-flat-fees', 'invoice-donations', 'invoice-refund-cc-fee', 'sales-tax-tickets', 'streaming-fee', 'pre-payment', 'cc-dispute-chargeback', 'transfer', 'invoice-ticket-refund'];
  
      transactions.forEach(function (t) {
        var transDate = new Date(t.transaction_date);
        if (transDate > today) {
          futureTransactions.push(t);
          futureTransactionsTotal += t.amount;
        } else {
          currentTransactions.push(t);        
          if (!t.PaymentLedgerId && invoicableTransactions.indexOf(t.transaction_type) >= 0) {
            receivableTransactions.push(t);
            receivableTransactionsAmount += t.amount;
          }
          currentTransactionsTotal += t.amount;
        }
      });
          
      return {
        ...state, 
        futureTransactions,
        futureTransactionsTotal,
        currentTransactions,
        currentTransactionsTotal,
        receivableTransactions,
        receivableTransactionsAmount,
      };    
    default:
      return state;
  }
}
