import * as types from "_constants";

export function openDialog(payload) {
  return async (dispatch) => {
    dispatch({ type: types.OPEN_DIALOG, payload });
  };
}

export function hideDialog() {
  return async (dispatch) => {
    dispatch({ type: types.HIDE_DIALOG });
  };
}

export function openNotification(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_NOTIFICATION, payload });
  };
}

export function hideNotification() {
  return async (dispatch) => {
    dispatch({ type: types.HIDE_NOTIFICATION });
  };
}


export function showWaiting(payload) {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_WAITING, payload });
  };
}

export function closeWaiting() {
  return async (dispatch) => {
    dispatch({ type: types.CLOSE_WAITING });
  };
}