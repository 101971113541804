// Orders
export const SEARCH_ORDERS_REQUEST = "SEARCH_ORDERS_REQUEST";
export const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
export const SEARCH_ORDERS_FAILURE = "SEARCH_ORDERS_FAILURE";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const REFUND_ORDER_REQUEST = "REFUND_ORDER_REQUEST";
export const REFUND_ORDER_SUCCESS = "REFUND_ORDER_SUCCESS";
export const REFUND_ORDER_FAILURE = "REFUND_ORDER_FAILURE";

export const MARK_ORDER_FOR_DONATION_REQUEST = "MARK_ORDER_FOR_DONATION_REQUEST";
export const MARK_ORDER_FOR_DONATION_SUCCESS = "MARK_ORDER_FOR_DONATION_SUCCESS";
export const MARK_ORDER_FOR_DONATION_FAILURE = "MARK_ORDER_FOR_DONATION_FAILURE";

export const UPDATE_ORDER_PHONE_REQUEST = "UPDATE_ORDER_PHONE_REQUEST";
export const UPDATE_ORDER_PHONE_SUCCESS = "UPDATE_ORDER_PHONE_SUCCESS";
export const UPDATE_ORDER_PHONE_FAILURE = "UPDATE_ORDER_PHONE_FAILURE";

export const UPDATE_ORDER_EMAIL_REQUEST = "UPDATE_ORDER_EMAIL_REQUEST";
export const UPDATE_ORDER_EMAIL_SUCCESS = "UPDATE_ORDER_EMAIL_SUCCESS";
export const UPDATE_ORDER_EMAIL_FAILURE = "UPDATE_ORDER_EMAIL_FAILURE";

export const UPDATE_ORDER_COMMENT_REQUEST = "UPDATE_ORDER_COMMENT_REQUEST";
export const UPDATE_ORDER_COMMENT_SUCCESS = "UPDATE_ORDER_COMMENT_SUCCESS";
export const UPDATE_ORDER_COMMENT_FAILURE = "UPDATE_ORDER_COMMENT_FAILURE";

export const UPDATE_ORDER_NAME_REQUEST = "UPDATE_ORDER_NAME_REQUEST";
export const UPDATE_ORDER_NAME_SUCCESS = "UPDATE_ORDER_NAME_SUCCESS";
export const UPDATE_ORDER_NAME_FAILURE = "UPDATE_ORDER_NAME_FAILURE";

export const RESEND_ORDER_EMAIL_REQUEST = "RESEND_ORDER_EMAIL_REQUEST";
export const RESEND_ORDER_EMAIL_SUCCESS = "RESEND_ORDER_EMAIL_SUCCESS";
export const RESEND_ORDER_EMAIL_FAILURE = "RESEND_ORDER_EMAIL_FAILURE";

export const RESEND_ORDER_SMS_REQUEST = "RESEND_ORDER_SMS_REQUEST";
export const RESEND_ORDER_SMS_SUCCESS = "RESEND_ORDER_SMS_SUCCESS";
export const RESEND_ORDER_SMS_FAILURE = "RESEND_ORDER_SMS_FAILURE";

export const EXCHANGE_TICKETS_REQUEST = "EXCHANGE_TICKETS_REQUEST";
export const EXCHANGE_TICKETS_SUCCESS = "EXCHANGE_TICKETS_SUCCESS";
export const EXCHANGE_TICKETS_FAILURE = "EXCHANGE_TICKETS_FAILURE";

export const GET_ORDERS_FOR_EVENT_REQUEST = "GET_ORDERS_FOR_EVENT_REQUEST";
export const GET_ORDERS_FOR_EVENT_SUCCESS = "GET_ORDERS_FOR_EVENT_SUCCESS";
export const GET_ORDERS_FOR_EVENT_FAILURE = "GET_ORDERS_FOR_EVENT_FAILURE";

export const MARK_ORDER_PURCHASED_REQUEST = "MARK_ORDER_PURCHASED_REQUEST";
export const MARK_ORDER_PURCHASED_SUCCESS = "MARK_ORDER_PURCHASED_SUCCESS";
export const MARK_ORDER_PURCHASED_FAILURE = "MARK_ORDER_PURCHASED_FAILURE";

export const GET_ORDER_VOD_ACTIVATION_REQUEST = "GET_ORDER_VOD_ACTIVATION_REQUEST";
export const GET_ORDER_VOD_ACTIVATION_SUCCESS = "GET_ORDER_VOD_ACTIVATION_SUCCESS";
export const GET_ORDER_VOD_ACTIVATION_FAILURE = "GET_ORDER_VOD_ACTIVATION_FAILURE";

export const RESET_ORDER_VOD_ACTIVATION_REQUEST = "RESET_ORDER_VOD_ACTIVATION_REQUEST";
export const RESET_ORDER_VOD_ACTIVATION_SUCCESS = "RESET_ORDER_VOD_ACTIVATION_SUCCESS";
export const RESET_ORDER_VOD_ACTIVATION_FAILURE = "RESET_ORDER_VOD_ACTIVATION_FAILURE";