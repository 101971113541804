import * as types from "_constants";

const initialState = {
  contacts: [],
  newContacts: [],
  actionableContacts: [],
  detail: {
    currentPhase: {}
  },
  personalInfo: {},
  contactInfo: {},
  contactLog: {},
  contactLogs: [],
  sources: [],
  canceledSubscriptionInfo: {},
  customizableFields: {},
  contactAttributes: {},
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        contacts: []
      };
    case types.CONTACTS_GET_SUCCESS:
      return {
        ...state,
        contacts: actions.payload.contacts,
      };
    case types.REMOVE_CONTACT_SUCCESS:
      const updatedContacts = state.contacts.filter(row => row.id !== actions.payload.contactId);
      return {
        ...state,
        contacts: updatedContacts,
      };      
    case types.GET_CONTACT_SUCCESS:
      return {
          ...state,
          detail: actions.payload.detail,
          contactLogs: actions.payload.detail.ContactLogs
      };
    case types.GET_CONTACT_ATTRIBUTES_SUCCESS:
      return {
          ...state,
          contactAttributes: actions.payload.contact_attributes,
      };
    case types.GET_NEW_CONTACTS_SUCCESS:
      return {
          ...state,
          newContacts: actions.payload.contacts,
      };
    case types.GET_ACTIONABLE_CONTACTS_SUCCESS:
      return {
          ...state,
          actionableContacts: actions.payload.contacts,
      };
    case types.GET_CONTACT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionInfo: actions.payload.subscription
      };
      case types.UPDATE_CONTACT_SUCCESS:
        return {
          ...state,
          detail: {
            ...state.detail,
            ...actions.payload.contact
          },
        }
    case types.CONTACT_LOGS_GET_SUCCESS:
      return {
          ...state,
          contactLogs: actions.payload.contactLogs,
        };
    case types.ADD_CONTACT_LOG_SUCCESS:
      return {
          ...state,
          contactLogs: [...state.contactLogs, actions.payload.contactLog],
      };
    
    case types.UPDATE_CONTACT_LOG_SUCCESS: 
      const updatedLogs = state.contactLogs.map((log) => {
        if (log.id === actions.payload.contactLog.id) {
          return actions.payload.contactLog;
        }
        return log;
      })
      return {
        ...state,
        contactLogs: [...updatedLogs],
      }
    case types.CONTACTS_SOURCES_GET_SUCCESS:
      return {
        ...state,
        sources: actions.payload.sources,
      };
    case types.CANCEL_CONTACT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        canceledSubscriptionInfo: {
            ...state,
            ...actions.payload.subscription
      }
    };
    default:
      return state;
  }
}
