import React from "react";

import { useSelector} from "react-redux";
import styled from "styled-components/macro";


import {
  Container as MuiContainer
} from "@material-ui/core";

const Container = styled(MuiContainer)`
  border: 2px dotted ${(props) => props.theme.palette.common.main};
  padding: 5px;

`

function ShowAdminRoles({ roles, children }) {
  const user = useSelector((state) => state.authReducer.user);
  const isShowing = user.is_admin && user && (user.roles.some(role => roles.indexOf(role) > -1));
  return (
    isShowing ? (
      <>{children}</>
    ) : null
  )
}

function ShowRoles({ roles, children }) {
  const user = useSelector((state) => state.authReducer.user);
  const isShowing = user && (user.is_admin || user.roles.some(role => roles.indexOf(role) > -1));
  return (
    isShowing ? (
      <>{children}</>
    ) : null
  )
}

function AdminView({ children, ...props}) {  
  const user = useSelector((state) => state.authReducer.user);
  return (
    user && user.is_admin ?
      <Container {...props}>
        {children}
      </Container>
      : null
  );
}

function AdminOnly({ children, ...props}) {  
  const user = useSelector((state) => state.authReducer.user);
  return (
    user && user.is_admin &&
      <div {...props}>
        {children}
      </div>
  );
}

function DraftOnly({ status, children }) {  
  return (
    <>
      {status === 'draft' && children}
    </>
  );
}

function LiveOnly({ status, children }) {  
  return (
    <>
      {status === 'current' && children}
    </>
  );
}

function ShowTypeView({ children, ...props}) {  
  return (
    props.show_type !== "registration" ?
      <div {...props}>
        {children}
      </div>
      : null
  );
}

export { AdminView, ShowRoles, DraftOnly, LiveOnly, AdminOnly, ShowTypeView, ShowAdminRoles };
export default ShowRoles;
