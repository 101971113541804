import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { updatePlaybillValue } from "_redux/actions/playbillActions";
import { openNotification, openDialog } from "_redux/actions/appActions";
import PlaybillMemberForm from './PlaybillMemberForm';
import {
  Grid,
  Typography as MuiTypography,
  Link as MuiLink,
  TextField as MuiTextField,
  Button as MuiButton
} from "@material-ui/core";
import {
  Add as AddIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  AccountCircle as AccountCircleIcon
} from '@material-ui/icons';
import {
  Spacer,
} from "_components/common"
import { spacing } from "@material-ui/system";
const Typography = styled(MuiTypography)(spacing);
const Link = styled(MuiLink)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

const CastName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;  
`;

const CastRole = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
`;

const CastBio = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
`;



const EditorContainer = styled.div`
  min-height: 200px;
`;

const DotAutoFiller = styled.p`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px

  > * {
    padding: 0;
    margin: 0;
  }

`;

const FillerSpan = styled.span`
  flex: 1;
  overflow: hidden;
`;

const OriginalTeamName = styled.span`

`;

const OriginalTeamRole = styled.span`

`;

const Image = styled.img`
  width: 50px;
  height: 66px;
  border-radius: 4px;
`;

const CastContainer = styled.div`
  margin-bottom: 10px;
  min-height: 200px;
`

const UserIcon = styled(AccountCircleIcon)`
  color: #2097F6;
`

const UserIconGrid = styled(Grid)`
  background-color: #E6ECFF;
  height: 80px;
  width: 80px;
  border-radius: 5px;
`

const initialCreativeMember = {
  name: "",
  role: "",
  bio: "",
  image: "",
}

const initialOriginalCreativeMember = {
  name: "",
  role: "",
}

function PlaybillCreativeContent({ production, page, showPreview, classes, handleUpdate }) {
  const dispatch = useDispatch();
  const [newCreativeMember, setNewCreativeMember] = useState(initialCreativeMember);
  const [newOriginalCreativeMember, setNewOriginalCreativeMember] = useState(initialOriginalCreativeMember);
  const [addAnotherCreativeMember, setAddAnotherCreativeMember] = useState(false)
  const [addAnotherOriginalCreativeMember, setAddAnotherOriginalCreativeMember] = useState(false)
  const creativeTeamList = page ? page.data.creative : [];
  const originalCreativeTeamList = page ? page.data.original_creative_team : [];

  const handleFieldChange = (e, mode, idx, memberType) => {
    if (mode === "new") {
      if (memberType === "creative") {
        setNewCreativeMember({
          ...newCreativeMember,
          [e.target.name]: e.target.value,
        })
      } else if (memberType === "originalCreative") {
        setNewOriginalCreativeMember({
          ...newOriginalCreativeMember,
          [e.target.name]: e.target.value,
        })
      }
    } else if (mode === "update") {
      if (memberType === "creative") {
        updateCreativeMembers(e.target.name, e.target.value, idx);
      } else if (memberType === "originalCreative") {
        updateOriginalCreativeMembers(e.target.name, e.target.value, idx);
      }
    }
  }

  const handleCreateMember = () => {
    if (creativeTeamList.length <= 0 || addAnotherCreativeMember) {
      const newCreativeMembers = [...page.data.creative, newCreativeMember]
      dispatch(updatePlaybillValue({ 
        key: "creative", 
        value: newCreativeMembers, 
        page: page
      }));
      setAddAnotherCreativeMember(false)
    } else if (originalCreativeTeamList.length <= 0 || addAnotherOriginalCreativeMember) {
      const newOriginalCreativeMembers = [...page.data.original_creative_team, newOriginalCreativeMember]
      dispatch(updatePlaybillValue({ 
        key: "original_creative_team", 
        value: newOriginalCreativeMembers, 
        page: page
      }));
      setAddAnotherOriginalCreativeMember(false)
    }
  }

  const updateCreativeMembers = (field, value, updatedIndex) => {
    const updatedCreativeMembers = creativeTeamList.map((creative, index) => {
      if (index === updatedIndex) {
        creative[field] = value;
      }
      return creative;
    })
    dispatch(updatePlaybillValue({
      key: "creative",
      value: updatedCreativeMembers,
      page: page
    }))
  }

  const updateOriginalCreativeMembers = (field, value, updatedIndex) => {
    const updatedOriginalCreativeMembers = originalCreativeTeamList.map((originalCreative, index) => {
      if (index === updatedIndex) {
        originalCreative[field] = value;
      }
      return originalCreative;
    })
    dispatch(updatePlaybillValue({
      key: "original_creative_team",
      value: updatedOriginalCreativeMembers,
      page: page
    }))
  }

  const handleAdd = async (memberType) => {
    await handleCreateMember();
    if (memberType === "creative") {
      setAddAnotherCreativeMember(true)
      setNewCreativeMember(initialCreativeMember);
    } else if (memberType === "originalCreative") {
      setAddAnotherOriginalCreativeMember(true)
      setNewOriginalCreativeMember(initialOriginalCreativeMember);
    }
  }

  const handleUploadImage = (e, mode, updatedIndex) => {
    const cloudinaryOpts = { 
      cloud_name: 'onthestage', 
      sources: [ 'local' ], 
      upload_preset: 'cropmode', 
      resourceType: 'image',
      client_allowed_formats: ['png', 'jpeg', 'jpg'],
      tags: [production.id, production.organization.id, 'programgenerator'], 
      multiple: false, 
      showAdvancedOptions: true,
      cropping: true,
      croppingShowDimensions: true,
      showSkipCropButton: false,
      croppingAspectRatio: 0.75 ,
      croppingCoordinatesMode: 'custom',
      showAdvancedOptions: true,
      folder: production.organization.id + '/' + production.id + '/showgram',
    };

    const cloudinaryWidget = window.cloudinary.openUploadWidget(cloudinaryOpts, async (err, result) => {
      if (err && err.message !== 'User closed widget') {
        cloudinaryWidget.close();
        dispatch(openDialog({
          message: `There was an error uploading your file: ${JSON.stringify(err.status)}`,        
        }));
      } else if (err && err.message === 'User closed widget') {
        return false;
      }
      if (result.event === 'success') {
        const image = result.info;

          try {

            if (mode === "update") {
              const updatedCreativeMembers = creativeTeamList.map((creative, index) => {
                if (index === updatedIndex) {
                  creative.image = image.public_id;
                }
                return creative;
              })
      
              dispatch(updatePlaybillValue({
                key: "creative",
                value: updatedCreativeMembers,
                page: page
              }))
            } else if (mode === "new") {
              setNewCreativeMember({
                ...newCreativeMember,
                image: image.public_id,
              })

              const newCreativeMembers = [...page.data.creative, newCreativeMember]        
              dispatch(updatePlaybillValue({ 
                key: "creative", 
                value: newCreativeMembers, 
                page: page
              }));
            }
            dispatch(openNotification({
              message: "Image uploaded!",
            }))
          } catch (err) {
            dispatch(openNotification({
              message: "Cannot upload image!",
            }))
          }
          cloudinaryWidget.close();
          return;
      }
    });
  };

  const handleRemove = async (mode, idx, memberType) => {
    if (mode === "new") {
      if (memberType === 'creative') {
        setNewCreativeMember(newCreativeMember);
        setAddAnotherCreativeMember(false);
      } else if (memberType === 'originalCreative') {
        setNewOriginalCreativeMember(newOriginalCreativeMember);
        setAddAnotherOriginalCreativeMember(false);
      }
    } else {
      try {
        if (memberType === 'creative') {
          creativeTeamList.splice(idx, 1);
          dispatch(updatePlaybillValue({ key: "creative", value: [...creativeTeamList], page: page }));
        } else if (memberType === 'originalCreative') {
          originalCreativeTeamList.splice(idx, 1);
          dispatch(updatePlaybillValue({ key: "originalCreative", value: [...originalCreativeTeamList], page: page }));
        }
      } catch (err) {
        dispatch(openNotification({
          message: err.response?.data || err.message || "Something went wrong!",
        }))
      }
    }
  }

  return (
        <EditorContainer>
          { showPreview ? (
            <>
            <Typography component="h1" variant="h1" mt={4} mb={6}>{page.data.creative_title}</Typography>
              <CastContainer>
                { creativeTeamList.map((member, index) => (
                  <Grid container spacing={3} key={index} justify="flex-start" alignContent="center" alignItems="flex-start">
                    <Grid item>
                      { member.image ? (
                        <Image src={`https://res.cloudinary.com/onthestage/image/upload/f_auto,w_100,h_100/${member.image}`} />
                      ) : (
                        <UserIconGrid container justify="center" alignContent="center">
                          <Grid item>
                            <UserIcon fontSize="large" />
                          </Grid>
                        </UserIconGrid>
                      )}
                    </Grid>
                    <Grid item xs>
                      <CastName>{member.name}</CastName>
                      <CastRole>{member.role}</CastRole>
                      <CastBio mt={2}>{member.bio}</CastBio>
                    </Grid>
                  </Grid>
                ))}
              </CastContainer>
            </>
          ) : (
            <Grid container>
              <TextField
                label="Title"
                placeholder="Type here"
                id="filled-hidden-label-small"
                name="creative_title"
                variant="outlined"
                size="small"
                fullWidth
                value={page.data.creative_title}
                onChange={handleUpdate}
                className={classes.root}
                mb={6}
              />
              { creativeTeamList.length > 0 ? creativeTeamList.map((member, idx) => (
                <Grid container spacing={2} justify="space-between" key={idx}>
                  <PlaybillMemberForm 
                    idx={idx}
                    member={member} 
                    handleChange={(e) => handleFieldChange(e, "update", idx, "creative")}
                    handleUploadClick={(e) => handleUploadImage(e, "update", idx)}
                    showPreview={showPreview} 
                    production={production}
                    handleRemove={() => handleRemove("update", idx, "creative")}
                    handleSave={handleCreateMember}
                  />
                </Grid>
              )) : (
                <Grid container spacing={2} justify="space-between">
                  <PlaybillMemberForm 
                    idx="new"
                    member={newCreativeMember} 
                    showPreview={showPreview} 
                    production={production} 
                    handleUploadClick={(e) => handleUploadImage(e, "new")}
                    handleChange={(e) => handleFieldChange(e, "new", 1, "creative")}
                    handleRemove={() => handleRemove("new", 1, "creative")}
                    handleSave={handleCreateMember}
                  />
                </Grid>
              )}

              { addAnotherCreativeMember && (
                <Grid container spacing={2} justify="space-between">
                  <PlaybillMemberForm 
                    idx="new"
                    member={newCreativeMember} 
                    showPreview={showPreview} 
                    production={production} 
                    handleUploadClick={(e) => handleUploadImage(e, "new")}
                    handleChange={(e) => handleFieldChange(e, "new", 1, "creative")}
                    handleRemove={() => handleRemove("new", 1, "creative")}
                    handleSave={handleCreateMember}
                  />
                </Grid>
              )}
              <Grid container alignContent="center" alignItems="center">
                <Grid item>
                  <AddIcon fontSize="small" color="primary" />
                </Grid>
                <Grid item>
                  <Link component="button" variant="body2" underline="none" color="primary" mb={1} onClick={() => handleAdd("creative")}>
                    <b>Add another</b>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Spacer my={6} />


        {/* Original Creative Team */}
          { showPreview ? (
            <>
              <Typography component="h1" variant="h1" mt={4} mb={6}>{page.data.original_creative_title}</Typography>
              <CastContainer>
                { originalCreativeTeamList.map((originalMember, index) => (
                  <DotAutoFiller>
                    <OriginalTeamName>{originalMember.name}</OriginalTeamName>
                    <FillerSpan>................................................................................................................................................................................................................................................................</FillerSpan>
                    <OriginalTeamRole>{originalMember.role}</OriginalTeamRole>
                  </DotAutoFiller>
                ))}
              </CastContainer>
            </>
          ) : (
            <Grid container>
              <TextField
                label="Title"
                placeholder="Type here"
                id="filled-hidden-label-small"
                name="original_creative_title"
                variant="outlined"
                size="small"
                fullWidth
                value={page.data.original_creative_title}
                onChange={handleUpdate}
                className={classes.root}
                mb={6}
              />
              { originalCreativeTeamList.length > 0 ? originalCreativeTeamList.map((originalMember, idx) => (
                <>
                <Grid container key={idx} spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label="Name"
                      id="filled-hidden-label-small"
                      variant="outlined"
                      name="name"
                      fullWidth
                      size="small"
                      value={originalMember.name}
                      onChange={(e) => handleFieldChange(e, "update", idx, "originalCreative")}
                      mb={2}
                      className={classes.root}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Role"
                      id="filled-hidden-label-small"
                      variant="outlined"
                      name="role"
                      fullWidth
                      size="small"
                      value={originalMember.role}
                      onChange={(e) => handleFieldChange(e, "update", idx, "originalCreative")}
                      mb={2}
                      className={classes.root}
                    />
                  </Grid>
                </Grid>
                
                <Grid container justify="flex-start" direction="row" spacing={2}>
                  <Grid item>
                    <Button 
                      align="left" 
                      variant="outlined" 
                      color="primary" 
                      aria-label="save-icon"
                      onClick={handleCreateMember}
                      startIcon={<SaveOutlinedIcon fontSize="small" />}
                      mt={2}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button 
                      align="left" 
                      variant="outlined" 
                      color="primary" 
                      aria-label="delete-icon"
                      onClick={() => handleRemove("update", idx, "originalCreative")}
                      startIcon={<DeleteOutlinedIcon fontSize="small" />}
                      mt={2}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
              <Spacer my={7} />
                </>
              )) : (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="Name"
                        id="filled-hidden-label-small"
                        variant="outlined"
                        name="name"
                        fullWidth
                        size="small"
                        value={newOriginalCreativeMember.name}
                        onChange={(e) => handleFieldChange(e, "new", 1, "originalCreative")}
                        mb={2}
                        className={classes.root}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Role"
                        id="filled-hidden-label-small"
                        variant="outlined"
                        name="role"
                        fullWidth
                        size="small"
                        value={newOriginalCreativeMember.role}
                        onChange={(e) => handleFieldChange(e, "new", 1, "originalCreative")}
                        mb={2}
                        className={classes.root}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container justify="flex-start" direction="row" spacing={2}>
                    <Grid item>
                      <Button 
                        align="left" 
                        variant="outlined" 
                        color="primary" 
                        aria-label="save-icon"
                        onClick={handleCreateMember}
                        startIcon={<SaveOutlinedIcon fontSize="small" />}
                        mt={2}
                      >
                        Create
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button 
                        align="left" 
                        variant="outlined" 
                        color="primary" 
                        aria-label="delete-icon"
                        onClick={() => handleRemove("new", 1, "originalCreative")}
                        startIcon={<DeleteOutlinedIcon fontSize="small" />}
                        mt={2}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                  <Spacer my={7} />
                </>
              )}

              { addAnotherOriginalCreativeMember && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <TextField
                        label="Name"
                        id="filled-hidden-label-small"
                        variant="outlined"
                        name="name"
                        fullWidth
                        size="small"
                        value={newOriginalCreativeMember.name}
                        onChange={(e) => handleFieldChange(e, "new", 1, "originalCreative")}
                        mb={2}
                        className={classes.root}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Role"
                        id="filled-hidden-label-small"
                        variant="outlined"
                        name="role"
                        fullWidth
                        size="small"
                        value={newOriginalCreativeMember.role}
                        onChange={(e) => handleFieldChange(e, "new", 1, "originalCreative")}
                        mb={2}
                        className={classes.root}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container justify="flex-start" direction="row" spacing={2}>
                  <Grid item>
                    <Button 
                      align="left" 
                      variant="outlined" 
                      color="primary" 
                      aria-label="save-icon"
                      onClick={handleCreateMember}
                      startIcon={<SaveOutlinedIcon fontSize="small" />}
                      mt={3}
                    >
                      Create
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button 
                      align="left" 
                      variant="outlined" 
                      color="primary" 
                      aria-label="delete-icon"
                      onClick={() => handleRemove("new", 1, "originalCreative")}
                      startIcon={<DeleteOutlinedIcon fontSize="small" />}
                      mt={3}
                    >
                      Delete
                    </Button>
                  </Grid>
                </Grid>
                <Spacer my={7} />
                </>
              )}
              <Grid container alignContent="center" alignItems="center">
                <Grid item>
                  <AddIcon fontSize="small" color="primary" />
                </Grid>
                <Grid item>
                  <Link component="button" variant="body2" underline="none" color="primary" mb={1} onClick={() => handleAdd("originalCreative")}>
                    <b>Add another</b>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
        </EditorContainer>    

  )
}

function PlaybillCreative({production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillCreativeContent production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillCreative;
