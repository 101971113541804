import React from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { openDialog } from '_redux/actions/appActions'
import {
  Grid,
  Typography as MuiTypography,
  TextField as MuiTextField,
  Fab as MuiFab,
} from "@material-ui/core";

import {
  CloudUpload as CloudUploadIcon
} from '@material-ui/icons';
import OTSMagazineSVG from '../icons/ots-magazine-logo.svg';
import OTSShowgramSVG from '../icons/ots-showgram-logo.svg';
import { spacing } from "@material-ui/system";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const Image = styled.img`
  max-width: 100%;
  max-height: 780px;
`;

const EditorContainer = styled.div`
  text-align: center;
`;

const ShowgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 180px;
  left: 288px;
  top: 138px;
  background: #BD1D1E;
`;

const ImageContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  text-align: center;
  border: 1px dotted;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  background-color: #ffffff;
`;

const ImageLabel = styled.label`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin: 10px;  
`;

const UploadedImage = styled.img`
  height: 300px;
  cursor: pointer;
  border: 4px solid #337ab7;
`;

const Fab = styled(MuiFab)`
  color: #0d47a1;
  cursor: pointer;
`;

const CloudIcon = styled(CloudUploadIcon)`
  color: #2E3A59;
`

function PlaybillCoverContent({ production, page, showPreview, classes, handleUpdate }) {
  const dispatch = useDispatch();
  const pageDetails = page.data

  const uploadCoverImage = async () => {
    const cloudinaryOpts = { 
      cloud_name: 'onthestage', 
      sources: [ 'local' ], 
      upload_preset: 'programgeneratorcover', 
      resourceType: 'image',
      client_allowed_formats: ['png', 'jpeg', 'jpg'],
      tags: [production.id, production.organization.id, pageDetails.slug, 'programgenerator', 'programcover'], 
      multiple: false, 
      showAdvancedOptions: true,
      folder: production.organization.id + '/' + production.id + '/showgram',
    };

    const cloudinaryWidget = window.cloudinary.openUploadWidget(cloudinaryOpts, async (err, result) => {
      if (err && err.message !== 'User closed widget') {
        cloudinaryWidget.close();
        dispatch(openDialog({
          message: `There was an error uploading your file: ${JSON.stringify(err.status)}`,        
        }));
      } else if (err && err.message === 'User closed widget') {
        return false;
      }
      if (result.event === 'success') {
        const image = result.info;
        handleUpdate({
          target: {
            name: 'image',
            value: image.public_id
          }
        })
          cloudinaryWidget.close();
          return;
      }
    });
  };

  return (
      <Grid container>
          <Grid item xs={6} style={{ alignSelf: "center" }}>
            <Image src={OTSShowgramSVG} style={{ width: 450 }} />
            {/* <Image src={OTSShowgramSVG} style={{ width: 600 }} /> */}
          </Grid>
           {/* <Grid item xs={4}></Grid> */}
          <Grid item xs={6}>
            <EditorContainer>
            { showPreview ? (
              <>
                <Typography component="h1" variant="h1" mt={5} mb={1}>{ pageDetails.production_name }</Typography>
                <Typography component="h3" variant="h3" mb={1}>{ pageDetails.organization_name  }</Typography>
                <Typography component="h4" variant="h4" mb={1}>{ pageDetails.address }</Typography>
                <Typography>{ pageDetails.dates }</Typography>

                {/* <Typography className={classes.showgramOrgTitle} mt={5} mb={1}>{ pageDetails.production_name }</Typography>
                <Typography className={classes.showgramOrgSubtitle} mb={1}>{ pageDetails.organization_name  }</Typography>
                <Typography className={classes.showgramOrgAddress}>{ pageDetails.dates }</Typography>
                <Typography className={classes.showgramOrgAddress} mb={1}>{ pageDetails.address }</Typography> */}
              </>
            ) : (
              <>
                <TextField
                  hiddenLabel
                  id="filled-hidden-label-small"
                  label="Production Name"
                  name="production_name"
                  variant="outlined"
                  size="small"
                  mb={2}
                  fullWidth
                  value={pageDetails.production_name}
                  onChange={handleUpdate}
                  className={classes.root}
                  inputProps={{ maxLength: 60 }}
                />
                <TextField
                  hiddenLabel
                  id="outlined-hidden-label-small"
                  label="Organization Name"
                  name="organization_name"
                  variant="outlined"
                  size="small"
                  mb={2}
                  fullWidth
                  value={pageDetails.organization_name}
                  onChange={handleUpdate}
                  className={classes.root}
                  inputProps={{ maxLength: 60 }}
                />
                <TextField
                  hiddenLabel
                  id="outlined-hidden-label-small"
                  label="Address"
                  name="address"
                  variant="outlined"
                  size="small"
                  mb={2}
                  fullWidth
                  value={pageDetails.address}
                  onChange={handleUpdate}
                  className={classes.root}
                />
                <TextField
                  hiddenLabel
                  id="outlined-hidden-label-small"
                  label="Date"
                  name="dates"
                  variant="outlined"
                  size="small"
                  mb={2}
                  fullWidth
                  value={pageDetails.dates}
                  onChange={handleUpdate}
                  className={classes.root}
                />
              </>
            )}
            </EditorContainer>

          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              { showPreview ? (
                <>
                  { pageDetails.image ? (
                    <Image width="100%" maxHeight="300px" src={`https://res.cloudinary.com/onthestage/image/upload/f_auto,c_pad,h_740,w_560/${pageDetails.image}`}/>
                  ) : null }
                </>
              ) : (
                <>
                <ImageContainter>
                  <ImageLabel>
                      { pageDetails.image ? (
                        <>
                          <UploadedImage
                            onClick={() => uploadCoverImage()}
                            width="100%"
                            src={`https://res.cloudinary.com/onthestage/image/upload/f_auto/${pageDetails.image}`}
                          />
                        </>
                      ) :
                        <Fab component="span" onClick={() => uploadCoverImage()} >
                          <CloudIcon fontSize="small" />
                        </Fab>
                      }
                    </ImageLabel>
                    <Typography mb={2} mt={1} variant="h2">Click to upload</Typography>
                    <Typography>File format JPEG, PNG</Typography>
                    <Typography>Size 15mb max</Typography>
                </ImageContainter>
                </>
              )}
            </Grid>
          </Grid>
    </Grid>
  )
}

function PlaybillCover({ production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillCoverContent production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillCover;
