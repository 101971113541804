import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TooltipSwitch from "_components/common/TooltipSwitch";
import CopyToClipBoard from "_components/common/CopyToClipboard";
import { updateProduction } from "_redux/actions/productionActions";
import { openNotification } from '_redux/actions/appActions';
import styled from "styled-components/macro";
import {
  Grid,
  Link,
  Typography as MuiTypography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from "@material-ui/core";
import {
  Close as CloseIcon,
} from "@material-ui/icons";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const Typography = styled(MuiTypography)`
  font-family: "DM Sans",serif;
`;

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  background: #F6F6F6;
  border: 1px solid #EDEBF9;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 20px;
`

const SocialIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const BoxItem = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background: #F6F6F6;
  border: 1px solid #EDEBF9;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  height: 100px;
  width: 100px;
`
const Image = styled.img`
  width: 80px;
  height: 80px;
`;

export default function SharePdfDialog({ production, open, handleOpen, handleClose }) {
  const title = 'OTS';
  const dispatch = useDispatch();
  const pdfUrl = useSelector(state => state.playbillReducer.pdf_url);
  const qrCodeUrl = useSelector(state => state.playbillReducer.qrcode_url);
  const [checked, setChecked] = useState(production.has_virtual_program_link || false);

  const handleCheck = (e) => {
    try {
      setChecked(e.target.checked)
      dispatch(updateProduction({ id: production.id, data: { has_virtual_program_link: e.target.checked } }));
      dispatch(openNotification({
        message: `Link ${e.target.checked ? 'published' : 'unpublished'}!`,
      }));
    } catch(err) {
      dispatch(openNotification({
        message: err.response?.data || err.message || "Something went wrong!",
      }));
    }
  }

  return (
    <Dialog open={open} onClose={handleOpen} aria-labelledby="form-dialog-title" maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Share PDF</Box>
            <Box>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'relative',
                  float: 'right',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box> 
          </Box>
      </DialogTitle>
        <DialogContent>
          <BoxContainer sx={{ height: 100 }} >
            <Box p={1}>
              <Link rel="noreferrer" target="_blank" href={pdfUrl} style={{ color: '#6E7191' }}>
                {pdfUrl}
              </Link>
            </Box>
            <Box p={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <CopyToClipBoard textToCopy={pdfUrl} color="primary" /> 
              </Box>
            </Box>
          </BoxContainer>    
          <Box display="flex" justifyContent="center">
            <Typography style={{ color: '#6E7191' }}>Or share by</Typography>
          </Box>
          <SocialIconsContainer>
            <BoxItem p={1}>
              <TwitterShareButton 
                url={pdfUrl}
                quote={title}
                hashtags={[]}
                via="onthestagetix" 
              >
                <TwitterIcon size={45} round={true} />
              </TwitterShareButton>
            </BoxItem>
            <BoxItem p={1}>
              <FacebookShareButton
                url={pdfUrl}
                quote={title}
              >
                <FacebookIcon size={45} round />
              </FacebookShareButton>
            </BoxItem>
            <BoxItem p={1}>
              <Image src={qrCodeUrl} />
            </BoxItem>
          </SocialIconsContainer> 
          {(production.event_type === 'virtual' || production.is_livestream || production.event_type === 'vod') && (
            <Grid container spacing={3} justify='center' alignItems='center' style={{ marginBottom: 10 }}>
              <Grid item>
                Publish link to streaming page:
              </Grid>
              <Grid item>
                <TooltipSwitch 
                  checked={checked} 
                  handleCheck={handleCheck} 
                  name="publish-to-streaming-page" 
                  placement="right"
              />
              </Grid>
            </Grid> 
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <a href={qrCodeUrl} download="downloadQRCode" target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
              <Button  
                type="submit"
                variant="contained"
                color="primary"
              >
                Download QR Code
              </Button>
            </a>
        </DialogActions>
    </Dialog>
  );
};