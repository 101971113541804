import * as types from "_constants";

const initialState = {
  offers: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: actions.payload
      };
    case types.SAVE_OFFER_SUCCESS:
      return {
        ...state,
        offers: [...state.offers, actions.payload],
      };
    case types.UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        offers: state.offers.map(offer => 
          offer.id === actions.payload.id ? actions.payload : offer
        ),
      };
    case types.UPDATE_OFFER_ORDER_SUCCESS:
      return {
        ...state,
        offers: actions.payload
      };
    case types.DELETE_OFFER_SUCCESS:
      debugger;
      return {
        ...state,
        offers: actions.payload
      };      
    default:
      return state;
  }
}
