import * as types from "_constants";

const initialState = {
    notifications: [],
};

const settingsBaseUrl = '/#/settings';
const livestreamSettingsBaseUrl = '/#/livestream-settings/';
const vodSettingsUrlBaseUrl = '/#/vod-settings/';

function formatOldDashboardMessage(message) {
  if (message.includes('/#/')) {
    let searchValue;
    let replaceValue;
    if (message.includes(settingsBaseUrl)) {
      searchValue = settingsBaseUrl;
      replaceValue = '/settings/social-media';
    } else if (message.includes(livestreamSettingsBaseUrl)) {
      // Get 24 chars after the base URL to get the mongo ID
      const index = message.indexOf(livestreamSettingsBaseUrl)
      const productionId = message.substring(index + livestreamSettingsBaseUrl.length, index + livestreamSettingsBaseUrl.length + 24);
      searchValue = `${livestreamSettingsBaseUrl}${productionId}`;
      replaceValue = `/productions/${productionId}/livestream-settings`
    } else if (message.includes(vodSettingsUrlBaseUrl)) {
      // Get 24 chars after the base URL to get the mongo ID
      const index = message.indexOf(vodSettingsUrlBaseUrl)
      const productionId = message.substring(index + vodSettingsUrlBaseUrl.length, index + vodSettingsUrlBaseUrl.length + 24);
      searchValue = `${vodSettingsUrlBaseUrl}${productionId}`;
      replaceValue = `/productions/${productionId}/video-on-demand`
    }
    message = message.replace(searchValue, replaceValue)
  }
  return message;
}
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.NOTIFICATIONS_LIST_GET_REQUEST:
      return {
        ...state,
        notifications: []
      };
    case types.NOTIFICATIONS_LIST_GET_SUCCESS:
      for (const n of actions.payload.notifications) {
        n.message = formatOldDashboardMessage(n.message);
      }
      return {
        ...state, 
        notifications: actions.payload.notifications,
      };   
    case types.NOTIFICATIONS_LIST_GET_FAILURE:
      return {
        ...state,
        notifications: []
      };
    default:
      return state;
  }
}
