import * as types from "_constants";
import {
  listContacts as actionListContacts,
  submitContactsService,
  uploadContactsFileService,
  updateContactService,
  removeContactService,
  listContactsService,
  getContactService, 
  getContactSubscriptionService,
  listContactLogsService,
  sendCcManagementLinkService,
  addContactLogService,
  updateContactLogService,
  listContactSourcesService,
  cancelContactSubscriptionService,
  getContactAttributesService,
  addContactAttributeService,
  editCustomizableFieldService,
} from "_services/contactService";


export function listContactSources(organizationId) {
  return async (dispatch) => {
    dispatch({ type: types.CONTACTS_SOURCES_GET_REQUEST });
    return listContactSourcesService(organizationId)
      .then((response) => {
        console.log('response', response);
        dispatch({
          type: types.CONTACTS_SOURCES_GET_SUCCESS,
          payload: {
            sources: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.CONTACTS_SOURCES_GET_FAILURE });
        throw error;
      });
  };
}


export function listContacts(organizationId, first_name, last_name, email) {
  return async (dispatch) => {
    dispatch({ type: types.CONTACTS_GET_REQUEST });
    return actionListContacts(organizationId, first_name, last_name, email)
      .then((response) => {
        dispatch({
          type: types.CONTACTS_GET_SUCCESS,
          payload: {
            contacts: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.CONTACTS_GET_FAILURE });
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message,
          }
        })        
        throw error;
      });
  };
}

export function submitContacts(data) {
  return async (dispatch) => {
    dispatch({ type: types.SUBMIT_CONTACTS_REQUEST });
    return submitContactsService(data)
      .then((response) => {
        dispatch({
          type: types.SUBMIT_CONTACTS_SUCCESS,
          payload: {
            contacts: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Contacts uploaded'
          }
        })
      })
      .catch((error) => {
        dispatch({ type: types.SUBMIT_CONTACTS_FAILURE });
        const message = error.response?.data?.message || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function uploadContactsFile(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPLOAD_CONTACTS_FILE_REQUEST });
    return uploadContactsFileService(data)
      .then((response) => {
        dispatch({
          type: types.UPLOAD_CONTACTS_FILE_SUCCESS,
          payload: {
            contacts: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Contacts uploaded'
          }
        })
      })
      .catch((error) => {
        dispatch({ type: types.UPLOAD_CONTACTS_FILE_FAILURE });
        throw error;
      });
  };
}


export function removeContact(data) {
  return async (dispatch) => {
    dispatch({ type: types.REMOVE_CONTACT_REQUEST });
    return removeContactService(data)
      .then((response) => {
        // dispatch({
        //   type: types.REMOVE_CONTACT_SUCCESS,
        //   payload: {
        //     contactId: data.contactId,
        //   }
        // });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Contact successfully removed!',
          }
        })                    
        return actionListContacts(data.orgId)
        .then((response) => {
          dispatch({
            type: types.CONTACTS_GET_SUCCESS,
            payload: {
              contacts: response,
            }
          });
        })
      })
      .catch((error) => {
        dispatch({ type: types.REMOVE_CONTACT_FAILURE });
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function getActionableContacts(organizationId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ACTIONABLE_CONTACTS_REQUEST });
    return listContactsService(organizationId, 'actionable')
      .then((response) => {
        dispatch({
          type: types.GET_ACTIONABLE_CONTACTS_SUCCESS,
          payload: {
            contacts: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_ACTIONABLE_CONTACTS_FAILURE });
        throw error;
      });
  };
}

export function getNewContacts(organizationId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_NEW_CONTACTS_REQUEST });
    return listContactsService(organizationId, 'new')
      .then((response) => {
        dispatch({
          type: types.GET_NEW_CONTACTS_SUCCESS,
          payload: {
            contacts: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_NEW_CONTACTS_FAILURE });
        throw error;
      });
  };
}


export function getContact(orgId, contactId) {
    return async (dispatch) => {
      dispatch({ type: types.GET_CONTACT_REQUEST });
      return getContactService(orgId, contactId)
        .then((response) => {
          dispatch({
            type: types.GET_CONTACT_SUCCESS,
            payload: {
              detail: response,
            }
          });
        })
        .catch((error) => {
          dispatch({ type: types.GET_CONTACT_FAILURE });
          throw error;
        });
    };
  }

export function getContactSubscription(orgId, contactId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_CONTACT_SUBSCRIPTION_REQUEST });
    return getContactSubscriptionService(orgId, contactId)
      .then((response) => {
        dispatch({
          type: types.GET_CONTACT_SUBSCRIPTION_SUCCESS,
          payload: {
            subscription: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_CONTACT_SUBSCRIPTION_FAILURE });
        throw error;
      });
  };
}


export function updateContact(orgId, contactId, data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_CONTACT_REQUEST });

    return updateContactService(orgId, contactId, data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_CONTACT_SUCCESS,
          payload: {
            contact: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Contact updated!',
          }
        })            
    }).catch((error) => {
        dispatch({ type: types.UPDATE_CONTACT_FAILURE });
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function sendCcManagementLink(orgId, contactId, email, stripe_customer_id) {
  return async (dispatch) => {
    dispatch({ type: types.GENERATE__CONTACT_CC_LINK_REQUEST });
    return sendCcManagementLinkService(orgId, contactId, email, stripe_customer_id)
      .then((response) => {
        dispatch({
          type: types.GENERATE__CONTACT_CC_LINK_SUCCESS,
          payload: {
            link: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `CC Link sent to ${email} successfully!`,
          }
        }) 
      })
      .catch((error) => {
        dispatch({ type: types.GENERATE__CONTACT_CC_LINK_FAILURE });
        throw error;
      });
  };
}

// Contact Logs
export function listContactLogs(organizationId, contactId) {
  return async (dispatch) => {
    dispatch({ type: types.CONTACT_LOGS_GET_REQUEST });
    return listContactLogsService(organizationId, contactId)
      .then((response) => {
        dispatch({
          type: types.CONTACT_LOGS_GET_SUCCESS,
          payload: {
            contactLogs: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.CONTACT_LOGS_GET_FAILURE });
        throw error;
      });
  };
}

export function addContactLog(orgId, contactId, contactEmail, subject, message, type, internalType, fromUserEmail) {
  return async (dispatch) => {
    dispatch({ type: types.ADD_CONTACT_LOG_REQUEST });
    console.log('addContactLogService');
    return addContactLogService(orgId, contactId, contactEmail, subject, message, type, internalType, fromUserEmail)
      .then((response) => {
        console.log('in addContactLog action response', response)
        dispatch({
          type: types.ADD_CONTACT_LOG_SUCCESS,
          payload: {
            contactLog: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.ADD_CONTACT_LOG_FAILURE });
        throw error;
      });
  }
}

export function updateContactLog(orgId, contactId, logId, subject, message, internalType) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_CONTACT_LOG_REQUEST });

    return updateContactLogService(orgId, contactId, logId, subject, message, internalType)
      .then((response) => {
        dispatch({
          type: types.UPDATE_CONTACT_LOG_SUCCESS,
          payload: {
            contactLog: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Contact updated!',
          }
        })            
    }).catch((error) => {
        dispatch({ type: types.UPDATE_CONTACT_LOG_FAILURE });
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function editContactLogField(data) {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_CONTACT_LOG_FIELD,
      data,
    });
  };
}

export function cancelContactSubscription(orgId, contactId, subscriptionId, cancelationReason) {
  return async (dispatch) => {
    dispatch({ type: types.CANCEL_CONTACT_SUBSCRIPTION_REQUEST });
    return cancelContactSubscriptionService(orgId, contactId, subscriptionId, cancelationReason)
      .then((response) => {
        dispatch({
          type: types.CANCEL_CONTACT_SUBSCRIPTION_SUCCESS,
          payload: {
            subscription: response,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Subscription canceled!`,
          }
        }) 
        return listContactLogsService(orgId, contactId) // calling this to update the ContactLogs state
        .then((response) => {
          dispatch({
            type: types.CONTACT_LOGS_GET_SUCCESS,
            payload: {
              contactLogs: response,
            }
          });
        })
      })
      .catch((error) => {
        dispatch({ type: types.CANCEL_CONTACT_SUBSCRIPTION_FAILURE });
        throw error;
      });
  };
}

export function addContactAttribute(orgId, contactId, fieldName) {
  return async (dispatch) => {
    return addContactAttributeService(orgId, contactId, fieldName)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION, payload: {
            message: 'Customizable field has been added successfully!',
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: types.SHOW_NOTIFICATION, payload: {
            message: 'Customizable field update failed. ',
          }
        })
        throw error;
      });
  }
}

export function editCustomizableField(orgId, contactId, fieldID, fieldValue) {
  return async (dispatch) => {
    return editCustomizableFieldService(orgId, contactId, fieldID, fieldValue)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION, payload: {
            message: 'Customizable field value has been edited successfully!',
          }
        })
      })
      .catch((error) => {
        dispatch({
          type: types.SHOW_NOTIFICATION, payload: {
            message: 'Customizable field value update failed. ',
          }
        })
        throw error;
      });
  }
}

export function getContactAttributes(orgId) {
  return async (dispatch) => {
    return getContactAttributesService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_CONTACT_ATTRIBUTES_SUCCESS,
          payload: {
            contact_attributes: response,
          }
        });
      })
      .catch((error) => {
        dispatch({ type: types.GET_CONTACT_FAILURE });
        throw error;
      });
  }
}

