import * as types from "_constants";

const initialState = {
  chart: {}
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_SEATING_CHART_SUCCESS:
      return {
        ...state,
        chart: actions.payload
      };
    default:
      return state;
  }
}
