import axios from "_utils/axios";

export function getAllMailchimpAudiences(orgId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`organizations/${orgId}/mailchimp`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          resolve([]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }