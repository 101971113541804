import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
  websiteEditorRoutes,
  orgSiteEditorRoutes,
  fundLayoutRoutes,
  walkupRoutes,
} from "./index";

import DashboardLayout from "_layouts/Dashboard";
import AuthLayout from "_layouts/Auth";
import PresentationLayout from "_layouts/Presentation";
import WebsiteEditorLayout from "_layouts/WebsiteEditor";
import WalkupLayout from "_layouts/WalkupLayout";
import OrgsiteEditorLayout from "_layouts/OrgsiteEditor";
import FundLayout from "_layouts/FundDashboard";


import Page404 from "_pages/auth/Page404";
import {
  Close as CloseIcon,
} from "@material-ui/icons";

import { ErrorDialog } from "_components/common";
import { Snackbar, IconButton } from "@material-ui/core";

import { hideDialog, hideNotification } from "_redux/actions/appActions";
import { WaitingDialog } from "_components/common/Dialog";
import PayWallGuard from "_components/PayWallGuard";


const childRoutes = (Layout, routes, settings) =>
  routes.map(({ component: Component, guard, children, path, plans }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        const Guard = element.guard || React.Fragment;
        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                    {
                      settings?.is_enterprise && element.enterprise_component ? 
                        <element.enterprise_component {...props} /> : <element.component {...props} />
                    }
                </Layout>
              </Guard>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Guard>
            <Layout>
                <Component {...props} />
            </Layout>
          </Guard>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const dialogStatus = useSelector((state) => state.appReducer.dialogStatus);
  const notificationStatus = useSelector((state) => state.appReducer.notificationStatus);
  const waitingStatus = useSelector((state) => state.appReducer.waitingStatus);
  const { organization: { id: organization_id, is_enterprise } }  = useSelector((state) => state.organizationReducer);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideDialog());
  };

  const handleSnackBarClose = () => {
    dispatch(hideNotification());
  };

  return (
    <>
      <Router>
        <Switch>
          {childRoutes(DashboardLayout, dashboardLayoutRoutes, {
            is_enterprise
          })}
          {childRoutes(AuthLayout, authLayoutRoutes)}
          {childRoutes(PresentationLayout, presentationLayoutRoutes)}
          {childRoutes(WebsiteEditorLayout, websiteEditorRoutes)}
          {childRoutes(WalkupLayout, walkupRoutes)}
          {childRoutes(OrgsiteEditorLayout, orgSiteEditorRoutes)}
          {/* {childRoutes(WalkupLayout, walkupSidebarRoutes)} */}
          {childRoutes(FundLayout, fundLayoutRoutes)}
          
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </Router>
      <ErrorDialog open={dialogStatus.open} contentText={dialogStatus.message} title={dialogStatus.title} handleCancel={handleClose} severity={dialogStatus.severity}/>
      <WaitingDialog  open={waitingStatus.open} message={waitingStatus.message} title={waitingStatus.title}></WaitingDialog>
      <Snackbar open={notificationStatus.open} autoHideDuration={6000} onClose={handleSnackBarClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        message={notificationStatus.message}
        action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackBarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      } />
    </>
  )
};

export default Routes;
