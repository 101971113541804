import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { addContactAttribute } from "_redux/actions/contactActions";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import {
  Spacer,
  Alert,
  SaveButtonComponent,
} from "_components/common"
import {
  AddCircleOutlined as AddCircleOutlinedIcon
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { addContactAttributeService } from "_services/contactService";

const TextField = styled(MuiTextField)(spacing);

const alphaNumericRegex = /^[^\x00-\x1F\x7F-\xFF'"]*$/;
//Doesn't allow single or double quotes, or non-ASCII characters



export default function AddCustomFieldDialog({ openAddContactDialog, setOpenAddContactDialog, orgId }) {
  const [customFields, setCustomFields] = useState([
    {
      dataType: 'none',
      attributeName: '',
    },
    {
      dataType: 'none',
      attributeName: '',
    },
    {
      dataType: 'none',
      attributeName: '',
    },
    {
      dataType: 'none',
      attributeName: '',
    },
    {
      dataType: 'none',
      attributeName: '',
    }
  ]);
  const itemsRef = useRef([]);

  const validateCustomFields = (fields) => {
    const errors = [];
    let hasError = false;
    for (const field of fields) {
      let error = {};
      if (field.dataType !== 'none' && field.attributeName === '') {
        error['attributeName'] = 'Field name must be entered';
      }

      if (field.attributeName !== '' && field.dataType === 'none') {
        error['dataType'] = 'Select Field Type';
      }

      if (error.attributeName === undefined && field.attributeName !== '') {
        if (field.attributeName.length > 30) {
          error['attributeName'] = 'Field name must not be more than 30 characters';
        } else if (field.attributeName.match(alphaNumericRegex) === null) {
          error['attributeName'] = 'Please only use ASCII characters.';
        }
      }

      if (Object.keys(error).length > 0) {
        hasError = true;
      }
      errors.push(error);
    }

    return { hasError, errors };
  }

  const onSelectedItemChange = (e) => {
    const elName = e.target.name;
    const indexVal = elName.split('.')[0];
    if (Number(indexVal) < itemsRef.current.length) {
      // use setTimeout to set the focus on the MUI textfield, this doesn't seem to work otherwise
      setTimeout(() => {
        // only after user action we know the currentIndex
        itemsRef.current[Number(indexVal)].focus();
      }, 100);
    }
  }

  const isFormEmpty = (fields) => {
    return fields.every(field => field.dataType === 'none' && field.attributeName === '');
  };

  return (
    <>
      <Dialog
        open={openAddContactDialog}
        onClose={() => setOpenAddContactDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle disableTypography={true} id="form-dialog-title">
          <Typography variant="h2" gutterBottom display="inline">
            Add New Custom Fields
          </Typography>
        </DialogTitle>

        <Formik
          initialValues={customFields}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const { hasError, errors } = validateCustomFields(values);
              console.log('errordata', errors)
              if (hasError) {
                setErrors(errors);
              } else {
                const attributesArray = values.filter(field => field.attributeName && field.attributeName.trim() !== '');
                // API POST to create custom fields
                const res = await addContactAttributeService(orgId, attributesArray);
                // console.log('response', res);
                setOpenAddContactDialog(false, true, res);
              }
            } catch (errorResponse) {
              const error = errorResponse.response.data.error;
              const message = error.message || "Something went wrong";
              if (error.fields !== undefined &&
                Object.keys(error.fields).length > 0
              ) {
                const errors = [];
                for (const field of values) {
                  let errorObject = {};
                  if (error.fields[field.attributeName]) {
                    const errorMessage = error.fields[field.attributeName];
                    errorObject.attributeName = errorMessage;
                  }
                  errors.push(errorObject);
                }
                setErrors(errors);
              } else {
                setErrors({ submit: message });
              }
              setStatus({ success: false, message: '' });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            values,
            status,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Alert mb={4} severity="info">
                  Only 5 Custom Fields can be added at a time. To add more, please save and add again.
                </Alert>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {status && status.success && (
                  <Alert mt={2} mb={1} severity="success">
                    {status.message}
                  </Alert>
                )}

                <Spacer mb={2} />

                {values.map((field, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={4} style={{ padding: '8px', height: '85px'}}>
                        <FormControl fullWidth variant="outlined" error={errors && errors[index] && errors[index].dataType}>
                          <InputLabel id={`${index}-dataType`}>Field Type</InputLabel>
                          <Select
                            labelId={`${index}-dataType`}
                            label={"Field Type"}
                            value={field.dataType}
                            error={errors && errors[index] && Boolean(errors[index].dataType)}
                            name={`${index}.dataType`}
                            onChange={(e) => {
                              handleChange(e);
                              onSelectedItemChange(e);
                            }}
                            >
                            <MenuItem value={'none'}>Select</MenuItem>
                            <MenuItem value={'text'}>Text</MenuItem>
                            <MenuItem value={'number'}>Number</MenuItem>
                            <MenuItem value={'date'}>Date</MenuItem>
                          </Select>
                          {errors && errors[index] && <FormHelperText>{errors[index].dataType}</FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={8} style={{ padding: '8px', height: '85px' }}>
                        <FormControl fullWidth>
                          <TextField
                            variant="outlined"
                            label={`Field Name`}
                            inputRef={(v) => itemsRef.current[index] = v}
                            error={errors && errors[index] && Boolean(errors[index].attributeName)}
                            helperText={errors && errors[index] && errors[index].attributeName}
                            name={`${index}.attributeName`}
                            onChange={handleChange} 
                            />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )
                })}

              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenAddContactDialog(false)} color="primary">
                  Cancel
                </Button>
                <SaveButtonComponent
                  type="submit"
                  disabled={!isValid || (isSubmitting || (status && status.success) || isFormEmpty(values))}
                  isLoading={isSubmitting}
                  label={'Save'}
                />
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}
