import accounting from "accounting";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currencies } from "_constants/currencies";
import LogRocket from "logrocket";
import locale from 'date-fns/locale/en-US'

const LocaleContext = createContext(null);

export const LocaleProvider = ({ children }) => {
  const currentCurrency = useSelector(state => state.organizationReducer.organization.currency_code);
  const [currency, setCurrency] = useState(currencies[currentCurrency] || currencies["usd"]);

  const formatCurrency = (amount, symbol) => {
    return accounting.formatMoney(amount, { symbol: symbol || currency.symbol, format: currency.format });
  };

  useEffect(()=> {
    setCurrency(currencies[currentCurrency] || currencies["usd"]);
  }, [currentCurrency])

  // Some monetary values are stored as integers in the database, so we need to divide by 100 to get the correct value.
  // https://onthestage.atlassian.net/wiki/spaces/Engineerin/pages/181207049/Storing+monetary+values
  const convertFromCentsAndFormatCurrency = (amount) => {
    if (amount < 100) {
      LogRocket.captureMessage("LocaleProvider: Amount provided is less than 1 dollar")
    }
    return formatCurrency(amount / 100);
  };

  const convertFromCents = (amount) => {
    if (amount < 100) {
      LogRocket.captureMessage("LocaleProvider: Amount provided is less than 1 dollar")
    }
    return amount / 100;
  };
  
  const formatCurrencyName = (currency) => `${currencies[currency].name} (${currencies[currency].symbol})`;

  const changeCurrency = (newCurrency) => {
    if (!currencies[newCurrency]) {
      LogRocket.captureMessage("LocaleProvider: Attempted to set invalid currency")
    }
    setCurrency(currencies[newCurrency])
  }

  locale.options.weekStartsOn = currency.weekStartsOn;

  return <LocaleContext.Provider 
  value={{ 
    currency,
    setCurrency,
    formatCurrency,
    convertFromCentsAndFormatCurrency,
    formatCurrencyName,
    changeCurrency,
    calendarLocale: locale,
    convertFromCents
  }}>{children}</LocaleContext.Provider>;
};

export const useLocale = () => useContext(LocaleContext);