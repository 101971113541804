import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEvent } from "_services/eventService";
import styled from "styled-components/macro";
import { getEventType } from "_utils/productionUtils";
import { openDialog } from "_redux/actions/appActions";
import { getItemCheckinListUrl } from "_utils/itemCheckinListUtils";


const HeaderTitle = styled.div`
  background: black;
  color: white;
  font-family: 'DM Sans';
  border-radius: 5px;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
`;

function ItemCheckInListPage() {
    console.log('ItemCheckInListPage')
  const [checkinUrl, setCheckinUrl] = useState('');
  const organization = useSelector(state => state.organizationReducer.organization);
  console.log('ItemCheckInListPage: organization', organization);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
        console.log('feccthing data');
      try {  
        const checkInUrl = await getItemCheckinListUrl(organization)
        console.log('checkInUrl', checkInUrl);
        setCheckinUrl(checkInUrl)
  
      } catch (error) {
        console.log("error in checkin list page")
        dispatch(openDialog({ 
          message: error.message || "Something went wrong!"
        }));
      }
    }
    if (organization?.id) {
        fetchData();
    }
  }, [organization._id]);

  return checkinUrl ? (
    <React.Fragment>
      <Helmet title="Item Check In List" />
      <HeaderTitle>Item Fulfillment</HeaderTitle>
      <iframe width="100%" height="100%" allow="camera; microphone; fullscreen" src={checkinUrl} frameBorder="0"></iframe>
    </React.Fragment>
  ) : <div>Please Wait...</div>;
}

export default ItemCheckInListPage;
