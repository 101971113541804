import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
export const SaveButtonComponent = ({
  onClick,
  disabled = false,
  label = 'Save',
  isLoading,
  margin,
  type = 'button',
  size,
  my,
  mx,
  m,
  fullWidth = false,
}) => {
  return (
    <Box my={my} mx={mx} m={m}>
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled}
        type={type}
        size={size}
        fullWidth={fullWidth}
        style={{ minWidth: 'fit-content', position: 'relative' }}
      >
        <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>{label}</span>
        {isLoading && (
          <CircularProgress
            size={20}
            color="primary"
            style={{ position: 'absolute' }}
          />
        )}
      </Button>
    </Box>
  );
};
export default SaveButtonComponent;


/**
 * SaveButtonComponent
 * 
 * A customizable and reusable save button component that includes a loading state. It is based on Material-UI's Button and CircularProgress components.
 * 
 * Props:
 * 
 * @param {function} onClick - The function to be executed when the button is clicked.
 * @param {boolean} [disabled=false] - Whether the button is disabled or not.
 * @param {string} [label='Save'] - The text to be displayed on the button.
 * @param {boolean} isLoading - Indicates whether the loading state is active or not. I.e Whether Loading spinner is showing or not. 
 * @param {string} [type='button'] - The type attribute of the underlying button element. When using forms, try use type=submit, rather than onClick = {HandleSubmit}. Due to enhanced accessibility features and better browser validation.
 * @param {string} size - The size of the button. Accepts 'small', 'medium', or 'large'.
 * @param {number} my - Sets the vertical margin on the button.
 * @param {number} mx - Sets the horizontal margin on the button.
 * @param {number} m - Sets the margin on the button.
 * @param {boolean} [fullWidth=false] - Whether the button should span the full width of its container.
 * 
 * 
 * Usage:
 * 
 * import SaveButtonComponent from './SaveButtonComponent';
 * 
 * function MyComponent() {
 *   const [isLoading, setIsLoading] = useState(false);
 * 
 *   const handleClick = () => {
 *     setIsLoading(true);
 *     // Perform your save action here, then set isLoading back to false when finished.
 *   };
 * 
 *   return (
 *     <div>
 *       <SaveButtonComponent onClick={handleClick} isLoading={isLoading}/>
 *     </div>
 *   );
 * }
 */
