import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import "./mocks";


import OnStageProvider from "_contexts/index";

import ErrorBoundary from "_components/ErrorBoundary";

ReactDOM.render(
    <OnStageProvider>
      <App />
    </OnStageProvider>,
  document.getElementById("root")
);
