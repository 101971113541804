import * as types from "_constants";

const initialState = {
  products: [],
  base_products: [],
  printful_mockups: [],
  selectedMerchProduct: {},
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {      
    case types.GET_MERCHANDISE_SUCCESS:
      return {
        ...state, 
        products: actions.products,
      };   
    case types.LIST_MERCH_BASE_PRODUCTS_SUCCESS:
      return {
        ...state, 
        base_products: actions.payload.base_products.filter(bp => bp.active === true),
      };   
    case types.CREATE_PRINTFUL_MOCKUP_SUCCESS:
      return {
        ...state, 
        printful_mockups: actions.payload.printful_mockups
      };   
    case types.SET_SELECTED_MERCH_PRODUCT:
      return {
        ...state, 
        selectedMerchProduct: actions.payload.selectedMerchProduct
      };   
    case types.UPDATE_SELECTED_MERCH_PRODUCT_VALUE:
      return {
        ...state, 
        selectedMerchProduct: actions.payload.data
      };
    case types.SET_PRODUCT_COLORS_SUCCESS:
      const updatedColors = state.selectedMerchProduct.colors = actions.payload.colorArray
      return {
        ...state, 
        selectedMerchProduct: {
          ...state.selectedMerchProduct,
          colors: updatedColors
        }
      };     
    case types.SET_PRODUCT_COLOR_VARIANTS_SUCCESS:
      const updatedVariants = state.selectedMerchProduct.variants = actions.payload.variants
      return {
        ...state, 
        selectedMerchProduct: {
          ...state.selectedMerchProduct,
          variants: updatedVariants
        }
      };     
    case types.SET_PRODUCT_VARIANTS_SUCCESS:
      const matchingVariant = state.selectedMerchProduct.variants.filter(v => v.variant_id === actions.payload.variant[0].variant_id);
      return {
        ...state, 
        selectedMerchProduct: {
          ...state.selectedMerchProduct,
          variants: matchingVariant.length > 0 ? matchingVariant : actions.payload.variant
        }
      };     
    case types.CLEAR_PRINTFUL_MOCKUP:
      return {
        ...state, 
        printful_mockups: []
      };     
    case types.SET_PRINTFUL_MOCKUPS:
      return {
        ...state, 
        printful_mockups: actions.payload.printful_mockups
      }; 
    default:
      return state;
  }
}
