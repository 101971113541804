import React, { useEffect, useState } from "react";
import { Font } from "@react-pdf/renderer";
import QRCode from "qrcode";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular.ttf";
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold.ttf";
import OpenSansLight from "../../assets/fonts/OpenSans-Light.ttf";
import SingleTicketView from "./SingleTicketView";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: OpenSansLight,
      fontWeight: 300,
    },
    {
      src: OpenSansRegular,
      fontWeight: 400,
    },
    {
      src: OpenSansSemiBold,
      fontWeight: 600,
    },
  ],
});
const SingleTicket = ({ order, ticket, maxProdNameLength }) => {
  const [mode, setMode] = useState("soldGeneral");
  useEffect(() => {
    if (ticket.ticket_type === "reserved") {
      if (window.location.pathname.includes("hard-tickets")) {
        setMode("unsoldReserved");
      } else {
        setMode("soldReserved");
      }
    } else if (ticket.ticket_type === "general") {
      if (window.location.pathname.includes("hard-tickets")) {
        setMode("unsoldGeneral");
      } else {
        setMode("soldGeneral");
      }
    }
    return () => {
      setMode("soldGeneral");
    };
  }, [ticket?.ticket_type]);

  // TODO: Hide this part qrcode
  let qrCodeData, qrCode;
  if (order) {
    qrCodeData = JSON.stringify({
      confirmation_code: order?.["confirmation_code"],
      ticket_id: ticket?.id,
      event_id: order ? ticket?.EventId : ticket.Event.id,
      order_id: order?.id || order?.order_id || ticket.OrderId,
      src: "HT",
      organization_id: order?.OrganizationId,
    });
    // QRCode.toDataURL(qrCodeData);
    qrCode = QRCode.toDataURL(qrCodeData, {
      errorCorrectionLevel: "M",
      width: 256,
      margin: 0, // Set padding to 0
      color: {
        dark: "#000", // Dark color (the actual QR code blocks), set to gray
        light: "#ffffff", // Light color (background of the QR code), set to white
      },
    });
  }
  return (
    <SingleTicketView
      ticket={ticket}
      qrCode={qrCode}
      order={order}
      mode={mode}
      maxProdNameLength={maxProdNameLength}
    />
  );
};

export default SingleTicket;
