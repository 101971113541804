export const SET_VOD_LINK_REQUEST = "SET_VOD_LINK_REQUEST";
export const SET_VOD_LINK_SUCCESS = "SET_VOD_LINK_SUCCESS";
export const SET_VOD_LINK_FAILURE = "SET_VOD_LINK_FAILURE";

export const GET_UPDATED_VOD_UPLOAD_URL_REQUEST = "GET_UPDATED_VOD_UPLOAD_URL_REQUEST";
export const GET_UPDATED_VOD_UPLOAD_URL_SUCCESS = "GET_UPDATED_VOD_UPLOAD_URL_SUCCESS";
export const GET_UPDATED_VOD_UPLOAD_URL_FAILURE = "GET_UPDATED_VOD_UPLOAD_URL_FAILURE";

export const GET_VOD_VIDEO_REQUEST = "GET_VOD_VIDEO_REQUEST";
export const GET_VOD_VIDEO_SUCCESS = "GET_VOD_VIDEO_SUCCESS";
export const GET_VOD_VIDEO_FAILURE = "GET_VOD_VIDEO_FAILURE";
export const VOD_RESET = "VOD_RESET";


export const UPDATE_VOD_ELIGIBLE_DATE_RANGE_REQUEST = "UPDATE_VOD_ELIGIBLE_DATE_RANGE_REQUEST";
export const UPDATE_VOD_ELIGIBLE_DATE_RANGE_SUCCESS = "UPDATE_VOD_ELIGIBLE_DATE_RANGE_SUCCESS";
export const UPDATE_VOD_ELIGIBLE_DATE_RANGE_FAILURE = "UPDATE_VOD_ELIGIBLE_DATE_RANGE_FAILURE";