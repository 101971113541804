import { authorizeFirebaseUserToOrganization } from "_services/authService";
import { openDialog } from "_redux/actions/appActions";

export async function getItemCheckinListUrl(organization) {
  const firebaseHost = process.env.REACT_APP_FIREBASE_CHECKIN_APP;
  const data = {
    orgId: organization.id,
    organizationId: organization.id,
    organizationName: organization.name,
    enable_qr: true
  };
  console.log('data', data);
  const res = await authorizeFirebaseUserToOrganization(organization, data)
  const token = res.customToken;
  const parameters = `token=${token}&orgId=${organization.id}&enable_qr=true&artixOrgId=${organization.artix_id}`;
  return `${firebaseHost}/itemlist.html?${encodeURIComponent(parameters)}`;
}


export async function onItemCheckInListClick(organization, dispatch) {
  try {
    const checkinUrl = await getItemCheckinListUrl(organization)
    console.log('checkinUrl', checkinUrl);
    window.location.href = checkinUrl;
  } catch (err) {
    dispatch(openDialog({ 
      message: err.message || "Something went wrong!"
    }));
  }
}