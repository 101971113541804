import * as types from "_constants";

import {
  getPlaybillService,
  updatePlaybillService,
  importPlaybillService,
  updatePlaybillProductsService,
} from "_services/playbillService";
import { openNotification } from "./appActions";

export function getPlaybillData(productionId) {
  return async (dispatch) => {
    if (productionId) {
      dispatch({ type: types.GET_PLAYBILL_DATA_REQUEST });
      return getPlaybillService(productionId)
        .then((response) => {
          console.log('getPlaybillDataAction response', response)
          dispatch({
            type: types.GET_PLAYBILL_DATA_SUCCESS,
            payload: {
              playbill: response,
            }
          });
        }).catch((error) => {
          dispatch({
            type: types.GET_PLAYBILL_DATA_FAILURE,
          });
          const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
          dispatch({
            type: types.OPEN_DIALOG, 
            payload: {
              message: message,
            }
          })        
        })
    } else {
      dispatch({ type: types.GET_PLAYBILL_DATA_SUCCESS });
    }
  };
};

export function updatePlaybill(productionId, playbill) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PLAYBILL_REQUEST });

    return updatePlaybillService(productionId, playbill)
      .then((response) => {
        console.log('updatePlaybillAction response', response)
        dispatch({
          type: types.UPDATE_PLAYBILL_SUCCESS,
          payload: {
            playbill: response,
          }
        });
      }).catch((error) => {
        dispatch({
          type: types.UPDATE_PLAYBILL_FAILURE,
        });
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG, 
          payload: {
            message: message,
          }
        })        
      })
  };
}

export function importPlaybill(productionId) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PLAYBILL_REQUEST });

    return importPlaybillService(productionId)
      .then((response) => {
        console.log('importPlaybillService response', response)
        dispatch({
          type: types.UPDATE_PLAYBILL_SUCCESS,
          payload: {
            playbill: response,
          }
        });
        dispatch(openNotification({
          message: 'Showgram updated!'
        }))        
      }).catch((error) => {
        dispatch({
          type: types.UPDATE_PLAYBILL_FAILURE,
        });
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG, 
          payload: {
            message: message,
          }
        })        
      })
  };
}

export function updatePlaybillValue(data) {
  console.log('dispatching data', data);
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_PLAYBILL_VALUE,
      payload: {
        data,
      }
    });
  };
}

export function setCurrentPage(currentPageIndex) {
  console.log('in actions set current page to ', currentPageIndex);
  return async (dispatch) => {
    dispatch({
      type: 'ATEST',
      payload: {
        currentPageIndex,
      }
    });    
    dispatch({
      type: types.SET_CURRENT_PAGE,
      payload: {
        currentPageIndex,
      }
    });
  };
}

export function updatePlaybillProducts(productionId) {
  return async (dispatch) => {
    if (productionId) {
      return updatePlaybillProductsService(productionId)
    } else {
      dispatch({ type: types.GET_PLAYBILL_DATA_SUCCESS });
    }
  };
};
