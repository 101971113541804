import * as types from "_constants";

const initialState = {
    rows: [],
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.ACCOUNT_USERS_GET_REQUEST:
      return {
        ...state,
        rows: []
      };
    case types.ACCOUNT_USERS_GET_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };    
    case types.ACCOUNT_USER_DELETE_SUCCESS:
      const updatedRows = state.rows.filter(row => row._id !== actions.userId);
      return {
        ...state,
        rows: updatedRows,
      };
    case types.CHANGE_ROLES_SUCCESS:
      const rolesChangedRows = state.rows.map(row => {
        if (row._id === actions.data.userId) {
          return {
            ...row,
            first_name: actions.data.first_name,
            last_name: actions.data.last_name,
            roles: actions.data.roles,
          }
        }
        return row;
      })

      return {
        ...state,
        rows: rolesChangedRows,
      }
    default:
      return state;
  }
}
