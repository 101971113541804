import * as types from "_constants";

const initialState = {
  events: [],
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.GET_ALL_EVENTS_ADMIN_SUCCESS:
      return {
        ...state,
        events: actions.payload.events,
      };
    default:
      return state;
  }
}
