import axios from "_utils/axios";

// Production/Series Promote Services
export function getMarketingSchedule(params) {
  return new Promise((resolve, reject) => {
      axios.get(`productions/${params.organization_id}/get-marketing-schedule/all`, {
        params :{
          type: params.type,
          ids: JSON.stringify(params.ids),
          filter: params.filter,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function previewEmail(organizationId, postId, seriesId, CampaignReferrer) {
    return new Promise((resolve, reject) => {
      axios
        .post('organizations/' + organizationId + '/preview-email', {
            postId: postId,
            seriesId: seriesId,
            CampaignReferrer: CampaignReferrer,
          })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  

  
export function regenerateSeriesAutomationService(productionId, profileIds) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/regenerateSeriesAutomation`, {
        profileIds,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Fund Campaign Promote Services
export function getFundCampaignMarketingScheduleService(orgId, campaignId) {
  return new Promise((resolve, reject) => {
    axios
      .get(campaignId === "all"? `organizations/${orgId}/campaigns/getFundCampaignMarketingSchedule/all`: `organizations/${orgId}/campaigns/${campaignId}/getFundCampaignMarketingSchedule`, {
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteSeriesAutomationService(productionId, profileIds) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/regenerateSeriesAutomation`, {
        profileIds,
        deleteOnly: true,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function generateMarketingPackageService(productionId) {
  const r = await axios.post(`marketing/${productionId}/generate`);
  return r.data;
}

export async function getMarketingPackageService(productionId) {
  const r = await axios.get(`marketing/${productionId}/package`);
  return r.data;
}

export function getMarketingEmailDetailsService(orgId, id) {
  return new Promise((resolve, reject) => {
    axios
    .get(`productions/${orgId}/get-marketing-schedule/${id}`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function updateMarketingEmailDetailsService(orgId, id, data) {
  return new Promise((resolve, reject) => {
    axios
    .put(`productions/${orgId}/get-marketing-schedule/${id}`, data)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function sendTestPromoteEmailService(emailId, email, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${orgId}/get-marketing-schedule/test-marketing-email`, {
        email,
        emailId,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}