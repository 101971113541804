import axios from "_utils/axios";

export function listContacts(organizationId, first_name, last_name, email) {  
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${organizationId}/contact-list`, {
        params: {
          first_name,
          last_name,
          email,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function listContactSourcesService(organizationId) {  
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${organizationId}/contacts-by-source`, {
      })
      .then((response) => {
        console.log('contacts by source response', response.data);
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitContactsService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`map-contact-list/${data.orgId}`, data.configuration)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function uploadContactsFileService(data) {

  return new Promise((resolve, reject) => {
    axios
      .post(`upload-contact-list/${data.orgId}`, data.formData, {
        "Content-Type": "multipart/form-data"
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateContactService(organizationId, contactId, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${organizationId}/contact-list/${contactId}`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeContactService(data) {

  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${data.orgId}/contact-list`, {
        params: {
          contact_id: data.contactId,
        }
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function searchContactsService(organizationId, phrase) {  
  return new Promise((resolve, reject) => {
    axios
    .get(`organizations/${organizationId}/contact-list/search`, {
      params: {
        phrase,
      }
    })
    .then((response) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      resolve([]);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export function listContactsService(organizationId, filter = null) {  
    let queryString = '';
    if (filter) {
      queryString = `?filter=${filter}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(`organizations/${organizationId}/contact-list${queryString}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          resolve([]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function getContactService(orgId, contactId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`organizations/${orgId}/contacts/${contactId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export function getContactSubscriptionService(orgId, contactId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`organizations/${orgId}/contacts/${contactId}/subscription`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          }
          resolve([]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }



export function sendCcManagementLinkService(organizationId, contactId, email, stripe_customer_id) {
    return new Promise((resolve, reject) => {
        axios
        .post(`organizations/${organizationId}/contact-list/${contactId}/sendCcLink`, {
            email,
            stripe_customer_id
        })
        .then((response) => {
            if (response.status === 200) {
                resolve(response.data);
              }
              resolve([]);
            })
            .catch((error) => {
              reject(error);
            });
    })
}

export function listContactLogsService(organizationId, contactId) {  
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${organizationId}/contact-logs/${contactId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addContactLogService(orgId, contactId, contactEmail, subject, message, type, internalType, fromUserEmail) {
return new Promise((resolve, reject) => {
  console.log('posting', addContactLogService);
  axios
  .post(`organizations/${orgId}/contact-logs/${contactId}`, {
      contactEmail,
      subject,
      message,
      type,
      internalType,
      fromUserEmail,
  })
  .then((response) => {
      console.log('in addContactLog service response.data', response.data)
      if (response.status === 200) {
          resolve(response.data);
      }
      resolve([])
  })
  .catch((error) => {
      reject(error);
  });
});
}

export function addContactAttributeService(orgId, attributesArray) {
  return new Promise((resolve, reject) => {
    axios
    .post(`organizations/${orgId}/contactAttribute`, {
      attributesArray
    })
    .then((response) => {
        console.log('in addContactAttribute response.data', response.data)
        if (response.status === 200) {
            resolve(response.data);
        }
        resolve([])
    })
    .catch((error) => {
        reject(error);
    });
  });
}

export function editCustomizableFieldService(orgId, contactId, fieldID, fieldValue) {
  return new Promise((resolve, reject) => {
    axios
    .put(`organizations/${orgId}/contacts/${contactId}/updateCustomizableField`, {
      field_id: fieldID,
      field_value: fieldValue
    })
    .then((response) => {
        if (response.status === 200) {
            resolve(response.data);
        }
        resolve([])
    })
    .catch((error) => {
        reject(error);
    });
  });
}

export function updateContactLogService(orgId, contactId, logId, subject, message, internalType) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/contact-logs/${contactId}/${logId}`, {
        subject,
        message,
        internalType
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function cancelContactSubscriptionService(orgId, contactId, subscriptionId, cancelationReason) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/contacts/${contactId}/cancel-subscription/${subscriptionId}`, {
        cancelationReason
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function addContact(orgId, contact) {
  try {
    const r = await axios.post(`organizations/${orgId}/contact-list`, contact);
    return r.data;
  } catch (error) {
    throw error;
  }
}

export async function getContactAttributesService(orgId) {
  try {
    const r = await axios.get(`organizations/${orgId}/contactAttributes`);
    return r.data;
  } catch (error) {
    throw error;
  }
}

export async function editContactAttributesService(orgId, attributeId, fieldName) {
  try {
    const r = await axios.put(`organizations/${orgId}/contactAttribute/${attributeId}`, { attributeName: fieldName });
    return r.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteContactAttributeService(orgId, attributeId) {
  try {
    const r = await axios.delete(`organizations/${orgId}/contactAttribute/${attributeId}`);
    return r.data;
  } catch (error) {
    throw error;
  }
} 

export async function addContactBulk(orgId, formData) {
  try {
    const r = await axios.post(`organizations/${orgId}/contacts/import`, formData);
    return r.data;
  } catch (error) {
    throw error;
  }
}