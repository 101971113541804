import React, { useState, useEffect } from 'react';
import { Alert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import InfoSVG from '../../_pages/settings/icons/info--blue.svg'

import {
  getAccountOwnerService,
} from "_services/organizationService";

export const useStylesUpgradeMessage = makeStyles({
    overlay: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      opacity: '0.55',
      zIndex: '10000',
      backgroundColor: '#ffffff',
      display: 'block',
      cursor: 'not-allowed',
      pointerEvents: 'all !important',
    },
    sectionsContainer: {
      position: 'relative',
    },
    nonPlatinumMessage: {
        display: 'flex',
        padding: '8px 16px',
        justifyContent: 'initial',
        alignItems: 'center',
        borderRadius: '4px',
        background: '#E5F6FD',
        color: '#00728C',
        marginBottom: '16px'
      },
      nonPlatinumMessageIcon: {
        marginRight: '8px',
      },
  });
export const FeatureContainer = ({ disableFeature, children }) => {
    const classes = useStylesUpgradeMessage();
    return (
        <div className={classes.sectionsContainer}>
            {disableFeature && <div className={classes.overlay}></div>}
            {children}
        </div>
    );
}
const UpgradeMessage = ({ message }) => {
  const userRoles = useSelector(state => state.authReducer.user.roles);
  const userArtixOrgId = useSelector(state => state.authReducer.user.userOrg.artix_id);
  const orgPlan = useSelector(state => state.organizationReducer.organization.plan);
  const classes = useStylesUpgradeMessage();
  const [AccountOwner, setAccountOwner] = useState(null);

  useEffect(() => {
    const loadPayment = async () => {
      if (!AccountOwner) {
        const email = await getAccountOwnerService(userArtixOrgId);
        setAccountOwner(email);
      }
    }
    loadPayment();
  }, []);


  const contactEmail = AccountOwner?.AccountOwner ? AccountOwner?.AccountOwnerEmail : "accountmanager@onthestage.com";
  const contactName = AccountOwner?.AccountOwner ? AccountOwner?.AccountOwner : "accountmanager@onthestage.com";
  
  const handleUpgradeClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event':  'contact_am_click',
      'organization_plan': orgPlan,
      'user_role': userRoles
    });
  };
  return (
     <div className={classes.nonPlatinumMessage}>
        <img className={classes.nonPlatinumMessageIcon} src={InfoSVG} alt="Info Icon" />
            <span>
                {message} 
                <a 
                id="display_upgrade_cta" 
                href={`mailto:"${contactName}" <${contactEmail}>?subject=Upgrade to Platinum`}
                style={{color:'#0070E0', textDecoration:'underline'}} 
                onClick={handleUpgradeClick}
                >
                Account Manager
                </a>.
            </span>
      </div>
    
  );
}
export default UpgradeMessage;
