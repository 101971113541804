import { DialogTitle, Dialog, DialogContent, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const Modal = ({ 
  children,
  isOpen,
  onClose,
  title,
  width,
  hideClose,
}) => {
  const ModalTitle = () => (
    <DialogTitle disableTypography style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}>
      <Typography variant='h4'>{title}</Typography>
      {
        !hideClose && <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} /> 
      }
    </DialogTitle>
  )

  return (
    <Dialog fullWidth={true} maxWidth={width || 'sm'} open={isOpen} onClose={onClose}>
        {title && <ModalTitle>{title}</ModalTitle>}
        <DialogContent style={{ overflow: 'auto'}}>
          {children}
        </DialogContent>
    </Dialog>
  );
}

export default Modal;