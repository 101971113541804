import React from 'react';
import { TextField } from '@material-ui/core';

export const NumberTextField = ({ value, onChange, ...restProps }) => {
  const handleWheel = (e) => {
    e.target.blur();
  };  return (
    <TextField
      type="number"
      value={value}
      onChange={onChange}
      onWheel={handleWheel}
      {...restProps}
    />
  );
};
