import * as types from "_constants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
          organization: actions.organization,
          roles: actions.roles,
          is_admin: actions.is_admin,
          allowOrgSwitch: actions.allowOrgSwitch,
          verified: actions.verified,
          userOrg: {
            name: actions.orgDetails,
            artix_id: null
          },
        },
      };
    case types.GET_LOGGEDINSTATUS_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          allowOrgSwitch: actions.allowOrgSwitch,
          name: actions.name,
          organization: actions.organization,
          roles: actions.roles,
          is_admin: actions.is_admin,
          verified: actions.verified,
          userOrg: actions.userOrg,
        },
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      };

    default:
      return state;
  }
}
