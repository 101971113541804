import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import appReducer from "./appReducer";
import authReducer from "./authReducer";
import ledgerReducer from "./ledgerReducer";
import accountAccessReducer from "./accountAccessReducer";
import reportReducer from "./reportReducer";
import contactReducer from "./contactReducer";
import promoCodeReducer from "./promoCodeReducer";
import productionReducer from "./productionReducer";
import notificationReducer from "./notificationReducer";
import organizationReducer from "./organizationReducer";
import productionListReducer from "./productionListReducer";
import promoteReducer from "./promoteReducer";
import videoOnDemandReducer from "./videoOnDemandReducer";
import livestreamReducer from "./livestreamReducer";
import zoomSettingsReducer from "./zoomSettingsReducer";
import merchandiseReducer from "./merchandiseReducer";
import ordersReducer from "./ordersReducer";
import eventReducer from "./eventReducer";
import adminReducer from "./adminReducer";
import campaignReducer from "./campaignReducer";
import affiliateReducer from "./affiliateReducer";
import donationReducer from "./donationReducer";
import productReducer from "./productReducer";
import subscriptionReducer from "./subscriptionReducer";
import socialMediaReducer from "./socialMediaReducer";
import stripeConnectReducer from "./stripeConnectReducer";
import playbillReducer from "./playbillReducer";
import seatingChartReducer from "./seatingChartReducer";
import eventTierModifierReducer from "./eventTierModifierReducer";
import addonsReducer from './addonsReducer';
import offersReducer from './offersReducer';
import customFlowsReducer from './customFlowsReducer';
import communicationsReducer from './communicationsReducer';

export const rootReducer = combineReducers({
  themeReducer,
  appReducer,
  authReducer,
  ledgerReducer,
  accountAccessReducer,
  reportReducer,
  contactReducer,
  promoCodeReducer,
  productionReducer,
  notificationReducer,
  organizationReducer,
  productionListReducer,
  ordersReducer,
  promoteReducer,
  videoOnDemandReducer,
  livestreamReducer,
  zoomSettingsReducer,
  merchandiseReducer,
  eventReducer,
  adminReducer,
  campaignReducer,
  affiliateReducer,
  donationReducer,
  productReducer,
  subscriptionReducer,
  socialMediaReducer,
  stripeConnectReducer,
  playbillReducer,
  seatingChartReducer, 
  eventTierModifierReducer,
  addonsReducer,
  offersReducer,
  customFlowsReducer,
  communicationsReducer,
});