import React from "react";
import ConfirmationModal from "_components/modal/ConfirmationModal/ConfirmationModal";
import { Typography} from "@material-ui/core";
import { deleteOffer } from '_redux/actions/offerActions';
import { useDispatch, useSelector } from "react-redux";
import { openDialog, openNotification } from "_redux/actions/appActions";

function DeleteOffer({ isOpen, onClose, field }) {
  const dispatch = useDispatch();
  const orgId = useSelector(state => state.organizationReducer.organization.id)

  const handleConfirmDelete = async () => {
    try {
      await dispatch(deleteOffer(orgId, field.id));
      await onClose();
    } catch (error) {
      dispatch(openDialog({ 
        message: error.message || "Something went wrong!"
      }));
    }
  };

  return (
    <React.Fragment>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        confirmAction={handleConfirmDelete}
        confirmLabel={"Delete"}
        title={"Delete Offer"}
        showWarningIcon={true}
      >
        <Typography variant="subtitle1" gutterBottom>
          {`Delete "${field.name}" offer?`}
        </Typography>
        <Typography variant="caption" gutterBottom style={{fontStyle: 'italic'}}>
          {`Note that this action will delete data from all productions and cannot be recovered.`}
        </Typography>
      </ConfirmationModal>
    </React.Fragment>
  );
}

export default DeleteOffer;
