import React from "react";
import styled from "styled-components/macro";
import {
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Fab as MuiFab,
  Button
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@material-ui/icons';
import { Spacer } from "_components/common";
import { useDispatch } from "react-redux";
import { spacing } from '@material-ui/system';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff",
  }
}));
const TextField = styled(MuiTextField)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Image = styled.img`
  width: 100%;
`;

const ImageContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  text-align: center;
  border: 1px solid #B0B0B0;
  border-radius: 4px;
  width: 100%;
  background-color: #ffffff;
`;

const ImageLabel = styled.label`
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;  
`;

const UploadedImage = styled.img`
  width: 40px;
  cursor: pointer;
  height: auto;
  border: 4px solid #337ab7;
`;

const Fab = styled(MuiFab)`
  color: #0d47a1;
  cursor: pointer;
`;

const CloudIcon = styled(CloudUploadIcon)`
  color: #2E3A59;
`

export default function PlaybillMemberForm({ production, showPreview, member, idx=0, ...props }) {
  const classes = useStyles();
 
  return (
    <>
      <Grid item xs={6}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            id="filled-hidden-label-small"
            variant="outlined"
            name="name"
            fullWidth
            size="small"
            value={member.name}
            onChange={(e) => props.handleChange(e, idx, "creative")}
            mb={2}
            className={classes.root}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Role"
            id="filled-hidden-label-small"
            variant="outlined"
            name="role"
            fullWidth
            size="small"
            value={member.role}
            onChange={(e) => props.handleChange(e, idx, "creative")}
            mb={2}
            className={classes.root}
          />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid item xs={12}>
        { showPreview ? (
          <Image src={member.image}/>
        ) : (
          <>
          <ImageContainter>
            <ImageLabel>
                {member.image ? (
                  <>
                    <UploadedImage
                      onClick={(e) => props.handleUploadClick(e, idx)}
                      width="100%"
                      src={`https://res.cloudinary.com/onthestage/image/upload/f_auto/${member.image}`} 
                    />
                  </>
                ) :
                  <Fab component="span" size="medium" onClick={(e) => props.handleUploadClick(e, idx)} >
                    <CloudIcon fontSize="small" />
                  </Fab>
                }
              </ImageLabel>
              <Typography variant="h6" mb={1}>Click to upload</Typography>
          </ImageContainter>
          </>
        )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Bio"
          id="filled-hidden-label-small"
          variant="outlined"
          name="bio"
          fullWidth
          multiline
          rows={2}
          size="small"
          value={member.bio}
          onChange={(e) => props.handleChange(e, idx, "creative")}
          mb={2}
          className={classes.root}
        />
        <Spacer my={3} />
        
        <Grid container justify="flex-start" direction="row" spacing={2}>
          <Grid item>
            <Button 
              align="left" 
              variant="outlined" 
              color="primary" 
              aria-label="save-icon"
              onClick={() => props.handleSave(idx)} 
              startIcon={<SaveOutlinedIcon fontSize="small" />}
            >
              {idx === 'new' ? 'Create' : 'Save'}
            </Button>
          </Grid>
          <Grid item>
            <Button 
              align="left" 
              variant="outlined" 
              color="primary" 
              aria-label="delete-icon"
              onClick={() => props.handleRemove(idx)}
              startIcon={<DeleteOutlinedIcon fontSize="small" />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Spacer my={4} />
      </Grid>
    </>
  )
}