import * as types from "_constants";

const initialState = {
    connectionSettings: null,
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        connectionSettings: null
      };
    case types.LIVESTREAM_CONNECTION_SETTINGS_GET_REQUEST_SUCCCESS:
      return {
        ...state, 
        connectionSettings: actions.payload,
      };   
    default:
      return state;
  }
}
