import React from 'react';
import {
  Tooltip,
  Switch,
} from "@material-ui/core";

function TooltipSwitch({ checked, handleCheck, name, placement, disabled }) {
  return (
    <Tooltip title={checked ? "Active" : "Inactive"} placement={placement}>
      <Switch
        checked={checked}
        onChange={handleCheck}
        name={name}
        inputProps={{ 'aria-label': `${name}` }}
        disabled={disabled}
      />
  </Tooltip>
  )
}

export default TooltipSwitch;