import * as types from "_constants";

const initialState = {
    reportList: [
        {
          "excluded_formats": [],
          "_id": 4351163,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Cash Sales Report",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 1572966,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Credit Card Flat Fees",
          "description": "Credit Card Flat Fee Report",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 1207468,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Donations",
          "description": "Donations",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 3023950,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Event Check In Report",
          "description": "Event Performance Sales / Check in Report",
          "admin_only": false,
          "excel": 3297994
        },
        {
          "excluded_formats": [],
          "_id": 4869341,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Event Contact List",
          "description": "Event Contact List",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 4128725,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Event Detailed Order Report",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086097,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Event Fees",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086193,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Event Sales",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 995496,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Merchandise Sales",
          "description": "Merchandise Sales",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6924834,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Patron Attendance Report",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 4177235,
          "organization_id": true,
          "production_id": true,
          "event_id": false,
          "start_date": false,
          "end_date": false,
          "name": "Production Detailed Order Report",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086143,
          "organization_id": true,
          "production_id": true,
          "event_id": false,
          "start_date": false,
          "end_date": false,
          "name": "Production Fees",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086243,
          "organization_id": true,
          "production_id": true,
          "event_id": false,
          "start_date": false,
          "end_date": false,
          "name": "Production Sales",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 5683843,
          "organization_id": true,
          "production_id": true,
          "event_id": false,
          "start_date": false,
          "end_date": false,
          "name": "Production Settlement",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 7486321,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Refunds",
          "description": " ",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 3652796,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Subscriptions",
          "description": "This is the Monthly Overview ",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 7466039,
          "organization_id": true,
          "production_id": true,
          "event_id": true,
          "start_date": false,
          "end_date": false,
          "name": "Ticket Donations",
          "description": " ",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6957850,
          "organization_id": true,
          "production_id": true,
          "event_id": false,
          "start_date": false,
          "end_date": false,
          "name": "Ticket Sales By Source",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086285,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Total Revenue by Event Date",
          "description": "",
          "admin_only": false
        },
        {
          "excluded_formats": [],
          "_id": 6086333,
          "organization_id": true,
          "production_id": false,
          "event_id": false,
          "start_date": true,
          "end_date": true,
          "name": "Total Revenue by Order Date",
          "description": "",
          "admin_only": false
        }
      ],
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.REPORTLIST_GET_REQUEST:
      return {
        ...state,
      };
    case types.REPORTLIST_GET_SUCCESS:
      return {
        ...state, 
        reportList: actions.payload.reportList

      };    
    default:
      return state;
  }
}
