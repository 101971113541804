import * as types from "_constants";

const initialState = {
    registrants: [],
    schedule: [],
    currentMeeting: null,
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {      
    case types.GET_ZOOM_SCHEDULE_SUCCESS:
      return {
        ...state, 
        registrants: actions.payload.registrants || [],
        schedule: actions.payload.schedule,
      };   
    case types.ZOOM_SCHEDULE_CLEAR_CURRENT_MEETING:
      return {
        ...state,
        currentMeeting: null,
      }
    case types.ZOOM_SCHEDULE_GET_MEETING_SUCCESS:
      return {
        ...state, 
        currentMeeting: actions.payload,
        registrants: actions.payload.registrants,
      };         
    default:
      return state;
  }
}
