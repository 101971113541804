import * as types from "_constants";

const initialState = {
  events: [],
  seatingChart: {},
  eventsDistinctTimes: []
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.GET_EVENTS_BY_ARTIX_ID_SUCCESS:
      return {
        ...state,
        events: actions.payload.rows,
      };
    case types.GET_EVENT_SEATING_CHART_SUCCESS:
      return {
        ...state,
        seatingChart: actions.payload.seatingChart,
      };
    case types.GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_SUCCESS:
      return {
        ...state,
        events: actions.payload.events,
      };
    case types.GET_PRODUCTION_EVENTS_SUCCESS:
      return {
        ...state,
        events: actions.payload.events,
      };
    case types.BULK_CREATE_PERFORMANCES_SUCCESS:
      return {
        ...state,
        events: state.events.concat(actions.payload.events),
      };
    case types.BULK_UPDATE_PERFORMANCES_SUCCESS:
      return {
        ...state,
        events: actions.payload.events,
      };
    case types.BULK_DELETE_PERFORMANCES_SUCCESS:
      return {
        ...state,
        events: actions.payload.events,
      };
    case types.GET_EVENTS_DISTINCT_TIMES_SUCCESS:
      return {
        ...state,
        eventsDistinctTimes: actions.payload.eventsDistinctTimes,
      };
    default:
      return state;
  }
}
