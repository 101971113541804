import Modal from "_components/modal/Modal";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Breadcrumbs, Button, Card, Paper } from "_components/common";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  List,
  Grid,
  ListItem,
  CardContent,
  Checkbox,
  Link,
  Typography,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
} from "@material-ui/core";
import PrintTickets from "_components/print-tickets/PrintTickets";
import {
  getPlansBySubscriptionService,
  getSubscriptionService,
} from "_services/subscriptionService";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import moment from "moment";
import useDateTimeFormatter from "_utils/dateTimeUtils";

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCellHead = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 600;
`;
const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;
export function SubscriptionEventSelectList({
  events,
  setEvents,
  subscription,
}) {
  const organization = useSelector(
    (state) => state.organizationReducer.organization
  );
  
  const { formatTimezone } = useDateTimeFormatter({
    format: "YYYY-MM-DD HH:mm",
    timezone: organization?.timezone,
  });

  const history = useHistory();

  return (
    <List>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                marginRight: "10px",
                marginLeft: "-12px",
                padding: "8px",
              }}
            >
              <div
                style={{
                  width: "24px",
                }}
              ></div>
            </div>
            <p
              style={{
                width: "7%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Order Date
            </p>
            <p
              style={{
                width: "20%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Plan ID
            </p>
            <p
              style={{
                width: "22%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Email
            </p>
            <p
              style={{
                width: "10%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              First Name
            </p>
            <p
              style={{
                width: "10%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Last Name
            </p>
            <p
              style={{
                width: "10%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              # Redeemed
            </p>
            <p
              style={{
                width: "10%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Print All
            </p>
            <p
              style={{
                width: "10%",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              Print Unprinted
            </p>
          </div>
          {events.map((event) => (
            <>
              <div
                key={event.id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  onClick={async () => {
                    setEvents((prev) => {
                      return prev.map((it) => {
                        if (it.plan_key === event.plan_key) {
                          return {
                            ...it,
                            expanded: !it.expanded,
                          };
                        }
                        return it;
                      });
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    marginLeft: "-12px",
                    padding: "8px",
                  }}
                >
                  {event.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <p
                  style={{
                    width: "7%",
                    fontSize: "13px",
                  }}
                >
                  {moment(event?.createdAt).format("MM/DD/YYYY")}
                </p>
                <p
                  style={{
                    width: "20%",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (event?.Order?.id) {
                      history.push(`/orders/${event?.Order?.id}`);
                    }
                  }}
                >
                  {event.plan_key}
                </p>
                <p
                  style={{
                    width: "22%",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (event?.Order?.contact_id) {
                      history.push(`/contacts/${event?.Order?.contact_id}`);
                    }                  
                  }}
                >
                  {event?.Order?.email}
                </p>
                <p
                  style={{
                    width: "10%",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (event?.Order?.contact_id) {
                      history.push(`/contacts/${event?.Order?.contact_id}`);
                    }
                  }}
                >
                  {event?.Order?.first_name}
                </p>
                <p
                  style={{
                    width: "10%",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (event?.Order?.contact_id) {
                      history.push(`/contacts/${event?.Order?.contact_id}`);
                    }
                  }}
                >
                  {event?.Order?.last_name}
                </p>
                <p
                  style={{
                    width: "10%",
                    fontSize: "13px",
                  }}
                >
                  {event?.Tickets?.length}/{subscription?.number_of_tickets}
                </p>
                <div
                  style={{
                    width: "10%",
                    fontSize: "13px",
                  }}
                >
                  <Checkbox
                    checked={
                      event?.Tickets.length > 0
                        ? event?.Tickets?.every((ticket) => ticket.selected)
                        : false
                    }
                    tabIndex={-1}
                    disableRipple
                    disabled={event?.Tickets.length === 0}
                    onChange={() => {
                      setEvents((prev) => {
                        return prev.map((it) => {
                          if (it.plan_key === event.plan_key) {
                            return {
                              ...it,
                              Tickets: it.Tickets.map((ticket) => {
                                return {
                                  ...ticket,
                                  selected: event.Tickets.every(
                                    (ticket) => ticket.selected
                                  )
                                    ? false
                                    : true,
                                };
                              }),
                            };
                          }
                          return it;
                        });
                      });
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "10%",
                  }}
                >
                  <Checkbox
                    checked={
                      event?.Tickets.length > 0
                        ? event?.Tickets?.filter(
                            (ticket) => ticket.TicketLogs.length === 0
                          ).length > 0
                          ? event?.Tickets?.filter(
                              (ticket) => ticket.TicketLogs.length === 0
                            )?.every((ticket) => ticket.selected)
                          : false
                        : false
                    }
                    tabIndex={-1}
                    disableRipple
                    disabled={event?.Tickets.length === 0}
                    onChange={() => {
                      console.log(
                        event?.Tickets.length > 0
                        ? event?.Tickets?.filter(
                            (ticket) => ticket.TicketLogs.length === 0
                          )?.every((ticket) => ticket.selected)
                        : false
                      );
                      setEvents((prev) => {
                        return prev.map((it) => {
                          if (it.plan_key === event.plan_key) {
                            return {
                              ...it,
                              Tickets: it.Tickets.map((ticket) => {
                                if(ticket.TicketLogs.length === 0){
                                return {
                                  ...ticket,
                                  selected: event.Tickets.filter(
                                    (ticket) => ticket.TicketLogs.length === 0
                                  ).every((ticket) => ticket.selected)
                                    ? false
                                    : true,
                                };
                              } else {
                                return {
                                  ...ticket,
                                  selected: false,
                                };
                              }
                              }),
                            };
                            
                          }
                          return it;
                        });
                      });
                    }}
                  />
                </div>
              </div>
              {event.expanded && (
                <Table
                  style={{
                    marginBottom: "30px",
                  }}
                >
                  {event.Tickets?.length === 0 ? (
                    <p
                      style={{
                        textAlign: "center",
                        width: "100%",
                        marginTop: "20px",
                      }}
                    >
                      No tickets to print
                    </p>
                  ) : (
                    <>
                      <TableHead>
                        <TableRow>
                          <TableCellHead>Order ID</TableCellHead>
                          <TableCellHead>Production Name</TableCellHead>
                          <TableCellHead>Date</TableCellHead>
                          <TableCellHead>Section</TableCellHead>
                          <TableCellHead>Seat</TableCellHead>
                          <TableCellHead>Last Printed</TableCellHead>
                          <TableCellHead>Print</TableCellHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          <TableCell component="th" scope="row">
                            <Checkbox
                              tabIndex={-1}
                              disableRipple
                              checked={event.Tickets.every(
                                (ticket) => ticket.selected
                              )}
                              onChange={() => {
                                // slecet all and unselect all
                                setEvents((prev) => {
                                  return prev.map((it) => {
                                    if (it.plan_key === event.plan_key) {
                                      return {
                                        ...it,
                                        Tickets: it.Tickets.map((it) => {
                                          return {
                                            ...it,
                                            selected: !event.Tickets.every(
                                              (ticket) => ticket.selected
                                            ),
                                          };
                                        }),
                                      };
                                    }
                                    return it;
                                  });
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {event.Tickets?.map((ticket) => (
                        <TableBody key={ticket?.id}>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              #{ticket?.OrderId}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ticket?.Event?.Series.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ticket?.Event.eventDayAndTimeShort}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ticket?.section}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ticket?.seat_number}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {ticket?.TicketLogs?.length > 0
                                ? formatTimezone(
                                    ticket?.TicketLogs?.[
                                      ticket?.TicketLogs.length - 1
                                    ]?.createdAt
                                  )
                                : ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Checkbox
                                checked={ticket?.selected}
                                tabIndex={-1}
                                disableRipple
                                onChange={() => {
                                  setEvents((prev) => {
                                    return prev.map((it) => {
                                      if (it.plan_key === event.plan_key) {
                                        return {
                                          ...it,
                                          Tickets: it.Tickets.map((it) => {
                                            if (it.id === ticket.id) {
                                              return {
                                                ...it,
                                                selected: !it.selected,
                                              };
                                            }
                                            return it;
                                          }),
                                        };
                                      }
                                      return it;
                                    });
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </>
                  )}
                </Table>
              )}
            </>
          ))}
        </List>
      </Collapse>
    </List>
  );
}
const SubscriptionViewPage = () => {
  const [showTicketsModal, setShowTicketsModal] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [events, setEvents] = useState([]);
  const [subscription, setSubscription] = useState({});
  const organization = useSelector(
    (state) => state.organizationReducer.organization
  );

  const params = useParams();
  useEffect(() => {
    const loadSubscriptionTickets = async () => {
      const getPlansBySubscription = await getPlansBySubscriptionService(
        organization.artix_id,
        params.id
      );
      if (getPlansBySubscription.status === 200) {
        setEvents(
          getPlansBySubscription.obj.map((it) => ({
            ...it,
            selected: false,
            expanded: false,
            Tickets: it.Tickets.map((ticket) => ({
              ...ticket,
              selected: false,
              Order: it.Order || {},
            })),
          }))
        );
      }
    };
    if (organization?.artix_id) {
      loadSubscriptionTickets();
    }

    const loadSubscription = async () => {
      const subscription = await getSubscriptionService(organization.artix_id, params.id);
      if (subscription) {
        setSubscription(subscription);
      }
    };
    loadSubscription();
  }, [params, organization?.artix_id]);
  return (
    <>
      <Modal
        isOpen={showTicketsModal}
        onClose={() => setShowTicketsModal(false)}
        title="Print Hard Tickets"
        width="md"
      >
        <PrintTickets tickets={tickets?.filter?.((t) => t.selected)} />
      </Modal>
      <div data-testid="root-div">
        <Helmet title="Subscription View" />
        <Grid justify="space-between" container spacing={10}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              {subscription?.Product?.name}
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link component={NavLink} exact to="/">
                Dashboard
              </Link>
              <Link component={NavLink} exact to="/subscriptions">
                Subscriptions
              </Link>
              <Typography>View Subscription</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              mt={2}
              ml={2}
              onClick={() => {
                setShowTicketsModal(!showTicketsModal);
                setTickets(
                  events
                    .map((it) => it.Tickets)
                    .flat()
                    .filter((it) => it.selected)
                );
              }}
            >
              Print Hard Ticket
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            style={{
              minWidth: "1000px",
            }}
          >
            <Card>
              <CardContent>
                <Paper>
                  <SubscriptionEventSelectList
                    events={events}
                    setEvents={setEvents}
                    subscription={subscription}
                  />
                </Paper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default SubscriptionViewPage;
