const en = {
  unitTest: {
    foo: 'bar',
    bar: (baz) => `foo, bar, ${baz}`
  },
  draftTiers: {
    recommendedFeeInfo: (recommendedFee) => `In order to encourage pre-sales and ensure that your organization does not lose money on Walk Up fees, our recommended Walk Up ticket price is ${recommendedFee} more than your designated online pricing. To override the recommended pricing, set Walk Up prices manually in the appropriate field(s).`,
    zeroDollarTicketWarning: (localizedZero) => `All free tickets have fees included, so your patron will pay ${localizedZero} and you are responsible for ticket fees. Are you sure you want to proceed?`
  },
  setTiers: {
    recommendedFeeInfo: (recommendedFee) => `To encourage pre-sales and ensure that your organization achieves expected revenue on Walk Up fees, our recommended Walk Up ticket price is ${recommendedFee} more than your online pricing per ticket. To override the recommended pricing, set Walk Up prices manually in the appropriate field(s). Your organization is still responsible for all applicable fees.`,
    extraFeesInfo: 'Important Notice: All fees (Credit Card, Tax, and OTS) apply to both online & Walk Up Ticket Sales. Fees are applied to only Online Ticket Sales. Walk Up ticket prices should be adjusted to cover all fees.',
  },
  subscriptionsListPage: {
    headCells: {
      name: 'Name',
      type: 'Type',
      startDate: 'Sale start',
      endDate: 'Sale end',
      redemptionStartDate: 'Redemption start',
      redemptionEndDate: 'Redemption end',
      countSales: '# of Orders',
      totalSales: (currencySymbol) => `${currencySymbol} Sales`,
      salesAmount: "Sales Amount",
      action: 'Actions'
    },
  },
  unitTest: {
    foo: 'bar',
    bar: (baz) => `foo, bar, ${baz}`
  },
  feeCalculator: {
    seatFees: {
      free: 'PIF Free',
      basic: 'Basic',
      plus: 'Plus',
      pro: 'Pro',
      empower: 'Empower',
      platinum: 'Platinum',
    }
  },
  billing: {
    seatFees: {
      free: {
        name: 'PIF Free 0',
        description: (currencySymbol) => `PIF Fee Plan (${currencySymbol}0.00 per ticket sold)`,
      },
      basic: {
        name: 'Basic .85',
        description: (currencySymbol) => `Basic Fee Plan (${currencySymbol}0.85 per ticket sold)`,
      },
      basicLegacy: {
        name: 'Basic Legacy .75',
        description: (currencySymbol) => `Basic Fee Plan (${currencySymbol}0.75 per ticket sold)`,
      },
      plus: {
        name: 'Plus 1.05',
        description: (currencySymbol) => `Plus Fee Plan (${currencySymbol}1.05 per ticket sold)`,
      },
      pro: {
        name: 'Pro 1.20',
        description: (currencySymbol) => `Pro Fee Plan (${currencySymbol}1.20 per ticket sold)`,
      },
      empower: {
        name: 'Empower 1.35',
        description: (currencySymbol) => `Empower Fee Plan (${currencySymbol}1.35 per ticket sold)`,
      },
      platinum: {
        name: 'Platinum 1.85',
        description: (currencySymbol) => `Platinum Fee Plan (${currencySymbol}1.85 per ticket sold)`,
      },
      select: {
        name: 'Select Seat Fee',
        description: null,
      },
    },
  },
}

export default en;