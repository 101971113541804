import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components/macro";
import Header from "_components/AppBar";
import Footer from "_components/Footer";
import { useParams } from "react-router-dom";
import { getLoggedInStatus } from "_redux/actions/authActions";
import { useHistory } from "react-router-dom";
import { SHOW_NOTIFICATION } from "_constants"
import { getProductionById } from "_redux/actions/productionActions"
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { isWidthUp } from "@material-ui/core/withWidth";
import ErrorBoundary from "_components/ErrorBoundary";
import WalkupSidebar from "_components/WalkupSidebar";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;


const WalkupLayout = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const user = useSelector((state) => state.authReducer.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const cb = () => history.push("/auth/sign-in");
  const newPasswordCb = () => history.push("/auth/create-password");

  useEffect(() => {
    const load = async () => {
      if (!user) {
        dispatch(getLoggedInStatus(cb, newPasswordCb));
      }
    }
    load();
  }, [dispatch, user])
  
  const params = useParams();


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Hidden mdUp implementation="js">
            <WalkupSidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              user={user}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <WalkupSidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              user={user}
            />
          </Hidden>
        </Drawer>
        <AppContent>
            <Header user={user}  onDrawerToggle={handleDrawerToggle} />
            <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
              {children}
            </MainContent>
            <Footer />
        </AppContent>
        {/* <Settings /> */}
      </Root>
  );
};

export default withWidth()(WalkupLayout);