import Modal from '../Modal';
import { Button, Grid } from '@material-ui/core';

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
    <mask id="mask0_177_1468" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="96">
    <rect width="96" height="96" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_177_1468)">
    <path d="M4 84L48 8L92 84H4ZM48 72C49.1333 72 50.0833 71.6167 50.85 70.85C51.6167 70.0833 52 69.1333 52 68C52 66.8667 51.6167 65.9167 50.85 65.15C50.0833 64.3833 49.1333 64 48 64C46.8667 64 45.9167 64.3833 45.15 65.15C44.3833 65.9167 44 66.8667 44 68C44 69.1333 44.3833 70.0833 45.15 70.85C45.9167 71.6167 46.8667 72 48 72ZM44 60H52V40H44V60Z" fill="#D32F2F"/>
    </g>
  </svg>
)

const ConfirmationModal = ({ isOpen, onClose, confirmAction, confirmLabel, children, title, showWarningIcon, showCancelButton = true }) => {
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} hideTopbar={!title} customWidth={'500px'} hideClose={true}>
      <Grid container>
        {
          showWarningIcon && 
          <Grid container alignItems='center' justify='center' item xs={12} sm={12} style={{'marginBottom': '1rem'}}>
            <WarningIcon />
          </Grid>
        }
        <Grid item xs={12} sm={12} style={{'marginBottom': '1rem'}}>
            {children}
        </Grid>
        <Grid item xs={12} sm={12} justify={'flex-end'} container style={{'marginBottom': '.35em'}}>
          {showCancelButton && (
            <Button color="primary" style={{marginRight: '1rem'}} onClick={onClose}>Cancel</Button>
          )}
          <Button color="primary" variant="contained" onClick={confirmAction}>{confirmLabel}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ConfirmationModal;