import * as types from "_constants";
import {
  signIn as authSignIn,
  signUp as authSignUp,
  signOut as authsignOut,
  resetPassword as authResetPassword,
  createPassword as authCreatePassword,
  getLoggedInStatus as authGetLoggedInStatus,
} from "_services/authService";

export function signIn(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

    return authSignIn(credentials)
      .then((response) => {
        localStorage.setItem('user', response.id)
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
          organization: response.organization,
          roles: response.roles,
          is_admin: response.is_admin,
          verified: response.verified,
          userOrg: response.userOrg,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
        throw error;
      });
  };
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    return authsignOut()
      .then((response) => {
        localStorage.removeItem("user");
        dispatch({
          type: types.AUTH_SIGN_OUT,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function createPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_CREATE_PASSWORD_REQUEST });
    return authCreatePassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_CREATE_PASSWORD_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_CREATE_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function changePassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.CHANGE_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.CHANGE_PASSWORD_FAILURE });
        throw error;
      });
  };
}

export function getLoggedInStatus(cb, newPasswordCb) {
  return async (dispatch) => {
    return authGetLoggedInStatus()
      .then((response) => {
        const userId = response?._id;
        if (!userId && cb) {
          localStorage.removeItem('user', userId)
          cb();
        } else if (userId && response?.requiresNewPassword) {
          newPasswordCb();
        } else {
          dispatch({
            type: types.GET_LOGGEDINSTATUS_SUCCESS,
            ...response,
          });  
        }
      })
      .catch((error) => {
        localStorage.removeItem("user");
        dispatch({ type: types.GET_LOGGEDINSTATUS_FAILURE });
        if (cb) {
          cb();
        } else {
          throw error;
        }         
      });
  };
}