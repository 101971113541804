import * as types from "_constants";

const initialState = {
  dialogStatus: {
    open: false,
    info: {
      title: "",
    },
  },
  notificationStatus: {
    open: false,
    message: "",
  },
  waitingStatus: {
    open: false,
    title: '',
    message: '',
  }
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {

    case types.SHOW_WAITING:
      console.log('actionsactions', actions);
      return {
        ...state,
        waitingStatus: {
          title: actions.payload.title || 'Please Wait',
          message: actions.payload.message || 'Please Wait',
          open: true,
        }
      };
    case types.CLOSE_WAITING:
      return {
        ...state,
        waitingStatus: {
          title: '',
          message: '',
          open: false,
        }
      };      

    case types.OPEN_DIALOG:
      return {
        ...state,
        dialogStatus: {
          ...state.dialogStatus,
          severity: actions.payload.severity || 'error',
          title: actions.payload.title || 'Error',
          open: true,
          message: actions.payload.message,
        }
      };
    case types.HIDE_DIALOG:
      return {
        ...state,
        dialogStatus: {
          ...state.dialogStatus,
          open: false,
        }
      };

    case types.SHOW_NOTIFICATION:
      return {
        ...state,
        notificationStatus: {
          open: true,
          status: actions.payload.status,
          message: actions.payload.message,
        }
      };

    case types.HIDE_NOTIFICATION:
      return {
        ...state,
        notificationStatus: {
          open: false,
          message: "",
          status: "",
        }
      };

    default:
      return state;
  }
}
