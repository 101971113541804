import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components/macro";
import { green } from "@material-ui/core/colors";

import {
    Chip,
  } from "@material-ui/core";

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 4px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;


function getPlanLabel(plan) {
    switch (plan) {
        case 'Pro':
        case 'Empower':
            return 'EMPOWER';
        default:
            return plan.toUpperCase();        
    }
}

export const OrganizationPlanChip = ({user}) => {
  return (
    user?.userOrg?.plan ? 
        <BrandChip label={getPlanLabel(user?.userOrg?.plan)} />
        : null
  )
};
