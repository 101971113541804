import React from "react";
import { Document } from "@react-pdf/renderer";
import SingleTicket from "./SingleTicket";

const AllTickets = ({ order, tickets, maxProdNameLength }) => {
  return (
    <Document
      style={{
        display: "none",
      }}
    >
      {order
        ? order.tickets.map((ticket) => {
            return (
              <SingleTicket key={ticket.id} order={order} ticket={ticket} maxProdNameLength={maxProdNameLength} />
            );
          })
        : tickets.map((ticket) => {
            return (
              <SingleTicket
                key={ticket.id}
                order={ticket?.Order}
                ticket={ticket}
                maxProdNameLength={maxProdNameLength}
              />
            );
          })}
    </Document>
  );
};

export default AllTickets;
