import * as types from "_constants";

const initialState = {
  connectionSettings: null,
  progress: 0,
  inProgress: false,
  video: {},
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_VOD_LINK_SUCCESS:
      return {
        ...state,
        uploadUrl: actions.data?.url ? actions.data.url : state.uploadUrl,
        upload_progress_url: actions.data?.upload_progress_url ? actions.data.upload_progress_url : state.upload_progress_url,
      };
    case types.VOD_RESET:
      console.log("in vod reset");
      return {
        connectionSettings: null,
        progress: 0,
        inProgress: false,
        video: {},   
        uploadUrl: null,
        upload_progress_url: null,     
      };
    case types.GET_UPDATED_VOD_UPLOAD_URL_SUCCESS:
      return {
        ...state,
        uploadUrl: actions.data?.url ? actions.data.url : state.uploadUrl,
        upload_progress_url: actions.data?.upload_progress_url ? actions.data.upload_progress_url : state.upload_progress_url,
      };
    case types.GET_VOD_VIDEO_SUCCESS:
      return {
        ...state,
        uploadUrl: actions.data.url,
        upload_progress_url: actions.data.upload_progress_url,
        video: actions.data,
      };
    default:
      return state;
  }
}
