import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";

const SingleTicketView = ({ mode, ticket, order, qrCode, maxProdNameLength }) => {
  const maxLength = maxProdNameLength || 50;

  const isTextTooLong = (text, maxLength) => {
    return text?.length > maxLength;
  };

  const ellipsisText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
  

  return (
    <Page
      size={["5.5in", "2in"]} // 4.5 inches by 2 inches in points for PDF
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#fff",
        paddingLeft: 14,
        paddingRight: 2,
        paddingTop: 8,
        paddingBottom: 8,
        fontFamily: "Open Sans",
      }}
    >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "70px",
            paddingRight: 6,
            borderRight: "4px solid #000",
            textAlign: "start",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "8px": "10px",
                fontWeight: "400",
                flexWrap: "nowrap",
              }}
            >
              Section
            </Text>
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "9px": "10px",
                fontWeight: "600",
              }}
            >
              {mode === "soldReserved" || mode === "unsoldReserved"
                ? ticket?.section 
                : "GA"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "8px": "10px",
                fontWeight: "400",
              }}
            >
              Row
            </Text>
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "9px": "10px",
                fontWeight: "600",
              }}
            >
              {mode === "soldReserved" || mode === "unsoldReserved"
                ? ticket?.seat_row
                : "-"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "8px": "10px",
                fontWeight: "400",
              }}
            >
              Seat
            </Text>
            <Text
              style={{
                fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "9px": "10px",
                fontWeight: "600",
              }}
            >
              {mode === "soldReserved" || mode === "unsoldReserved"
                ? ticket?.seat_number
                : "-"}
            </Text>
          </View>
        </View>
      <View
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingRight: 4,
          paddingLeft: 12,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            width: "267px",
            height: "100%",
            paddingRight: 8,
            overflow: "hidden",
          }}
        >
          <View
            style={{
              textAlign: "start",
            }}
          >
            <Text
              style={{
                fontSize: !isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "11px" : "9px",
                fontWeight: "600",
                flexWrap: "nowrap",
              }}
            >
              {ticket?.Event?.Series?.name}
            </Text>
            <Text
              style={{
                fontSize: !isTextTooLong(ticket?.Event?.Series?.name, maxLength)? "10px": "8px",
                fontWeight: "400",
                flexWrap: "nowrap",
                textAlign: "start",
              }}
            >
              {ticket?.Event?.eventDayAndTimeShort}
            </Text>
            <Text
              style={{
                marginTop: 6,
                fontSize: !isTextTooLong(ticket?.Event?.Series?.name, maxLength) ?"11px": "9px",
                fontWeight: "600",
                flexWrap: "nowrap",
                textAlign: "start",
              }}
            >
              {ticket?.Order?.first_name
                ? ticket?.Order?.first_name
                : order?.first_name}{" "}
              {ticket?.Order?.last_name
                ? ticket?.Order?.last_name
                : order?.last_name}
            </Text>
          </View>
          <Text
            style={{
              marginTop: 4,
              fontSize: !isTextTooLong(ticket?.Event?.Series?.name, maxLength) ?"11px": "9px",
              fontWeight: "600",
              textAlign: "start",
            }}
          >
            {mode === "soldReserved" || mode === "unsoldReserved"
              ? "Reserved Seating"
              : "General Admission"}
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 4,
              textAlign: "start",
            }}
          >
            {/* {!isTextTooLong(ticket?.tier, maxLength) && ( */}
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width:
                    mode === "unsoldReserved" || mode === "unsoldGeneral"
                      ? "100%"
                      : "50%",
                }}
              >
                <Text
                  style={{
                    fontSize: isTextTooLong(ticket?.tier, maxLength) ? "8px": "9px",
                    fontWeight: "400",
                  }}
                >
                  Type
                </Text>
                <Text
                  style={{
                    fontSize: isTextTooLong(ticket?.tier, maxLength) ? "9": "10px",
                    fontWeight: "600",
                  }}
                >
                  {ticket?.tier}
                </Text>
              </View>
            {/* )} */}
            {/* <View
              style={{
                display: "flex",
                flexDirection: "column",
                width:
                  mode === "unsoldReserved" || mode === "unsoldGeneral"
                    ? "50%"
                    : "33.33%",
              }}
            >
              <Text
                style={{
                  fontSize: "9px",
                  fontWeight: "400",
                }}
              >
                Variant
              </Text>
              <Text
                style={{
                  fontSize: "11px",
                  fontWeight: "600",
                }}
              >
                Variant 1
              </Text>
            </View> */}
          </View>
        </View>
        {order && (
          <View
            style={{
              height: "100%",
              width: "95px",
              minWidth: "95px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "80px",
                height: "80px",
                backgroundColor: "#000",
                padding: 6,
              }}
            >
              <Image
                src={qrCode}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </View>
            <Text
              style={{
                fontSize: "9px",
                fontWeight: "400",
              }}
            >
              {order?.["confirmation_code"]}
            </Text>
          </View>
        )}
      </View>

      <View
        style={{
          width: "1in",
          minWidth: "1in",
          maxWidth: "1in",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            transform: "rotate(-90deg)",
            minWidth: "2in",
            minHeight: "1in",
            maxHeight: "1in",
            padding: 8,
            paddingTop: 12,
          }}
        >
          <Text
            style={{
              fontSize: "7px",
              fontWeight: "600",
              marginTop: 4,
            }}
          >
            {ellipsisText(ticket?.Event?.Series?.name, maxLength)}
          </Text>

          <Text
            style={{
              fontSize: "7px",
              fontWeight: "400",
              marginTop: 2,
            }}
          >
            {ticket?.Event?.eventDayAndTimeShort}
          </Text>
          <Text
            style={{
              marginTop: 4,
              fontSize: "7px",
              fontWeight: "600",
            }}
          >
            {mode === "soldReserved" || mode === "unsoldReserved"
              ? "Reserved Seating"
              : "General Admission"}
          </Text>
          <View
            style={{
              marginTop: 4,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: 40,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "400",
                }}
              >
                Section
              </Text>
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "600",
                }}
              >
                {mode === "soldReserved" || mode === "unsoldReserved"
                  ? ticket?.section
                  : "GA"}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "400",
                }}
              >
                Row
              </Text>
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "600",
                }}
              >
                {mode === "soldReserved" || mode === "unsoldReserved"
                  ? ticket?.seat_row
                  : "-"}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "400",
                }}
              >
                Seat
              </Text>
              <Text
                style={{
                  fontSize: isTextTooLong(ticket?.Event?.Series?.name, maxLength) ? "5px": "7px",
                  fontWeight: "600",
                }}
              >
                {mode === "soldReserved" || mode === "unsoldReserved"
                  ? ticket?.seat_number
                  : "-"}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default SingleTicketView;