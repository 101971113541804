import axios from "_utils/axios";


export function listSubscriptionsService(orgId, only_ongoing = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/subscriptions`, {
        params: {
          only_ongoing
        }
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getSubscriptionDashboardDataService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/getSubscriptionDashboardData`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createSubscriptionService(artixOrgId, subscriptionData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${artixOrgId}/subscriptions`, subscriptionData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function getSubscriptionService(artixOrgId, subscriptionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${artixOrgId}/subscriptions/${subscriptionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDuplicateSubscriptionService(artixOrgId, subscriptionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${artixOrgId}/subscriptions/${subscriptionId}/duplicate`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function getPlansBySubscriptionService(orgId, subscriptionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/subscriptions/getPlansBySubscription/${subscriptionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

}


export function updateSubscriptionService(artixOrgId, subscriptionId, subscriptionData) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${artixOrgId}/subscriptions/${subscriptionId}`, subscriptionData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSubscriptionActiveStatusService(artixOrgId, subscriptionId, active) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${artixOrgId}/subscriptions/${subscriptionId}/updateActiveStatus`, {
        value: active,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteSubscriptionService(artixOrgId, subscriptionId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${artixOrgId}/subscriptions/${subscriptionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getEligibleProductionsForNewSubscriptionsService() {
  return new Promise((resolve, reject) => {
    axios
      .get(`productions/available-productions-for-new-subscriptions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}





