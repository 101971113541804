import axios from "_utils/axios";

export async function listOffersService(organization_id) {
  try {
    const offersList = await axios.get(`/organizations/${organization_id}/offers`);
    return offersList;
  } catch (error) {
    throw error;
  }
}

export async function createOfferService(organization_id, data) {
  try {
    const newOffer = await axios.post(`/organizations/${organization_id}/offers`, data);
    return newOffer;
  } catch (error) {
    throw error;
  }
}

export async function updateOfferService(organization_id, offer_id, data) {
  try {
    const updatedOffer = await axios.put(`/organizations/${organization_id}/offers/${offer_id}`, data);
    return updatedOffer;
  } catch (error) {
    throw error;
  }
}

export function updateOfferOrderService (orgId, OfferIds) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/offers/order`, {
        OfferIds,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function deleteOfferService(orgId, OfferId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${orgId}/offers/${OfferId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
