import React from 'react';
import Typography from "@material-ui/core/Typography";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  componentDidCatch(error, info) {
    if (error.toString().includes('ChunkLoadError')) {
      console.log('[ChunkLoadError] Reloading due to error');
      if (window.confirm("Do you really want to leave?")) {
        window.location.reload(true);
      }
    } else {
      this.setState({ hasError: true });
      console.log('Boundary: ', error)
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('Boundary Error: ', error)
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div>
          <Typography variant="subtitle1">
            We're sorry — something went wrong.
          </Typography>
          <Typography variant="subtitle1">
            Our team has been notified
          </Typography>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
