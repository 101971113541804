import React from "react";
import styled from "styled-components/macro";

export const Container = styled.div`
  text-align: center;
`

export const Centered = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  )
}
