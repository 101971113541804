import axios from "_utils/axios";

export function getEventsByArtixId(orgId, eventStatusIdOpts) {
  return new Promise((resolve, reject) => {
    axios
    .get(`organizations/${orgId}/eventsByArtixId`, {
      params: eventStatusIdOpts
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEvent(eventId) {
  return new Promise((resolve, reject) => {
    axios
    .get(`event/${eventId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEventSeatingChart(eventId, params = null) {
  return new Promise((resolve, reject) => {
    axios
    .get(`event/${eventId}/seatingChart`, {
      params,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTicketAttribute(ticketId, payload) {
  return new Promise((resolve, reject) => {
    axios
    .put(`updateTicketAttribute/${ticketId}`, payload)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function removeEventService(eventId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`events/${eventId}/removeEvent`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function getCurrentProductionEventsByOrgService() {
  return new Promise((resolve, reject) => {
    axios
      .get(`getCurrentProductionEventsByOrg`)
      .then((response) => {
        resolve(response.data.body);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllEvents(start, end) {
  return new Promise((resolve, reject) => {
    axios
    .get(`events/all?start=${start}&end=${end}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function refundOrders(orders, eventId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`events/${eventId}/refundOrders`, {
        orders
      })
      .then((response) => {
        console.log(response)
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  });
}

export function convertOrders(orders, eventId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`events/${eventId}/convertOrders`, {
        orders
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductionEventsService(productionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`productions/${productionId}/events`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEventsDistinctTimesService(organizationId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${organizationId}/getEventsDistinctTimes`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}