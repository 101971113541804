import * as types from "_constants";

const initialState = {
  isCreating: false,
  isLoading: false,
  socialProfiles: [],
  socialProfileEnabled: false,
  socialProfileMessage: ''
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SOCIAL_PROFILE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_ORGANIZATION_SOCIALPROFILE:
      return {
        ...state,
        socialProfiles: actions.payload.social_profiles,
        socialProfileEnabled: actions.payload.social_profile_enabled,
        socialProfileMessage: actions.payload.social_profile_message,
        url: actions.payload.url,
        isLoading: false
      };
    case types.CREATE_ORGANIZATION_SOCIALPROFILE:
      return {
        ...state,
        isCreating: true
      };
    case types.CREATE_ORGANIZATION_SOCIALPROFILE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        socialProfiles: actions.payload.social_profiles,
        socialProfileEnabled: actions.payload.social_profile_enabled,
        socialProfileMessage: actions.payload.social_profile_message,
        url: actions.payload.url
      };
    case types.CREATE_ORGANIZATION_SOCIALPROFILE_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case types.DISCONNECT_ORGANIZATION_SOCIALPROFILE_SUCCESS:
      return {
        ...state,
        socialProfileEnabled: false,
        socialProfiles: [],
        socialProfileMessage: actions.payload.social_profile_message,
        url: ''
      };
    default:
      return state;
  }
}
