import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const SubHeader = ({title, link, curLink}) => {
  return (
    <>
      <Helmet title={title} />
      <Typography variant="h3" gutterBottom display="inline">
        {title}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          {link}
        </Link>
        <Typography>{curLink}</Typography>
      </Breadcrumbs>

      <Divider my={6} />
    </>
  )
}
