// EVENTS
export const GET_EVENTS_BY_ARTIX_ID_REQUEST = "GET_EVENTS_BY_ARTIX_ID_REQUEST";
export const GET_EVENTS_BY_ARTIX_ID_SUCCESS = "GET_EVENTS_BY_ARTIX_ID_SUCCESS";
export const GET_EVENTS_BY_ARTIX_ID_FAILURE = "GET_EVENTS_BY_ARTIX_ID_FAILURE";

export const GET_EVENT_SEATING_CHART_REQUEST = "GET_EVENT_SEATING_CHART_REQUEST";
export const GET_EVENT_SEATING_CHART_SUCCESS = "GET_EVENT_SEATING_CHART_SUCCESS";
export const GET_EVENT_SEATING_CHART_FAILURE = "GET_EVENT_SEATING_CHART_FAILURE";

export const GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_REQUEST = "GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_REQUEST";
export const GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_SUCCESS = "GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_SUCCESS";
export const GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_FAILURE = "GET_CURRENT_PRODUCTION_EVENTS_BY_ORG_FAILURE";

export const GET_PRODUCTION_EVENTS_REQUEST = "GET_PRODUCTION_EVENTS_REQUEST";
export const GET_PRODUCTION_EVENTS_SUCCESS = "GET_PRODUCTION_EVENTS_SUCCESS";
export const GET_PRODUCTION_EVENTS_FAILURE = "GET_PRODUCTION_EVENTS_FAILURE";

export const BULK_CREATE_PERFORMANCES_REQUEST = "BULK_CREATE_PERFORMANCES_REQUEST";
export const BULK_CREATE_PERFORMANCES_SUCCESS = "BULK_CREATE_PERFORMANCES_SUCCESS";
export const BULK_CREATE_PERFORMANCES_FAILURE = "BULK_CREATE_PERFORMANCES_FAILURE";

export const BULK_UPDATE_PERFORMANCES_REQUEST = "BULK_UPDATE_PERFORMANCES_REQUEST";
export const BULK_UPDATE_PERFORMANCES_SUCCESS = "BULK_UPDATE_PERFORMANCES_SUCCESS";
export const BULK_UPDATE_PERFORMANCES_FAILURE = "BULK_UPDATE_PERFORMANCES_FAILURE";

export const BULK_DELETE_PERFORMANCES_REQUEST = "BULK_DELETE_PERFORMANCES_REQUEST";
export const BULK_DELETE_PERFORMANCES_SUCCESS = "BULK_DELETE_PERFORMANCES_SUCCESS";
export const BULK_DELETE_PERFORMANCES_FAILURE = "BULK_DELETE_PERFORMANCES_FAILURE";

export const GET_EVENTS_DISTINCT_TIMES_REQUEST = "GET_EVENTS_DISTINCT_TIMES_REQUEST";
export const GET_EVENTS_DISTINCT_TIMES_SUCCESS = "GET_EVENTS_DISTINCT_TIMES_SUCCESS";
export const GET_EVENTS_DISTINCT_TIMES_FAILURE = "GET_EVENTS_DISTINCT_TIMES_FAILURE";