import { useEffect, useState } from "react";
import styled from "styled-components";
import { Alert } from "./common";
import { useSelector } from "react-redux";

import { getPaymentMethodService } from "_services/organizationService";

const PayWall = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

const PayWallContent = styled.div`
  padding-top: 100px;
`;

const routeToCopyMap = {
  '/settings/widget': {
    emailSubject: 'Upgrade to Platinum - Widget Customization'
  }
};

const PayWallGuard = ({ children, plans, path }) => {
  const userPlan =
    useSelector((state) => state.authReducer.user?.userOrg?.plan) || "free";

  useEffect(() => {
    if (plans && !plans.includes(userPlan.toLowerCase()))
      document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [plans, userPlan]);

  if (!plans) return children;
  if (plans.includes(userPlan.toLowerCase())) return children;

  const formattedPlans = plans
    .map((plan, index) => {
      if (plans.length === 1)
        return `${plan.charAt(0).toUpperCase() + plan.slice(1)} plan`;
      if (index === 0) return plan.charAt(0).toUpperCase() + plan.slice(1);
      if (index === plans.length - 1)
        return ` or ${plan.charAt(0).toUpperCase() + plan.slice(1)} plans`;
      return `, ${plan.charAt(0).toUpperCase() + plan.slice(1)}`;
    })
    .join("");

  const emailSubjectLine = routeToCopyMap[path]?.emailSubject || 'Upgrade to Platinum';

  return (
    <>
      <PayWall />
      <Alert style={{ zIndex: 1001, position: "absolute" }} severity="warning">
        This feature is only available on the {formattedPlans}. To upgrade and
        access this feature, please contact your <a href={`mailto:accountmanager@onthestage.com?subject=${emailSubjectLine}`} style={{color:'#0070E0', textDecoration:'underline'}}>Account Manager</a>.
      </Alert>
      <PayWallContent>{children}</PayWallContent>
    </>
  );
};

export default PayWallGuard;
