import axios from "_utils/axios";

export function createEventTierModifierService(orgId, eventTierModifier) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/eventTierModifiers`, { eventTierModifier })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEventTierModifiersService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}/eventTierModifiers`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateEventTierModifierService(orgId, eventTierModifierId, eventTierModifier) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/eventTierModifiers/${eventTierModifierId}`, { eventTierModifier })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteEventTierModifierService(orgId, eventTierModifierId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${orgId}/eventTierModifiers/${eventTierModifierId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
