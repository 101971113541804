import axios from "_utils/axios";

export function getPlaybillService(productionId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`productions/${productionId}/playbill`)
      .then((response) => {
        console.log('in getPlaybillService response.data', response.data)
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updatePlaybillService(productionId, playbill) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/playbill`, playbill)
      .then((response) => {
        console.log('updatePlaybillService response.data', response.data)
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function updatePlaybillProductsService(productionId, playbill) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/updatePlaybillProducts`, playbill)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};



export function downloadPlaybillService(productionId, pages, useBlanks, font_family) {
  return new Promise((resolve, reject) => {
    const blankPage = {
      "page_type": "Blank",
      "show_page" : true,
    }
    let filteredSorttedPages = pages.filter(p => p.show_page).sort((page1, page2) => {
      return page1.sort_order - page2.sort_order;
    });

    filteredSorttedPages.splice(1, 0, blankPage) //second to last page add blank
    let pagesWithBlanks = [...filteredSorttedPages, blankPage, blankPage, blankPage, blankPage];
    pagesWithBlanks = pagesWithBlanks.map((p, index) => {
      return {
        ...p,
        sort_order: index + 1
      }
    });

    axios
      .post(`productions/${productionId}/playbill/createPlaybillPdf`, { pages: useBlanks ? pagesWithBlanks : pages, font_family, })
      .then((response) => {
        console.log('updatePlaybillService response.data', response.data)
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function importPlaybillService(productionId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/playbill/import`, {})
      .then((response) => {
        console.log('importPlaybill response.data', response.data)
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


