import { green, grey } from "@material-ui/core/colors";
const overrides = {
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.5) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
  MuiSwitch: {
    switchBase: {
      // Controls default (unchecked) color for the thumb
      // color: `${green[500]}`
    },
    colorSecondary: {
      "&$checked": {
        // Controls checked color for the thumb
        color: `${green[500]}`,
        '&:hover': {
          backgroundColor: `${green[100]}`,
          color: `${green[500]}`,
        },
      },
    },
    track: {
      // Controls default (unchecked) color for the track
      opacity: 0.2,
      // backgroundColor: `${grey[900]}`,
      "$checked$checked + &": {
        // Controls checked color for the track
        opacity: 0.7,
        backgroundColor: `${green[500]}`
      }
    }
  },
  MuiMenu: {
    paper: { 
      boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
    }
  },
};

export default overrides;
