import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import {
  Card,
  Spacer
} from "_components/common"
import {
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Grid
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { SHOW_NOTIFICATION } from '_constants'
import { updateOrganization } from "_redux/actions/organizationActions";
import { 
  getAllMailchimpAudiences, 
} from "_services/mailchimpService";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const GTM_REGEX = /^G-[A-Z0-9]{1,9}/;

function IntegrationsHub() {
  const organization = useSelector(state => state.organizationReducer.organization);
  const dispatch = useDispatch();
  const [checkedState, setCheckedState] = React.useState({
    ga_measurement_id: false,
    fb_pixel_id: false,
    mailchimp_api_key: false
  });
  const [values, setValues] = React.useState({
    is_edited: false,
    GA_MEASUREMENT_ID: {
      value: "",
      error: null
    },
    fb_pixel_id: {
      value: "",
      error: null
    },
    mailchimp_api_key: {
      value: "",
      error: null
    },
    mailchimp_audience_name: {
      value: "",
      error: null
    }
  });
  const [audiences, setAudiences] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const hasApiKey = !!values.mailchimp_api_key.value;
  const isApiKeyChecked = checkedState.mailchimp_api_key;
  const isOrgApiKeySameAsEntered = organization.mailchimp_api_key === values.mailchimp_api_key.value;
  const isAudienceListEmpty = audiences.length === 0;
  
  const isMailchimpAudienceDisabled = !hasApiKey || !isApiKeyChecked || !isOrgApiKeySameAsEntered;
  const isMailchimpAudienceError = hasApiKey && isApiKeyChecked && isOrgApiKeySameAsEntered && isAudienceListEmpty && !isLoading;
  const mailchimpAudienceHelperText = isMailchimpAudienceError ? "No audiences available, please check your API key" : "";

  const handleTextChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChange = (event) => {
    setCheckedState({ ...checkedState, [event.target.name]: event.target.checked });
    if (event.target.name === "ga_measurement_id") {
      setValues({
        ...values,
        is_edited: !values.is_edited ? true : false,
        GA_MEASUREMENT_ID: {
          value: "",
          error: null
        }
      });
    } else if (event.target.name === "fb_pixel_id") {
      setValues({
        ...values,
        is_edited: !values.is_edited ? true : false,
        fb_pixel_id: {
          value: "",
          error: null
        }
      });
    } else if (event.target.name === "mailchimp_api_key") {
      setValues({
        ...values,
        is_edited: !values.is_edited ? true : false,
        mailchimp_api_key: {
          value: "",
          error: null
        },
        mailchimp_audience_name: {
          value: "",
          error: null
        }
      });
    }
  };

  const handleAudienceNameChange = (event) => {
    // Find the selected audience object based on the list_id
    const selectedAudience = audiences.find(audience => audience.list_id === event.target.value);

    setValues({
      ...values,
      mailchimp_audience_name: {
        ...values.mailchimp_audience_name,
        value: selectedAudience ? selectedAudience.list_id : "",
      }
    });
  };


  const onTextValidateAndUpdate = name => event => {
    if (name === "GA_MEASUREMENT_ID") {
      if (event.target.value.match(GTM_REGEX) === null) {
        // error
        setValues({
          ...values,
          is_edited: !values.is_edited ? true : false,
          GA_MEASUREMENT_ID: {
            value: values.GA_MEASUREMENT_ID.value,
            error: 'Entered Google Measurement ID is not matching the format of G-XXXXXX, Please enter the value properly.'
          }
        });
      } else {
        setValues({
          ...values,
          GA_MEASUREMENT_ID: {
            value: event.target.value,
            error: null
          }
        });
      }
    } else if (name === "fb_pixel_id") {
      setValues({
        ...values,
        is_edited: !values.is_edited ? true : false,
        fb_pixel_id: {
          value: event.target.value,
          error: null
        }
      });
    } else if (name === "mailchimp_api_key") {
      setValues({
        ...values,
        is_edited: !values.is_edited ? true : false,
        mailchimp_api_key: {
          value: event.target.value,
          error: null
        }
      });
    }
  };

  const onSave = async () => {
    try {
      await dispatch(updateOrganization({
        id: organization._id, data: {
          GA_MEASUREMENT_ID: values.GA_MEASUREMENT_ID.value,
          fb_pixel_id: values.fb_pixel_id.value,
          mailchimp_api_key: values.mailchimp_api_key.value,
          mailchimp_audience_list_id: values.mailchimp_audience_name.value
        }
      }));
      dispatch({
        type: SHOW_NOTIFICATION, payload: {
          message: `Successfully updated your tracking integrations.`
        }
      });
    } catch (err) {
      dispatch({
        type: SHOW_NOTIFICATION, payload: {
          status: 'error',
          message: err.response?.data || err.message || "Something went wrong!",
        }
      })
    }
  };

  useEffect(() => {
    const fetchAudiences = async () => {
      setIsLoading(true);
      try {
        const mailchimpAudiences = await getAllMailchimpAudiences(organization.id);
        if (!mailchimpAudiences) {
          values.mailchimp_audience_name.value = "";
        }
        setAudiences(mailchimpAudiences);
      } catch (error) {
        console.error('Error fetching audiences', error);
      }
      setIsLoading(false);
    };

    if (organization.mailchimp_api_key) {
      fetchAudiences();
    }
  }, [organization]);

  useEffect(() => {
    if (organization && (organization.GA_MEASUREMENT_ID || organization.fb_pixel_id || organization.mailchimp_api_key || organization.mailchimp_audience_list_id)) {
      setValues({
        ...values,
        GA_MEASUREMENT_ID: {
          value: organization.GA_MEASUREMENT_ID ? organization.GA_MEASUREMENT_ID : "",
          error: null
        },
        fb_pixel_id: {
          value: organization.fb_pixel_id ? organization.fb_pixel_id : "",
          error: null
        },
        mailchimp_api_key: {
          value: organization.mailchimp_api_key ? organization.mailchimp_api_key : "",
          error: null
        },
        mailchimp_audience_name: {
          value: organization.mailchimp_api_key ? organization.mailchimp_audience_list_id : "",
          error: null
        },
      });

      setCheckedState({
        ...checkedState,
        ga_measurement_id: organization.GA_MEASUREMENT_ID !== undefined && organization.GA_MEASUREMENT_ID !== "" ? true : false,
        fb_pixel_id: organization.fb_pixel_id !== undefined && organization.fb_pixel_id !== "" ? true : false,
        mailchimp_api_key: organization.mailchimp_api_key !== undefined && organization.mailchimp_api_key !== "" ? true : false,
      });
    }
  }, [organization]);

  return (
    <React.Fragment>
      <Helmet title="Integrations Hub" />
      <Typography variant="h3" gutterBottom display="inline">
        Integrations Hub
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Integrations Hub</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Card>
        <CardContent>
          <Alert mb={4} severity="info">
            {"You can add a Google Analytics Measurement ID directly to your Patron page to track visitors in Google Analytics."}
          </Alert>

          <Spacer my={2} />

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.ga_measurement_id}
                  onChange={handleChange}
                  name="ga_measurement_id"
                  color="primary"
                />
              }
              label="Integrate with Google Analytics"
            />
          </FormGroup>

          <Spacer my={2} />

          <FormGroup row>
            <TextField
              style={{ width: "520px" }}
              label="Enter your GA Measurement ID"
              variant="outlined"
              onChange={handleTextChange("GA_MEASUREMENT_ID")}
              onBlur={onTextValidateAndUpdate("GA_MEASUREMENT_ID")}
              value={values.GA_MEASUREMENT_ID.value}
              error={values.GA_MEASUREMENT_ID.error !== null}
              helperText={values.GA_MEASUREMENT_ID.error !== null ? values.GA_MEASUREMENT_ID.error : ""}
              disabled={!checkedState.ga_measurement_id}
            />
          </FormGroup>

          <Divider my={6} />

          <Alert mb={4} severity="info">
            {"You can add a Facebook Pixels ID directly to your Patron page to track visitors in Facebook."}
          </Alert>

          <Spacer my={2} />

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.fb_pixel_id}
                  onChange={handleChange}
                  name="fb_pixel_id"
                  value={values.fb_pixel_id.value}
                  error={values.fb_pixel_id.error}
                  color="primary"
                />
              }
              label="Integrate with Facebook Pixels"
            />
          </FormGroup>

          <Spacer my={2} />

          <FormGroup row>
            <TextField
              style={{ width: "520px" }}
              label="Enter your Facebook Pixels ID"
              variant="outlined"
              disabled={!checkedState.fb_pixel_id}
              onChange={handleTextChange("fb_pixel_id")}
              onBlur={onTextValidateAndUpdate("fb_pixel_id")}
              value={values.fb_pixel_id.value}
              error={values.fb_pixel_id.error !== null}
              helperText={values.fb_pixel_id.error !== null ? values.fb_pixel_id.error : ""}
            />
          </FormGroup>

          <Divider my={6} />

          <Alert mb={4} severity="info">
            {"You can add a Mailchimp API key to integrate with mailchimp platform. You will be able to choose an audience/list from mailchimp once you have added your API key."}
          </Alert>

          <Spacer my={2} />

          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedState.mailchimp_api_key}
                  onChange={handleChange}
                  name="mailchimp_api_key"
                  value={values.mailchimp_api_key.value}
                  error={values.mailchimp_api_key.error}
                  color="primary"
                />
              }
              label="Integrate with Mailchimp"
            />
          </FormGroup>

          <Spacer my={2} />

          <FormGroup row>
            <TextField
              style={{ width: "520px" }}
              label="Enter your Mailchimp API key"
              variant="outlined"
              disabled={!checkedState.mailchimp_api_key}
              onChange={handleTextChange("mailchimp_api_key")}
              onBlur={onTextValidateAndUpdate("mailchimp_api_key")}
              value={values.mailchimp_api_key.value}
              error={values.mailchimp_api_key.error !== null}
              helperText={values.mailchimp_api_key.error !== null ? values.mailchimp_api_key.error : ""}
            />
          </FormGroup>

          <Spacer my={4} />

          <FormGroup row>
            <TextField
              style={{ width: "520px" }}
              select
              type="text"
              name="mailchimp_audience_name"
              label="Audience name"
              required
              variant="outlined"
              value={values.mailchimp_audience_name.value}
              fullWidth
              onChange={handleAudienceNameChange}
              disabled={isMailchimpAudienceDisabled}
              error={isMailchimpAudienceError}
              helperText={mailchimpAudienceHelperText}
            >
                {audiences.length > 0 ? (
                  audiences.map((audience, index) => (
                    <MenuItem key={index} value={audience.list_id}>
                      {audience.audience_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    -
                  </MenuItem>
                )}
            </TextField>
          </FormGroup>

          <Spacer my={4} />

          <Button color="primary" variant="contained" onClick={onSave}>Save</Button>
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

export default IntegrationsHub;
