import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import PlaybillRightColComponent from './PlaybillRightColumnComponent';
import PlaybillEditorPageLayout from "./PlaybillEditorPageLayout";
import {
  Grid,
} from "@material-ui/core";
import { CloudinaryAdapterPlugin } from "_components/common";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ots-dashboard-ckeditor';
import { updatePlaybillValue } from "_redux/actions/playbillActions";

const MainViewContainer = styled.div`
  padding: 20px;
`;

const EditorContainer = styled.div`
  min-height: 200px;
`;

const CustomContentContainer = styled.div`
  background-color: #32212;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  img {
    max-width: 100%;
  }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }


`;

function PlaybillCustomPageContent({ production, page, showPreview, fontFamily }) {
  const [customPage, setCustomPage] = useState(page.data.section);
  const dispatch = useDispatch();

  function createMarkup(markup) {
    return { __html: markup };
  }

  useEffect(() => {
    setCustomPage(page.data.section)
  }, [page]);

  const customFontStyle = {
    fontFamily: `${fontFamily}`,
  };

  return (
    <EditorContainer>
    { showPreview ? (
      <CustomContentContainer style={customFontStyle}>
        <span dangerouslySetInnerHTML={createMarkup(customPage)}></span>
      </CustomContentContainer>
    ) : (
      <>
        <CKEditor
          editor={ CustomEditor }
          data={customPage}
          config={{
            extraPlugins: [ CloudinaryAdapterPlugin ],
          }}
          onReady={ editor => {
              // You can store the "editor" and use when it is needed.
              console.log( 'Editor is ready to use!', editor );
          } }
          onChange={ ( event, editor ) => {
              const data = editor.getData();
                dispatch(updatePlaybillValue({ 
                  key: "section", 
                  value: data, 
                  page: page
                }));
          } }
          onBlur={ ( event, editor ) => {
              console.log( 'Blur.', editor );
          } }
          onFocus={ ( event, editor ) => {
              console.log( 'Focus.', editor );
          } }
        />
    </>
    )}
  </EditorContainer>
  )
}

function PlaybillCustomPage({ production, page, showPreview, fontFamily }) {
  return <PlaybillCustomPageContent production={production} page={page} showPreview={showPreview} fontFamily={fontFamily} />
}
export default PlaybillCustomPage;
