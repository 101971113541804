import * as types from "_constants";
import { OPEN_DIALOG } from "_constants";

import {
  getNamesService,
  getOrganizationService,
  getLicensorsService,
  submitFormDataService,
  getBasicInfoService,
  getVirtualDraftInfoService,
  submitVirtualDraftInfoService,
  getProductionByIdService,
  updateProductionService,
  updateGeofencingService,
  toggleStandbyService,
  deleteDraftService,
  setGeneralCapacityService,
  sendEmailService,
  toggleSuppressMarketingCampaignsService,
  addPerformanceDateService,
  toggleTicketsPurchasableService,
  changePerformanceDateService,
  changeNameService,
  addDraftTierService,
  deleteDraftTierService,
  updateDraftTierService,
  removePerformanceDateFromDraftService,
  updateSalesTaxService,
  changeEventTypeService,
  changeVenueService,
  editEventTierService,
  updateOrderConfirmationMessageService,
  updateTicketsPerOrderLimitService,
  updateSocialDistancingService,
  savePerformanceStatusChangeService,
  updateSeriesAttributesService,
  updateFeesSalesTaxService,
  updateAddonsSalesTaxService,
} from "_services/productionService";

import {
  deleteSeriesAutomationService
} from "_services/promoteService";

export function setDeliveryFormat(value) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_DELIVERY_FORMAT,
      deliveryFormat: value,
    });
  };
}


export function setVenueVisible(value) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_VENUE_VISIBLE,
      venueVisible: value,
    });
  };
}

export function setLiveStreamVisible(value) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LIVESTREAM_VISIBLE,
      livestreamVisible: value,
    });
  };
}

export function getNames(name) {
  const data = { name };

  return async (dispatch) => {
    dispatch({
      type: types.GET_NAMES_REQUEST,
      data,
    });

    return getNamesService(name)
      .then((response) => {
        dispatch({
          type: types.GET_NAMES_SUCCESS,
          nameList: response,
        });
      })
  };
}

export function getOrgData(orgId) {
  return async (dispatch) => {
    if (orgId) {
      dispatch({ type: types.GET_ORG_DATA_REQUEST });

      return getOrganizationService(orgId)
        .then((response) => {
          dispatch({
            type: types.GET_ORG_DATA_SUCCESS,
            organization: response,
          });
        })
    } else {
      dispatch({ type: types.GET_ORG_DATA_MISSING_ORG_ID });
    }

  };
}

export function getLicensors() {
  return async (dispatch) => {
    dispatch({ type: types.GET_LICENSORS_REQUEST });

    return getLicensorsService()
      .then((response) => {
        dispatch({
          type: types.GET_LICENSORS_SUCCESS,
          licensors: response.body,
        });
      })
  };
}

export function setFormData(data) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_FORM_DATA,
      data,
    });
  };
}

export function setLivestreamOptionsData(data) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_LIVESTREAM_OPTIONS_DATA,
      data,
    });
  };
}

export function setAddress(data) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_ADDRESS,
      data,
    });
  };
}

export function submitFormData(formData, cb) {
  return async (dispatch) => {
    dispatch({ type: types.SUBMIT_FORMDATA_REQUEST });

    return submitFormDataService(formData)
      .then((response) => {
        dispatch({
          type: types.RESET_FORM_DATA,
        });
        return getProductionByIdService(response.id)
        .then((getProductionResponse) => {
          dispatch({
            type: types.GET_PRODUCTION_BY_ID_SUCCESS,
            production: getProductionResponse,
          });
          return response;
        })
      })
      .then((response) => {
        if (cb) {
          cb(response.id);
        }
      })
      .catch((err) => {
        dispatch({
          type: types.SUBMIT_FORMDATA_FAILURE,
          formErrorMessage: err.response.data,
        });
        dispatch({
          type: OPEN_DIALOG, payload: {
            status: 'error',
            message: err.response?.data || err.message || "Something went wrong!",
          }
        })        
      })
  };
}

export function getBasicInfo(formData, cb) {
  return async (dispatch) => {
    dispatch({ type: types.GET_BASIC_INFO_REQUEST });

    return getBasicInfoService(formData)
      .then((response) => {
        dispatch({
          type: types.GET_BASIC_INFO_SUCCESS,
          basicInfo: response,
        });
        return response;
      })
      .then((response) => {
        if (cb) {
          cb(response.id);
        }
      })
  };
}

export function getVirtualDraftInfo(id) {
  return async (dispatch) => {
    dispatch({ type: types.GET_VIRTUAL_DRAFT_INFO_REQUEST });

    return getVirtualDraftInfoService(id)
      .then((response) => {
        dispatch({
          type: types.GET_VIRTUAL_DRAFT_INFO_SUCCESS,
          info: response,
        });
      })
  };
}

export function submitVirtualDraftInfo(formData, id, cb) {
  return async (dispatch) => {
    dispatch({ type: types.SUBMIT_VIRTUAL_DRAFT_INFO_REQUEST });

    return submitVirtualDraftInfoService(formData, id)
      .then((response) => {
        dispatch({
          type: types.GET_PRODUCTION_BY_ID_SUCCESS,
          production: response,
        });
      })
      .then(() => {
        if (cb) {
          cb();
        }
      })
  };
}

export function getProductionById(id, includeInactive = false) {
  return async (dispatch) => {
    dispatch({ type: types.GET_PRODUCTION_BY_ID_REQUEST });

    return getProductionByIdService(id, includeInactive)
      .then((response) => {
        dispatch({
          type: types.GET_PRODUCTION_BY_ID_SUCCESS,
          production: response,
        });
      })
  };
}

export function updateProduction(actions) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PRODUCTION_REQUEST });

    return updateProductionService(actions)
      .then((response) => {
        dispatch({
          type: types.UPDATE_PRODUCTION,
          production: response,
        });
      })
  };
}

export function toggleSuppressMarketingCampaigns(productionId,  suppress_campaigns) {
  return async (dispatch) => {
    dispatch({ type: types.TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_REQUEST });
    return toggleSuppressMarketingCampaignsService(productionId, suppress_campaigns)
      .then((response) => {
        dispatch({
          type: types.TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_SUCCESS,
          payload: {
            productionId,
            suppress_campaigns,
          },
        });
      })
  };
}

export function updateSocialDistancing(productionId, social_distancing_seat_distance_x) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_SOCIAL_DISTANCING_REQUEST });
    return updateSocialDistancingService(productionId, social_distancing_seat_distance_x)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Social distancing range updated`,
          }
        });
        dispatch({
          type: types.UPDATE_SOCIAL_DISTANCING_SUCCESS,
          payload: {
            social_distancing_seat_distance_x,
          },
        });
      }).catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  };
}

export function updateProductionValue(data) {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_PRODUCTION_VALUE,
      data,
    });
  };
}

export function updateProductionStyle(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_PRODUCTION_STYLE, data });
  };
}

export function updateGeofencing(productionId, geofencing_enabled, geofencing_rules) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_GEOFENCING_REQUEST });
    return updateGeofencingService(productionId, geofencing_enabled, geofencing_rules)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Geofencing Updated',
          }
        })
        dispatch({
          type: types.UPDATE_GEOFENCING_SUCCESS,
          payload: {
            geofencing_enabled,
            geofencing_rules,
          },
        });
      }).catch(err => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err?.response?.data || err.message
          }
        })        
      })
  };
}

export function toggleStandby(productionId, website_visible) {
  return async (dispatch) => {
    dispatch({ type: types.TOGGLE_STANDBY_REQUEST });

    return toggleStandbyService(productionId, website_visible)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Standby ${website_visible ? 'On' : 'Off'}`,
          }
        })
        dispatch({
          type: types.TOGGLE_STANDBY_REQUEST_SUCCESS,
          website_visible: website_visible,
        });
      })
  };
}



export function toggleTicketsPurchasable(eventId, tickets_purchasable) {
  return async (dispatch) => {
    dispatch({ type: types.TOGGLE_TICKETS_PURCHASABLE_REQUEST });

    return toggleTicketsPurchasableService(eventId, tickets_purchasable)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Sales ${tickets_purchasable ? 'On' : 'Off'}`,
          }
        })
        dispatch({
          type: types.TOGGLE_TICKETS_PURCHASABLE_REQUEST_SUCCESS,
          tickets_purchasable: tickets_purchasable,
        });
      })
  };
}



export function deleteDraft(productionId, cb) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_DRAFT_REQUEST });

    return deleteDraftService(productionId)
      .then((response) => {
        cb && cb();
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Draft Deleted`,
          }
        })
        dispatch({
          type: types.DELETE_DRAFT_REQUEST_SUCCESS,
        });
      }).catch(err => {
        console.log('err', err.response);
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data?.message || err.response?.data || err.message,
          }
        })        
      });
  };
}

export function setGeneralCapacity(productionId, numTickets, eventType) {
  return async (dispatch) => {
    dispatch({ type: types.SET_GENERAL_CAPACITY_REQUEST });

    return setGeneralCapacityService(productionId, numTickets, eventType)
      .then((response) => {
        dispatch(getProductionById(productionId, true));
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Capacity Updated to ${numTickets}`,
          }
        })
        dispatch({
          type: types.PRODUCTION_API_REQUEST_SUCCESS,
          successMessage: 'Capacity Updated',
        });
      })
  };
}

export function updateTicketsPerOrderLimit(production, limit_tickets_in_order, limit_tickets_in_order_num) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_TICKETS_PER_ORDER_LIMIT_REQUEST });

    return updateTicketsPerOrderLimitService(production, limit_tickets_in_order, limit_tickets_in_order_num)
      .then((response) => {
        console.log('updateTicketsPerOrderLimit action limit_tickets_in_order', limit_tickets_in_order)
        dispatch({
          type: types.UPDATE_TICKETS_PER_ORDER_LIMIT_SUCCESS,
          payload: {
            limit_tickets_in_order,
            limit_tickets_in_order_num
          }
        })
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: limit_tickets_in_order ? `Purchase limit updated to ${limit_tickets_in_order_num}!` : 'Purchase limit disabled!',
          }
        })
      })
  };
}

export function removePerformanceDateFromDraft(productionId, index) {
  return async (dispatch) => {
    removePerformanceDateFromDraftService(productionId, index)
      .then(response => {
        dispatch({
          type: types.REMOVE_PEFORMANCE_DATE_FROM_DRAFT_REQUEST_SUCCESS,
          payload: {
            index: index,
          },
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Date Removed`,
          }
        })
      }).catch(err => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message || "Something went wrong!",
          }
        })
      });
  }
}
export function addPerformanceDate(productionId, date, eventType, shouldDispatch) {
  return async (dispatch) => {
    addPerformanceDateService(productionId, date, eventType)
      .then(response => {

        if (shouldDispatch) {
          dispatch({
            type: types.ADD_PEFORMANCE_DATE_REQUEST_SUCCESS,
            payload: {
              dates: response
            },
          });
          dispatch({
            type: types.SHOW_NOTIFICATION,
            payload: {
              message: `Date Added`,
            }
          })
          dispatch(getProductionById(productionId, true));
        }
      }).catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  }
}

export async function sendPatronEmails(productionId, { numberOfEmails, email, selectedEventIds }) {
  if (!selectedEventIds.length) {
    throw new Error('Please choose at least 1 event');
  }
  if (!numberOfEmails) {
    throw new Error('No Recipients to send to');
  }
  try {
    const response = await sendEmailService(productionId, { numberOfEmails, email, selectedEventIds })
    return response;
  } catch (err) {
    throw new Error(err.response.data);
  }
}


export function clearFormErrorMessage() {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_FORM_ERROR_MESSAGE });
  };
}

export function changeName(productionId, newName) {
  return async (dispatch) => {
    return changeNameService(productionId, newName)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Name changed to ${newName}`,
          }
        })
        dispatch(getProductionById(productionId, true));
      }).catch(err => {
        dispatch({
          type: types.UPDATE_PRODUCTION_NAME_FAILURE,
          errorMessage: err.data,
        });
      });
  };
}

export function changePerformanceDate(eventId, productionId, datetime, oldDatetime) {
  return async (dispatch) => {
    return changePerformanceDateService(eventId, productionId, datetime, oldDatetime)
      .then((response) => {
        dispatch(getProductionById(productionId, true));
        dispatch({
          type: types.CHANGE_PERFORMANCE_DATE_SUCCESS,
          successMessage: 'Performance Date Updated!',
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Performance Date Updated!`,
          }
        })
      }).catch(error => {
        dispatch({ type: types.CHANGE_PERFORMANCE_DATE_FAILURE });
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function addDraftTier(productionId, tier) {
  return async (dispatch) => {
    return addDraftTierService(productionId, tier)
      .then((response) => {
        dispatch({
          type: types.ADD_DRAFT_TIER_SUCCESS,
          payload: {
            tiers: response
          }
        });
      }).catch(err => {
        dispatch({
          type: types.ADD_DRAFT_TIER_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })
      });
  };
}

export function deleteDraftTier(productionId, tierId) {
  return async (dispatch) => {
    return deleteDraftTierService(productionId, tierId)
      .then((response) => {
        dispatch({
          type: types.DELETE_DRAFT_TIER_SUCCESS,
          payload: {
            tiers: response
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: `Ticket Type Removed`,
          }
        })
      }).catch(err => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })
        dispatch({
          type: types.DELETE_DRAFT_TIER_FAILURE,
          errorMessage: err.data,
        });
      });
  };
}

export function updateDraftTiers(productionId, tiers) {
  return async (dispatch) => {
    return updateDraftTierService(productionId, tiers)
      .then((response) => {
        dispatch({
          type: types.ADD_DRAFT_TIER_SUCCESS,
          payload: {
            tiers: response
          }
        });
      }).catch(err => {
        dispatch({
          type: types.ADD_DRAFT_TIER_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })
      });
  };
}

export function updateSalesTax(productionId, sales_tax) {
  return async (dispatch) => {
    return updateSalesTaxService(productionId, sales_tax)
      .then((response) => {
        dispatch({
          type: types.UPDATE_SALES_TAX_REQUEST_SUCCESS,
          payload: {
            sales_tax_percent: sales_tax,
          }
        });
      }).catch(err => {
        dispatch({
          type: types.UPDATE_SALES_TAX_REQUEST_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })        
      });
  };
}

export function updateFeesSalesTax(productionId, fees_sales_tax) {
  return async (dispatch) => {
    return updateFeesSalesTaxService(productionId, fees_sales_tax)
      .then((response) => {
        dispatch({
          type: types.UPDATE_FEES_SALES_TAX_REQUEST_SUCCESS,
          payload: {
            fees_sales_tax_percent: fees_sales_tax,
          }
        });
      }).catch(err => {
        dispatch({
          type: types.UPDATE_FEES_SALES_TAX_REQUEST_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })        
      });
  };
}

export function updateAddonsSalesTax(productionId, addons_sales_tax) {
  return async (dispatch) => {
    return updateAddonsSalesTaxService(productionId, addons_sales_tax)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ADDONS_SALES_TAX_REQUEST_SUCCESS,
          payload: {
            addons_sales_tax_percent: addons_sales_tax,
          }
        });
      }).catch(err => {
        dispatch({
          type: types.UPDATE_ADDONS_SALES_TAX_REQUEST_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })        
      });
  };
}

export function editEventTier(eventId, tiers) {
  return async (dispatch) => {
    return editEventTierService(eventId, tiers)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Ticket type updated',
          }
        })
      }).catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
  };
}

export function changeEventType(productionId, event_type) {
  return async (dispatch) => {
    return changeEventTypeService(productionId, event_type)
      .then((response) => {
        dispatch({
          type: types.CHANGE_EVENT_TYPE_REQUEST_SUCCESS,
          payload: {
            event_type: event_type,
          }
        });
        dispatch(getProductionById(productionId, true));
      }).catch(err => {
        dispatch({
          type: types.CHANGE_EVENT_TYPE_REQUEST_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })        
      });
  };
}

export function changeVenue(productionId, venue) {
  const venueData = productionId.orgVenues.find(orgVenue => {
    return orgVenue._id === venue;
  })

  return async (dispatch) => {
    return changeVenueService(productionId.productionId, venueData, venue, productionId.artix_series_id)
      .then((response) => {
        dispatch(getProductionById(productionId.productionId));
      }).catch(err => {
        dispatch({
          type: types.CHANGE_EVENT_TYPE_REQUEST_FAILURE,
          errorMessage: err.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            status: 'error',
            message: err.response?.data || err.message,
          }
        })        
      });
  };
}

export function updateShortCode(value) {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_SHORT_CODE,
      value,
    });
  };
}

export function updateOrderConfirmationMessage(productionId, order_confirmation_message) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORDER_CONFIRMATION_MESSAGE_REQUEST });

    return updateOrderConfirmationMessageService(productionId, order_confirmation_message)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORDER_CONFIRMATION_MESSAGE_SUCCESS,
          payload: {
            order_confirmation_message: order_confirmation_message,
          },
        });
      })
  };  
}

export function savePerformanceStatusChange(events, productionId, status) {
  return async (dispatch) => {
    try {
      await deleteSeriesAutomationService(productionId, []);
      return savePerformanceStatusChangeService(events, productionId, status).then(async (response) => {
        dispatch(getProductionById(productionId, true));
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Event status updated',
          }
        })
        dispatch({
          type: types.CHANGE_EVENTS_STATUS_SUCCESS,
          payload: {
            changeStatusResponse: true,
          }
        })
      }).catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      });
    } catch (error) {
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    }
  };
}

export function updateSeriesAttributes(data, saveText) {
  return async (dispatch) => {
    dispatch({type: types.SAVE_SERIES_ATTRIBUTES});
    return updateSeriesAttributesService(data)
      .then((response) => {
        dispatch({
          type: types.SAVE_SERIES_ATTRIBUTES_SUCCESS,
          payload: data,
        });
        dispatch({ type: types.SHOW_NOTIFICATION, payload: {
          message: saveText || 'Custom message successfully updated.',
        }})
      })
  }
}
