import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { listNotifications, dismissNotification } from "_redux/actions/notificationActions";


import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Close as CloseIcon,
} from "@material-ui/icons";

import { Bell } from "react-feather";
import { SHOW_NOTIFICATION } from "_constants";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
  max-height: 80%;
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NotificationMessage = styled.div`
  a {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;


function createMarkup(markup) {
  return { __html: markup };
}

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ createdAt, message, dismissNotification }) {
  const history = useHistory();
  return (
    <ListItem divider component={Link} to="#">
      <ListItemText
        onClick={(e) => {
          // We can't add an onClick() event to an <a> or <Link> tag because the <a> tag HTML is embedded in the notification message string
          // The workaround is to add an onClick() handler to the entire ListItemText component and only handle events that a have a "href" property (i.e. <a> tags)
          // using this if statement
          
          // Example message
          // Your account is not configured for automated social media posting! Please visit your <a href="/settings/social-media">Settings</a> page to enable this function.
          if (e.target.href) {
            if (window.location.origin === e.target.origin) { // relative link
              const relativePath = e.target.href.replace(e.target.origin, '');
              history.push(relativePath);
            } else { // external link
              window.location.href = e.target.href;
            }
          }
        }}
        primary={createdAt}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={
          <NotificationMessage dangerouslySetInnerHTML={createMarkup(message)} ></NotificationMessage>
        }
      >
      </ListItemText>
      <ListItemAvatar>
        <IconButton
          aria-label="details"
          onClick={dismissNotification}
          >
          <CloseIcon />
        </IconButton>
      </ListItemAvatar>
    </ListItem>
  );
}

function NotificationsDropdown() {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();

  const notifications = useSelector(state => {
    return state.notificationReducer.notifications
  }).filter((n) => !n.dismissed_at)

  const handleDismissNotification = (notificationId) => {
    dispatch(dismissNotification(notificationId))
  }

  useEffect(() => {
    const load = async () => {
      try {
        await dispatch(listNotifications());
      } catch (error) {
        dispatch({ type: SHOW_NOTIFICATION, payload: {
          message: "Cannot load notification list.",
        }})
      }
    }

    load();
  }, [dispatch]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        {notifications && (
          <IconButton color="inherit" ref={ref} onClick={handleOpen}>
            <Indicator badgeContent={notifications.length} >
              <Bell />
            </Indicator>
          </IconButton>
        )}
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notifications.length > 0 ? `${notifications.length} new` : 'No'} notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          {notifications.length > 0 && (
          <List disablePadding>
            {notifications.map((notification, index) => {
              return (
                <Notification
                  key={`notification-${index}`}
                  createdAt={notification.from_now}
                  message={notification.message}
                  dismissNotification={() => handleDismissNotification(notification.id)}
                />
              )
            })}
          </List>

          )}
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/notifications">
              Show all notifications
            </Button>       
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NotificationsDropdown;
