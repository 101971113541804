import { Provider } from "react-redux";
import store from "_redux/store/index";

import { LocaleProvider } from "./LocaleContext";
import { LanguageProvider } from "./LanguageContext";
import { languages } from "_languages";

const OnStageProvider = ({children}) => {
  return (
    <Provider store={store}>
      <LanguageProvider value={languages['en']}>
        <LocaleProvider>
          {children}
        </LocaleProvider>
      </LanguageProvider>
    </Provider>
  );
}

export default OnStageProvider;