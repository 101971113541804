export * from "./app";
export * from "./videoOnDemand";
export * from "./orders";
export * from "./events";
export * from "./reports";
export * from "./admin";
export * from "./subscriptions";
export * from "./seatingCharts";

// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
  RED: "RED"
};

export const PRODUCT_CATEGORIES = {
  'alcohol' : 'Alcohol', 
  'concessions': 'Concessions', 
  'merchandise - house': 'Merchandise - At The Venue', 
  'merchandise - printful': 'Merchandise - Shipped', 
  'parking': 'Parking',
  'other': 'Other', 
};

export const getProductCategoryLabel = function(categoryValue) {
return PRODUCT_CATEGORIES[categoryValue];
}

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

export const GET_LOGGEDINSTATUS_SUCCESS = "GET_LOGGEDINSTATUS_SUCCESS";
export const GET_LOGGEDINSTATUS_FAILURE = "GET_LOGGEDINSTATUS_FAILURE";

//Notifications
export const NOTIFICATIONS_LIST_GET_REQUEST = "NOTIFICATIONS_LIST_GET_REQUEST";
export const NOTIFICATIONS_LIST_GET_SUCCESS = "NOTIFICATIONS_LIST_GET_SUCCESS";
export const NOTIFICATIONS_LIST_GET_FAILURE = "NOTIFICATIONS_LIST_GET_FAILURE";

export const NOTIFICATIONS_DISMISS_REQUEST = "NOTIFICATIONS_DISMISS_REQUEST";
export const NOTIFICATIONS_DISMISS_SUCCESS = "NOTIFICATIONS_DISMISS_SUCCESS";
export const NOTIFICATIONS_DISMISS_FAILURE = "NOTIFICATIONS_DISMISS_FAILURE";

export const LEDGER_FAILURE = "LEDGER_FAILURE";
export const LEDGER_GET_REQUEST = "LEDGER_GET_REQUEST";
export const LEDGER_GET_SUCCESS = "LEDGER_GET_SUCCESS";

export const AUTH_CREATE_PASSWORD_REQUEST = "AUTH_CREATE_PASSWORD_REQUEST";
export const AUTH_CREATE_PASSWORD_SUCCESS = "AUTH_CREATE_PASSWORD_SUCCESS";
export const AUTH_CREATE_PASSWORD_FAILURE = "AUTH_CREATE_PASSWORD_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const ACCOUNT_USERS_GET_REQUEST = "ACCOUNT_USERS_GET_REQUEST";
export const ACCOUNT_USERS_GET_SUCCESS = "ACCOUNT_USERS_GET_SUCCESS";
export const ACCOUNT_USERS_GET_FAILURE = "ACCOUNT_USERS_GET_FAILURE";

export const ACCOUNT_USER_DELETE_REQUEST = "ACCOUNT_USER_DELETE_REQUEST";
export const ACCOUNT_USER_DELETE_SUCCESS = "ACCOUNT_USER_DELETE_SUCCESS";
export const ACCOUNT_USER_DELETE_FAILURE = "ACCOUNT_USER_DELETE_FAILURE";

export const USER_CREATE_REQUEST = "USER_CREATE_REQUEST";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILURE = "USER_CREATE_FAILURE";

export const CHANGE_ROLES_REQUEST = "CHANGE_ROLES_REQUEST";
export const CHANGE_ROLES_SUCCESS = "CHANGE_ROLES_SUCCESS";
export const CHANGE_ROLES_FAILURE = "CHANGE_ROLES_FAILURE";

export const SEND_VERIFICATION_REQUEST = "SEND_VERIFICATION_REQUEST";
export const SEND_VERIFICATION_SUCCESS = "SEND_VERIFICATION_SUCCESS";

export const CONTACTS_GET_REQUEST = "CONTACTS_GET_REQUEST";
export const CONTACTS_GET_SUCCESS = "CONTACTS_GET_SUCCESS";
export const CONTACTS_GET_FAILURE = "CONTACTS_GET_FAILURE";

export const PROMOCODES_GET_REQUEST = "PROMOCODES_GET_REQUEST";
export const PROMOCODES_GET_SUCCESS = "PROMOCODES_GET_SUCCESS";
export const PROMOCODES_GET_FAILURE = "PROMOCODES_GET_FAILURE";

export const PROMOCODE_GET_REQUEST = "PROMOCODE_GET_REQUEST";
export const PROMOCODE_GET_SUCCESS = "PROMOCODE_GET_SUCCESS";
export const PROMOCODE_GET_FAILURE = "PROMOCODE_GET_FAILURE";

export const PROMOCODE_DELETE_REQUEST = "PROMOCODE_DELETE_REQUEST";
export const PROMOCODE_DELETE_SUCCESS = "PROMOCODE_DELETE_SUCCESS";
export const PROMOCODE_DELETE_FAILURE = "PROMOCODE_DELETE_FAILURE";

export const PROMOCODE_CREATE_REQUEST = "PROMOCODE_CREATE_REQUEST";
export const PROMOCODE_CREATE_SUCCESS = "PROMOCODE_CREATE_SUCCESS";
export const PROMOCODE_CREATE_FAILURE = "PROMOCODE_CREATE_FAILURE";

// create multiple promocodes
export const PROMOCODES_CREATE_REQUEST = "PROMOCODES_CREATE_REQUEST";
export const PROMOCODES_CREATE_SUCCESS = "PROMOCODES_CREATE_SUCCESS";
export const PROMOCODES_CREATE_FAILURE = "PROMOCODES_CREATE_FAILURE";

export const PROMOCODE_UPDATE_REQUEST = "PROMOCODE_UPDATE_REQUEST";
export const PROMOCODE_UPDATE_SUCCESS = "PROMOCODE_UPDATE_SUCCESS";
export const PROMOCODE_UPDATE_FAILURE = "PROMOCODE_UPDATE_FAILURE";

// Production
export const SET_DELIVERY_FORMAT = "SET_DELIVERY_FORMAT";
export const SET_VENUE_VISIBLE = "SET_VENUE_VISIBLE";
export const SET_LIVESTREAM_VISIBLE = "SET_LIVESTREAM_VISIBLE";

export const GET_TITLES_REQUEST = "GET_TITLES_REQUEST";
export const GET_TITLES_SUCCESS = "GET_TITLES_SUCCESS";
export const GET_TITLES_FAILURE = "GET_TITLES_FAILURE";

export const GET_NAMES_REQUEST = "GET_NAMES_REQUEST";
export const GET_NAMES_SUCCESS = "GET_NAMES_SUCCESS";
export const GET_NAMES_FAILURE = "GET_NAMES_FAILURE";

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

export const GET_LICENSORS_REQUEST = "GET_LICENSORS_REQUEST";
export const GET_LICENSORS_SUCCESS = "GET_LICENSORS_SUCCESS";

export const SET_NAME = "SET_NAME";
export const GET_ORG_DATA_REQUEST = "GET_ORG_DATA_REQUEST";
export const GET_ORG_DATA_SUCCESS = "GET_ORG_DATA_SUCCESS";
export const GET_ORG_VENUES_SUCCESS = "GET_ORG_VENUE_SUCCESS";
export const GET_ORG_VENUES_REQUEST = "GET_ORG_VENUES_REQUEST";
export const GET_ORG_DATA_MISSING_ORG_ID = "GET_ORG_DATA_MISSING_ORG_ID";

export const GET_ORG_SF_CONTACT_EMAIL_REQUEST = "GET_ORG_SF_CONTACT_EMAIL_REQUEST";
export const GET_ORG_SF_CONTACT_EMAIL_SUCCESS = "GET_ORG_SF_CONTACT_EMAIL_SUCCESS";

export const SET_FORM_DATA = "SET_FORM_DATA";
export const SET_LIVESTREAM_OPTIONS_DATA = "SET_LIVESTREAM_OPTIONS_DATA";
export const SET_ADDRESS = "SET_ADDRESS";

export const SUBMIT_FORMDATA_REQUEST = "SUBMIT_FORMDATA_REQUEST";
export const SUBMIT_FORMDATA_SUCCESS = "SUBMIT_FORMDATA_SUCCESS";
export const SUBMIT_FORMDATA_FAILURE = "SUBMIT_FORMDATA_FAILURE";


export const GET_BASIC_INFO_REQUEST = "GET_BASIC_INFO_REQUEST";
export const GET_BASIC_INFO_SUCCESS = "GET_BASIC_INFO_SUCCESS";

export const GET_VIRTUAL_DRAFT_INFO_REQUEST = "GET_VIRTUAL_DRAFT_INFO_REQUEST";
export const GET_VIRTUAL_DRAFT_INFO_SUCCESS = "GET_VIRTUAL_DRAFT_INFO_SUCCESS";

export const SUBMIT_VIRTUAL_DRAFT_INFO_REQUEST = "SUBMIT_VIRTUAL_DRAFT_INFO_REQUEST"

export const GET_LIVE_SUCCESS = "GET_LIVE_SUCCESS";
export const GET_DRAFT_SUCCESS = "GET_DRAFT_SUCCESS";
export const GET_PAST_SUCCESS = "GET_PAST_SUCCESS";
export const GET_LIVE_AND_DRAFT_SUCCESS = "GET_LIVE_AND_DRAFT_SUCCESS";
export const GET_LIVE_ENTERPRISE_SUCCESS = "GET_LIVE_ENTERPRISE_SUCCESS";
export const GET_ALL_SUCCESS = "GET_ALL_SUCCESS";

export const GET_LIVE_FAILURE = "GET_LIVE_FAILURE";
export const GET_DRAFT_FAILURE = "GET_DRAFT_FAILURE";
export const GET_PAST_FAILURE = "GET_PAST_FAILURE";
export const GET_ALL_FAILURE = "GET_ALL_FAILURE";

export const GET_PRODUCTION_BY_ID_REQUEST = "GET_PRODUCTION_BY_ID_REQUEST";
export const GET_PRODUCTION_BY_ID_SUCCESS = "GET_PRODUCTION_BY_ID_SUCCESS";
export const GET_PRODUCTION_BY_ID_REQUEST_FAILURE = "GET_PRODUCTION_BY_ID_REQUEST_FAILURE";

export const UPDATE_PRODUCTION_REQUEST = "UPDATE_PRODUCTION_REQUEST";
export const UPDATE_PRODUCTION = "UPDATE_PRODUCTION";
export const UPDATE_PRODUCTION_VALUE = "UPDATE_PRODUCTION_VALUE";

export const UPDATE_PRODUCTION_NAME_REQUEST = "UPDATE_PRODUCTION_NAME_REQUEST";
export const UPDATE_PRODUCTION_NAME_SUCCESS = "UPDATE_PRODUCTION_NAME_SUCCESS";
export const UPDATE_PRODUCTION_NAME_FAILURE = "UPDATE_PRODUCTION_NAME_FAILURE";

export const TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_REQUEST = "TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_REQUEST";
export const TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_SUCCESS = "TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_SUCCESS";

export const UPDATE_SOCIAL_DISTANCING_REQUEST = "UPDATE_SOCIAL_DISTANCING_REQUEST";
export const UPDATE_SOCIAL_DISTANCING_SUCCESS = "UPDATE_SOCIAL_DISTANCING_SUCCESS";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_REQUEST_SUCCESS = "UPDATE_ORGANIZATION_REQUEST_SUCCESS";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORG_DATA_VALUE_SUCCESS = "UPDATE_ORG_DATA_VALUE_SUCCESS";

export const GET_BOOKING_FEES_REQUEST = "GET_BOOKING_FEES_REQUEST";
export const GET_BOOKING_FEES_SUCCESS = "GET_BOOKING_FEES_SUCCESS";

export const CREATE_BOOKING_FEE_REQUEST = "CREATE_BOOKING_FEE_REQUEST";
export const CREATE_BOOKING_FEE_SUCCESS = "CREATE_BOOKING_FEE_SUCCESS";

export const DELETE_BOOKING_FEE_REQUEST = "DELETE_BOOKING_FEE_REQUEST";
export const DELETE_BOOKING_FEE_SUCCESS = "DELETE_BOOKING_FEE_SUCCESS";

export const UPDATE_BOOKING_FEE_REQUEST = "UPDATE_BOOKING_FEE_REQUEST";
export const UPDATE_BOOKING_FEE_SUCCESS = "UPDATE_BOOKING_FEE_SUCCESS";

export const TOGGLE_STANDBY_REQUEST = "TOGGLE_STANDBY_REQUEST";
export const TOGGLE_STANDBY_REQUEST_SUCCESS = "TOGGLE_STANDBY_REQUEST_SUCCESS";

export const DELETE_DRAFT_REQUEST = "DELETE_DRAFT_REQUEST";
export const DELETE_DRAFT_REQUEST_SUCCESS = "DELETE_DRAFT_REQUEST_SUCCESS";

export const SET_GENERAL_CAPACITY_REQUEST = "SET_GENERAL_CAPACITY_REQUEST";
export const SET_GENERAL_CAPACITY_REQUEST_SUCCESS = "SET_GENERAL_CAPACITY_REQUEST_SUCCESS";

export const UPDATE_TICKETS_PER_ORDER_LIMIT_REQUEST="UPDATE_TICKETS_PER_ORDER_LIMIT_REQUEST";
export const UPDATE_TICKETS_PER_ORDER_LIMIT_SUCCESS="UPDATE_TICKETS_PER_ORDER_LIMIT_SUCCESS";

export const PRODUCTION_API_REQUEST_SUCCESS = "PRODUCTION_API_REQUEST_SUCCESS";

export const SEND_PATRON_EMAIL_REQUEST = "SEND_PATRON_EMAIL_REQUEST";
export const SEND_PATRON_EMAIL_REQUEST_SUCCESS = "SEND_PATRON_EMAIL_REQUEST_SUCCESS";
export const SEND_PATRON_EMAIL_REQUEST_FAILURE = "SEND_PATRON_EMAIL_REQUEST_FAILURE";

export const ADD_PEFORMANCE_DATE_REQUEST = "ADD_PEFORMANCE_DATE_REQUEST";
export const ADD_PEFORMANCE_DATE_REQUEST_SUCCESS = "ADD_PEFORMANCE_DATE_REQUEST_SUCCESS";
export const ADD_PEFORMANCE_DATE_REQUEST_FAILURE = "ADD_PEFORMANCE_DATE_REQUEST_FAILURE";

export const ADD_DRAFT_TIER_SUCCESS = "ADD_DRAFT_TIER_SUCCESS";
export const DELETE_DRAFT_TIER_SUCCESS = "DELETE_DRAFT_TIER_SUCCESS";
export const ADD_DRAFT_TIER_FAILURE = "ADD_DRAFT_TIER_FAILURE";
export const DELETE_DRAFT_TIER_FAILURE = "DELETE_DRAFT_TIER_FAILURE";

export const REMOVE_PEFORMANCE_DATE_FROM_DRAFT_REQUEST_SUCCESS = "REMOVE_PEFORMANCE_DATE_FROM_DRAFT_REQUEST_SUCCESS";

export const UPDATE_SALES_TAX_REQUEST = "UPDATE_SALES_TAX_REQUEST";
export const UPDATE_SALES_TAX_REQUEST_SUCCESS = "UPDATE_SALES_TAX_REQUEST_SUCCESS";
export const UPDATE_SALES_TAX_REQUEST_FAILURE = "UPDATE_SALES_TAX_REQUEST_FAILURE";

export const UPDATE_FEES_SALES_TAX_REQUEST = "UPDATE_FEES_SALES_TAX_REQUEST";
export const UPDATE_FEES_SALES_TAX_REQUEST_SUCCESS = "UPDATE_FEES_SALES_TAX_REQUEST_SUCCESS";
export const UPDATE_FEES_SALES_TAX_REQUEST_FAILURE = "UPDATE_FEES_SALES_TAX_REQUEST_FAILURE";

export const UPDATE_ADDONS_SALES_TAX_REQUEST = "UPDATE_ADDONS_SALES_TAX_REQUEST";
export const UPDATE_ADDONS_SALES_TAX_REQUEST_SUCCESS = "UPDATE_ADDONS_ALES_TAX_REQUEST_SUCCESS";
export const UPDATE_ADDONS_SALES_TAX_REQUEST_FAILURE = "UPDATE_ADDONS_SALES_TAX_REQUEST_FAILURE";

export const CHANGE_EVENT_TYPE_REQUEST_SUCCESS = "CHANGE_EVENT_TYPE_REQUEST_SUCCESS";
export const CHANGE_EVENT_TYPE_REQUEST_FAILURE = "CHANGE_EVENT_TYPE_REQUEST_FAILURE";

export const UPDATE_SHORT_CODE = "UPDATE_SHORT_CODE";

export const CHANGE_EVENTS_STATUS_SUCCESS = "CHANGE_EVENTS_STATUS_SUCCESS";

// refund

export const SAVE_REFUND_ORDERS = "SAVE_REFUND_ORDERS"

// Artwork theme
export const GET_THEMES_REQUEST = "GET_THEMES_REQUEST";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";

export const UPDATE_THEME_REQUEST = "UPDATE_THEME_REQUEST";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";

export const UPDATE_PRODUCTION_STYLE = "UPDATE_PRODUCTION_STYLE";

export const UPLOAD_IMAGE_THEME_REQUEST = "UPLOAD_IMAGE_THEME_REQUEST";
export const UPLOAD_IMAGE_THEME_SUCCESS = "UPLOAD_IMAGE_THEME_SUCCESS";
export const UPLOAD_IMAGE_THEME_FAILURE = "UPLOAD_IMAGE_THEME_FAILURE";

//Promotional Marketing Schedule
export const GET_MARKETING_SCHEDULE_REQUEST = "GET_MARKETING_SCHEDULE_REQUEST";
export const GET_MARKETING_SCHEDULE_SUCCESS = "GET_MARKETING_SCHEDULE_SUCCESS";
export const GET_MARKETING_SCHEDULE_FAILURE = "GET_MARKETING_SCHEDULE_FAILURE";
export const CLEAR_MARKETING_SCHEDULE = "CLEAR_MARKETING_SCHEDULE";
export const UPDATE_MARKETING_SCHEDULE = "UPDATE_MARKETING_SCHEDULE";
export const SET_FILTER = 'SET_FILTER';
export const SET_SELECTED_PROMOTE = 'SET_SELECTED_PROMOTE';

//Livestream
export const LIVESTREAM_CONNECTION_SETTINGS_GET_REQUEST = "LIVESTREAM_CONNECTION_SETTINGS_GET_REQUEST";
export const LIVESTREAM_CONNECTION_SETTINGS_GET_REQUEST_SUCCCESS = "LIVESTREAM_CONNECTION_SETTINGS_GET_REQUEST_SUCCCESS";
export const LIVESTREAM_CONNECTION_SETTINGS_GET_FAILURE = "LIVESTREAM_CONNECTION_SETTINGS_GET_FAILURE";

export const UPDATE_GEOFENCING_REQUEST = "UPDATE_GEOFENCING_REQUEST";
export const UPDATE_GEOFENCING_SUCCESS = "UPDATE_GEOFENCING_SUCCESS";

//Zoom

export const GET_ZOOM_SCHEDULE_REQUEST = "GET_ZOOM_SCHEDULE_REQUEST";
export const GET_ZOOM_SCHEDULE_SUCCESS = "GET_ZOOM_SCHEDULE_SUCCESS";
export const GET_ZOOM_SCHEDULE_FAILURE = "GET_ZOOM_SCHEDULE_FAILURE";


export const ZOOM_SCHEDULE_ADD_MEETING_REQUEST = "ZOOM_SCHEDULE_ADD_MEETING_REQUEST";
export const ZOOM_SCHEDULE_ADD_MEETING_SUCCESS = "ZOOM_SCHEDULE_ADD_MEETING_SUCCESS";
export const ZOOM_SCHEDULE_ADD_MEETING_FAILURE = "ZOOM_SCHEDULE_ADD_MEETING_FAILURE";

export const ZOOM_SCHEDULE_DELETE_REGISTRANT_REQUEST = "ZOOM_SCHEDULE_DELETE_REGISTRANT_REQUEST";
export const ZOOM_SCHEDULE_DELETE_REGISTRANT_SUCCESS = "ZOOM_SCHEDULE_DELETE_REGISTRANT_SUCCESS";
export const ZOOM_SCHEDULE_DELETE_REGISTRANT_FAILURE = "ZOOM_SCHEDULE_DELETE_REGISTRANT_FAILURE";

export const ZOOM_SCHEDULE_ADD_REGISTRANT_REQUEST = "ZOOM_SCHEDULE_ADD_REGISTRANT_REQUEST";
export const ZOOM_SCHEDULE_ADD_REGISTRANT_SUCCESS = "ZOOM_SCHEDULE_ADD_REGISTRANT_SUCCESS";
export const ZOOM_SCHEDULE_ADD_REGISTRANT_FAILURE = "ZOOM_SCHEDULE_ADD_REGISTRANT_FAILURE";

export const ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_REQUEST = "ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_REQUEST";
export const ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_SUCCESS = "ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_SUCCESS";
export const ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_FAILURE = "ZOOM_SCHEDULE_SEND_REGISTRANTS_EMAILS_FAILURE";


export const ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_REQUEST = "ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_REQUEST";
export const ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_SUCCESS = "ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_SUCCESS";
export const ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_FAILURE = "ZOOM_SCHEDULE_RESEND_REGISTRANTS_ZOOMLINKS_FAILURE";

export const ZOOM_SCHEDULE_GET_MEETING_REQUEST = "ZOOM_SCHEDULE_GET_MEETING_REQUEST";
export const ZOOM_SCHEDULE_GET_MEETING_SUCCESS = "ZOOM_SCHEDULE_GET_MEETING_SUCCESS";
export const ZOOM_SCHEDULE_GET_MEETING_FAILURE = "ZOOM_SCHEDULE_GET_MEETING_FAILURE";

export const ZOOM_SCHEDULE_CHANGE_DATE_REQUEST = "ZOOM_SCHEDULE_CHANGE_DATE_REQUEST";
export const ZOOM_SCHEDULE_CHANGE_DATE_SUCCESS = "ZOOM_SCHEDULE_CHANGE_DATE_SUCCESS";
export const ZOOM_SCHEDULE_CHANGE_DATE_FAILURE = "ZOOM_SCHEDULE_CHANGE_DATE_FAILURE";

export const ZOOM_SCHEDULE_CANCEL_MEETING_REQUEST = "ZOOM_SCHEDULE_CANCEL_MEETING_REQUEST";
export const ZOOM_SCHEDULE_CANCEL_MEETING_SUCCESS = "ZOOM_SCHEDULE_CANCEL_MEETING_SUCCESS";
export const ZOOM_SCHEDULE_CANCEL_MEETING_FAILURE = "ZOOM_SCHEDULE_CANCEL_MEETING_FAILURE";

export const ZOOM_SCHEDULE_CLEAR_CURRENT_MEETING = "ZOOM_SCHEDULE_CLEAR_CURRENT_MEETING";

//merchandise
export const GET_MERCHANDISE_REQUEST = "GET_MERCHANDISE_REQUEST";
export const GET_MERCHANDISE_SUCCESS = "GET_MERCHANDISE_SUCCESS";
export const GET_MERCHANDISE_FAILURE = "GET_MERCHANDISE_FAILURE";

export const MERCH_REQUEST_SUCCESS = "MERCH_REQUEST_SUCCESS";
export const MERCH_REQUEST_REQUEST = "MERCH_REQUEST_REQUEST";
export const MERCH_REQUEST_FAILURE = "MERCH_REQUEST_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const LIST_MERCH_BASE_PRODUCTS_REQUEST = "LIST_MERCH_BASE_PRODUCTS_REQUEST";
export const LIST_MERCH_BASE_PRODUCTS_SUCCESS = "LIST_MERCH_BASE_PRODUCTS_SUCCESS";
export const LIST_MERCH_BASE_PRODUCTS_FAILURE = "LIST_MERCH_BASE_PRODUCTS_FAILURE";

export const CREATE_PRINTFUL_MOCKUP_REQUEST = "CREATE_PRINTFUL_MOCKUP_REQUEST";
export const CREATE_PRINTFUL_MOCKUP_SUCCESS = "CREATE_PRINTFUL_MOCKUP_SUCCESS";
export const CREATE_PRINTFUL_MOCKUP_FAILURE = "CREATE_PRINTFUL_MOCKUP_FAILURE";

export const SET_SELECTED_MERCH_PRODUCT = "SET_SELECTED_MERCH_PRODUCT";
export const UPDATE_SELECTED_MERCH_PRODUCT_VALUE = "UPDATE_SELECTED_MERCH_PRODUCT_VALUE";
export const CLEAR_PRINTFUL_MOCKUP = "CLEAR_PRINTFUL_MOCKUP";
export const SET_PRINTFUL_MOCKUPS = "SET_PRINTFUL_MOCKUPS";

export const CREATE_MERCH_PRODUCT_REQUEST = "CREATE_MERCH_PRODUCT_REQUEST";
export const CREATE_MERCH_PRODUCT_SUCCESS = "CREATE_MERCH_PRODUCT_SUCCESS";
export const CREATE_MERCH_PRODUCT_FAILURE = "CREATE_PRINTFUL_MOCKUP_FAILURE";

export const SET_PRODUCT_COLORS_REQUEST = "SET_PRODUCT_COLORS_REQUEST";
export const SET_PRODUCT_COLORS_SUCCESS = "SET_PRODUCT_COLORS_SUCCESS";
export const SET_PRODUCT_COLORS_FAILURE = "SET_PRODUCT_COLORS_FAILURE";

export const SET_PRODUCT_COLOR_VARIANTS_REQUEST = "SET_PRODUCT_COLOR_VARIANTS_REQUEST";
export const SET_PRODUCT_COLOR_VARIANTS_SUCCESS = "SET_PRODUCT_COLOR_VARIANTS_SUCCESS";
export const SET_PRODUCT_VARIANTS_FAILURE = "SET_PRODUCT_VARIANTS_FAILURE";

export const SET_PRODUCT_VARIANTS_SUCCESS = "SET_PRODUCT_VARIANTS_SUCCESS";

// Search Orders
export const SEARCH_ORDERS_REQUEST = "SEARCH_ORDERS_REQUEST";
export const SEARCH_ORDERS_REQUEST_SUCCESS = "SEARCH_ORDERS_REQUEST_SUCCESS";
export const SEARCH_ORDERS_REQUEST_FAILURE = "SEARCH_ORDERS_REQUEST_FAILURE";
export const SET_SEARCH_ORDERS_PARAMETERS = "SET_SEARCH_ORDERS_PARAMETERS";



//Events
export const TOGGLE_TICKETS_PURCHASABLE_REQUEST = "TOGGLE_TICKETS_PURCHASABLE_REQUEST";
export const TOGGLE_TICKETS_PURCHASABLE_REQUEST_SUCCESS = "TOGGLE_TICKETS_PURCHASABLE_REQUEST_SUCCESS";
export const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
export const SEARCH_ORDERS_FAILURE = "SEARCH_ORDERS_FAILURE";

// API PARTNER
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";

// Settings -> Venues
export const CREATE_VENUE_REQUEST = "CREATE_VENUE_REQUEST";
export const CREATE_VENUE_SUCCESS = "CREATE_VENUE_SUCCESS";
export const CREATE_VENUE_FAILURE = "CREATE_VENUE_FAILURE";

export const UPDATE_VENUE_REQUEST = "UPDATE_VENUE_REQUEST";
export const UPDATE_VENUE_SUCCESS = "UPDATE_VENUE_SUCCESS";
export const DELETE_VENUE_SUCCESS = "DELETE_VENUE_SUCCESS";
export const UPDATE_VENUE_FAILURE = "UPDATE_VENUE_FAILURE";

export const EDIT_VENUE_FIELD = "EDIT_VENUE_FIELD";

// Settings -> Billing
export const SET_SEAT_FEE_PLAN_REQUEST = "SET_SEAT_FEE_PLAN_REQUEST";
export const SET_SEAT_FEE_PLAN_SUCCESS = "SET_SEAT_FEE_PLAN_SUCCESS";
export const SET_SEAT_FEE_PLAN_FAILURE = "SET_SEAT_FEE_PLAN_FAILURE";

export const UPDATE_TAX_DEDUCTIBLE_FIELD_REQUEST = "UPDATE_TAX_DEDUCTIBLE_FIELD_REQUEST";
export const UPDATE_TAX_DEDUCTIBLE_FIELD_SUCCESS = "UPDATE_TAX_DEDUCTIBLE_FIELD_SUCCESS";
export const UPDATE_TAX_DEDUCTIBLE_FIELD_FAILURE = "UPDATE_TAX_DEDUCTIBLE_FIELD_FAILURE";

// Contacts
export const SUBMIT_CONTACTS_REQUEST = "SUBMIT_CONTACTS_REQUEST";
export const SUBMIT_CONTACTS_SUCCESS = "SUBMIT_CONTACTS_SUCCESS";
export const SUBMIT_CONTACTS_FAILURE = "SUBMIT_CONTACTS_FAILURE";

export const UPLOAD_CONTACTS_FILE_REQUEST = "UPLOAD_CONTACTS_FILE_REQUEST";
export const UPLOAD_CONTACTS_FILE_SUCCESS = "UPLOAD_CONTACTS_FILE_SUCCESS";
export const UPLOAD_CONTACTS_FILE_FAILURE = "UPLOAD_CONTACTS_FILE_FAILURE";

export const UPDATE_CONTACT_REQUEST = "UPDATE_CONTACT_REQUEST";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAILURE = "UPDATE_CONTACT_FAILURE";

export const REMOVE_CONTACT_REQUEST = "REMOVE_CONTACT_REQUEST";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_FAILURE = "REMOVE_CONTACT_FAILURE";

export const CLEAR_FORM_ERROR_MESSAGE = "CLEAR_FORM_ERROR_MESSAGE";

export const RESET_FORM_DATA = "RESET_FORM_DATA";

export const CHANGE_PERFORMANCE_DATE_SUCCESS = "CHANGE_PERFORMANCE_DATE_SUCCESS";
export const CHANGE_PERFORMANCE_DATE_FAILURE = "CHANGE_PERFORMANCE_DATE_FAILURE";

export const REGENERATE_SERIES_AUTOMATION_REQUEST = "REGENERATE_SERIES_AUTOMATION_REQUEST";
export const REGENERATE_SERIES_AUTOMATION_SUCCESS = "REGENERATE_SERIES_AUTOMATION_SUCCESS";
export const REGENERATE_SERIES_AUTOMATION_FAILURE = "REGENERATE_SERIES_AUTOMATION_FAILURE";

export const GET_ORG_DASHBOARD_REQUEST = "GET_ORG_DASHBOARD_REQUEST";
export const GET_ORG_DASHBOARD_SUCCESS = "GET_ORG_DASHBOARD_SUCCESS";

export const GET_ORG_ENTERPRISE_DASHBOARD_REQUEST = "GET_ORG_ENTERPRISE_DASHBOARD_REQUEST";
export const GET_ORG_ENTERPRISE_DASHBOARD_SUCCESS = "GET_ORG_ENTERPRISE_DASHBOARD_SUCCESS";

export const GET_BUYERS_LIST_FROM_EVENT_REQUEST = "GET_BUYERS_LIST_FROM_EVENT_REQUEST";
export const GET_BUYERS_LIST_FROM_EVENT_SUCCESS = "GET_BUYERS_LIST_FROM_EVENT_SUCCESS";

export const UPDATE_ORDER_CONFIRMATION_MESSAGE_REQUEST = "UPDATE_ORDER_CONFIRMATION_MESSAGE_REQUEST";
export const UPDATE_ORDER_CONFIRMATION_MESSAGE_SUCCESS = "UPDATE_ORDER_CONFIRMATION_MESSAGE_SUCCESS";

export const UPDATE_SIDEBAR_BADGE_DRAFT = "UPDATE_SIDEBAR_BADGE_DRAFT";
export const UPDATE_SIDEBAR_BADGE_LIVE = "UPDATE_SIDEBAR_BADGE_LIVE";

export const UPDATE_ORGSITE_REQUEST = "UPDATE_ORGSITE_REQUEST";
export const UPDATE_ORGSITE_SUCCESS = "UPDATE_ORGSITE_SUCCESS";

export const UPDATE_ORGSITE_STYLE_REQUEST = "UPDATE_ORGSITE_STYLE_REQUEST";
export const UPDATE_ORGSITE_STYLE_SUCCESS = "UPDATE_ORGSITE_STYLE_SUCCESS";

export const GET_ORGSITE_REQUEST = "GET_ORGSITE_REQUEST";
export const GET_ORGSITE_SUCCESS = "GET_ORGSITE_SUCCESS";


export const GET_RECENT_ORDERS_REQUEST = "GET_RECENT_ORDERS_REQUEST";
export const GET_RECENT_ORDERS_SUCCESS = "GET_RECENT_ORDERS_SUCCESS";

// Fund.Campaign
export const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";

export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN_TRANSACTIONS_REQUEST = "GET_CAMPAIGN_TRANSACTIONS_REQUEST";
export const GET_CAMPAIGN_TRANSACTIONS_SUCCESS = "GET_CAMPAIGN_TRANSACTIONS_SUCCESS";
export const GET_CAMPAIGN_TRANSACTIONS_FAILURE = "GET_CAMPAIGN_TRANSACTIONS_FAILURE";

export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST"
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

export const DEACTIVATE_CAMPAIGN_REQUEST = "DEACTIVATE_CAMPAIGN_REQUEST"
export const DEACTIVATE_CAMPAIGN_SUCCESS = "DEACTIVATE_CAMPAIGN_SUCCESS";
export const DEACTIVATE_CAMPAIGN_FAILURE = "DEACTIVATE_CAMPAIGN_FAILURE";


export const DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN_TEMPLATES_REQUEST = "GET_CAMPAIGN_TEMPLATES_REQUEST";
export const GET_CAMPAIGN_TEMPLATES_SUCCESS = "GET_CAMPAIGN_TEMPLATES_SUCCESS";
export const GET_CAMPAIGN_TEMPLATES_FAILURE = "GET_CAMPAIGN_TEMPLATES_FAILURE";

export const CREATE_CAMPAIGN_FROM_TEMPLATE_REQUEST = "CREATE_CAMPAIGN_FROM_TEMPLATE_REQUEST"
export const CREATE_CAMPAIGN_FROM_TEMPLATE_SUCCESS = "CREATE_CAMPAIGN_FROM_TEMPLATE_SUCCESS";
export const CREATE_CAMPAIGN_FROM_TEMPLATE_FAILURE = "CREATE_CAMPAIGN_FROM_TEMPLATE_FAILURE";

// Fund.Donation
export const CREATE_DONATION_REQUEST = "CREATE_DONATION_REQUEST"
export const CREATE_DONATION_SUCCESS = "CREATE_DONATION_SUCCESS";
export const CREATE_DONATION_FAILURE = "CREATE_DONATION_FAILURE";

export const GET_DONATION_REQUEST = "GET_DONATION_REQUEST"
export const GET_DONATION_SUCCESS = "GET_DONATION_SUCCESS";
export const GET_DONATION_FAILURE = "GET_DONATION_FAILURE";

export const GET_DONATION_TRANSACTIONS_REQUEST = "GET_DONATION_TRANSACTIONS_REQUEST"
export const GET_DONATION_TRANSACTIONS_SUCCESS = "GET_DONATION_TRANSACTIONS_SUCCESS";
export const GET_DONATION_TRANSACTIONS_FAILURE = "GET_DONATION_TRANSACTIONS_FAILURE";
// Fund Affiliate
export const AFFILIATES_GET_REQUEST = "AFFILIATES_GET_REQUEST";
export const AFFILIATES_GET_SUCCESS = "AFFILIATES_GET_SUCCESS";
export const AFFILIATES_GET_FAILURE = "AFFILIATES_GET_FAILURE";

export const AFFILIATE_CREATE_REQUEST = "AFFILIATE_CREATE_REQUEST";
export const AFFILIATE_CREATE_SUCCESS = "AFFILIATE_CREATE_SUCCESS";
export const AFFILIATE_CREATE_FAILURE = "AFFILIATE_CREATE_FAILURE";

export const UPDATE_AFFILIATE_REQUEST = "UPDATE_AFFILIATE_REQUEST";
export const UPDATE_AFFILIATE_SUCCESS = "UPDATE_AFFILIATE_SUCCESS";
export const UPDATE_AFFILIATE_FAILURE = "UPDATE_AFFILIATE_FAILURE";

export const REMOVE_AFFILIATE_SUCCESS = "REMOVE_AFFILIATE_SUCCESS";
export const REMOVE_AFFILIATE_REQUEST = "REMOVE_AFFILIATE_REQUEST";
export const REMOVE_AFFILIATE_FAILURE = "REMOVE_AFFILIATE_FAILURE";

// Fund Product
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const UPDATE_FUND_PRODUCT_REQUEST = "UPDATE_FUND_PRODUCT_REQUEST"
export const UPDATE_FUND_PRODUCT_SUCCESS = "UPDATE_FUND_PRODUCT_SUCCESS";
export const UPDATE_FUND_PRODUCT_FAILURE = "UPDATE_FUND_PRODUCT_FAILURE";

export const EDIT_PRODUCT_FIELD = "EDIT_PRODUCT_FIELD";

// Fund Contact

export const GET_NEW_CONTACTS_REQUEST = "GET_NEW_CONTACTS_REQUEST";
export const GET_NEW_CONTACTS_SUCCESS = "GET_NEW_CONTACTS_SUCCESS";
export const GET_NEW_CONTACTS_FAILURE = "GET_NEW_CONTACTS_FAILURE";

export const GET_ACTIONABLE_CONTACTS_REQUEST = "GET_ACTIONABLE_CONTACTS_REQUEST";
export const GET_ACTIONABLE_CONTACTS_SUCCESS = "GET_ACTIONABLE_CONTACTS_SUCCESS";
export const GET_ACTIONABLE_CONTACTS_FAILURE = "GET_ACTIONABLE_CONTACTS_FAILURE";

export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";

export const GET_CONTACT_ATTRIBUTES_SUCCESS = "GET_CONTACT_ATTRIBUTES_SUCCESS";
export const GET_CONTACT_ATTRIBUTES_FAILURE = "GET_CONTACT_ATTRIBUTES_FAILURE";

export const GET_CONTACT_SUBSCRIPTION_REQUEST = "GET_CONTACT_SUBSCRIPTION_REQUEST"
export const GET_CONTACT_SUBSCRIPTION_SUCCESS = "GET_CONTACT_SUBSCRIPTION_SUCCESS";
export const GET_CONTACT_SUBSCRIPTION_FAILURE = "GET_CONTACT_SUBSCRIPTION_FAILURE";

export const GENERATE__CONTACT_CC_LINK_REQUEST = "GENERATE__CONTACT_CC_LINK_REQUEST"
export const GENERATE__CONTACT_CC_LINK_SUCCESS = "GENERATE__CONTACT_CC_LINK_SUCCESS";
export const GENERATE__CONTACT_CC_LINK_FAILURE = "GENERATE__CONTACT_CC_LINK_FAILURE";

// CONTACT Logs
export const CONTACT_LOGS_GET_REQUEST = "CONTACT_LOGS_GET_REQUEST";
export const CONTACT_LOGS_GET_SUCCESS = "CONTACT_LOGS_GET_SUCCESS";
export const CONTACT_LOGS_GET_FAILURE = "CONTACT_LOGS_GET_FAILURE";

export const ADD_CONTACT_LOG_REQUEST = "ADD_CONTACT_LOG_REQUEST"
export const ADD_CONTACT_LOG_SUCCESS = "ADD_CONTACT_LOG_SUCCESS";
export const ADD_CONTACT_LOG_FAILURE = "ADD_CONTACT_LOG_FAILURE";

export const UPDATE_CONTACT_LOG_REQUEST = "UPDATE_CONTACT_LOG_REQUEST"
export const UPDATE_CONTACT_LOG_SUCCESS = "UPDATE_CONTACT_LOG_SUCCESS";
export const UPDATE_CONTACT_LOG_FAILURE = "UPDATE_CONTACT_LOG_FAILURE";

export const EDIT_CONTACT_LOG_FIELD = "EDIT_CONTACT_LOG_FIELD";

export const CONTACTS_SOURCES_GET_REQUEST = "CONTACTS_SOURCES_GET_REQUEST";
export const CONTACTS_SOURCES_GET_SUCCESS = "CONTACTS_SOURCES_GET_SUCCESS";
export const CONTACTS_SOURCES_GET_FAILURE = "CONTACTS_SOURCES_GET_FAILURE";

export const UPDATE_SOCIAL_URL_REQUEST = "UPDATE_SOCIAL_URL_REQUEST";
export const UPDATE_SOCIAL_URL_SUCCESS = "UPDATE_SOCIAL_URL_SUCCESS";

export const CANCEL_CONTACT_SUBSCRIPTION_REQUEST = "CANCEL_CONTACT_SUBSCRIPTION_REQUEST"
export const CANCEL_CONTACT_SUBSCRIPTION_SUCCESS = "CANCEL_CONTACT_SUBSCRIPTION_SUCCESS";
export const CANCEL_CONTACT_SUBSCRIPTION_FAILURE = "CANCEL_CONTACT_SUBSCRIPTION_FAILURE";

export const GET_CONTENT_TEMPLATES_REQUEST = "GET_CONTENT_TEMPLATES_REQUEST"
export const GET_CONTENT_TEMPLATES_REQUEST_SUCCESS = "GET_CONTENT_TEMPLATES_REQUEST_SUCCESS";
export const GET_CONTENT_TEMPLATES_REQUEST_FAILURE = "GET_CONTENT_TEMPLATES_REQUEST_FAILURE";

// social media actions
export const GET_ORGANIZATION_SOCIALPROFILE = "GET_ORGANIZATION_SOCIALPROFILE";
export const SOCIAL_PROFILE_LOADING = "SOCIAL_PROFILE_LOADING";
export const CREATE_ORGANIZATION_SOCIALPROFILE = "CREATE_ORGANIZATION_SOCIALPROFILE";
export const CREATE_ORGANIZATION_SOCIALPROFILE_SUCCESS = "CREATE_ORGANIZATION_SOCIALPROFILE_SUCCESS";
export const CREATE_ORGANIZATION_SOCIALPROFILE_ERROR = "CREATE_ORGANIZATION_SOCIALPROFILE_ERROR";
export const DISCONNECT_ORGANIZATION_SOCIALPROFILE_SUCCESS = "DISCONNECT_ORGANIZATION_SOCIALPROFILE_SUCCESS";

// Fund Campaign Promote Schedule
export const GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_REQUEST = "GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_REQUEST";
export const GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_SUCCESS = "GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_SUCCESS";
export const GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_FAILURE = "GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_FAILURE";

// Stripe Connect Account
export const GET_STRIPE_CONNECT_ACCOUNT = "GET_STRIPE_CONNECT_ACCOUNT";
// Showgram constant
export const GET_PLAYBILL_DATA_REQUEST = "GET_PLAYBILL_DATA_REQUEST";
export const GET_PLAYBILL_DATA_SUCCESS = "GET_PLAYBILL_DATA_SUCCESS";
export const GET_PLAYBILL_DATA_FAILURE = "GET_PLAYBILL_DATA_FAILURE";
export const UPDATE_PLAYBILL_REQUEST = "UPDATE_PLAYBILL_REQUEST";
export const UPDATE_PLAYBILL_SUCCESS = "UPDATE_PLAYBILL_SUCCESS";
export const UPDATE_PLAYBILL_FAILURE = "UPDATE_PLAYBILL_FAILURE";
export const UPDATE_PLAYBILL_VALUE = "UPDATE_PLAYBILL_VALUE";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";


export const CLOSE_WAITING = "CLOSE_WAITING";
export const SHOW_WAITING = "SHOW_WAITING";

export const GET_MARKETING_PACKAGE = "GET_MARKETING_PACKAGE";
export const GENERATE_MARKETING_PACKAGE_REQ = "GENERATE_MARKETING_PACKAGE_REQ";
export const GENERATE_MARKETING_PACKAGE = "GENERATE_MARKETING_PACKAGE";
export const GENERATE_MARKETING_PACKAGE_ERR = "GENERATE_MARKETING_PACKAGE_ERR";

export const GET_READERS_SUCCESS = "GET_RECENT_ORDERS_SUCCESS";

// Event Tier Modifier
export const CREATE_EVENT_TIER_MODIFIER_REQUEST = "CREATE_EVENT_TIER_MODIFIER_REQUEST";
export const CREATE_EVENT_TIER_MODIFIER_SUCCESS = "CREATE_EVENT_TIER_MODIFIER_SUCCESS";
export const CREATE_EVENT_TIER_MODIFIER_FAILURE = "CREATE_EVENT_TIER_MODIFIER_FAILURE";

export const GET_EVENT_TIER_MODIFIERS_REQUEST = "GET_EVENT_TIER_MODIFIERS_REQUEST";
export const GET_EVENT_TIER_MODIFIERS_SUCCESS = "GET_EVENT_TIER_MODIFIERS_SUCCESS";
export const GET_EVENT_TIER_MODIFIERS_FAILURE = "GET_EVENT_TIER_MODIFIERS_FAILURE";

export const UPDATE_EVENT_TIER_MODIFIER_REQUEST = "UPDATE_EVENT_TIER_MODIFIER_REQUEST";
export const UPDATE_EVENT_TIER_MODIFIER_SUCCESS = "UPDATE_EVENT_TIER_MODIFIER_SUCCESS";
export const UPDATE_EVENT_TIER_MODIFIER_FAILURE = "UPDATE_EVENT_TIER_MODIFIER_FAILURE";

export const DELETE_EVENT_TIER_MODIFIER_REQUEST = "DELETE_EVENT_TIER_MODIFIER_REQUEST";
export const DELETE_EVENT_TIER_MODIFIER_SUCCESS = "DELETE_EVENT_TIER_MODIFIER_SUCCESS";
export const DELETE_EVENT_TIER_MODIFIER_FAILURE = "DELETE_EVENT_TIER_MODIFIER_FAILURE";

// Organization Details
export const GET_ORG_DETAILS_REQUEST = 'GET_ORG_DETAILS_REQUEST';
export const GET_ORG_DETAILS_SUCCESS = 'GET_ORG_DETAILS_SUCCESS';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const SEND_TEST_EMAIL_SUCCESS = 'SEND_TEST_EMAIL_SUCCESS';
export const SAVE_ORG_ATTRIBUTES = 'SAVE_ORG_ATTRIBUTES';
export const SAVE_ORG_ATTRIBUTES_SUCCESS = 'SAVE_ORG_ATTRIBUTES_SUCCESS';
export const SAVE_SERIES_ATTRIBUTES = 'SAVE_SERIES_ATTRIBUTES';
export const SAVE_SERIES_ATTRIBUTES_SUCCESS = 'SAVE_SERIES_ATTRIBUTES_SUCCESS';
export const GET_ORG_CSS_TEMPLATE = 'GET_ORG_CSS_TEMPLATE';
export const GET_ORG_CSS_TEMPLATE_SUCCESS = 'GET_ORG_CSS_TEMPLATE_SUCCESS';
export const SAVE_ORG_CSS_TEMPLATE = 'SAVE_ORG_CSS_TEMPLATE';
export const SAVE_ORG_CSS_TEMPLATE_SUCCESS = 'SAVE_ORG_CSS_TEMPLATE_SUCCESS';
export const UPDATE_ORG_CSS_TEMPLATE = 'UPDATE_ORG_CSS_TEMPLATE';
export const UPDATE_ORG_CSS_TEMPLATE_SUCCESS = 'UPDATE_ORG_CSS_TEMPLATE_SUCCESS';

// Add-ons
export const GET_ADDONS = 'GET_ADDONS';
export const GET_ADDONS_SUCCESS = 'GET_ADDONS_SUCCESS';
export const SET_PRODUCTION_FOR_ADD_ONS = 'SET_PRODUCTION_FOR_ADD_ONS';
export const DELETE_PRODUCTION_FOR_ADD_ONS = 'DELETE_PRODUCTION_FOR_ADD_ONS';
export const UPDATE_ADDON = 'UPDATE_ADDON';
export const UPDATE_ADDON_SUCCESS = 'UPDATE_ADDON_SUCCESS';
export const SAVE_ADDON = 'SAVE_ADDON';
export const SAVE_ADDON_SUCCESS = 'SAVE_ADDON_SUCCESS';

export const SAVE_ADDON_VARIANT = 'SAVE_ADDON_VARIANT';
export const SAVE_ADDON_VARIANT_SUCCESS = 'SAVE_ADDON_VARIANT_SUCCESS';
export const DELETE_ADDON_VARIANT = 'DELETE_ADDON_VARIANT';
export const DELETE_ADDON_VARIANT_SUCCESS = 'DELETE_ADDON_VARIANT_SUCCESS';
export const UPDATE_ADDON_VARIANT = 'UPDATE_ADDON_VARIANT';
export const UPDATE_ADDON_VARIANT_SUCCESS = 'UPDATE_ADDON_VARIANT_SUCCESS';

export const UPDATE_ADDON_ORDER = 'UPDATE_ADDON_ORDER';
export const UPDATE_ADDON_ORDER_SUCCESS = 'UPDATE_ADDON_ORDER_SUCCESS';
export const UPDATE_ADDON_ORDER_FAILURE = 'UPDATE_ADDON_ORDER_FAILURE';

// Offers
export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const SAVE_OFFER = 'SAVE_OFFER';
export const SAVE_OFFER_SUCCESS = 'SAVE_OFFER_SUCCESS';
export const UPDATE_OFFER_ORDER = 'UPDATE_OFFER_ORDER';
export const UPDATE_OFFER_ORDER_SUCCESS = 'UPDATE_OFFER_ORDER_SUCCESS';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const UPDATE_OFFER_SUCCESS = 'UPDATE_OFFER_SUCCESS';
export const DELETE_OFFER = 'DELETE_OFFER';
export const DELETE_OFFER_SUCCESS = 'DELETE_OFFER_SUCCESS';
export const DELETE_OFFER_FAILURE = 'DELETE_OFFER_FAILURE';

// Communications
export const GET_COMMUNICATION_EMAILS_REQUEST = 'GET_COMMUNICATION_EMAILS_REQUEST';
export const GET_COMMUNICATION_EMAILS_REQUEST_SUCCESS = 'GET_COMMUNICATION_EMAILS_REQUEST_SUCCESS';
export const UPDATE_COMMUNICATION_EMAIL = 'UPDATE_COMMUNICATION_EMAIL';
export const UPDATE_COMMUNICATION_EMAIL_SUCCESS = 'UPDATE_COMMUNICATION_EMAIL_SUCCESS';
export const DELETE_COMMUNICATION_EMAIL = 'DELETE_COMMUNICATION_EMAIL';
export const DELETE_COMMUNICATION_EMAIL_SUCCESS = 'DELETE_COMMUNICATION_EMAIL_SUCCESS';
export const CREATE_COMMUNICATION_EMAIL = 'CREATE_COMMUNICATION_EMAIL';
export const CREATE_COMMUNICATION_EMAIL_SUCCESS = 'CREATE_COMMUNICATION_EMAIL_SUCCESS';

// Filtered flows
export const GET_CUSTOM_FLOWS = 'GET_CUSTOM_FLOWS';
export const GET_CUSTOM_FLOWS_SUCCESS = 'GET_CUSTOM_FLOWS_SUCCESS';
export const UPDATE_CUSTOM_FLOWS = 'UPDATE_CUSTOM_FLOWS';
export const UPDATE_CUSTOM_FLOWS_SUCCESS = 'UPDATE_CUSTOM_FLOWS_SUCCESS';
export const UPDATE_CUSTOM_FLOWS_FAILURE = 'UPDATE_CUSTOM_FLOWS_FAILURE';
export const SAVE_CUSTOM_FLOWS = 'SAVE_CUSTOM_FLOWS';
export const SAVE_CUSTOM_FLOWS_SUCCESS = 'SAVE_CUSTOM_FLOWS_SUCCESS';
export const SAVE_CUSTOM_FLOWS_FAILURE = 'SAVE_CUSTOM_FLOWS_FAILURE';

// Edit promote email
export const GET_MARKETING_EMAIL_DETAILS_REQUEST = 'GET_MARKETING_EMAIL_DETAILS_REQUEST';
export const GET_MARKETING_EMAIL_DETAILS_SUCCESS = 'GET_MARKETING_EMAIL_DETAILS_SUCCESS';
export const UPDATE_MARKETING_EMAIL_DETAILS_REQUEST = 'UPDATE_MARKETING_EMAIL_DETAILS_REQUEST';
export const UPDATE_MARKETING_EMAIL_DETAILS_SUCCESS = 'UPDATE_MARKETING_EMAIL_DETAILS_SUCCESS';
export const RESET_SELECTED_SCHEDULED_EMAIL = 'RESET_SELECTED_SCHEDULED_EMAIL';
export const SEND_TEST_PROMOTE_EMAIL_REQ = 'SEND_TEST_PROMOTE_EMAIL_REQ';
export const SEND_TEST_PROMOTE_EMAIL_SUCCESS = 'SEND_TEST_PROMOTE_EMAIL_SUCCESS';