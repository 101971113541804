import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Box,
  Grid,
  Link,
  TextField,
  FormControlLabel,
  Radio,
  MenuItem,
  CircularProgress,
  RadioGroup,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { createOffer, updateOffer } from "_redux/actions/offerActions";

import Modal from '_components/modal/Modal';
import { SaveButtonComponent } from '_components/common';
import { openDialog } from '_redux/actions/appActions';
import { getEventTierModifiers } from '_redux/actions/eventTierModifierActions';
import { getAddOns } from '_redux/actions/addonsActions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  nameCell: {
    width: '20%',
    maxWidth: '100px',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
  },
  perCell: {
    width: '15%',
  },
  staticCell: {
    width: '10%',
  },
  editCell: {
    width: '20%',
  },
  emptyImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '240px',
    width: '100%',
    border: '1px dashed grey',
    marginBottom: '16px',
  },
  imageContainer: {
    height: '240px',
    width: '100%',
    marginBottom: '16px',
  },
  productImage: {
    maxHeight: '100%',
    objectFit: 'fill',
  },
  addOnDropdown: {
    width: '45%',
  },
  logicStyle: {
    padding: theme.spacing(1),
  },
  fieldStyle: {
    marginTop: '16px',
  },
  nonPlatinumMessage: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'initial',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#E5F6FD',
    color: '#00728C',
    marginBottom: '16px',
  },
  nonPlatinumMessageIcon: {
    marginRight: '8px',
  },
}));

function OffersForm({ isOpen, toggleModal, initialFormValues, isEditing, selectedOfferId, offersList }) {
  // Redux Hooks
  const dispatch = useDispatch();
  const userOrg = useSelector((state) => state.authReducer.user.userOrg);
  const {
    organization: { id: organization_id, is_enterprise },
  } = useSelector((state) => state.organizationReducer);
  const { live, draft, live_enterprise } = useSelector(
    (state) => state.productionListReducer
  );
  const { addOns } = useSelector((state) => state.addonsReducer);
  const eventTierModifiersList = useSelector(
    (state) => state.eventTierModifierReducer.eventTierModifiers
  );

  // React State Hooks
  const [selectProductionOptions, setSelectProductionOptions] = useState([]);
  const [selectEventTiers, setSelectEventTiers] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectAddOnOptions, setSelectAddOnOptions] = useState([]);
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  // Constants and Regex Patterns
  const nonAsciiRegex = /^[ -~]*$/;

  // Other Hooks
  const classes = useStyles({});

  const SectionTitle = ({ children, mb }) => {
    return (
      <Grid item xs={12}>
        <Box style={{ marginBottom: mb || '8px' }}>
          <Typography variant='h5' fontWeight='bold'>
            {children}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const nameComparator = (a, b) => {
    if (a.name === undefined) {
      return 0;
    }

    const nameA = a.name.toLowerCase(); // Convert to lowercase for case-insensitive sorting
    const nameB = b.name.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; // Names are equal
  };

  const validateDuplicateName = () => {
    let currentName = initialFormValues ? initialFormValues['name'] : '';

    // If the current fee being created/updated is one of the series_ids, skip validation
    if (currentName === formik.values['name']) {
      return true;
    }

    if (offersList) {
      let names = offersList.map(offer => offer.name);
      if (names.includes(formik.values['name'])) {
        return false;
      }
    }

    return true;
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(nonAsciiRegex, 'Invalid characters')
        .max(30, 'Max 30 characters')
        .required('Offer name is required'),
      SeriesId: Yup.string()
        .nullable()
        .required('Production is required')
        .notOneOf(['', 'NONE'], 'Production must be selected'),
      rules: Yup.array()
        .when('applies_to', {
          is: 'ticket',
          then: Yup.array()
            .min(1, 'Field is required')
            .of(
              Yup.object().shape({
                if: Yup.string()
                  .required('Field is required')
                  .notOneOf(['', 'NONE'], 'Ticket Type must be selected'),
                then: Yup.string()
                  .required('Field is required')
                  .notOneOf(['', 'NONE'], 'Ticket Type must be selected'),
              })
            ),
        })
        .when('applies_to', {
          is: 'addon',
          then: Yup.array()
            .min(1, 'Field is required')
            .of(
              Yup.object().shape({
                if: Yup.string()
                  .required('Field is required')
                  .notOneOf(['', 'NONE'], 'Addon must be selected'),
              })
            ),
        }),
      img_url: Yup.string().when('applies_to', {
        is: 'ticket',
        then: Yup.string().required('Promotional photo is required'),
        otherwise: Yup.string(), // No validation when Applies To is "addon"
      }),
    }),
    onSubmit: async (values, { setErrors, setSubmitting, setStatus }) => {
      // Handle form submission here
      try {
        console.log('offer submit: ', formik.values);

        if (!validateDuplicateName()) {
          formik.setFieldError('name', 'Offer name already exists');
          return;
        }

        const { artix_series_id } = selectProductionOptions.find(
          (t) => t.artix_series_id === formik.values.SeriesId
        );
        const data = {
          ...formik.values,
          SeriesId: artix_series_id,
        };

        if (isEditing) {
          await dispatch(updateOffer(organization_id, selectedOfferId, data));
        } else {
          await dispatch(createOffer(organization_id, data));
        }
        setStatus({ success: true });
        setSubmitting(true);
        toggleModal(); //close on success
      } catch (error) {
        const message =
          error.response?.data?.message ||
          error.response?.data ||
          error.message ||
          'Something went wrong';
        setStatus({ success: false });
        setErrors({ submit: message.error ? message.error : message });
        setSubmitting(false);
      }
    },
  });

  const selectImage = () => {
    setIsUploading(true);
    const cloudinaryOpts = {
      cloud_name: 'onthestage',
      sources: ['local'],
      upload_preset: 'qr1vgiyi',
      resourceType: 'image',
      client_allowed_formats: ['png', 'jpeg', 'jpg'],
      tags: ['offers', 'c_' + userOrg.id],
      validateMaxWidthHeight: false,
      multiple: false,
      showAdvancedOptions: true,
      folder: userOrg.id + '/offers',
    };

    const cloudinaryWidget = window.cloudinary.openUploadWidget(
      cloudinaryOpts,
      (err, result) => {
        setIsUploading(false);
        if (err && err.message !== 'User closed widget') {
          cloudinaryWidget.close();
          dispatch(
            openDialog({
              message: `There was an error uploading your file: ${JSON.stringify(
                err.status
              )}`,
            })
          );
        } else if (err && err.message === 'User closed widget') {
          return false;
        }
        if (result.event === 'success') {
          const image = result.info;
          // aspect ratio check = width === height
          if (image.width && image.height && image.width !== image.height) {
            cloudinaryWidget.close();
            dispatch(
              openDialog({
                message: `Image upload failed: Aspect ratio must be 1:1.`,
              })
            );
            formik.setErrors({
              img_url: 'Image upload failed: Aspect ratio must be 1:1',
            });
          } else {
            // console.log('imageu url', image.secure_url);
            formik.setFieldValue('img_url', image.secure_url);
          }
          cloudinaryWidget.close();
        }
      }
    );
  };

  useEffect(() => {
    if (!is_enterprise) {
      const productions = [
        ...[...live, ...draft].sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setSelectProductionOptions(productions);
      if (productions.length > 0 && !isEditing) {
        formik.setFieldValue('SeriesId', productions[0].artix_series_id);
      }
    }

    if (is_enterprise) {
      const productions = [
        ...[...live_enterprise].sort((a, b) => a.name.localeCompare(b.name)),
      ];
      setSelectProductionOptions(productions);
      if (productions.length > 0 && !isEditing) {
        formik.setFieldValue('SeriesId', productions[0].artix_series_id);
      }
    }
  }, [dispatch, organization_id, live, draft, is_enterprise, live_enterprise]);

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(getEventTierModifiers(organization_id));
        await dispatch(getAddOns(organization_id, null));
      } catch (error) {
        dispatch(
          openDialog({
            message:
              error.message ||
              'Something went wrong! Could not load data. Please refresh.',
          })
        );
      }
    }
    if (is_enterprise && organization_id && !isLoadingDone) {
      fetchData();
      setIsLoadingDone(true);
    }
  }, [
    organization_id,
    dispatch,
    is_enterprise,
    eventTierModifiersList,
    addOns,
    isLoadingDone,
  ]);

  // update event tiers for core event tiers from production
  useEffect(() => {
    async function updateTiersForCore() {
      // based on selected production event tiers would change
      if (formik.values.SeriesId && selectProductionOptions?.length > 0) {
        const production = selectProductionOptions.find(
          (t) => t.artix_series_id === formik.values.SeriesId
        );
        if (production) {
          setSelectEventTiers(
            production.tiers.map((t) => {
              return { id: t._id, name: t.name };
            })
          );
        }
      }

      // fetch again since this function gets called when is_enterprise is false
      if (addOns.length === 0) {
        await dispatch(getAddOns(organization_id, null));
      }
    }

    async function updateTicketTypesForEnt() {
      if (formik.values.SeriesId) {
        const production = selectProductionOptions.find(
          (t) => t.artix_series_id === formik.values.SeriesId
        );
        if (production) {
          setSelectEventTiers(
            eventTierModifiersList
              .filter((t) => {
                const seriesFound = t.Series.find(
                  (series) => series.id === (production?.enterprise_api_config?.event_id || production.artix_series_id)
                );
                return t.minimum_in_cart === 1 && seriesFound !== undefined;
              })
              .map((t) => {
                return { id: t.id, name: t.name };
              })
          );
        }
      }
    }

    if (!is_enterprise && organization_id) {
      updateTiersForCore();
    } else if (is_enterprise && organization_id) {
      updateTicketTypesForEnt();
    }
  }, [
    organization_id,
    is_enterprise,
    selectProductionOptions,
    eventTierModifiersList,
    formik.values.SeriesId,
  ]);

  useEffect(() => {
    function updateAddons() {
      // load org level addons first
      let filteredAddons = addOns.filter((t) => t.SeriesId === null);

      if(selectAddOnOptions.length === 0) {
        setSelectAddOnOptions(filteredAddons);
      }

      if (formik.values.SeriesId) {
        const production = addOns.filter((t) => {
          return t.SeriesId === formik.values.SeriesId
        }
        );

        setSelectAddOnOptions(
          [].concat(
            filteredAddons,
            (production ? production : [])
          )
        );
      }
    }

    updateAddons();
  }, [organization_id, is_enterprise, addOns, formik.values.SeriesId]);

  return (
    <Grid item xs={12} md={8}>
      <Modal
        title={isEditing ? 'Edit Offer' : 'Add New Offer Configuration'}
        isOpen={isOpen}
        onClose={toggleModal}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          {formik.errors.submit && (
            <Alert mt={2} mb={1} severity='warning' style={{ margin: '8px' }}>
              {formik.errors.submit}
            </Alert>
          )}
          <Grid container justify='center' alignItems='center'>
            <Grid item xs={9}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <TextField
                    required
                    autoFocus
                    type='text'
                    name='name'
                    label='Offer name'
                    value={
                      formik.values.name === null ? '' : formik.values.name
                    }
                    variant='outlined'
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    fullWidth
                    helperText={formik.touched.name && formik.errors.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid item sm={12} className={classes.fieldStyle}>
                    {selectProductionOptions.length > 0 ? (
                      <TextField
                        select
                        type='text'
                        name='SeriesId'
                        label='Production'
                        variant='outlined'
                        fullWidth
                        disabled={formik.isSubmitting}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (!formik.touched.SeriesId) {
                            formik.setFieldTouched('SeriesId', true);
                          }

                          formik.setFieldValue('SeriesId', value);
                        }}
                        error={Boolean(
                          formik.touched.SeriesId && formik.errors.SeriesId
                        )}
                        helperText={
                          formik.touched.SeriesId && formik.errors.SeriesId
                        }
                        SelectProps={{
                          value:
                            formik.values.SeriesId === null
                              ? ''
                              : formik.values.SeriesId,
                          MenuProps: {
                            PaperProps: {
                              getcontentanchorel: null,
                            },
                          },
                        }}
                      >
                        {selectProductionOptions
                          .sort(nameComparator)
                          .map((production, index) => (
                            <MenuItem
                              value={production.artix_series_id}
                              key={`${production.artix_series_id}-${index}`}
                            >
                              {production.name}{' '}
                              {production.status === 'draft' ? '(Draft)' : ''}
                            </MenuItem>
                          ))}
                      </TextField>
                    ) : (
                      <Typography>
                        {isLoadingDone && (
                          <Alert severity='warning'>
                            {'Create a production '}
                            <Link
                              component={NavLink}
                              to='/productions'
                              style={{ color: 'Primary' }}
                            >
                              here
                            </Link>
                            {' before creating an offer.'}
                          </Alert>
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid item sm={12}>
                    <SectionTitle>Offer type</SectionTitle>
                    <RadioGroup
                      row
                      name='applies_to'
                      value={formik.values.applies_to}
                      onChange={(e) => {
                        formik.setFieldValue('applies_to', e.target.value);
                        formik.setFieldValue('rules', []);
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            value={'ticket'}
                            name='ticket'
                            color='primary'
                          />
                        }
                        label='Ticket Type'
                      />
                      <FormControlLabel
                        control={
                          <Radio value={'addon'} name='addon' color='primary' />
                        }
                        label='Add-on'
                      />
                    </RadioGroup>
                    {
                      formik.values.applies_to === 'addon' && (
                        <FormControlLabel
                          control={<Checkbox color="primary"　checked={formik.values['per_ticket']} onChange={(event) => {
                            formik.setFieldValue('per_ticket', event.target.checked);
                          }} name="per_ticket" />}
                          label="Per Ticket"
                        />
                      )
                    }
                  </Grid>
                </Grid>

              

                <Grid item xs={12}>
                  <SectionTitle>Logic</SectionTitle>
                  {formik.values.applies_to === 'ticket' && (
                    <>
                      <Grid
                        container
                        justify='space-evenly'
                        className={classes.logicStyle}
                      >
                        <Grid item xs={1} style={{ marginTop: '16px' }}>
                          If
                        </Grid>
                        <Grid item xs={6}>
                          {selectEventTiers.length > 0 ? (
                            <TextField
                              select
                              type='text'
                              name='if_tickettype'
                              label='Ticket Type'
                              variant='outlined'
                              required
                              fullWidth
                              style={{ marginLeft: '12px' }}
                              error={Boolean(
                                formik.touched.rules &&
                                  ((Array.isArray(formik.errors.rules) &&
                                    formik.errors.rules.length > 0) ||
                                    (formik.errors.rules !== undefined &&
                                      formik.errors.rules !== ''))
                              )}
                              helperText={
                                formik.touched.rules &&
                                (Array.isArray(formik.errors.rules) &&
                                formik.errors.rules.length > 0
                                  ? formik.errors.rules[0].if
                                  : formik.errors.rules)
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                if (!formik.touched.rules) {
                                  formik.setFieldTouched('rules', true);
                                }
                                /*if (value === 'NONE') {
                                formik.setFieldValue('rules', []);
                              } else {*/
                                formik.setFieldValue('rules', [
                                  {
                                    operator: 'in_cart',
                                    if: value, //addon id
                                  },
                                ]);
                                // }
                              }}
                              disabled={formik.isSubmitting}
                              SelectProps={{
                                value:
                                  formik.values.rules.length > 0
                                    ? formik.values.rules[0].if
                                    : '',
                                MenuProps: {
                                  PaperProps: {
                                    getcontentanchorel: null,
                                  },
                                },
                              }}
                            >
                              {selectEventTiers
                                .sort(nameComparator)
                                .map((ttype, index) => (
                                  /**Storing name for OTS-Core */
                                  <MenuItem
                                    value={
                                      is_enterprise ? ttype.id : ttype.name
                                    }
                                    key={`${ttype.id}-${index}`}
                                  >
                                    {ttype.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            <TextField
                              select
                              type='text'
                              name='if_tickettype'
                              label='Ticket Type'
                              variant='outlined'
                              fullWidth
                              style={{ marginLeft: '12px' }}
                              SelectProps={{
                                value: 'NONE',
                                MenuProps: {
                                  PaperProps: {
                                    getcontentanchorel: null,
                                  },
                                },
                              }}
                            >
                              <MenuItem value='NONE'>-</MenuItem>
                            </TextField>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ marginTop: '16px', marginLeft: '16px' }}
                        >
                          is in cart, then
                        </Grid>
                      </Grid>

                      <Grid container className={classes.logicStyle}>
                        <Grid item xs={1} style={{ marginTop: '16px' }}>
                          Show{' '}
                        </Grid>
                        <Grid item xs={6}>
                          {selectEventTiers.length > 0 ? (
                            <TextField
                              select
                              type='text'
                              name='then_tickettype'
                              variant='outlined'
                              fullWidth
                              label='Ticket Type'
                              required
                              error={Boolean(
                                formik.touched.rules &&
                                  ((Array.isArray(formik.errors.rules) &&
                                    formik.errors.rules.length > 0) ||
                                    (formik.errors.rules !== undefined &&
                                      formik.errors.rules !== ''))
                              )}
                              helperText={
                                formik.touched.rules &&
                                (Array.isArray(formik.errors.rules) &&
                                formik.errors.rules.length > 0
                                  ? formik.errors.rules[0].then
                                  : formik.errors.rules)
                              }
                              style={{ marginLeft: '20px' }}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (!formik.touched.rules) {
                                  formik.setFieldTouched('rules', true);
                                }
                                /*if (value === 'NONE') {
                                let removeThen = {
                                  ...formik.values.rules[0],
                                };
                                if (removeThen.then) {
                                  delete removeThen.then
                                }
                                formik.setFieldValue('rules', [
                                  ...removeThen
                                ]);
                              } else {*/
                                formik.setFieldValue('rules', [
                                  {
                                    ...formik.values.rules[0],
                                    then: value,
                                  },
                                ]);
                                //}
                              }}
                              disabled={formik.isSubmitting}
                              SelectProps={{
                                value:
                                  formik.values.rules.length > 0 &&
                                  formik.values.rules[0].then
                                    ? formik.values.rules[0].then
                                    : '',
                                MenuProps: {
                                  PaperProps: {
                                    getcontentanchorel: null,
                                  },
                                },
                              }}
                            >
                              {selectEventTiers
                                .filter(
                                  (t) =>
                                    formik.values.rules.length > 0 &&
                                    formik.values.rules[0].if &&
                                    (is_enterprise
                                      ? t.id !== formik.values.rules[0].if
                                      : t.name !== formik.values.rules[0].if)
                                )
                                .sort(nameComparator)
                                .map((ttype, index) => (
                                  <MenuItem
                                    value={
                                      is_enterprise ? ttype.id : ttype.name
                                    }
                                    key={`${ttype.id}-${index}`}
                                  >
                                    {ttype.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                          ) : (
                            <TextField
                              select
                              type='text'
                              name='then_tickettype'
                              label='Ticket Type'
                              variant='outlined'
                              fullWidth
                              style={{ marginLeft: '20px' }}
                              SelectProps={{
                                value: 'NONE',
                                MenuProps: {
                                  PaperProps: {
                                    getcontentanchorel: null,
                                  },
                                },
                              }}
                            >
                              <MenuItem value='NONE'>-</MenuItem>
                            </TextField>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{ marginTop: '16px', marginLeft: '26px' }}
                        >
                          {' '}
                          as an offer
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {formik.values.applies_to === 'addon' && (
                    <>
                      <Grid
                        container
                        justify='space-evenly'
                        className={classes.logicStyle}
                      >
                        <span style={{ marginTop: '16px' }}>If </span>
                        {selectAddOnOptions.length > 0 ? (
                          <TextField
                            select
                            type='text'
                            name='if_addon'
                            variant='outlined'
                            required
                            label='Add-on'
                            style={{ marginLeft: '12px' }}
                            error={Boolean(
                              formik.touched.rules &&
                                (formik.errors.rules !== undefined ||
                                  (Array.isArray(formik.errors.rules) &&
                                    formik.errors.rules.length > 0))
                            )}
                            disabled={formik.isSubmitting}
                            className={classes.addOnDropdown}
                            onChange={(e) => {
                              const { value } = e.target;
                              if (!formik.touched.rules) {
                                formik.setFieldTouched('rules', true);
                              }
                              /*if (value === 'NONE') {
                              formik.setFieldValue('rules', []);
                            } else {*/
                              formik.setFieldValue('rules', [
                                {
                                  operator: 'not_in_cart',
                                  if: value, //addon id
                                },
                              ]);
                              //}
                            }}
                            SelectProps={{
                              value:
                                formik.values.rules.length > 0
                                  ? formik.values.rules[0].if
                                  : '',
                              MenuProps: {
                                PaperProps: {
                                  getcontentanchorel: null,
                                },
                              },
                            }}
                          >
                            {selectAddOnOptions
                              .sort(nameComparator)
                              .map((addon, index) => (
                                <MenuItem
                                  value={addon.id}
                                  key={`${addon.id}-${index}`}
                                >
                                  {addon.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        ) : (
                          <TextField
                            select
                            type='text'
                            name='if_addon'
                            label='Add-on'
                            style={{ marginLeft: '12px' }}
                            className={classes.addOnDropdown}
                            variant='outlined'
                            SelectProps={{
                              value: 'NONE',
                              MenuProps: {
                                PaperProps: {
                                  getcontentanchorel: null,
                                },
                              },
                            }}
                          >
                            <MenuItem value='NONE'>-</MenuItem>
                          </TextField>
                        )}
                        <span style={{ marginTop: '16px', marginLeft: '16px' }}>
                          {' '}
                          is not in cart, show as offer
                        </span>
                      </Grid>
                    </>
                  )}
                </Grid>

                {formik.values.applies_to === 'ticket' && (
                  <Grid item xs={12}>
                    <SectionTitle>Promotional photo</SectionTitle>
                    <Grid container alignItems='center' justify='center'>
                      <Grid item xs={8}>
                        {!formik.values.img_url && (
                          <div className={classes.emptyImageContainer}>
                            <span>No Image Set</span>
                          </div>
                        )}
                        {formik.touched.img_url && formik.errors.img_url && (
                          <div className={classes.emptyImageContainer}>
                            <span>{formik.errors.img_url}</span>
                          </div>
                        )}
                        {formik.values.img_url && (
                          <>
                            <Grid
                              container
                              alignItems='center'
                              justify='space-around'
                              spacing={3}
                              className={classes.imageContainer}
                            >
                              <img
                                src={formik.values.img_url}
                                className={classes.productImage}
                                alt='Offer'
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Alert severity="info" style={{ margin: "8px" }}>
                        Upload a high resolution image with a 1:1 aspect ratio.
                        Images wil be resized and optimized for the patron’s
                        screen.
                      </Alert>
                      <Grid item xs={8}>

                        <Button
                          color='primary'
                          variant='outlined'
                          aria-haspopup='true'
                          fullWidth
                          onClick={selectImage}
                          style={{ marginTop: '12px' }}
                          disabled={isUploading}
                        >
                          {'Upload Image'}{' '}
                          {isUploading && (
                            <CircularProgress
                              size={20}
                              color='secondary'
                              style={{ marginLeft: '5px' }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12} container justify='flex-end'>
                  <SaveButtonComponent
                    type='submit'
                    disabled={
                      formik.isSubmitting ||
                      !formik.values.name ||
                      Object.keys(formik.errors).length > 0
                    }
                    isLoading={formik.isSubmitting}
                    label='Save'
                    size='medium'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </Grid>
  );
}

export default OffersForm;
