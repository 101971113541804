import axios from "_utils/axios";

export function getAddonsService(orgId, seriesId) {
  let url = `organizations/${orgId}/add-ons`;
  if (seriesId) {
    url += `?series_id=${seriesId}`;
  }
  
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export function updateAddOnService(orgId, addOnId, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/add-ons/${addOnId}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateAddOnOrderService(orgId, addOnIds) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/add-ons-order`, addOnIds)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createAddOnService(orgId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/add-ons`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createAddOnVariantService(orgId, addOnId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${orgId}/add-ons/${addOnId}/variants`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteAddOnVariantService(orgId, addOnId, variantId, filterId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`organizations/${orgId}/add-ons/${addOnId}/variants/${variantId}`, { data: { filterId } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateAddOnVariantService(orgId, addOnId, variantId, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/${orgId}/add-ons/${addOnId}/variants/${variantId}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}