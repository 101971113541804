import * as types from "_constants";

const initialState = {
  subscriptionsActive: [],
  subscriptionsInactive: [],
  detail: {},
  eligibleProductions: [],
  dashboardData: {
    sales: 0,
    sales30: 0,
    countOrders: 0,
    countOrders30: 0,
    subscribers: [],
  }
};

function sortSubscriptions(subscriptions) {
  console.log('in sort subscriptions', subscriptions);
  return ({
    subscriptionsActive: subscriptions.filter(subscription => subscription.Product.is_active === true),
    subscriptionsInactive: subscriptions.filter(subscription => subscription.Product.is_active === false)
  })
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_SUBSCRIPTIONS_SUCCESS:
      const subscriptions = actions.payload.subscriptions;
      console.log('subscriptions', subscriptions);
      return {
        ...state,
        ...sortSubscriptions(subscriptions)
      };
    case types.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
      };
    case types.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        detail: actions.payload.detail,
      };
    case types.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
      };
    case types.UPDATE_SUBSCRIPTION_ACTIVE_STATUS_SUCCESS:
      const subs = [...state.subscriptionsActive, ...state.subscriptionsInactive];
      const index = subs.findIndex(s => s.id === actions.payload.subscriptionId);
      subs[index].Product.is_active = actions.payload.active;
      return {
        ...state,
        ...sortSubscriptions(subs)
      };
    case types.UPDATE_SUBSCRIPTION_AVAILABLE_FOR_PURCHASE_SUCCESS:
      return {
        ...state,
      };
    case types.DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
      };
    case types.GET_ELIGIBLE_PRODUCTIONS_FOR_NEW_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        eligibleProductions: actions.payload.productions,
      };
    case types.GET_SUBSCRIPTION_DASHBOARD_SUCCESS:
      console.log('dashboard success dashboarddata', actions.payload);
      return {
        ...state,
        dashboardData: actions.payload,
      }
    case types.DUPLICATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionsInactive: [...state.subscriptionsInactive, actions.payload],
      }
    default:
      return state;
  }
}
