import axios from "_utils/axios";

export function getNamesService(term) {
  return new Promise((resolve, reject) => {
    axios
      .get(`showSearch?phrase=${term}&format=json`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrganizationService(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`organizations/${orgId}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLicensorsService() {
  return new Promise((resolve, reject) => {
    axios
      .get(`licensors`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitFormDataService(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions`, formData)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function bulkCreateEventsService(production, events) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${production.id}/bulkCreateEvents`, {
        production, 
        events
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function bulkUpdateEventsService(productionId, bulk_events) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/bulkUpdateEvents`, {
        productionId,
        bulk_events
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.body);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log('error', error)
        reject(error);
      });
  });
}


export function bulkDeleteEventsService(productionId, bulk_events) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`productions/${productionId}/bulkDeleteEvents`, {
        data: {
          bulk_events
        }
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data.body);
        }
        reject(response.data);
      })
      .catch((error) => {
        console.log('error', error)
        reject(error);
      });
  });
}

export function getBasicInfoService(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post(`virtual-drafts`, formData)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getVirtualDraftInfoService(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`virtual-drafts/${id}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function submitVirtualDraftInfoService(formData, id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`virtual-drafts/${id}`, formData)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductionByIdService(id, includeInactive = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(`productions/${id}?include_inactive=${includeInactive}`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateProductionService(actions) {
  const params = actions.params ? { params: actions.params } : null;
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${actions.id}`, actions.data, params)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateSocialDistancingService(productionId, social_distancing_seat_distance_x) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/updateSocialDistancing`, { social_distancing_seat_distance_x })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function toggleSuppressMarketingCampaignsService(productionId, suppress_campaigns) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/toggleSuppressMarketingCampaigns`, {
        suppress_campaigns,
      })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeNameService(productionId ,newName) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/changeName`, { newName })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export function updateGeofencingService(productionId, geofencing_enabled, geofencing_rules) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/updateGeofencing`, {
        geofencing_enabled,
        geofencing_rules,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function toggleStandbyService(productionId, website_visible) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/toggleStandby`, {
        data: website_visible,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function toggleTicketsPurchasableService(eventId, tickets_purchasable) {
  return new Promise((resolve, reject) => {
    axios
      .put(`events/${eventId}`, {
        tickets_purchasable,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteDraftService(productionId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`productions/${productionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function setGeneralCapacityService(productionId, numTickets, eventType) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/setGeneralTickets`, {
        numTickets: parseInt(numTickets),
        eventType,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateTicketsPerOrderLimitService(production, limit_tickets_in_order, limit_tickets_in_order_num) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${production.id}/update-tickets-per-order-limit`, {
        limit_tickets_in_order,
        limit_tickets_in_order_num
      })
      .then((response) => {
        console.log('updateTicketsPerOrderLimitService response', response.data)
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendEmailService(productionId, { numberOfEmails, email, selectedEventIds}) {
  return new Promise((resolve, reject) => {
    
    const requestData = {
      events: selectedEventIds,
      message: email.message,
      subject: email.subject,
      numberOfEmails: numberOfEmails,
      replyTo: email.replyTo
    };    
    axios
      .post(`productions/${productionId}/sendPatronEmails`, requestData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPatronEmailCountService(productionId, selectedEventIds) {
  return new Promise((resolve, reject) => {
    axios
      .get(`productions/${productionId}/getPatronEmails?events=${selectedEventIds.join(',')}`)
      .then((response) => {
        resolve(response.data.obj.numberOfEmails);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addPerformanceDateService(productionId, date, event_type, tiers) {
  return new Promise((resolve, reject) => {

    var payload = {
      date: date,
      tiers: tiers,
      event_type: event_type,
    };    
    axios
      .put(`productions/${productionId}/addPerformanceDate`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePerformanceDateService(eventId, productionId, datetime, oldDatetime) {
  return new Promise((resolve, reject) => {

    var payload = {
      productionId,
      datetime,
      oldDatetime,
    };    

    axios
      .post(`events/${eventId}/changeDateTime`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editEventTierService(eventId, tier) {
  return new Promise((resolve, reject) => {
    axios
      .post(`events/${eventId}/editTier`, {
        ...tier,
      })
      .then((response) => {
        resolve(response.data.body);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addTicketTypeToEventsService(eventIds, tier) {
  return new Promise((resolve, reject) => {

    var payload = {
      eventIds: eventIds,
      tier: tier
    };    

    axios
      .post(`events/addTiers`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getReviewDataForPublishService(orgArtixId, productionId) {
  return new Promise((resolve, reject) => {

    axios
      .get(`organizations/${orgArtixId}/getReviewDataForPublish`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  }); 
}

export function getProductionSeatingChart(organization, seatingChartId) {
  return new Promise((resolve, reject) => {

    axios
      .get(`organizations/${organization}/seatingChart/${seatingChartId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  }); 

}

export function addDraftTierService(productionId, {admission_type, box_office_price, name, price, tier_type, box_office_only, locked_tier, seating_chart_section}) {
  return new Promise((resolve, reject) => {


    if (name === 'undefined' || name == null) {
      return reject(new Error('Please provide a tier name.'));
    } else if (!admission_type) {
      return reject(new Error('Please provide an admission type.'));
    } else if (!tier_type) {
      return reject(new Error('Please provide an tier type.'));
    }

    const tier = {
      active: true,
      admission_type,
      box_office_price,
      name,
      price,
      tier_type,
      box_office_only,
      locked_tier,
      seating_chart_section
    };

    axios
      .put(`productions/${productionId}/addTier`, { tier: tier })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteDraftTierService(productionId, tierId) {
  return new Promise((resolve, reject) => {
    if (!tierId) {
      throw new Error('Tier not provided');
    }
    const tier = {
      tierId: tierId
    };

    axios
      .put(`productions/${productionId}/deleteTier`, tier)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateDraftTierService(productionId, tiers) {
  return new Promise((resolve, reject) => {
    if (!tiers) {
      throw new Error('Tier not provided');
    }

    axios
      .put(`productions/${productionId}/updateTiers`, { tiers: tiers })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removePerformanceDateFromDraftService(productionId, indexId) {
  return new Promise((resolve, reject) => {
    if (indexId === undefined) {
      throw new Error('Date not provided');
    }
    axios
      .put(`productions/${productionId}/removePerformanceDate`, {
        index: indexId,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function updateSalesTaxService(productionId, sales_tax) {
  return new Promise((resolve, reject) => {
    if (sales_tax < 0 || sales_tax > 100) {
      return reject(new Error('Please set a sales tax between 0 and 100'));
    }
    axios
      .put(`productions/${productionId}/updateSalesTax`, {
        percent: sales_tax
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateFeesSalesTaxService(productionId, fees_sales_tax) {
  return new Promise((resolve, reject) => {
    if (fees_sales_tax < 0 || fees_sales_tax > 100) {
      return reject(new Error('Please set a fee sales tax between 0 and 100'));
    }
    axios
      .put(`productions/${productionId}/updateFeesSalesTax`, {
        percent: fees_sales_tax
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function updateAddonsSalesTaxService(productionId, addons_sales_tax) {
  return new Promise((resolve, reject) => {
    if (addons_sales_tax < 0 || addons_sales_tax > 100) {
      return reject(new Error('Please set a sales tax between 0 and 100'));
    }
    axios
      .put(`productions/${productionId}/updateAddonsSalesTax`, {
        percent: addons_sales_tax
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeEventTypeService(productionId, eventType) {
  return new Promise((resolve, reject) => {
    if (eventType === 'general' || eventType === 'reserved') {
      axios
      .put(`productions/${productionId}/changeEventType`, {
        eventType,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });            
    } else {
      return reject(new Error('Currently, event types can only be switched between general and reserved'));      
    }

  });
}

export function changeVenueService(productionId, venueData, venue, artix_series_id) {
  return new Promise((resolve, reject) => {
      axios
      .put(`productions/${productionId}/changeVenue`, {
        venue,
        venueData,
        artix_series_id
      })
      .then((response) => {
        console.log('response', response)
        resolve(response.data);
      })
      .catch((error) => {

        console.log('error', error)
        reject(error);
      });            
  });
}


export function copyProductionService(productionId, name) {
  return new Promise((resolve, reject) => {
    axios
      .post(`productions/${productionId}/copy-production`, { productionName: name })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUniqueProductionShortCodeService(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`updateUniqueProductionShortCode`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        return reject(error)
      });
  });
}

export function updateSpecialMessageService(eventId, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`events/${eventId}/update-special-message`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function updateOrderConfirmationMessageService(productionId, order_confirmation_message) {
  return new Promise((resolve, reject) => {
    axios
      .put(`productions/${productionId}/updateOrderConfirmationMessage`, { order_confirmation_message })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getOTSContentTemplatesService() {
  return new Promise((resolve, reject) => {
    axios
      .get(`templates`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOTSContentTemplate(templateId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`templates/${templateId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function savePerformanceStatusChangeService(events, productionId, status) {
  return new Promise((resolve, reject) => {
    if (!events.length) {
      return reject(new Error('Must have at least 1 event'));
    }
    var payload = {
      events,
      status,
    };    
    axios
      .post(`/productions/${productionId}/changeEventsStatus`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addLivestreamService(productionId, { livestream_type, virtual_capacity, livestreamTiers }) {
  return new Promise((resolve, reject) => {
    
    const payload = { livestream_type, virtual_capacity, tiers: livestreamTiers };    
    axios
    .post(`productions/${productionId}/addLivestream`, payload)
    .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSeriesAttributesService(data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`organizations/productions/${data.productionId}/updateSeriesAttributes`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

export async function getSeriesAttributesService(productionId) {
  try {
    const res = await axios.get(`organizations/productions/${productionId}/getSeriesAttributes`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export async function resyncSalesforceOpportunity(productionId) {
  try {
    const res = await axios.get(`organizations/productions/${productionId}/resyncSalesforceOpportunity`);
    return res.data;
  } catch (error) {
    throw error;
  }
}