import * as types from "_constants";
import _ from 'lodash';

// Example Price: 
// 
// {
//   "id": "price_1J3FFWDY3BzcP9iqlbHJ26oa",
//   "active": true,
//   "created": 1623913370,
//   "currency": "usd",
//   "recurring": {
//     "aggregate_usage": null,
//     "interval": "month",
//     "interval_count": 1,
//     "trial_period_days": null,
//     "usage_type": "licensed"
//   },
//   "type": "recurring",
//   "metadata": {
//     "artix_id": "1",
//     "lastModified": "2021-06-17T07:02:48.376Z"
//   },
//   "unit_amount": 1000,
//   "base_amount": 933
// },


const initialState = {
  one_time: [],
  recurring: [],
  transactions: []
};

export default function reducer(state = initialState, actions) {

  switch (actions.type) {
    case types.GET_DONATION_SUCCESS:
      return {
        ...state,
        ...actions.payload.donation_prices
      };
    case types.GET_DONATION_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: actions.payload.transactions
      }
    case types.CREATE_DONATION_SUCCESS:
      return {
        ...state,
        ...actions.payload.donation_prices
      };
    default:
      return state;
  }
}
