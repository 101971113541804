import React from "react";
import {
  IconButton,
  makeStyles,
  InputAdornment,
  Grid,
} from "@material-ui/core";

import {
  TextFieldSpacing as TextField
} from "_components/common"

import {
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  disabled: {
    paddingTop: `${theme.spacing(0.5)}`,
    paddingBottom: `${theme.spacing(0.5)}`,
    color: "black",
    borderBottom: 0,
    "&:before": {
      borderBottom: 0
    }
  },
}));

export const EditableTextField = (props) => {
  const {
    value,
    onSave,
    editing,
    placeholder,
    helperText,
    error,
    multiline,
    rows
  } = props;
  const [editMode, setEditMode] = React.useState(editing);
  const [updatedValue, setUpdatedValue] = React.useState(value);
  const classes = useStyles();
  const [mouseOver, setMouseOver] = React.useState(false);

  const handleChange = (event) => {
    setUpdatedValue(event.target.value);
  }
  const handleMouseEnter = (event) => {
    setMouseOver(true); 
  }
  const handleMouseLeave = (event) => {
    setMouseOver(false) 
  }

  const startEdit = (event) => {
    setEditMode(true);
  }

  const saveEdit = (event) => {
    onSave(updatedValue);
    setEditMode(false);
  }

  return (
    <Grid item xs={11} justify="flex-start" container>
      <TextField
        value={updatedValue}
        fullWidth={true}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={!editMode}
        className={editMode ? '' : null}
        helperText={helperText}
        error={error}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        multiline={multiline}
        rows={rows}
        InputProps={{
          classes: {
            disabled: classes.disabled
          },
          endAdornment: mouseOver ? (
            <InputAdornment px={5} position="end">
              {editMode ? (
                <IconButton onClick={saveEdit}>
                  <SaveIcon />
                </IconButton>
              ) : (
                <IconButton onClick={startEdit}>
                  <EditIcon />
                </IconButton>
              )}
            </InputAdornment>
          ) : (
            ""
          )
        }}
      />
    </Grid>)
}