import React from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function CircularProgressLoader({
  color = "secondary",
}) {
  return (
    <Root>
      <CircularProgress
        size={24}
        color={color}
        style={{ marginLeft: "5px" }}
      />
    </Root>
  );
}

export default CircularProgressLoader;
