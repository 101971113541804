import axios from "_utils/axios";

export function listTransactions() {
  return new Promise((resolve, reject) => {
    axios
      .get("transactions")
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function generateDownloadUrl(transaction) {
  return `${axios.defaults.baseURL}/download-attachment?fileName=${transaction.attachment}&redirect=true${transaction.s3bucket ? '&bucket=' + transaction.s3bucket : ''}`;
}

export function generateViewUrl(transaction) {
  return `${axios.defaults.baseURL}/download-attachment?fileName=${transaction.attachment}&bucket=${transaction.s3bucket}`
}

export function downloadPdfForRendering (url) {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function approveTransactionService(transaction) {
  return new Promise((resolve, reject) => {
    axios
      .post(`organizations/${transaction.OrganizationId}/transactions/${transaction.id}/approve`)
      .then((response) => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export function markInvoicePaidService(transaction) {
  return new Promise((resolve, reject) => {
    axios
      .put(`transactions/${transaction.id}/markInvoicePaid`, {})
      .then((res) => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  });
};



export function voidPaymentService(transaction) {
  return new Promise((resolve, reject) => {
    if (!transaction.internal_comments) {
      return reject(new Error('Please provide a reason'));
    }
    axios.post(`transactions/${transaction.id}/voidPayment`, {
      transaction,
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  });
}

export function undoPendingPaymentService(transaction) {
  return new Promise((resolve, reject) => {
    if (!transaction.internal_comments) {
      reject(new Error('Please provide a reason'));
    } 
    axios.post(`transactions/${transaction.id}/undoPendingPayment`, {
      transaction,
    }).then((res) => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  });
}


export function getContactEmailService(OrganizationId) {
  return new Promise((resolve, reject) => {
    axios.get(`organizations/${OrganizationId}/salesforce/getContactEmail`).then((res) => resolve(res)).catch((err) => reject(err));
  });
}

export function recordCheckNumberService(transaction, sendEmail, contactEmailAddress, emailMessage, multipleRecipients) {
  return new Promise((resolve, reject) => {
    const paymentDate = transaction.payment_date ? new Date(transaction.payment_date) : new Date();
    const pendingPayment = {
      payment_id: transaction.payment_id,
      payment_date: paymentDate,
      description: transaction.description,
      payment_type: transaction.payment_type,
      email: {
        to: '',
        message: emailMessage,
      }
    };
    
    if (contactEmailAddress && sendEmail) {
      pendingPayment.email.to = contactEmailAddress;
    }
    console.log('pending Payment: ', pendingPayment);
    
    pendingPayment.email.message = pendingPayment.email.message.replace('[CHECKNUMBER]', pendingPayment.payment_id);

    if (multipleRecipients) {
      var emailReg = /([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)/g;
      var emails = multipleRecipients.match(emailReg);

      if (emails.length) {
        pendingPayment.email.bcc = emails;
      }
    }
    axios.post(`transactions/${transaction.id}/updatePayment`, {
      transaction: pendingPayment
    }).then((res) => resolve(res)).catch(err => { reject(err)} );
  });
}



export function saveInternalCommentService(transaction, internal_comments) {
  console.log('saveInternalComment: ', transaction.internal_comments);
  return new Promise((resolve, reject) => {
    axios.put(`transactions/${transaction.id}/internalComments`, {
      internal_comments,
      OrganizationId: transaction.OrganizationId,
    }).then((res) => resolve(res)).catch(err => reject(err));
  });
};

export function removeTransaction(transaction) {
  return new Promise((resolve, reject) => {
    axios.delete(`transactions/${transaction.id}`).then(res => {
      return resolve(res);
    }).catch(err => {
      return reject(err);
    })
  });
};

export function voidInvoiceService(transaction, organizationId) {
  return new Promise((resolve, reject) => {
    if (!transaction.internal_comments) {
      return reject(new Error('Please provide a reason'));
    }
    axios.post(`transactions/${transaction.id}/voidInvoice`, {
      transaction,
      organizationId,
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  });
};

export function resendInvoiceService(transaction, email, organizationId) {
  return new Promise((resolve, reject) => {
    if (!email) {
      return reject(new Error('Please provide an email address'));
    }
    axios.post(`transactions/${transaction.id}/resendInvoice`, {
      transaction,
      email,
      organizationId,
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  });
};

export function markInvoicePaidServiceWithStripe(transaction, organizationId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`transactions/${transaction.id}/markInvoicePaidStripe`, {
        organizationId
      })
      .then((res) => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      })
  });
};