import * as types from "_constants";
import { getLoggedInStatus } from "_redux/actions/authActions";

import {
  getOrganizationService,
  createVenueService,
  updateVenueService,
  deleteVenueService,
  setSeatFeePlanService,
  updateOrganizationService,
  getDashboardDataService,
  updateOrganizationWebsiteService,
  getOrganizationWebsiteService,
  getRecentOrdersService,
  getOrganizationVenuesService,
  updateSocialUrlService,
  updateTaxDeductibleFieldService,
  contactEmailService,
  downloadQrCodeService,
  getStripeReadersListService,
  deleteReaderService,
  getBookingFeesService,
  createBookingFeeService,
  deleteBookingFeeService,
  updateBookingFeeService,
  getOrgDetailsService,
  sendTestEmailService,
  updateOrgAttributesService,
  getOrganizationCssTemplatesService,
  saveOrganizationCssTemplateService,
  updateOrganizationCssTemplateService,
  deleteOrganizationCssTemplateService,
  getEnterpriseDashboardDataService,
  getBuyersListFromEventService
} from "_services/organizationService";


export function getOrgData(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_DATA_REQUEST });

    return getOrganizationService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_ORG_DATA_SUCCESS,
          organization: response,
        });
      })
  };
}

export function getOrgVenues() {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_VENUES_REQUEST });
    return getOrganizationVenuesService()
      .then((response) => {
        dispatch({
          type: types.GET_ORG_VENUES_SUCCESS,
          venues: response,
        });
      })
  };
}



export function updateOrganization(actions) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORGANIZATION_REQUEST });

    return updateOrganizationService(actions)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORG_DATA_VALUE_SUCCESS,
          payload: actions.data,
        });
      })
  };
}

export function createVenue(data) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_VENUE_REQUEST });

    return createVenueService(data)
      .then((response) => {
        dispatch({
          type: types.CREATE_VENUE_SUCCESS,
          venue: response.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Venue Created',
          }
        })  
        return response;
      })
  };
}

export function updateVenue(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_VENUE_REQUEST });

    return updateVenueService(data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_VENUE_SUCCESS,
          venue: response.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Venue Updated',
          }
        })          
        return response;
      })
  };
}

export function deleteVenue(data) {
  return async (dispatch) => {
    return deleteVenueService(data)
      .then((response) => {
        dispatch({
          type: types.DELETE_VENUE_SUCCESS,
          venue: response.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Venue Deleted',
          }
        })          
        return response;
      })
  };
}

export function editVenueField(data) {
  return async (dispatch) => {
    dispatch({
      type: types.EDIT_VENUE_FIELD,
      data,
    });
  };
}

export function setSeatFeePlan(data) {
  return async (dispatch) => {
    dispatch({ type: types.SET_SEAT_FEE_PLAN_REQUEST });

    return setSeatFeePlanService(data)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Fee Plan Updated',
          }
        })
        dispatch(getLoggedInStatus()); //refresh the plan
        dispatch({
          type: types.SET_SEAT_FEE_PLAN_SUCCESS,
          data: data.per_ticket_fee,
        });
        return response;
      }).catch((error) => {
        const message = error.response?.data?.error || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  };
}


export function getDashboardData(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_DASHBOARD_REQUEST });

    return getDashboardDataService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_ORG_DASHBOARD_SUCCESS,
          payload: response,
        });
      })
  };
}

export function getEnterpriseDashboardData(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_ENTERPRISE_DASHBOARD_REQUEST });

    return getEnterpriseDashboardDataService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_ORG_ENTERPRISE_DASHBOARD_SUCCESS,
          payload: response,
        });
      })
  };
}

export function getBuyersListFromEvent(eventId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_BUYERS_LIST_FROM_EVENT_REQUEST });

    return getBuyersListFromEventService(eventId)
      .then((response) => {
        dispatch({
          type: types.GET_BUYERS_LIST_FROM_EVENT_SUCCESS,
          payload: response,
        });
        return response;
      })
  }
}

export function updateOrganizationWebsite(actions) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORGSITE_REQUEST });

    return updateOrganizationWebsiteService(actions)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORGSITE_SUCCESS,
          payload: {
            website: response,
          }
        });
      })
  };
}

export function updateOrganizationWebsiteStyle(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORGSITE_STYLE_SUCCESS, data });
  };
}

export function getOrganizationWebsite() {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORGSITE_REQUEST });

    return getOrganizationWebsiteService()
      .then((response) => {
        dispatch({
          type: types.GET_ORGSITE_SUCCESS,
          payload: {
            website: response,
          }
        });
      })
  };
}

export function getRecentOrders(orgId, page, rowsPerPage) {
  return async (dispatch) => {
    dispatch({ type: types.GET_RECENT_ORDERS_REQUEST });
    return getRecentOrdersService(orgId, page, rowsPerPage)
      .then((response) => {
        dispatch({
          type: types.GET_RECENT_ORDERS_SUCCESS,
          payload: {
            rows: response.orders,
            totalOrderCount: response.totalOrderCount,
            currentPage: response.currentPage,
            totalPages: response.totalPages
          }
        });
      })
  };
}

export function getCreditCardReaders(orgId) {
  return async (dispatch) => {
    return getStripeReadersListService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_READERS_SUCCESS,
          payload: {
            readers: response.data,
          }
        });
      })
  };
}

export function updateSocialUrl(orgId, platform, value) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_SOCIAL_URL_REQUEST });

    return updateSocialUrlService(orgId, platform, value)
      .then((response) => {

        var payload = {}
        payload[platform] = value
          dispatch({
            type: types.UPDATE_SOCIAL_URL_SUCCESS,
            payload: payload
          });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Social link updated!',
          }
        })          
        return response;
      })
  };
}

export function updateTaxDeductibleField(orgId, taxDeductible) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_TAX_DEDUCTIBLE_FIELD_REQUEST });

    return updateTaxDeductibleFieldService(orgId, taxDeductible)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Tax preference updated!',
          }
        })
        dispatch({
          type: types.UPDATE_TAX_DEDUCTIBLE_FIELD_SUCCESS,
          payload: {
            data: taxDeductible,
          }
        });
        return response;
      }).catch((error) => {
        const message = error.response?.data?.error || error.message || "Something went wrong";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  };
}

export function getContactEmail(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_SF_CONTACT_EMAIL_REQUEST });
    return contactEmailService(orgId)
      .then((response) => {
        console.log('getContactEmail response', response)
        dispatch({
          type: types.GET_ORG_SF_CONTACT_EMAIL_SUCCESS,
          payload: {
            email: response,
          }
        });
      })
  };
}

export function donwloadQrCode(orgId, qrUrl, options, userTicketFee) {
  return async (dispatch) => {
    if(userTicketFee < 1.35) {
      return dispatch({ 
        type: types.SHOW_NOTIFICATION, 
        payload: {
          message: "QR Codes are only available on the Empower/Platinum plan. To upgrade and access this feature contact your Account Manager.",
        }
      })
    }
    
    return downloadQrCodeService(orgId, qrUrl, options).then((response) => {
      const linkSource = response.data;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = 'qrcode.png';
      downloadLink.click();
    }).catch((error) => {
      const message = error.response?.data?.error || error.message || "Something went wrong";
      dispatch({ type: types.SHOW_NOTIFICATION, payload: {
        message,
      }})
    })
  };
}

export function deleteReader(readerId, orgId) {
  return async (dispatch) => {
    return deleteReaderService(readerId, orgId)
      .then((response) => {
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Your reader has been deleted!',
          }
        })
      })
  };
}

export function getBookingFees(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_BOOKING_FEES_REQUEST });
    return getBookingFeesService(orgId)
      .then((response) => {
        dispatch({
          type: types.GET_BOOKING_FEES_SUCCESS,
          payload: {
            booking_fees: response.data,
          }
        });
      })
  };
}

export function createBookingFee(orgId, bookingFee) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_BOOKING_FEE_REQUEST });
    return createBookingFeeService(orgId, bookingFee)
      .then((response) => {
        dispatch({
          type: types.CREATE_BOOKING_FEE_SUCCESS,
          payload: {
            booking_fees: response.data,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            title: 'Success!',
            message: 'Fee has been saved',
            severity: 'success'
          }
        })  
      })
      .catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message,
          }
        })
      });
  };
}

export function deleteBookingFee(orgId, bookingFee) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_BOOKING_FEE_REQUEST });
    return deleteBookingFeeService(orgId, bookingFee)
      .then((response) => {
        dispatch({
          type: types.DELETE_BOOKING_FEE_SUCCESS,
          payload: {
            booking_fees: response.data,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Fee has been deleted',
          }
        })  
      })
      .catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message,
          }
        })
      });
  };
}

export function updateBookingFee(orgId, bookingFee) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_BOOKING_FEE_REQUEST });
    return updateBookingFeeService(orgId, bookingFee)
      .then((response) => {
        dispatch({
          type: types.UPDATE_BOOKING_FEE_SUCCESS,
          payload: {
            booking_fees: response.data,
          }
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            title: 'Success!',
            message: 'Fee has been saved',
            severity: 'success'
          }
        })  
      })
      .catch(error => {
        const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message,
          }
        })
      });
  };
}
export function getOrgDetails(id) {
  /** Get all org details from artix */
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_DETAILS_REQUEST });
    return getOrgDetailsService(id)
      .then((response) => {
        dispatch({
          type: types.GET_ORG_DETAILS_SUCCESS,
          payload: response,
        })
      })
  }
}

export function sendTestEmail(productionArtixId, email, orgId) {
  return async (dispatch) => {
    dispatch({type: types.SEND_TEST_EMAIL});
    return sendTestEmailService(productionArtixId, email, orgId)
      .then((response) => {
        dispatch({
          type: types.SEND_TEST_EMAIL_SUCCESS,
          payload: 'Test email has been sent!',
        })
        dispatch({ type: types.SHOW_NOTIFICATION, payload: {
          message: 'Test email has been sent!',
        }})
      })
  }
}

export function updateOrgAttributes(data) {
  return async (dispatch) => {
    dispatch({ type: types.SAVE_ORG_ATTRIBUTES});
    return updateOrgAttributesService(data)
      .then((response) => {
        dispatch({
          type: types.SAVE_ORG_ATTRIBUTES_SUCCESS,
          payload: response,
        })
        dispatch({ type: types.SHOW_NOTIFICATION, payload: {
          message: 'Header attributes successfully updated',
        }})
      })
  }
}
export function saveOrganizationCssTemplate(data) {
  return async (dispatch) => {
    dispatch({ type: types.SAVE_ORG_CSS_TEMPLATE});
    return saveOrganizationCssTemplateService(data)
      .then((response) => {      
        dispatch({
          type: types.SAVE_ORG_CSS_TEMPLATE_SUCCESS,
          payload: response,
        });

        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'CSS template has been uploaded successfully!',
          }
        })
      })
  }
}

export function getOrganizationCssTemplates(data) {
  return async (dispatch) => {
    dispatch({ type: types.GET_ORG_CSS_TEMPLATE});
    return getOrganizationCssTemplatesService(data)
      .then((response) => {
        dispatch({
          type: types.GET_ORG_CSS_TEMPLATE_SUCCESS,
          payload: response,
        })
      })
  }
}


export function updateOrganizationCssTemplate(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORG_CSS_TEMPLATE});
    return updateOrganizationCssTemplateService(data) 
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORG_CSS_TEMPLATE_SUCCESS,
          payload: response,
        });

        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'CSS Template Updated!',
          }
        })
      })
  }
}

export function deleteOrganizationCssTemplate(data) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_ORG_CSS_TEMPLATE});
    return deleteOrganizationCssTemplateService(data) 
      .then((response) => {
        dispatch({
          type: types.UPDATE_ORG_CSS_TEMPLATE_SUCCESS,
          payload: response,
        });

        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'CSS Template Deleted',
          }
        })
      })
  }
}
