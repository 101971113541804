import React from "react";
import styled from "styled-components/macro";
import { green, yellow, red, grey, orange } from "@material-ui/core/colors";

import {
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Paper as MuiPaper,
  Select as MuiSelect,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Chip as MuiChip,
  Tooltip,
  Avatar as MuiAvatar,
} from "@material-ui/core";

import { Pagination as MuiPagination } from "@material-ui/lab";

import { Alert as MuiAlert, AlertTitle as MuiAlertTitle } from "@material-ui/lab";

import { spacing } from "@material-ui/system";

const CardContainer = styled(MuiCard)(spacing);

export const Alert = styled(MuiAlert)(spacing);

export const AlertTitle = styled(MuiAlertTitle)(spacing);

export const Button = styled(MuiButton)(spacing);

export const Pagination = styled(MuiPagination)(spacing);

export const Divider = styled(MuiDivider)(spacing);

export const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Spacer = styled.div(spacing);

export const Select = styled(MuiSelect)`
  min-width: 200px;
`;

const variants = {
  success: {
    backgroundColor: `${green[500]}`,
    color: (props) => props.theme.palette.common.white,
  },
  info: {
    backgroundColor: `${grey[300]}`,
    color: (props) => props.theme.palette.common.black,
  },
  danger: {
    backgroundColor: `${red[700]}`,
    color: (props) => props.theme.palette.common.white,
  },
  orange: {
    backgroundColor: `${orange[500]}`,
    color: (props) => props.theme.palette.common.white,
  },
  warning: {
    color: (props) => props.theme.palette.common.black,
    backgroundColor: `${yellow[500]}`,
  }
}

const StyledChip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.textcolor};
`;

export const Chip = ({ text, variant }) => {
  const variantStyle = variants[variant];
  return (
    <StyledChip label={text} bgcolor={variantStyle?.backgroundColor || 'black'} textcolor={variantStyle?.color || 'white'} />
  )
}


export const Paper = styled(MuiPaper)(spacing);

export const FormControl = styled(MuiFormControl)`
  min-width: ${(props) => props.width || 200}px;
`;
export const FormControlSpacing = styled(MuiFormControl)(spacing);
export const TextField = styled(MuiTextField)`
  min-width: ${(props) => props.width || 400}px;
`;

export const TextFieldNarrow = styled(MuiTextField)`
  min-width: 200px
`;

const Avatar = styled(MuiAvatar)(spacing);

export const PrimaryColorAvatar = styled(Avatar)`
  background-color: ${(props) => props.theme.palette.primary.main};
`;


export const TextFieldSpacing = styled(MuiTextField)(spacing);
export const CapitalizedTextField = styled(TextFieldSpacing)`
  label::first-letter {
    text-transform: capitalize;
  }
`

export const CardSpacing = styled(MuiCard)(spacing);

export const Card = ({ children }) => {
  return (
    <CardContainer>
      {children}
    </CardContainer>
  )
}

export const TooltipDisabledButton = ({ isDisabled, tooltipText, buttonText, ...props }) => {
  return (
    <>
      {isDisabled ? (
        <Tooltip title={tooltipText}>
          <span>
            <Button {...props} disabled={true}>{buttonText}</Button>
          </span>
        </Tooltip>
      ) : (
        <Button {...props}>{buttonText}</Button>
      )}
    </>
  )
}