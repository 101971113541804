import React from "react";
import styled from "styled-components/macro";

import { HelpOutline } from "@material-ui/icons";
import { darken, lighten } from "polished";
import { Button } from "@material-ui/core";

const StyledHelpButton = styled(Button)`
  color: ${(props) => props.theme.sidebar.color};
  font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  border-radius: 0;
  width: 100%;
  justify-content: flex-start;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
    margin-left: 4px;
    margin-right: 8px;
  }

  ${(props) => props.showhover && `
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  `}
  `;

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function HelpCenterButton({showhover}) {
  return (
    <Root>
         <StyledHelpButton showhover={showhover} startIcon={<HelpOutline/>} variant = "text" target="_blank" noreferrer="true" href="https://intercom.help/on-the-stage/en"> Help Center</StyledHelpButton>
    </Root>
  );
}

export default HelpCenterButton;

// background: ${props => (props.showhover ? "rgba(255, 255, 255, 0.3)" : "none")};