import * as types from "_constants";

const initialState = {
  selectedProductionForAddonsFilter: null,
  addOns: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_PRODUCTION_FOR_ADD_ONS:
      return {
        ...state,
        selectedProductionForAddonsFilter: actions.payload,
      }
    case types.GET_ADDONS_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data
      }
    case types.UPDATE_ADDON_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data
      }
    case types.SAVE_ADDON_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data
      }
    case types.UPDATE_ADDON_ORDER:
      return {
        ...state,
        UPDATE_ADDON_ORDER_LOADING: true
      }
    case types.UPDATE_ADDON_ORDER_SUCCESS:
      return {
        ...state,
        UPDATE_ADDON_ORDER_LOADING: false,
        addOns: actions.payload.data
      }
    case types.UPDATE_ADDON_ORDER_FAILURE:
      return {
        ...state,
        UPDATE_ADDON_ORDER_LOADING: false
      }
    case types.DELETE_PRODUCTION_FOR_ADD_ONS:
      return {
        ...state,
        selectedProductionForAddonsFilter: null,
      }
    case types.SAVE_ADDON_VARIANT_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data.addons
      }
    case types.DELETE_ADDON_VARIANT_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data.addons
      }
    case types.UPDATE_ADDON_VARIANT_SUCCESS:
      return {
        ...state,
        addOns: actions.payload.data.addons
      }
    default:
      return state;
  }
}

