import * as types from "_constants";

const initialState = {
    rows: [],
    row: []
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        rows: []
      };
    case types.PROMOCODES_GET_SUCCESS:
      return {
        ...state, 
        rows: actions.payload.rows,
      };  
    case types.PROMOCODE_GET_SUCCESS:
      return {
        ...state, 
        row: actions.payload.row,
      };  
    case types.PROMOCODE_DELETE_SUCCESS:
      return {
        ...state,
        rows: [...state.rows.filter(row => row.id !== actions.payload.id)],
      };
    case types.PROMOCODE_CREATE_SUCCESS:
      return {
        ...state,
        rows: state.arr.concat(actions.coupon)
      };
    default:
      return state;
  }
}
