import * as types from "_constants";

const initialState = {
  _id: null,
  pdf_url: "",
  qrcode_url: "",
  pages: [],
  currentPageIndex: 0,
  isEdited: false,
  font_family: ""
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.UPDATE_PLAYBILL_REQUEST:
      return {
        ...state,
        isEdited: true,
      }
    case types.GET_PLAYBILL_DATA_SUCCESS:
      return {
        ...state,
        pdf_url: actions.payload.playbill.pdf_url,
        qrcode_url: actions.payload.playbill.qrcode_url,
        pages: [...actions.payload.playbill.pages],
        font_family: actions.payload.playbill.font_family,
      }
    case types.UPDATE_PLAYBILL_SUCCESS:
      return {
        ...state,
        pages: actions.payload.playbill.pages,
        pdf_url: actions.payload.playbill.pdf_url,
        qrcode_url: actions.payload.playbill.qrcode_url,
        isEdited: true,
      };

    case types.UPDATE_PLAYBILL_VALUE:
      console.log('in update_playbill_value reducer', actions);
      const updatedPages = state.pages.map((page, index) => {
        if (page.slug === actions.payload.data.page.slug) {
          page.data[actions.payload.data.key] = actions.payload.data.value
        }
        console.log('page', page);
        return page;
      })

      console.log('updatedPages', updatedPages);
      return {
        ...state,
        isEdited: true,
        pages: [...updatedPages],
      };
    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPageIndex: actions.payload.currentPageIndex
      }
    default:
      return state;
  }
}
