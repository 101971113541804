import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import LogRocket from 'logrocket';

import { rootReducer } from "_redux/reducers/index";

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
);

export default store;
