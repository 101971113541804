import axios from "_utils/axios";

export function listNotifications(filter = null) {
    const filterString = filter ? `?filter=${filter}` : '';
    return new Promise((resolve, reject) => {
      axios
        .get(`/notifications${filterString}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function dismissNotification(notificationId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/notifications/${notificationId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }