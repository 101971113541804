export function getProductionType(eventType, viewing_at_venue, is_livestream) {
    const eventTypes = {
      general: 'General',
      reserved: 'Reserved',
      virtual: 'Zoom',
      vod: 'Video On Demand',
    };
    let label = eventTypes[eventType] || 'Unknown';
    if (viewing_at_venue && is_livestream) {
        label += ' & Livestream';
    } else if (!viewing_at_venue && is_livestream) {
        label = 'Livestream';
    }
    return (label);
  }

  export function getEventType(eventType) {
    const eventTypes = {
      general: 'General',
      reserved: 'Reserved',
      virtual: 'Zoom',
      vod: 'Video On Demand',
      livestream: 'Livestream',
    };
    const label = eventTypes[eventType] || 'Unknown';
    return (label);
  }


  export function renderFeeStyle(fee_style) {
    switch(fee_style) {
      case 'exclude':
        return 'Passed on to customer';
      case 'include':
        return 'Included in ticket price';
      default: 
        return 'Unknown';
    }
  }
  

  export function isDraftPublishable(production) {
    const errors = [];
    if (!production.fee_style) {
      errors.push('Please set a fee style');
    }
    if (production.dates?.length === 0 && production.event_type !== 'vod') {
      errors.push('Please set dates');
    }
    if (production.tiers?.length === 0) {
      errors.push('Please set ticket prices');
    }
    if (production.event_type === 'general' && !production.capacity) {
      errors.push('Please set ticket capacity');
    }
    if (production.event_type === 'reserved' && !production.seating_chart_id) {
      errors.push('Please configure a seating chart');
    }
    if ((production.is_livestream || production.is_virtual) && production.event_type !== 'vod' && !production.livestream_capacity) {
      errors.push('Please set livestream capacity');
    }

    if (errors.length) {
      console.log('returning publishable false')
      return {
        publishable: false,
        errors,
      }
    } else {
      return {
        publishable: true,
        errors: [],
      }
    }
  }
  
  export function mapItemType(itemType) {
    switch (itemType) {
      case 'printful':
        return 'Merchandise';
      case 'donation':
        return 'Donation';
      case 'donation-ticket':
        return 'Ticket Donation';
      case 'campaign':
        return 'Campaign';
      case 'subscription':
        return 'Subscription';
      case 'booking_fee':
        return 'Booking Fee';
      default:
        return itemType;
    }
  }

  export function getVenue(venue, orgVenues) {
    let label = {
      name: 'Unknown'
    }

   orgVenues.find(orgVenue => {
      if(orgVenue._id === venue) {
        label.name = orgVenue.name;
      } 
    })

    return label.name;
  }