import React, { useEffect, useRef } from "react";
import SingleTicket from "./SingleTicket";
import { BlobProvider, Document } from "@react-pdf/renderer";
import { useState } from "react";
import { Button } from "_components/common";
import CircularProgressLoader from "_components/common/CircularProgressLoader";
import { te } from "date-fns/locale";

const Tickets = ({ order, tickets, setPdfUrls, pdfUrls, maxProdNameLength }) => {
  const tempPdfUrls = useRef({});
  const [activeTicketId, setActiveTicketId] = useState(null);
  useEffect(() => {
    setPdfUrls(tempPdfUrls.current);
  }, [order?.tickets, tickets]);
  const handleViewClick = (ticketId) => {
    setActiveTicketId(ticketId); // Set the active ticket ID to the one clicked
  };
  return (
    <div
      style={{
        height: "6.375in",
        width: "6in",
        overflow: "hidden",
        overflowY: "auto",
      }}
    >
      {order
        ? order?.tickets?.map?.((ticket, index) => (
            <BlobProvider
              key={ticket.id}
              document={
                <Document
                  style={{
                    display: "none",
                  }}
                >
                    <SingleTicket order={order} ticket={ticket} maxProdNameLength={maxProdNameLength} />
                  </Document>
              }
            >
              {({ url, loading, error }) => {
                if (loading) {
                  return (
                    <>
                      <div
                        style={{
                          height: "2.125in",
                          width: "5.7in",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          backgroundColor: "gray",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgressLoader />
                        </p>
                      </div>
                    </>
                  );
                }
                if (error) {
                  console.error("Error generating PDF: ", error);
                  return <div>Error!</div>;
                }

                if (url) {
                  // Store the URL temporarily
                  tempPdfUrls.current[ticket.id] = url;
                  if (ticket.id === activeTicketId) {
                    return (
                      <iframe
                        src={`${url}#toolbar=0`}
                        style={{ height: "2.125in", width: "5.7in" }}
                        title="PDF"
                      />
                    );
                  } else {
                    return (
                      <button
                        onClick={() => handleViewClick(ticket.id)}
                        className="p-2 px-2 mb-6 text-white bg-green-600 rounded-lg appearance-none"
                      >
                        View Ticket
                      </button>
                    );
                  }
                }

                return <div></div>;
                // Handle loading and error states
                // Store the URL for printing
              }}
            </BlobProvider>
          ))
        : tickets?.map?.((ticket, index) => (
            <BlobProvider
              key={ticket.id}
              document={
                <Document
                  style={{
                    display: "none",
                  }}
                >
                  <SingleTicket order={ticket?.Order} ticket={ticket} maxProdNameLength={maxProdNameLength} />
                </Document>
              }
            >
              {({ blob, url, loading, error }) => {
                // console.log({ url, blob });

                if (loading) {
                  return (
                    <>
                      <div
                        style={{
                          height: "2.125in",
                          width: "5.7in",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          backgroundColor: "gray",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <CircularProgressLoader />
                        </p>
                      </div>
                    </>
                  );
                }
                if (error) {
                  console.error("Error generating PDF: ", error);
                  return <div>Error!</div>;
                }

                if (url) {
                  // Store the URL temporarily
                  tempPdfUrls.current[ticket.id] = url;
                  if (ticket.id === activeTicketId) {
                    return (
                      <iframe
                        src={`${url}#toolbar=0`}
                        style={{ height: "2.125in", width: "5.7in" }}
                        title="PDF"
                      />
                    );
                  } else {
                    return (
                      <div
                        style={{
                          height: "2.125in",
                          width: "5.7in",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                          backgroundColor: "gray",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          mt={2}
                          ml={2}
                          onClick={() => handleViewClick(ticket.id)}
                        >
                          View Ticket
                        </Button>
                      </div>
                    );
                  }
                }

                return <div></div>;
                // Handle loading and error states
                // Store the URL for printing
              }}
            </BlobProvider>
          ))}
    </div>
  );
};

export default Tickets;
