import * as types from "_constants";

const initialState = {
  campaignsActive: [],
  campaignsInactive: [],
  detail: {
  },
  transactions: [],
};

function sortCampaigns(campaigns) {
  return ({
    campaignsActive: campaigns.filter(campaign => campaign.is_active === 1),
    campaignsInactive: campaigns.filter(campaign => campaign.is_active === 0)
  })
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_CAMPAIGNS_SUCCESS:
      const camapigns = actions?.payload?.campaigns?.map(c => {
        return {
          ...c,
          currentGoal: parseFloat(c.currentGoal),
        }
      });
      return {
        ...state,
        ...sortCampaigns(camapigns)
      };
    case types.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...sortCampaigns([...state.campaignsActive, ...state.campaignsInactive, actions.payload.campaign])
      };
    case types.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        detail: actions.payload.detail,
      };
    case types.UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...sortCampaigns(actions.payload.campaigns)
      };
    case types.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        ...sortCampaigns(actions.payload.campaigns)
      };
    case types.GET_CAMPAIGN_TRANSACTIONS_SUCCESS:
      const spreadedTransactions = [];
      actions.payload.transactions.forEach((t) => {
        if (t.TeamDonations) {
          spreadedTransactions.push({
            ...t,
            payment_type: 'Team Donations 🟩',
            payment_amount_no_fee: t.TeamDonations,
          });
        }
        if (t.CampaignDonations) {
          spreadedTransactions.push({
            ...t,
            payment_type: 'Campaign Donations 🟦',
            payment_amount_no_fee: t.CampaignDonations,
          });          
        }
        if (t.TeamSales) {
          spreadedTransactions.push({
            ...t,
            payment_type: 'Team Sales 🟨',
            payment_amount_no_fee: t.TeamSales,
          });          
        }
        if (t.CampaignSales) {
          spreadedTransactions.push({
            ...t,
            payment_type: 'Campaign Sales 🟪',
            payment_amount_no_fee: t.CampaignSales,
          });
        }            
      });
      console.log('spreadedTransactions', spreadedTransactions);
      return {
        ...state,
        transactions: spreadedTransactions,
      };
      case types.GET_CAMPAIGN_TEMPLATES_SUCCESS:
        return {
          ...state,
          campaignTemplates: actions.payload.campaignTemplates,
        };
    default:
      return state;
  }
}
