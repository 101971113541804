import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { getPlaybillData, updatePlaybillProducts } from "_redux/actions/playbillActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography as MuiTypography,
  TextField as MuiTextField
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useLocale } from "_contexts/LocaleContext";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EditorContainer = styled.div`
  min-height: 200px;
`;

const MerchItemContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
`;

const Image = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 4px;
`;

function PlaybillShopContent({ page, showPreview, classes, handleUpdate }) {
  const shopPageMerchList = page ? page.data.merchandise : [];
  const { formatCurrency } = useLocale();

  const productionId = useSelector(state => state.productionReducer.production._id);
  const dispatch = useDispatch();

  useEffect(() => {
    const load = async () => {
      dispatch(updatePlaybillProducts(productionId))
      dispatch(getPlaybillData(productionId));
    }
    load();

  }, []);

  return (
    <EditorContainer>
      { showPreview ? ( 
        <>
          <Typography component="h1" variant="h1" mt={4} mb={6}>{page.data.page_title}</Typography>

            <Grid container xs={12} spacing={2} justify="flex-start" alignContent="center" direction="row">
              { shopPageMerchList.map((merchItem, index) => (
                <>
                  { merchItem.show_item && (
                    <Grid item key={index}>
                      <MerchItemContainer>
                        <Image src={merchItem.image} />
                        <Typography mb={2}>{merchItem.type}</Typography>
                        <Typography mb={2}>{merchItem.price ? formatCurrency(merchItem.price) : ""}</Typography>
                      </MerchItemContainer>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </>
          ) : (
          <TextField
            label="Title"
            placeholder="Type here"
            id="filled-hidden-label-small"
            name="page_title"
            variant="outlined"
            size="small"
            fullWidth
            value={page.data.page_title}
            onChange={handleUpdate}
            className={classes.root}
            mb={4}
            mt={4}
          />
          )
      }
    </EditorContainer>
  )
}

function PlaybillShop({ production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillShopContent page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillShop;
