
// Subscriptions
export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILURE = "GET_SUBSCRIPTION_FAILURE";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAILURE = "CREATE_SUBSCRIPTION_FAILURE";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAILURE = "UPDATE_SUBSCRIPTION_FAILURE";

export const DELETE_SUBSCRIPTION_REQUEST = "DELETE_SUBSCRIPTION_REQUEST";
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS";
export const DELETE_SUBSCRIPTION_FAILURE = "DELETE_SUBSCRIPTION_FAILURE";

export const GET_ELIGIBLE_PRODUCTIONS_FOR_NEW_SUBSCRIPTIONS_REQUEST = "GET_ELIGIBLE_PRODUCTIONS_FOR_NEW_SUBSCRIPTIONS_REQUEST";
export const GET_ELIGIBLE_PRODUCTIONS_FOR_NEW_SUBSCRIPTIONS_SUCCESS = "GET_ELIGIBLE_PRODUCTIONS_FOR_NEW_SUBSCRIPTIONS_SUCCESS";

export const UPDATE_SUBSCRIPTION_ACTIVE_STATUS_REQUEST = "UPDATE_SUBSCRIPTION_ACTIVE_STATUS_REQUEST";
export const UPDATE_SUBSCRIPTION_ACTIVE_STATUS_SUCCESS = "UPDATE_SUBSCRIPTION_ACTIVE_STATUS_SUCCESS";

export const UPDATE_SUBSCRIPTION_AVAILABLE_FOR_PURCHASE_REQUEST = "UPDATE_SUBSCRIPTION_AVAILABLE_FOR_PURCHASE_REQUEST";
export const UPDATE_SUBSCRIPTION_AVAILABLE_FOR_PURCHASE_SUCCESS = "UPDATE_SUBSCRIPTION_AVAILABLE_FOR_PURCHASE_SUCCESS";

export const GET_SUBSCRIPTION_DASHBOARD_REQUEST = "GET_SUBSCRIPTION_DASHBOARD_REQUEST";
export const GET_SUBSCRIPTION_DASHBOARD_SUCCESS = "GET_SUBSCRIPTION_DASHBOARD_SUCCESS";

export const DUPLICATE_SUBSCRIPTION = "DUPLICATE_SUBSCRIPTION";