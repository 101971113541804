import * as types from "_constants";

import {
  listOffersService,
  createOfferService,
  updateOfferService,
  updateOfferOrderService,
  deleteOfferService,
} from "_services/offerService";

export function getAllOffers(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_OFFERS });
    const offersList = await listOffersService(orgId);
    dispatch({
      type: types.GET_OFFERS_SUCCESS,
      payload: offersList.data
    });
  };
}

export function createOffer(orgId, data) {
  return async (dispatch) => {
    dispatch({type: types.SAVE_OFFER});
    return createOfferService(orgId, data)
      .then((response) => {
        dispatch({
          type: types.SAVE_OFFER_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Offer created successfully!',
          }
        });
      })
  }
}

export function updateOffer(orgId, OfferId, data) {
  return async (dispatch) => {
    dispatch({type: types.UPDATE_OFFER});
    return updateOfferService(orgId, OfferId, data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_OFFER_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Offer status updated',
          }
        })
      })
  }
}

export function updateOfferOrder(orgId, OfferIds) {
  return async (dispatch) => {
    dispatch({type: types.UPDATE_OFFER_ORDER});
    return updateOfferOrderService(orgId, OfferIds)
      .then((response) => {
        dispatch({
          type: types.UPDATE_OFFER_ORDER_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Offer priority updated',
          }
        });
      })
  }
}

export function deleteOffer(orgId, OfferId) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_OFFER });
    return deleteOfferService(orgId, OfferId)
    .then((response) => {
      debugger;
      dispatch({
        type: types.DELETE_OFFER_SUCCESS,
        payload: response,
      })
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'Offer has been deleted',
        }
      });       
    }).catch(error => {
      dispatch({ type: types.DELETE_OFFER_FAILURE });
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    });
  };
}