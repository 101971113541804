// React and React Related Libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';

// Material-UI Components and Icons
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
  Fab,
  TableHead,
  Paper,
  Table,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DragIndicator as DragIcon } from '@material-ui/icons';

// Redux and Actions
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '_redux/actions/appActions';
import {
  listLiveEnterpriseProductions,
  listLiveProductions,
  listDraftProductions,
} from '_redux/actions/productionListActions';
import {
  getAllOffers,
  updateOffer,
  updateOfferOrder,
} from '_redux/actions/offerActions';
import { getAddOns } from '_redux/actions/addonsActions';
import { getEventTierModifiers } from '_redux/actions/eventTierModifierActions';

// Project Specific Components, Utilities, and Assets
import TooltipSwitch from '_components/common/TooltipSwitch';
import OffersForm from './OffersForm';
import DeleteOffer from './DeleteOffer';
import EditOffer from './EditOffer';
import useModal from '_components/modal/useModal';
import InfoSVG from '../../settings/icons/info--blue.svg';

// Utility Functions or Libraries
import { spacing } from '@material-ui/system';
import { getComparator, stableSort } from '_utils/tableUtils';
import update from 'immutability-helper';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  nameCell: {
    width: '20%',
    maxWidth: '100px',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
  },
  perCell: {
    width: '15%',
  },
  staticCell: {
    width: '10%',
  },
  editCell: {
    width: '20%',
  },
  emptyImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '240px',
    width: '100%',
    border: '1px dashed grey',
    marginBottom: '16px',
  },
  imageContainer: {
    height: '240px',
    width: '100%',
    marginBottom: '16px',
  },
  productImage: {
    maxHeight: '100%',
    objectFit: 'fill',
  },
  addOnDropdown: {
    width: '45%',
  },
  logicStyle: {
    padding: theme.spacing(1),
  },
  fieldStyle: {
    marginTop: '16px',
  },
  nonPlatinumMessage: {
    display: 'flex',
    padding: '8px 16px',
    justifyContent: 'initial',
    alignItems: 'center',
    borderRadius: '4px',
    background: '#E5F6FD',
    color: '#00728C',
    marginBottom: '16px',
  },
  nonPlatinumMessageIcon: {
    marginRight: '8px',
  },
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);

function OfferRow({
  offerItem,
  moveRow,
  organization,
  id,
  index,
  classes,
  handleIsActive,
  onShowDeleteDialog,
  toggleEditModal,
  setSelectedOfferId,
}) {
  const ItemTypes = {
    ROW: 'row',
  };
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <TableRow
      ref={ref}
      style={{ opacity }}
      hover
      tabIndex={-1}
      data-handler-id={handlerId}
    >
      <TableCell style={{ cursor: 'move' }} align='center'>
        <DragIcon />
      </TableCell>
      <TableCell align='center'>{offerItem.sort_order}</TableCell>
      <TableCell align='center'>{offerItem.name}</TableCell>
      <TableCell
        align='center'
        className={`${classes.capitalize} ${classes.perCell}`}
        style={{ flexBasis: '0' }}
      >
        {offerItem.applies_to === 'Ticket' ? 'Ticket' : 
        offerItem.applies_to === 'addon' ? 'Add-on' : 
        offerItem.applies_to}
      </TableCell>
      <TableCell
        align='center'
        className={`${classes.capitalize} ${classes.perCell}`}
        style={{ flexBasis: '0' }}
      >
        {offerItem.applies_to === 'addon' && offerItem.addon
          ? offerItem.addon.name
          : offerItem.applies_to === 'ticket' && offerItem.ticket
          ? offerItem.ticket.name
          : ''}
      </TableCell>
      <TableCell
        align='center'
        className={`${classes.capitalize} ${classes.staticCell}`}
        style={{ flexBasis: '0' }}
      >
        <TooltipSwitch
          checked={offerItem.is_active}
          handleCheck={(e) => handleIsActive(offerItem, organization)}
          name='statusToggle'
        />
      </TableCell>
      <TableCell align='center'>
        <IconButton
          onClick={() => {
            toggleEditModal();
            setSelectedOfferId(offerItem.id, offerItem);
          }}
          aria-label='edit offer'
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label='delete offer'
          onClick={() => onShowDeleteDialog(offerItem)}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function Offers() {
  // Redux Hooks
  const dispatch = useDispatch();
  const organization = useSelector(
    (state) => state.organizationReducer.organization
  );
  const { offers } = useSelector((state) => state.offersReducer);
  const { addOns } = useSelector((state) => state.addonsReducer);
  const userRoles = useSelector((state) => state.authReducer.user.roles);
  const eventTierModifiersList = useSelector(
    (state) => state.eventTierModifierReducer.eventTierModifiers
  );

  // React State Hooks
  const [isOpen, toggleModal] = useModal();
  const [isOpenEditModal, toggleEditModal] = useModal();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('sort_order');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offersList, setOffersList] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState({
    isOpen: false,
    field: null,
  });
  const [selectedOfferId, setSelectedOfferId] = useState();

  // Constants and Other Variables
  const classes = useStyles();
  const disableFeature =
    organization.plan !== 'Platinum' && !organization.is_enterprise;
  const is_enterprise = organization.is_enterprise;
  const rowsPerPageOptions = [5, 10, 25, 100];

  const handleIsActive = async (offerItem, organization) => {
    await dispatch(
      updateOffer(organization.id, offerItem.id, {
        is_active: !offerItem.is_active,
      })
    );
  };

  const onShowDeleteDialog = (field) => {
    setShowDeleteDialog({
      isOpen: true,
      field: field,
    });
  };

  const onDeleteSuccess = (isOpen, isSuccess, field) => {
    setShowDeleteDialog({
      isOpen: false,
      field: null,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    // load all offers
    const loadOffers = async () => {
      try {
        if (organization && organization.id) {
          dispatch(getAllOffers(organization.id));

          if (!is_enterprise) {
            dispatch(listLiveProductions());
            dispatch(listDraftProductions());
          }

          if (is_enterprise) {
            dispatch(listLiveEnterpriseProductions());
          }
        }
      } catch (error) {
        openDialog({
          message:
            error.message ||
            'Something went wrong! Could not load data. Please refresh.',
        });
      }
    };

    // load addons
    const loadAddons = async () => {
      try {
        if (organization && organization.id) {
          dispatch(getAddOns(organization.id));
        }
      } catch (error) {
        openDialog({
          message:
            error.message ||
            'Something went wrong! Could not load data. Please refresh.',
        });
      }
    };

    // load ticket types
    const loadTicketTypes = async () => {
      try {
        if (organization && organization.id) {
          dispatch(getEventTierModifiers(organization.id));
        }
      } catch (error) {
        openDialog({
          message:
            error.message ||
            'Something went wrong! Could not load data. Please refresh.',
        });
      }
    };

    loadOffers();
    loadAddons();
    loadTicketTypes();
  }, [dispatch, organization?.id, is_enterprise]);

  useEffect(() => {
    setOffersList(offers);
  }, [offers]);

  useEffect(() => {
    if (Array.isArray(offers)) {
      const enrichedOffers = offers.map((offer) => {
        const enrichedOffer = { ...offer };

        if (offer.applies_to === 'addon') {
          const addon = addOns.find((addOn) => addOn.id === offer.rules[0].if);
          enrichedOffer.addon = addon || null; // Adding the entire addon object
        } else if (offer.applies_to === 'ticket') {
          const ticket = eventTierModifiersList.find(
            (ticket) => ticket.id === offer.rules[0].then
          );
          enrichedOffer.ticket = ticket || null; // Adding the entire ticket object
        }

        return enrichedOffer;
      });

      setOffersList(enrichedOffers);
    }
  }, [offers, addOns, eventTierModifiersList]);

  const moveRow = React.useCallback(
    async (dragIndex, hoverIndex) => {
      const dragProduct = offersList[dragIndex];
      const updatedList = update(offersList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragProduct],
        ],
      });
      setOffersList(updatedList);
      try {
        dispatch(
          updateOfferOrder(
            organization.id,
            updatedList.map((p) => p.id)
          )
        );
      } catch (error) {
        const message =
          error.response?.data?.message ||
          error.response?.data ||
          error.message ||
          'Unknown error';
        dispatch(
          openDialog({
            message: `There was an error reordering the offers: ${message}`,
          })
        );
      }
    },
    [offersList]
  );

  const initialFormValues = {
    name: '',
    applies_to: 'ticket',
    rules: [],
    img_url: '',
    SeriesId: null,
    per_ticket: false,
  };

  // handle ga4 tag
  const handleUpgradeClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'contact_am_click',
      organization_plan: organization.plan,
      user_role: userRoles,
    });
  };

  const renderNonPlatinumAndEnterpriseMessage = () => {
    return (
      <div className={classes.nonPlatinumMessage}>
        <img
          className={classes.nonPlatinumMessageIcon}
          src={InfoSVG}
          alt='Info Icon'
        />
        <span>
          Creating offers is only available to Platinum Level Members. If you
          would like to use this feature, please contact your{' '}
          <a
            href='mailto:accountmanager@onthestage.com?subject=Upgrade to Platinum - Offers'
            style={{ color: '#0070E0', textDecoration: 'underline' }}
            onClick={handleUpgradeClick}
          >
            Account Manager
          </a>
          .
        </span>
      </div>
    );
  };

  return (
    <>
      <Helmet title='Offers' />
      
      <Grid justify='space-between' container spacing={10}>
        <Grid item>
          <Typography variant='h3' gutterBottom display='inline'>
            Offers
          </Typography>

          <Breadcrumbs aria-label='Breadcrumb' mt={2}>
            <Link component={NavLink} exact to='/'>
              Dashboard
            </Link>
            <Typography>Offers</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Tooltip title='Offer Configuration' aria-label='add'>
            <span>
              <Fab
                onClick={() => toggleModal()}
                size='small'
                color='secondary'
                aria-label='Add'
                disabled={disableFeature}
              >
                <AddIcon />
              </Fab>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Alert severity='info' variant='outlined'>
        Increase revenue during the checkout process by encouraging patrons to upgrade ticket types or purchase add-ons through a pop-up offer.
        <br />
        Create multiple offers for the same production, and prioritize which offer is shown by dragging and dropping the entries on the table.
      </Alert>

      {disableFeature ? renderNonPlatinumAndEnterpriseMessage() : ''}

      {offersList.length === 0 && !disableFeature && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Alert severity='info' variant='outlined'>
              No offers defined
            </Alert>
          </Grid>
        </Grid>
      )}

      {offersList.length > 0 && !disableFeature && (
        <>
          <TableContainer component={Paper}>
            <DndProvider backend={HTML5Backend}>
              <Table className={classes.table} aria-label='product table'>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'></TableCell>
                    <TableCell align='center'>Priority</TableCell>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>Type</TableCell>
                    <TableCell align='center'>Offer</TableCell>
                    <TableCell align='center'>Status</TableCell>
                    <TableCell align='center'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(offersList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((offerItem, index) => (
                      <OfferRow
                        key={offerItem.id}
                        offerItem={offerItem}
                        organization={organization}
                        moveRow={moveRow}
                        id={offerItem.id}
                        index={index}
                        classes={classes}
                        handleIsActive={handleIsActive}
                        onShowDeleteDialog={onShowDeleteDialog}
                        toggleEditModal={toggleEditModal}
                        setSelectedOfferId={setSelectedOfferId}
                      />
                    ))}
                </TableBody>
              </Table>
            </DndProvider>

            {
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component='div'
                count={offersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            }
          </TableContainer>
        </>
      )}

      {isOpen && (
        <OffersForm
          key={`offers-form-${isOpen}`}
          isOpen={isOpen}
          toggleModal={toggleModal}
          initialFormValues={initialFormValues}
          isEditing={false}
          offersList={offersList}
        />
      )}

      {isOpenEditModal && (
        <EditOffer
          isOpen={isOpenEditModal}
          toggleModal={toggleEditModal}
          selectedOfferId={selectedOfferId}
          offersList={offersList}
        />
      )}

      {showDeleteDialog.isOpen && (
        <DeleteOffer
          isOpen={showDeleteDialog.isOpen}
          onClose={onDeleteSuccess}
          field={showDeleteDialog.field}
        />
      )}
    </>
  );
}
export default Offers;
