import * as types from "_constants";

const initialState = {
  live: [],
  draft: [],
  past: [],
  live_and_draft: [],
  all: [],
  live_enterprise: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_LIVE_SUCCESS:
      return {
        ...state,
        live: actions.payload.live,
      }
    case types.GET_DRAFT_SUCCESS:
      return {
        ...state,
        draft: actions.payload.draft,
      }  
    case types.GET_PAST_SUCCESS:
      return {
        ...state,
        past: actions.payload.past,
      }
    case types.GET_LIVE_AND_DRAFT_SUCCESS:
      return {
        ...state,
        live_and_draft: actions.payload.live_and_draft,
      }
    case types.GET_ALL_SUCCESS:
      return {
        ...state,
        all: actions.payload.all,
      }
    case types.GET_LIVE_ENTERPRISE_SUCCESS:
      return {
        ...state,
        live_enterprise: actions.payload.live_enterprise,
      }
    case types.SAVE_SERIES_ATTRIBUTES_SUCCESS: 
      const filteredLive = state.live.filter((live) => live._id !== actions.payload.productionId);
      const filterDraft = state.draft.filter((draft) => draft._id !== actions.payload.productionId);
      
      let liveTarget = state.live.filter((live) => live._id === actions.payload.productionId);
      let draftTarget = state.draft.filter((draft) => draft._id === actions.payload.productionId);
      
      delete actions.payload.productionId;
      
      if (liveTarget.length) {
        liveTarget[0] = {
          ...liveTarget[0],
          details: {
            ...liveTarget[0].details,
            ...actions.payload
          }
        }
      }

      if (draftTarget.length) {
        draftTarget[0] = {
          ...draftTarget[0],
          details: {
            ...draftTarget[0].details,
            ...actions.payload
          }
        }
      }

      return {
        ...state,
        live: [...filteredLive, ...liveTarget],
        draft: [...filterDraft, ...draftTarget],
      }
    default:
      return state;
  }
}
