import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

const centeredFlexBaseStyle = {
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
};
export const DialogComponent = ({
  open,
  handleOK,
  handleCancel,
  title,
  contentText,
  children,
  actionDisabled=false,
  cancelText='Close',
  okText='Yes',
  hideCancel=false,
  hideOk=false,
  fullWidth=false,
  maxWidth,
  variant="default",
  hideFooter=false,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth={fullWidth}
      maxWidth={maxWidth || "sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
     {title && <DialogTitle id="alert-dialog-title" style={variant === 'centered' ? centeredFlexBaseStyle : {}}>
        {variant === 'centered' ? (
          <Typography variant="h2">
            {title}
          </Typography>
        ): (
          <Typography variant="h2">
            {title}
          </Typography>
        )}
      </DialogTitle>}
      <DialogContent style={variant === 'centered' ? {...centeredFlexBaseStyle, flexDirection: 'column'} : {}}>
        {contentText && (
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        )}
        {children}
      </DialogContent>
      {!hideFooter && <DialogActions style={variant === 'centered' ? { ...centeredFlexBaseStyle, columnGap: '20px' } : {}}>
        {!hideCancel && (
          <Button onClick={handleCancel} variant="outlined">
            {cancelText}
          </Button>
        )}
        {!hideOk && (
          <Button onClick={handleOK} color="primary" variant="contained" disabled={actionDisabled}>
            {okText}
          </Button>
        )}
      </DialogActions>
      }
    </Dialog>
  )
}

export const ErrorDialog = ({
  open,
  handleCancel,
  title='Error',
  contentText,
  severity="error",
  cancelText='OK',
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Alert severity={severity} >
          {contentText && (
            <DialogContentText id="alert-dialog-description">
              {contentText}
            </DialogContentText>
          )}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export const WaitingDialog = ({
  open,
  message,
}) => {
  return (
    <Dialog
      open={open}
      maxWidth={"lg"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <Grid item><CircularProgress size={18} color="secondary" /></Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item>{message}</Grid>
          </Grid>
          
          
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}