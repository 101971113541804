import * as types from "_constants";

const initialState = {
    OrgName: null,
    isSocialSchedulerEligible: null,
    marketingSchedule: [],
    campaignSchedule: [],
    selectedScheduledEmail: {},
    fetchingSelectedScheduledEmail: false,
    filter: 'upcoming',
    selectedPromote: 'all'
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types:
      return {
        ...state,
        OrgName: null,
        isSocialSchedulerEligible: null,
        marketingSchedule: []
      };
    case types.GET_MARKETING_SCHEDULE_SUCCESS:
      return {
        ...state, 
        ...actions.payload
      };   
    case types.GET_FUND_CAMPAIGN_MARKETING_SCHEDULE_SUCCESS:
      return {
        ...state, 
        campaignSchedule: actions.payload.campaignSchedule
      };
    case types.GENERATE_MARKETING_PACKAGE:
      return {
        ...state,
        marketingPackage: actions.payload.data
      };
    case types.CLEAR_MARKETING_SCHEDULE:
      return {
        ...state,
        marketingSchedule: []
      };
    case types.UPDATE_MARKETING_SCHEDULE:
      return {
        ...state,
        marketingSchedule: actions.payload
      };
    case types.GENERATE_MARKETING_PACKAGE_ERR:
      return {
        ...state,
        err: actions?.payload?.data ?? "Error generating marketing package. Please try again later."
      };
    case types.GET_MARKETING_PACKAGE:
      return {
        ...state,
        marketingPackage: actions.payload.data
      };
    case types.GET_MARKETING_EMAIL_DETAILS_REQUEST:
      return {
        ...state,
        fetchingSelectedScheduledEmail: true,
      };
    case types.GET_MARKETING_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        selectedScheduledEmail: {...state.selectedScheduledEmail, ...actions.payload},
        fetchingSelectedScheduledEmail: false,
      };
    case types.UPDATE_MARKETING_EMAIL_DETAILS_SUCCESS:
      return {
        ...state,
        selectedScheduledEmail: { ...state.selectedScheduledEmail, ...actions.payload },
      };

    case types.RESET_SELECTED_SCHEDULED_EMAIL:
      return {
        ...state,
        selectedScheduledEmail: {},
      };
    case types.SET_FILTER:
      return {
        ...state,
        filter: actions.payload
      };
    case types.SET_SELECTED_PROMOTE:
      return {
        ...state,
        selectedPromote: actions.payload
      };
    default:
      return state;
  }
}
