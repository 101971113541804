import axios from "_utils/axios";
import LogRocket from 'logrocket';


export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`login`, credentials)
      .then((response) => {
        
        if (response.status === 200) {
          const user = response.data;

          if (process.env.NODE_ENV !== 'development') {
            LogRocket.identify(user.email, {
              email: user.email,
              name: user.name,
            });
  
            const bootInfo = {
              app_id: 'ue7giqoe',
              user_id: user.email,
              name: user.name,
              email: user.email
            };
            window.Intercom('boot', bootInfo);         
          }

          resolve(user);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`logout`)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("users/forgot-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("users/create-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changePassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("users/change-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getLoggedInStatus() {
  return new Promise((resolve, reject) => {
    axios
      .get("isLoggedIn")
      .then((response) => {
        if (response.status === 200) {
          const user = response.data;
          try {

            if (process.env.NODE_ENV !== 'development') {

              const bootInfo = {
                app_id: 'ue7giqoe',
                user_id: user.email,
                name: user.name,
                email: user.email
              };
              if (!user.is_admin && user.userOrg && user.userOrg.artix_id) {
                bootInfo.company = {
                  company_id: user.userOrg.artix_id,
                  name: user.userOrg.name,
                }
              }
              window.Intercom('boot', bootInfo);       
                          
              // This is an example script - don't forget to change it!
              LogRocket.identify(user.email, {
                name: user.name,
                email: user.email,
                organization_id: user.userOrg.artix_id,
                organization_name: user.userOrg.name,
              });      
              
              window.Intercom('update', {
                logrocketURL: `https://app.logrocket.com/rwl5hk/onthestage-dashboard/sessions?u=${user.email}`,
              });
            }


            // window.dataLayer.push({
            //   email: user.email,
            //   user_id: user.id,
            //   name: user.name,
            //   company_id: user.userOrg?.artix_id,
            //   company_name: user.userOrg?.name,
            //   company_plan: user.userOrg?.plan,
            // });            
          } catch (err) {
            console.log('err', err);
          }
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function authorizeFirebaseUserToOrganization(organization, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${organization._id}/authorizeFirebaseUser`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function authorizeFirebaseUser(organization, event, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${organization._id}/${event.external_id}/authorizeFirebaseUser`, data)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function switchOrganization(orgId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`switch-organization`, {
        organization: orgId
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function sendMagicLink(email, recaptchaToken) {
  return new Promise((resolve, reject) => {

    const credentials = {
      email,
      recaptchaToken,
    };
    axios
      .post(`users/magic-link`, credentials)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}