import * as types from "_constants";

import {
  getAddonsService,
  updateAddOnService,
  createAddOnService,
  createAddOnVariantService,
  deleteAddOnVariantService,
  updateAddOnVariantService,
  updateAddOnOrderService
} from "_services/addonsService";


// TODO: update this when backend is set
export function getAddOns(orgId, seriesId) {
  return async (dispatch) => {
    dispatch({type: types.GET_ADDONS});
    return getAddonsService(orgId, seriesId)
      .then((response) => {
        dispatch({
          type: types.GET_ADDONS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error fetching add-ons";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function setSelectedProductionForAddons(value) {
  return async (dispatch) => {
    dispatch({
      type: types.SET_PRODUCTION_FOR_ADD_ONS,
      payload: value,
    });
  };
}

export function deleteSelectedProductionForAddons() {
  return async (dispatch) => {
    dispatch({
      type: types.DELETE_PRODUCTION_FOR_ADD_ONS,
    });
  };
}

export function updateAddOn(orgId, addOnId, data) {
  return async (dispatch) => {
    dispatch({type: types.UPDATE_ADDON});
    return updateAddOnService(orgId, addOnId, data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ADDON_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on updated successfully!',
          }
        })
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error updating add-on";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function updateAddOnOrder(orgId, addOnIds) {
  return async (dispatch) => {
    dispatch({type: types.UPDATE_ADDON_ORDER});
    return updateAddOnOrderService(orgId, addOnIds)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ADDON_ORDER_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on priority updated',
          }
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error updating add-on priority";
        dispatch({type: types.UPDATE_ADDON_ORDER_FAILURE});
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function createAddOn(orgId, data) {
  return async (dispatch) => {
    dispatch({type: types.SAVE_ADDON});
    return createAddOnService(orgId, data)
      .then((response) => {
        dispatch({
          type: types.SAVE_ADDON_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on created successfully',
          }
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error creating add-on";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function createAddOnVariant(orgId, addOnId, data) {
  return async (dispatch) => {
    dispatch({type: types.SAVE_ADDON_VARIANT});
    return createAddOnVariantService(orgId, addOnId, data)
      .then((response) => {
        dispatch({
          type: types.SAVE_ADDON_VARIANT_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on variant created successfully',
          }
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error creating add-on variant";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function deleteAddOnVariant(orgId, addOnId, variantId, filterId) {
  return async (dispatch) => {
    dispatch({type: types.DELETE_ADDON_VARIANT});
    return deleteAddOnVariantService(orgId, addOnId, variantId, filterId)
      .then((response) => {
        dispatch({
          type: types.DELETE_ADDON_VARIANT_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on variant deleted successfully',
          }
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error deleting add-on variant";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}

export function updateAddOnVariant(orgId, addOnId, variantId, data) {
  return async (dispatch) => {
    dispatch({type: types.UPDATE_ADDON_VARIANT});
    return updateAddOnVariantService(orgId, addOnId, variantId, data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_ADDON_VARIANT_SUCCESS,
          payload: response,
        });
        dispatch({
          type: types.SHOW_NOTIFICATION,
          payload: {
            message: 'Add-on variant updated successfully',
          }
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Error updating add-on variant";
        dispatch({
          type: types.OPEN_DIALOG,
          payload: {
            message,
          }
        })
      })
  }
}