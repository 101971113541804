import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { updatePlaybillValue } from "_redux/actions/playbillActions";
import { openNotification, openDialog } from "_redux/actions/appActions";
import PlaybillMemberForm from './PlaybillMemberForm';
import {
  Grid,
  Typography as MuiTypography,
  Link as MuiLink,
  TextField as MuiTextField
} from "@material-ui/core";
import {
  Add as AddIcon,
  AccountCircle as AccountCircleIcon
} from '@material-ui/icons';
import { spacing } from "@material-ui/system";
const Typography = styled(MuiTypography)(spacing);
const Link = styled(MuiLink)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EditorContainer = styled.div`
  min-height: 200px;
`;

const Image = styled.img`
  width: 50px;
  height: 66px;
  border-radius: 4px;
`;

const CastContainer = styled.div`
  margin-bottom: 10px;
`

const UserIcon = styled(AccountCircleIcon)`
  color: #2097F6;
`

const UserIconGrid = styled(Grid)`
  background-color: #E6ECFF;
  height: 80px;
  width: 80px;
  border-radius: 5px;
`

const CastName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;  
`;

const CastRole = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
`;

const CastBio = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
`;

const initialCast = {
  name: "",
  role: "",
  bio: "",
  image: "",
}

function PlaybillCastContent({ production, page, showPreview, classes, handleUpdate }) {
  const dispatch = useDispatch();
  const [newCast, setNewCast] = useState(initialCast);
  const [addAnother, setAddAnother] = useState(false)
  const castList = page ? page.data.cast : [];

  const handleFieldChange = (e, mode, idx) => {
    if (mode === "new") {
      setNewCast({
        ...newCast,
        [e.target.name]: e.target.value,
      })
    } else if (mode === "update") {
      updateCasts(e.target.name, e.target.value, idx);
    }
  }

  const handleCreateMember = () => {
    if (castList.length <= 0 || addAnother) {
      const newCasts = [...page.data.cast, newCast]
      dispatch(updatePlaybillValue({ 
        key: "cast", 
        value: newCasts, 
        page: page
      }));
    }
    setAddAnother(false)
  }

  const updateCasts = (field, value, updatedIndex) => {
    const updatedCasts = castList.map((cast, index) => {
      if (index === updatedIndex) {
        cast[field] = value;
      }
      return cast;
    })
    dispatch(updatePlaybillValue({
      key: "cast",
      value: updatedCasts,
      page: page
    }))
  }

  const handleAdd = async () => {
    await handleCreateMember();
    setAddAnother(true)
    setNewCast(initialCast);
  }

  const handleUploadImage = (e, mode, updatedIndex) => {
    const cloudinaryOpts = { 
      cloud_name: 'onthestage', 
      sources: [ 'local' ], 
      upload_preset: 'cropmode', 
      resourceType: 'image',
      client_allowed_formats: ['png', 'jpeg', 'jpg'],
      tags: [production.id, production.organization.id, 'programgenerator'],
      multiple: false, 
      showAdvancedOptions: true,
      cropping: true,
      croppingShowDimensions: true,
      showSkipCropButton: false,
      croppingAspectRatio: 0.75 ,
      croppingCoordinatesMode: 'custom',
      showAdvancedOptions: true,
      folder: production.organization.id + '/' + production.id + '/showgram',
    };

    const cloudinaryWidget = window.cloudinary.openUploadWidget(cloudinaryOpts, async (err, result) => {
      if (err && err.message !== 'User closed widget') {
        cloudinaryWidget.close();
        dispatch(openDialog({
          message: `There was an error uploading your file: ${JSON.stringify(err.status)}`,        
        }));
      } else if (err && err.message === 'User closed widget') {
        return false;
      }
      if (result.event === 'success') {
        const image = result.info;

          try {

            if (mode === "update") {
              const updatedCasts = castList.map((cast, index) => {
                if (index === updatedIndex) {
                  cast.image = image.public_id;
                }
                return cast;
              })
      
              dispatch(updatePlaybillValue({
                key: "cast",
                value: updatedCasts,
                page: page
              }))
            } else if (mode === "new") {
              setNewCast({
                ...newCast,
                image: image.public_id,
              })

              const newCasts = [...page.data.cast, newCast]        
              dispatch(updatePlaybillValue({ 
                key: "cast", 
                value: newCasts, 
                page: page
              }));
            }
            dispatch(openNotification({
              message: "Image uploaded!",
            }))
          } catch (err) {
            dispatch(openNotification({
              message: "Cannot upload image!",
            }))
          }
          cloudinaryWidget.close();
          return;
      }
    });
  };

  const handleRemove = async (mode, idx) => {
    if (mode === "new") {
      setNewCast(newCast);
      setAddAnother(false);
    } else {
      try {
        castList.splice(idx, 1);
        dispatch(updatePlaybillValue({ key: "cast", value: [...castList], page: page }));
      } catch (err) {
        dispatch(openNotification({
          message: err.response?.data || err.message || "Something went wrong!",
        }))
      }
    }
  }

  return (
    <EditorContainer>
    { showPreview ? (
      <>
      <Typography component="h1" variant="h1" mt={4} mb={6}>{page.data.page_title}</Typography>
        <CastContainer>
          { castList.map((cast, index) => (
            <Grid container spacing={3} key={index} justify="flex-start" alignContent="center" alignItems="flex-start">
              <Grid item>
                {/* <Image src={`http://res.cloudinary.com/onthestage/image/upload/${cast.image}`} /> */}
                { cast.image ? (
                  <Image src={`https://res.cloudinary.com/onthestage/image/upload/f_auto,w_100,h_100/${cast.image}`} />
                ) : (
                  <UserIconGrid container justify="center" alignContent="center">
                    <Grid item>
                      <UserIcon fontSize="large" />
                    </Grid>
                  </UserIconGrid>
                )}
              </Grid>
              <Grid item xs>
                <CastName>{cast.name}</CastName>
                <CastRole>{cast.role}</CastRole>
                <CastBio>{cast.bio}</CastBio>
              </Grid>
            </Grid>
          ))}
        </CastContainer>
      </>
    ) : (
      <Grid container>
        <TextField
          label="Title"
          placeholder="Type here"
          id="filled-hidden-label-small"
          name="page_title"
          variant="outlined"
          size="small"
          fullWidth
          value={page.data.page_title}
          onChange={handleUpdate}
          className={classes.root}
          mb={6}
        />
        
        { castList.length > 0 ? castList.map((member, idx) => (
          <Grid container spacing={2} justify="space-between" key={idx}>
            <PlaybillMemberForm 
              idx={idx}
              member={member} 
              handleChange={(e) => handleFieldChange(e, "update", idx)}
              handleUploadClick={(e) => handleUploadImage(e, "update", idx)}
              showPreview={showPreview} 
              production={production}
              handleRemove={() => handleRemove("update", idx)}
              handleSave={handleCreateMember}
            />
          </Grid>
        )) : (
          <Grid container spacing={2} justify="space-between">
            <PlaybillMemberForm 
              idx="new"
              member={newCast} 
              showPreview={showPreview} 
              production={production} 
              handleUploadClick={(e) => handleUploadImage(e, "new")}
              handleChange={(e) => handleFieldChange(e, "new")}
              handleRemove={() => handleRemove("new")}
              handleSave={handleCreateMember}
            />
          </Grid>
        )}

        { addAnother && (
          <Grid container spacing={2} justify="space-between">
            <PlaybillMemberForm 
              idx="new"
              member={newCast} 
              showPreview={showPreview} 
              production={production} 
              handleUploadClick={(e) => handleUploadImage(e, "new")}
              handleChange={(e) => handleFieldChange(e, "new")}
              handleRemove={() => handleRemove("new")}
              handleSave={handleCreateMember}
            />
          </Grid>
        )}
        <Grid container alignContent="center" alignItems="center">
          <Grid item>
            <AddIcon fontSize="small" color="primary" />
          </Grid>
          <Grid item>
            <Link component="button" variant="body2" underline="none" color="primary" mb={1} onClick={handleAdd}>
              <b>Add another</b>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    )}
  </EditorContainer>

  )
}

function PlaybillCast({production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillCastContent  production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillCast;
