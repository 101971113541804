import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from 'react-pdf'
import { downloadPdfForRendering } from "_services/ledgerService";
import {
  Spacer,
  Pagination,
} from "_components/common"

import {
  SaveAlt as SaveAltIcon
} from "@material-ui/icons";

import {
  Button,
  Grid,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { SHOW_NOTIFICATION } from "_constants";
import { useDispatch } from "react-redux";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const PdfView = ({ url, show, setShow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPagination, setShowPagination] = useState(false);
  const dispatch = useDispatch();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setShowPagination(true);
  }

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      setShowPagination(false);
      try {
        const downloadUrl = await downloadPdfForRendering(url);
        setDownloadUrl(downloadUrl);
      } catch (error) {
        dispatch({ type: SHOW_NOTIFICATION, payload: {
          message: "Cannot get download URL.",
        }})
      }
      setIsLoading(false);
    }
    load();
  }, [url]);

  const closeModal = () => {
    setShow(false);
  }

  const downloadFile = () => {
    window.open(downloadUrl);
  }

  return (
    <Dialog
      open={show}
      fullWidth
      maxWidth="md"
      onClose={closeModal}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Payout attachment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isLoading ? 
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
            : (
              downloadUrl && (
                <>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Button size="large" onClick={closeModal} color="primary">
                      Close
                    </Button>
                    <Button ml={6} size="large" variant="contained" onClick={downloadFile} color="primary">
                      Download
                      <SaveAltIcon />
                    </Button>
                  </Grid>
                  <Spacer my={2} />
                  <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="center"
                  >
                    {showPagination && (
                      <Pagination
                        onChange={(e, v) => { setPageNumber(parseInt(v))}}
                        page={pageNumber}
                        count={numPages}
                        variant="outlined" 
                        color="secondary" />
                    )}
                    <Document
                      file={downloadUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      }
                    >
                      <Page size="A4" pageNumber={pageNumber} scale={1.4} />
                    </Document>
                  </Grid>
                </>
              )
          )}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
