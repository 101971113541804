import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { getOrgData } from "_redux/actions/organizationActions";
import { organizationList } from "_services/organizationService";
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useHistory } from "react-router-dom";
import { SHOW_NOTIFICATION } from "_constants"

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  TextField,
  Link,
  Typography,
  
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash/debounce";


import { Button, Menu, MenuItem } from "@material-ui/core";

import {
  ArrowDropDown as ArrowDropDownIcon
} from "@material-ui/icons";

import {
  Menu as MenuIcon,
  CalendarToday as CalendarIcon
} from "@material-ui/icons";

import NotificationsDropdown from "./NotificationsDropdown";
import UserDropdown from "./UserDropdown";
import { switchOrganization } from "_services/authService";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

// const Search = styled.div`
//   border-radius: 2px;
//   background-color: ${(props) => props.theme.header.background};
//   display: none;
//   position: relative;
//   width: 100%;
//   &:hover {
//     background-color: ${(props) => darken(0.05, props.theme.header.background)};
//   }
//   ${(props) => props.theme.breakpoints.up("md")} {
//     display: block;
//   }
// `;

// const SearchIconWrapper = styled.div`
//   width: 50px;
//   height: 100%;
//   position: absolute;
//   pointer-events: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   svg {
//     width: 22px;
//     height: 22px;
//   }
// `;

// const Input = styled(InputBase)`
//   color: inherit;
//   width: 100%;
//   > input {
//     color: ${(props) => props.theme.header.search.color};
//     padding-top: ${(props) => props.theme.spacing(2.5)}px;
//     padding-right: ${(props) => props.theme.spacing(2.5)}px;
//     padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
//     padding-left: ${(props) => props.theme.spacing(12)}px;
//     width: 160px;
//   }
// `;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  button: {
    margin: theme.spacing(1),

  },
}));

function AdminOrgSwitch({user}) {
  const [phrase, setPhrase] = useState(user.userOrg?.name || '');
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSearchPhrase = async (e) => {
    setPhrase(e.target.value);

    if (e.target.value) {
      const results = await organizationList(e.target.value);
      setResults(results);
    } else {
      setResults([]);
    }
  }

  const handleChangeWithDebounce = debounce(handleSearchPhrase, 500);

  const handleSwitchOrganization = async (organizationId) => {
    try {
      await switchOrganization(organizationId);
      window.location.href = '/';
    } catch (err) {
      dispatch({ type: SHOW_NOTIFICATION, payload: {
        message: "Cannot switch admin organization.",
      }})
    }
  }

  return (
    <Autocomplete
      style={{padding: 0, width: 300}}
      onChange={(event, value) => handleSwitchOrganization(value._id)}
      options={results}
      className={classes.root}
      getOptionLabel = {(option) => `OTSID${option.artix_id} ${option.name} `}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Switch Org Name / Id"
          margin="none"
          variant="outlined"
          value={phrase}    
          className={classes.root}
          onChange={handleChangeWithDebounce}
        />
      )}
    />
  )
}

function OrgSwitch({user}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const childOrganizations = useSelector(state => state.organizationReducer.organization?.children_organizations);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchOrganization = async (organizationId) => {
    try {
      await switchOrganization(organizationId);
      window.location.href = '/';
    } catch (err) {
      dispatch({ type: SHOW_NOTIFICATION, payload: {
        message: "Cannot switch organization.",
      }})
    }
  }

  const orgId = user?.organization;
  const allowOrgSwitch =  user?.allowOrgSwitch;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const loadData = async (orgId) => {
      try {
        await dispatch(getOrgData(orgId));
      } catch (err) {
        dispatch({ type: SHOW_NOTIFICATION, payload: {
          status: 'error',
          message: err.response?.data || err.message || "Something went wrong!",
        }})
        history.push('/404')
      }
    };
    orgId && loadData(orgId);
  }, [dispatch, getOrgData, orgId])


  return allowOrgSwitch ? (
    <>
      <Button
        variant="outlined"
        color="secondary"
        aria-haspopup="true"
        aria-owns={anchorEl ? "simple-menu" : undefined}
        onClick={handleClick}
        className={classes.button}
      >
        {user.userOrg?.name || ''}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {childOrganizations && childOrganizations.map(o => (
          <MenuItem
            key={o._id}
            disabled={o._id === user.userOrg?._id || null}
            onClick={async () => { handleSwitchOrganization(o._id) }}
          >
            {o.name}
          </MenuItem>
        ))}
        <MenuItem>
          <Link component={NavLink} exact to="/add-child-org">
            Add New Organization
          </Link>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <Typography><Button>{user.userOrg?.name || ''}</Button></Typography>
  )
}

const AppBarComponent = ({ user, onDrawerToggle }) => {  
  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder="Search anything" />
              </Search> */}
            </Grid>
            <Grid item>
              {user && <OrgSwitch user={user} />}
            </Grid>
            <Grid item>
              {user && user.is_admin && (<AdminOrgSwitch user={user} />)}
            </Grid>
            <Grid item>
              {user && user.is_admin && (
                <IconButton
                  aria-label="details"
                  component={NavLink} exact to={'/admin/calendar'}
                >
                  <CalendarIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs />
            <Grid item>
              <NotificationsDropdown />
              <UserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(AppBarComponent);
