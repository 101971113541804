import * as types from "_constants";

const initialState = {
  organization: {
    venues: [],
    twitter: '',
    facebook: '',
    instagram: '',
    readers: [],
    booking_fees: [],
    marketing_redirect_url: ''
  },
  organizationAttribute: {

  },
  organizationWebsite: {
    style: {},
    contactEmail: "",
    showContactForm: false
  },
  dashboardData: {
    thirtydays: null,
    upcomingProductions: [],
    upcomingDrafts: [],
    recentOrders: [],
    productionCountByStatus: {},
    productionCount: [],
    isNewAccount: false,
    maxTeams: 0,
  },
  venue: {
    address_line1: "",
    address_city: "",
    address_state: "",
    address_zip: "",
    name: "",
  },
  salesforce: {
    email: ""
  },
  partners: [],
  emailLogoUrl: null,
  headerBackgroundColor :null,
  headerTextColor: null,
  fetching: false,
  cssTemplates: {
    templates: [],
    currentPage: null,
    totalPages: null,
    count: null,
  },
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.GET_ORG_DATA_SUCCESS:
      return {
        ...state,
        organization: Object.assign({}, actions.organization),
      }
    case types.GET_ORG_VENUES_SUCCESS:
      state.organization.venues = actions.venues;
      return {
        ...state,
        organization: Object.assign({}, state.organization),
      }
    case types.CREATE_VENUE_SUCCESS:
      const venues = state.organization.venues;
      venues.push(actions.venue);
      return {
        ...state,
        organization: {
          ...state.organization,
          venues: [...venues],
        },
      }
    case types.UPDATE_ORG_DATA_VALUE_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          ...actions.payload,
        }
      }
    case types.UPDATE_VENUE_SUCCESS:
      const updatedVenues = state.organization.venues.map(venue => {
        if (venue._id === actions.venue._id) {
          return actions.venue;
        }
        return venue;
      })

      return {
        ...state,
        organization: {
          ...state.organization,
          venues: [...updatedVenues],
        },
      }
 
    case types.DELETE_VENUE_SUCCESS:

      return {
        ...state,
        organization: {
          ...state.organization,
          venues: actions.venue,
        },
      }
    case types.EDIT_VENUE_FIELD:
      return {
        ...state,
        venue: Object.assign({}, state.venue, actions.data),
      };

    case types.SET_SEAT_FEE_PLAN_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          per_ticket_fee: actions.data,
        }
      };
    case types.TOGGLE_SUPPRESS_MARKETING_CAMPAIGNS_SUCCESS:
        const existingDraftIndex = state.dashboardData.upcomingDrafts.findIndex((d) => d._id === actions.payload.productionId);
        state.dashboardData.upcomingDrafts[existingDraftIndex].suppress_campaigns = actions.payload.suppress_campaigns;
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            upcomingDrafts: [...state.dashboardData.upcomingDrafts],
          }
        }
    case types.GET_ORG_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: {
          thirtydays: actions.payload.thirtydays,
          upcomingProductions: actions.payload.upcomingProductions || [],
          recentOrders: actions.payload.recentOrders || [],
          upcomingDrafts: actions.payload.upcomingDrafts || [],
          productionCountByStatus: actions.payload.productionCountByStatus || {},
          productionCount: actions.payload.productionCount || [],    
          isNewAccount: actions.payload.isNewAccount || false,     
          openInvoices: actions.payload.openInvoices || 0,
          errors: actions.payload.errors || []
        },        
      };
    case types.GET_ORG_ENTERPRISE_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: {
          upcomingProductions: actions.payload.events || [],
          maxTeams: actions.payload.maxTeams || 0,
          teams: actions.payload.teams || [],
        },
      };
    case types.UPDATE_SIDEBAR_BADGE_DRAFT:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          productionCountByStatus: {
            ...state.dashboardData.productionCountByStatus,
            draft: actions.payload.draft
          }
        }
      };
    case types.UPDATE_SIDEBAR_BADGE_LIVE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          productionCountByStatus: {
            ...state.dashboardData.productionCountByStatus,
            current: actions.payload.current
          }
        }
      };      
    case types.GET_ORGSITE_SUCCESS:
      return {
        ...state,
        organizationWebsite: actions.payload.website,
      }
    case types.UPDATE_SOCIAL_URL_SUCCESS:
      return {
        ...state,
        payload: actions.payload,
      }

    case types.UPDATE_TAX_DEDUCTIBLE_FIELD_SUCCESS:
      return {
        ...state,
        organizationAttribute: {
          tax_deductible: actions.payload.data,
        }
      };
    case types.GET_ORG_SF_CONTACT_EMAIL_SUCCESS:
      return {
        ...state,
        salesforce: {
          email: actions.payload.email,
        }
      }
    case types.GET_READERS_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          readers: actions.payload.readers,
        }
      }
    case types.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: actions.payload.rows,
      }
    case types.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
        partners: [...state.partners, actions.payload.rows],
      }
    case types.DELETE_PARTNER_SUCCESS:
      return {
        ...state,
        partners: state.partners.filter(partner => partner.id !== actions.payload.rows.id),
      }
    case types.UPDATE_PARTNER_SUCCESS:
      return {
        ...state,
        partners: state.partners.map(
          (partner) => partner.id === actions.payload.rows.id ? actions.payload.rows : partner
        ),
      }
    case types.GET_BOOKING_FEES_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          booking_fees: actions.payload.booking_fees,
        }
      }
    case types.CREATE_BOOKING_FEE_SUCCESS:
        return {
          ...state,
          organization: {
            ...state.organization,
            booking_fees: actions.payload.booking_fees,
          }
        }
    case types.DELETE_BOOKING_FEE_SUCCESS:
        return {
          ...state,
          organization: {
            ...state.organization,
            booking_fees: actions.payload.booking_fees
          }
        }
    case types.UPDATE_BOOKING_FEE_SUCCESS:
        return {
          ...state,
          organization: {
            ...state.organization,
            booking_fees: actions.payload.booking_fees
          }
        }
    case types.GET_ORG_DETAILS_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case types.GET_ORG_DETAILS_SUCCESS:
      const { 
        data: { 
          body: {
            email_logo_url, 
            header_bg_color, 
            header_text_color
          }
        }
      } = actions.payload;
      
      return {
        ...state,
        emailLogoUrl: email_logo_url,
        headerBackgroundColor: header_bg_color,
        headerTextColor: header_text_color,
        fetching: false,
      }
    case types.SAVE_ORG_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        emailLogoUrl: actions.payload.data.email_logo_url,
        headerBackgroundColor: actions.payload.data.header_bg_color,
        headerTextColor: actions.payload.data.header_text_color,
      }
    case types.GET_ORG_CSS_TEMPLATE_SUCCESS:
      return {
        ...state,
        // cssTemplates: { ...actions.payload.data }
        cssTemplates: {
          templates: actions.payload.data.cssTemplates,
          currentPage:actions.payload.data.currentPage,
          totalPages:actions.payload.data.totalPages,
          count:actions.payload.data.totalStylesheetsCount,
        },
      }
    case types.SAVE_ORG_CSS_TEMPLATE_SUCCESS:
      return {
        ...state,
        cssTemplates: {
          ...state.cssTemplates,
          templates: [...state.cssTemplates.templates, { ...actions.payload.data.template }],
          count: state.cssTemplates.count + 1,
        }
      }
    case types.UPDATE_ORG_CSS_TEMPLATE_SUCCESS:
      return {
        ...state,
        cssTemplates: {
          ...state.cssTemplates,
          templates: actions.payload.data.updated
        }
      }
    default:
      return state;
  }
}
