import * as types from "_constants";

const initialState = {
  products: [],
  product: {
    _id: null,
    name: '',
    available_until: '',
    price: '',
    value_for_tax_receipts: '',
    inventory: '',
    description: '',
    img_url: ""
  },
};


export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types:
        return {
          ...state,
          products: []
        };

    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: actions.payload.products,
      };

    case types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: actions.payload.products,
      };

    case types.UPDATE_FUND_PRODUCT_SUCCESS:
      console.log('actions.payload', actions.payload)
      const updatedProducts = state.products.map(product => {
        console.log('product', product);
        if (product.id === actions.payload.product.id) {
          console.log("returning new product");
          return actions.payload.product;
        }
        return product;
      })
      console.log('current products', state.products);

      return {
        ...state,
          products: [...updatedProducts],
      }

    case types.EDIT_PRODUCT_FIELD:
      return {
        ...state,
        product: Object.assign({}, state.product, actions.data),
      };

    // case types.DELETE_CAMPAIGN_SUCCESS:
    //   return {
    //     ...state,
    //     ...sortCampaigns(actions.payload.campaigns)
    //   };
    default:
      return state;
  }
}
