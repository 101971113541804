import React from "react";
import styled from "styled-components/macro";
import {
  Typography as MuiTypography,
  TextField as MuiTextField,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EditorContainer = styled.div`
  min-height: 200px;
`;

const AboutContainer = styled.div`
  min-height: 200px;
`;

function PlaybillAboutContent({ production, page, showPreview, classes, handleUpdate }) {

  return (
      <EditorContainer>
        {showPreview ? (
          <AboutContainer>
            <Typography component="h1" variant="h1" mt={4} mb={2}>{page.data.first_title}</Typography>
            <Typography mt={2} style={{whiteSpace: 'pre-line'}}>{page.data.about1}</Typography>
          </AboutContainer>
        ) : (
          <>
            <TextField
              label="Title"
              placeholder="Type here"
              id="filled-hidden-label-small"
              name="first_title"
              variant="outlined"
              size="small"
              fullWidth
              value={page.data.first_title}
              onChange={handleUpdate}
              className={classes.root}
              mb={2}
            />
            <TextField
              hiddenLabel
              placeholder="Type here"
              id="filled-hidden-label-small"
              name="about1"
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              value={page.data.about1}
              onChange={handleUpdate}
              className={classes.root}
              mb={2}
            />
          </>
        )}

        {showPreview ? (
          <AboutContainer>
            <Typography component="h1" variant="h1" mt={4} mb={2}>{page.data.second_title}</Typography>
            <Typography mt={2} style={{whiteSpace: 'pre-line'}}>{page.data.about2}</Typography>
          </AboutContainer>
        ) : (
          <>
            <TextField
              label="Title"
              placeholder="Type here"
              id="filled-hidden-label-small"
              name="second_title"
              variant="outlined"
              size="small"
              fullWidth
              value={page.data.second_title}
              onChange={handleUpdate}
              className={classes.root}
              mb={2}
              mt={4}
            />
            <TextField
              hiddenLabel
              placeholder="Type here"
              id="filled-hidden-label-small"
              name="about2"
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              value={page.data.about2}
              onChange={handleUpdate}
              className={classes.root}
            />
          </>
        )}
      </EditorContainer>    
  )
}


function PlaybillAbout({production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillAboutContent production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillAbout;