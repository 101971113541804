import * as types from "_constants";
import { 
  createEventTierModifierService, 
  getEventTierModifiersService, 
  updateEventTierModifierService,
  deleteEventTierModifierService,
} from "_services/eventTierModifierService";

export function createEventTierModifier(orgId, eventTierModifier) {
  return async (dispatch) => {
    dispatch({ type: types.CREATE_EVENT_TIER_MODIFIER_REQUEST });
    return createEventTierModifierService(orgId, eventTierModifier)
    .then((response) => {
      dispatch({
        type: types.CREATE_EVENT_TIER_MODIFIER_SUCCESS,
        payload: {
          eventTierModifier: response
        }
      })  
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'Ticket type created!',
        }
      })
    }).catch(error => {
      dispatch({ type: types.CREATE_EVENT_TIER_MODIFIER_FAILURE });
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    });
  };
}

export function getEventTierModifiers(orgId) {
  return async (dispatch) => {
    dispatch({ type: types.GET_EVENT_TIER_MODIFIERS_REQUEST });
    return getEventTierModifiersService(orgId)
    .then((response) => {
      dispatch({
        type: types.GET_EVENT_TIER_MODIFIERS_SUCCESS,
        payload: {
          eventTierModifiers: response
        }
      })       
    }).catch(error => {
      dispatch({ type: types.GET_EVENT_TIER_MODIFIERS_FAILURE });
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    });
  };
}

export function updateEventTierModifier(orgId, eventTierModifierId, eventTierModifier) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_EVENT_TIER_MODIFIER_REQUEST });
    return updateEventTierModifierService(orgId, eventTierModifierId, eventTierModifier)
    .then((response) => {
      dispatch({
        type: types.UPDATE_EVENT_TIER_MODIFIER_SUCCESS,
        payload: {
          eventTierModifier: response
        }
      })
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'Ticket type updated!',
        }
      }) 
    }).catch(error => {
      dispatch({ type: types.UPDATE_EVENT_TIER_MODIFIER_FAILURE });
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    });
  };
}

export function deleteEventTierModifier(orgId, eventTierModifierId) {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_EVENT_TIER_MODIFIER_REQUEST });
    return deleteEventTierModifierService(orgId, eventTierModifierId)
    .then((response) => {
      dispatch({
        type: types.DELETE_EVENT_TIER_MODIFIER_SUCCESS,
        payload: {
          eventTierModifierId
        }
      })       
    }).catch(error => {
      dispatch({ type: types.DELETE_EVENT_TIER_MODIFIER_FAILURE });
      const message = error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: types.OPEN_DIALOG,
        payload: {
          message,
        }
      })
    });
  };
}

