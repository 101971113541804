import * as types from "_constants";

const initialState = {
  orders: [],
  order: {
    order_payments: [{}],
    activation: {},
  },
  refundOrders: [],
  totalOrderCount: 0,
  currentPage: 0,
  totalPages: 0,
  searchParams: {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    confirmation_number: "",
  },
};
  
export default function reducer(state = initialState, actions) {
  switch (actions.type) {  
    case types.SEARCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: actions.payload.rows,
        totalOrderCount: actions.payload.rows.length
      };
    case types.GET_RECENT_ORDERS_SUCCESS:
      return {
        ...state,
        orders: state.orders.concat(actions.payload.rows),
        totalOrderCount: actions.payload.totalOrderCount,
        currentPage: actions.payload.currentPage,
        totalPages: actions.payload.totalPages
      };
    case types.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: actions.payload.order,
      };
    case types.GET_ORDERS_FOR_EVENT_FAILURE:
      return {
        ...state,
        orders: []
      }      
    case types.GET_ORDERS_FOR_EVENT_REQUEST:
      return {
        ...state,
        orders: []
      }
    case types.GET_ORDERS_FOR_EVENT_SUCCESS:
      return {
        ...state,
        orders: actions.payload.orders,
      };
    case types.GET_ORDER_VOD_ACTIVATION_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          activation: actions.payload.activation,
        }
      };
    case types.SAVE_REFUND_ORDERS:
      return {
        ...state,
        refundOrders: actions.payload.refund
      };

    case types.SET_SEARCH_ORDERS_PARAMETERS:
      return {
        ...state,
        searchParams: actions.payload
      };
    default:
      return state;
  }
}
