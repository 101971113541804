import moment from "moment-timezone";

export const formatDateWithTimezone = (date, timezone, format) => {
  return date ? moment.utc(date).tz(timezone).format(format) : null;
};

export const convertLocalDateToUTCString = (dateStr, timezone, format) => {
  return dateStr
    ? moment
        .tz(dateStr, moment.HTML5_FMT.DATETIME_LOCAL, timezone)
        .utc()
        .format(format)
    : "";
};

const useDateTimeFormatter = ({
  format = "YYYY-MM-DDTHH:mm",
  timezone,
} = {}) => {
  const formatTimezone = (date, formatTimeZone) => {
    return formatDateWithTimezone(
      date,
      timezone,
      formatTimeZone ? formatTimeZone : format
    );
  };

  const toUTCString = (dateStr) => {
    return convertLocalDateToUTCString(dateStr, timezone, format);
  };

  const getStandardFutureDate = (days = 14, hours = 19) => {
    return moment
      .tz(timezone)
      .add(days, "days")
      .hour(hours)
      .minute(0)
      .second(0);
  };

  return {
    formatTimezone,
    toUTCString,
    getStandardFutureDate,
  };
};
export default useDateTimeFormatter;
