import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography as MuiTypography,
  Button,
  TextField as MuiTextField
} from "@material-ui/core";
import {
  Add as AddIcon,
} from '@material-ui/icons';
import { Divider } from "_components/common";
import { spacing } from "@material-ui/system";
import { updatePlaybillValue } from "_redux/actions/playbillActions";
import { openNotification } from "_redux/actions/appActions";
import ActComponent from './PlaybillActComponent';
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);

const EditorContainer = styled.div`
  min-height: 200px;
`;

const DotAutoFiller = styled.p`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px

  > * {
    padding: 0;
    margin: 0;
  }
`;

const FillerSpan = styled.span`
  flex: 1;
  overflow: hidden;
`;

const OriginalTeamName = styled.span`

`;

const OriginalTeamRole = styled.span`

`;

const initialAct = [{
  act_title: "",
  scenes: [{
    scene_title: "",
    songs: [{
      song_name: "",
      cast_member: ""
    }]
  }]
}]

function PlaybillScenesContent({ production, page, showPreview, classes, handleUpdate }) {
  const dispatch = useDispatch();
  const actsList = page ? page.data.acts : [];
  const [newAct, setNewAct] = useState(initialAct);
  const [addNewAct, setAddNewAct] = useState(false);

  const handleSaveAct = () => {
    if (addNewAct) {
      const newActs = actsList.concat(newAct)
      dispatch(updatePlaybillValue({ 
        key: "acts", 
        value: newActs, 
        page: page
      }));
    }
    setAddNewAct(false)
    setNewAct(initialAct)
  }

  const handleAddNewAct = async () => {
    await handleSaveAct();
    setAddNewAct(true)
    setNewAct([{
      act_title: "",
      scenes: [{
        scene_title: "",
        songs: [{
          song_name: "",
          cast_member: ""
        }]
      }]
    }]);
  }

  const handleAddNewScene = (actIdx, status) => {
    if (status === "new") {
      const newArr = [...newAct]
      newArr[actIdx].scenes.push({ 
        scene_title: "",
        songs: [{
          song_name: '', 
          cast_member: '' 
        }]
      });
      setNewAct(newArr)
    } else if (status === "update") {
      const newArr = [...actsList]
      newArr[actIdx].scenes.push({ 
        scene_title: "",
        songs: [{
          song_name: '', 
          cast_member: '' 
        }]
      });
  
      dispatch(updatePlaybillValue({ 
        key: "acts", 
        value: newArr, 
        page: page
      }));
    }
 }

  const handleRemove = async (mode, idx) => {
    if (mode === "new") {
      setNewAct(newAct);
      setAddNewAct(false);
    } else {
      try {
        actsList.splice(idx, 1);
        dispatch(updatePlaybillValue({ key: "acts", value: [...actsList], page: page }));
      } catch (err) {
        dispatch(openNotification({
          message: err.response?.data || err.message || "Something went wrong!",
        }))
      }
    }
  }

  const handleRemoveSongs = async (songIdx, sceneIdx, actIdx) => {
    try {
      actsList[actIdx].scenes[sceneIdx].songs.splice(songIdx, 1)
      dispatch(updatePlaybillValue({ key: "acts", value: [...actsList], page: page }));
    } catch (err) {
      dispatch(openNotification({
        message: err.response?.data || err.message || "Something went wrong!",
      }))
    }
  }

  const handleRemoveScenes = async (sceneIdx, actIdx) => {
    try {
      actsList[actIdx].scenes.splice(sceneIdx, 1)
      dispatch(updatePlaybillValue({ key: "acts", value: [...actsList], page: page }));
    } catch (err) {
      dispatch(openNotification({
        message: err.response?.data || err.message || "Something went wrong!",
      }))
    }
  }

  return (
    <EditorContainer>
      { showPreview ? (
        <>
          <Typography component="h1" variant="h1" mt={4} mb={4}>{page.data.page_title}</Typography>

          { actsList?.length > 0 && actsList.map((act, actIdx) => (
            <Grid container key={actIdx} justify="flex-start" alignContent="center" alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="h2" component="h2">{act.act_title}</Typography>
              </Grid>
              <Grid item xs={12}>
                { actsList[actIdx].scenes?.length > 0 && actsList[actIdx].scenes.map((scene, sceneIdx) => (
                  <>
                    { scene.scene_title && <Typography variant="h4" component="h4">Scene: {scene.scene_title}</Typography>}
                      { actsList[actIdx].scenes[sceneIdx].songs?.length > 0 && actsList[actIdx].scenes[sceneIdx].songs.map((song, songIdx) => (
                        <>
                          { song.song_name && (
                            <DotAutoFiller>
                              <OriginalTeamName>"{song.song_name}"</OriginalTeamName>
                              <FillerSpan>.....................................................................................................................................................................</FillerSpan>
                              <OriginalTeamRole>{song.cast_member}</OriginalTeamRole>
                            </DotAutoFiller>
                          )}
                        </>
                      ))}
                  </>
                ))}
              </Grid>
            </Grid>
          ))}
      </>
    ) : (
      
      <>
        <TextField
          label="Title"
          placeholder="Type here"
          id="filled-hidden-label-small"
          name="page_title"
          variant="outlined"
          size="small"
          fullWidth
          value={page.data.page_title}
          onChange={handleUpdate}
          className={classes.root}
          mb={2}
        />
        { actsList.map((act, actIdx) => (
          <Grid container key={actIdx}>
            <ActComponent
              actIdx={actIdx}
              act={act} 
              actsList={actsList}
              status="update"
              page={page}
              setNewAct={setNewAct}
              handleSave={handleSaveAct}
              handleAddNewScene={() => handleAddNewScene(actIdx, "update")}
              handleRemove={() => handleRemove("update", actIdx)}
              handleRemoveSongs={(songIdx, sceneIdx, actIdx) => handleRemoveSongs(songIdx, sceneIdx, actIdx)}
              handleRemoveScenes={(sceneIdx, actIdx) => handleRemoveScenes(sceneIdx, actIdx)}
            />
          </Grid>
          ))}

        { addNewAct && (
          <>
            { newAct.map((act, actIdx) => (
              <Grid container key={actIdx}>
                <ActComponent
                  actIdx={actIdx}
                  act={act} 
                  actsList={newAct}
                  status="new"
                  page={page}
                  setNewAct={setNewAct}
                  handleSave={handleSaveAct}
                  handleAddNewScene={() => handleAddNewScene(actIdx, "new")}
                  handleRemove={() => handleRemove("new")}
                />
              </Grid>
            ))}
          </>
        )}

        <Divider my={6} />

        <Grid container justify="flex-start" direction="row" spacing={3}>
          <Grid item>
            <Button 
              align="left" 
              variant="contained" 
              color="primary" 
              aria-label="add-icon"
              onClick={() => handleAddNewAct()} 
              startIcon={<AddIcon fontSize="small" />}
            >
              Add Act
            </Button>
          </Grid>
        </Grid>
      </>
    )}
  </EditorContainer>
  )
}

function PlaybillScenes({production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillScenesContent production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillScenes;
