import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router-dom";
import { addContactAttribute } from "_redux/actions/contactActions";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components/macro";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField as MuiTextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  DialogContentText
} from "@material-ui/core";
import {
  Spacer,
  Alert,
  SaveButtonComponent,
} from "_components/common";

import { spacing } from "@material-ui/system";
import { editContactAttributesService } from "_services/contactService";

const TextField = styled(MuiTextField)(spacing);



export default function EditCustomFieldDialog({ openEditContactDialog, setOpenEditContactDialog, field, orgId }) {
  return (
    <>
      <Dialog
        open={openEditContactDialog}
        onClose={() => setOpenEditContactDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xs"}
      >
        <Box my={3} mx={2}>
        <Box>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h4" gutterBottom display="inline">
              Edit Custom Field
            </Typography>
          </DialogTitle>
        </Box>

        <Formik
          initialValues={{
            id: '',
            field_name: field.attributeName
          }}
          validationSchema={Yup.object().shape({
            field_name: Yup.string().max(30, "Field name must be at most 30 characters").required("Field name is required").matches(/^[^\x00-\x1F\x7F-\xFF'"]*$/, "Please only use ASCII characters."),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await editContactAttributesService(orgId, field.id, values.field_name);
              console.log('response', res);
              setOpenEditContactDialog(false, true, { id: field.id, attributeName: values.field_name });
            } catch (errorResponse) {
              const error = errorResponse.response.data.error;
              const message = error.message || "Something went wrong";
              if (error.fields !== undefined && Object.keys(error.fields).length > 0) {
                setErrors({ field_name: error.fields[values.field_name] })
              } else {
                setErrors({ submit: message });
              }
              setStatus({ success: false, message: '' });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            values,
            status,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {status && status.success && (
                  <Alert mt={2} mb={1} severity="success">
                    {status.message}
                  </Alert>
                )}

                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label={'Field Name'}
                    value={values.field_name}
                    error={errors && Boolean(errors.field_name)}
                    helperText={errors && errors.field_name}
                    name={'field_name'}
                    onChange={handleChange} 
                    style={{ height: '70px' }}
                    autoFocus
                    />
                </FormControl>

              </DialogContent>
              <DialogActions>
                <Box>
                  <Button onClick={() => setOpenEditContactDialog(false)} color="primary" variant="outlined">
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <SaveButtonComponent
                    type="submit"
                    disabled={!isValid || (isSubmitting || (status && status.success))}
                    isLoading={isSubmitting}
                    label={'Save'}
                  />
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
        </Box>
      </Dialog>
    </>
  )
}
