import React, { useState } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";
import { updatePlaybillValue } from '_redux/actions/playbillActions';
import { openDialog, openNotification } from "_redux/actions/appActions";
import {
  Grid,
  Typography as MuiTypography,
  Fab as MuiFab,
  Link as MuiLink,
  Tooltip,
  IconButton,
  TextField as MuiTextField
} from "@material-ui/core";
import {
  CloudUpload as CloudUploadIcon,
  Add as AddIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@material-ui/icons';
import {
  Spacer
} from "_components/common"
import { spacing } from "@material-ui/system";
const Typography = styled(MuiTypography)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Link = styled(MuiLink)(spacing);

const EditorContainer = styled.div`
  min-height: 200px;
`;

const Image = styled.img`
  width: 100%
  border-radius: 4px;
`;

const ImageContainter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #C0C0C0;
  border-radius: 4px;
  padding: 10px;
  background-color: #ffffff;
`;

const ImageLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;  
`;

const UploadedImage = styled.img`
  cursor: pointer;
  height: 50px;
  border: 4px solid #337ab7;
  border-radius: 4px;
`;

const Fab = styled(MuiFab)`
  color: #0d47a1;
  cursor: pointer;
`;

const CloudIcon = styled(CloudUploadIcon)`
  color: #2E3A59;
`

const initialSponsor = {
  image: "",
}

function PlaybillSponsorComponent({ idx, sponsor, mode, gridSize, pageLayout, ...props}) {
  console.log('pageLayout', pageLayout)
  const fullImageStyle = {
    width: '100%',
    height: '500px'
  };

  const twoImagesStyle = {
    width: '100%',
    height: '250px'
  };

  let imageStyle = {};

  if(pageLayout && pageLayout === 'full_image') {
    imageStyle = fullImageStyle;
  } else if(pageLayout === '2_images') {
    imageStyle = twoImagesStyle;
  } 

  return (
    <Grid item xs={gridSize ? gridSize : 6} key={idx} style={{height: '100%'}}>
      <Typography variant="h4">{mode === "new" ? 'New Sponsor' : (idx ? `Sponsor ${idx + 1}` : 'Sponsor')}</Typography>
      <ImageContainter>
        <ImageLabel>
          {sponsor.image ? (
            <>
              <UploadedImage
                onClick={() => props.handleUploadImage(mode, idx)}
                width="100%"
                style={imageStyle}
                src={`https://res.cloudinary.com/onthestage/image/upload/f_auto/${sponsor.image}`}
              />
            </>
          ) :
            <Fab component="span" size="medium" onClick={() => props.handleUploadImage(mode, idx)} >
              <CloudIcon fontSize="small" />
            </Fab>
          }
        </ImageLabel>
        <Typography variant="h6" mb={2}>Click to upload</Typography>
        <Grid container justify="center" direction="row">
          <Grid item>
            <IconButton aria-label="save-icon" onClick={() => props.handleSave()}>
              <Tooltip title="Save">
                <SaveOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete-icon" onClick={() => props.handleRemove(mode, idx)}>
              <Tooltip title="Delete">
                <DeleteOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </ImageContainter>
    </Grid>
  )
}

function PlaybillSponsorsContent({ production, page, showPreview, classes, handleUpdate }) {
  const dispatch = useDispatch();
  const [newSponsor, setNewSponsor] = useState(initialSponsor);
  const [addAnother, setAddAnother] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const sponsorsList = page?.data?.sponsors ? page.data.sponsors : [];

  const handleUploadImage = (mode, updatedIndex) => {
    const cloudinaryOpts = {
      cloud_name: 'onthestage',
      sources: ['local'],
      upload_preset: 'programgenerator',
      resourceType: 'image',
      client_allowed_formats: ['png', 'jpeg', 'jpg'],
      tags: [production.id, production.organization.id, 'programgenerator', process.env.NODE_ENV],
      multiple: false,
      showAdvancedOptions: true,
      folder: production.organization.id + '/' + production.id + '/showgram',
    };

    const cloudinaryWidget = window.cloudinary.openUploadWidget(cloudinaryOpts, async (err, result) => {
      if (err && err.message !== 'User closed widget') {
        cloudinaryWidget.close();
        dispatch(openDialog({
          message: `There was an error uploading your file: ${JSON.stringify(err.status)}`,
        }));
      } else if (err && err.message === 'User closed widget') {
        return false;
      }
      if (result.event === 'success') {
        const image = result.info;
        try {
          if (mode === "update") {
            const updatedSponsors = sponsorsList.map((sponsor, index) => {
              if (index === updatedIndex) {
                return { ...sponsor, image: image.public_id };
              }
              return sponsor;
            });
    
            dispatch(updatePlaybillValue({
              key: "sponsors",
              value: updatedSponsors,
              page: page
            }));
          } else if (mode === "new") {
            const newSponsorToAdd = { ...newSponsor, image: image.public_id };
            const updatedSponsors = [...sponsorsList, newSponsorToAdd];
            dispatch(updatePlaybillValue({
              key: "sponsors",
              value: updatedSponsors,
              page: page
            }));
          }
        } catch (err) {
          dispatch(openNotification({
            message: "Cannot upload image!",
          }))
        }
        cloudinaryWidget.close();
        return;
      }
    });
  };

  const handleSave = () => {
    setIsSaving(true);
    if (sponsorsList.length <= 0 || addAnother) {
      const newSponsors = [...page.data.sponsors, newSponsor]
      dispatch(updatePlaybillValue({
        key: "sponsors",
        value: newSponsors,
        page: page
      }));
      setAddAnother(false);
    }
    setIsSaving(false);
  }

  const handleRemove = async (mode, idx) => {
    if (mode === "new") {
      setNewSponsor(newSponsor);
      setAddAnother(false);
    } else {
      try {
        sponsorsList.splice(idx, 1);
        dispatch(updatePlaybillValue({ key: "sponsors", value: [...sponsorsList], page: page }));
        setNewSponsor({ image: "" });
      } catch (err) {
        dispatch(openNotification({
          message: err.response?.data || err.message || "Something went wrong!",
        }))
      }
    }
  }

  function getGridSize(pageLayout) {
    const gridMapping = {
      'full_image': 12, 
      '2_images': 12,   
      '4_images': 6,   
      '8_images': 6, 
    };
    return gridMapping[pageLayout] || 6; 
  }
  
  function getNumberOfSponsorsToRender(pageLayout, sponsorsLength) {
    const layoutMapping = {
      'full_image': 1,
      '2_images': 2,
      '4_images': 4,
      '6_images': 6,
      '8_images': 8,
    };

    if(!pageLayout && sponsorsLength === 0) {
      return 1
    } else {
      return layoutMapping[pageLayout] || sponsorsLength;
    }
  }

  const getImageSize = (layout) => {
    switch (layout) {
      case 'full_image':
        return { width: '100%',  marginTop: '10%', maxHeight: '750px'  }; 
      case '2_images':
        return { width: '100%', height: '420px',  maxHeight: '380px', marginTop: '25px' }; 
      case '4_images':
        return { width: '100%', height: 200, maxHeight: '180px', marginTop: '15%', marginBottom: '50%'  }; 
      case '8_images' :
        return { width: '100%', maxHeight: '150px', marginTop: '15%' }; 
      default:
        return { width: 200, height: 200 };
    }
  };

  const applyStyleToContainer = () => {
    if(page.page_layout === 'full_image' || '2_images') {
      return {
        textAlign: 'center'
      }
    }
  }

  const handleAdd = async () => {
    if (!page.page_layout) {
      await handleSave();
      setAddAnother(true);
      setNewSponsor(initialSponsor);
    }
  };

  return (
    <EditorContainer showPreview={showPreview}>
    {showPreview ? (
      <>
        <Typography component="h1" variant="h1" mt={4} mb={6}>{page.data.page_title}</Typography>
        <Grid container justify="flex-start" direction="row" style={applyStyleToContainer()}>
          {sponsorsList.slice(0, getNumberOfSponsorsToRender(page.page_layout, sponsorsList.length)).map((sponsor, index) => {
            const imageSize = getImageSize(page.page_layout);
            return (
              <Grid item xs={getGridSize(page.page_layout)} key={index} style={{ height: '50%' }}>
                {sponsor.image && (
                  <Image 
                    src={`https://res.cloudinary.com/onthestage/image/upload/f_auto,b_white,c_pad/${sponsor.image}`} 
                    style={{ maxWidth: imageSize.width, maxHeight: imageSize.maxHeight, marginTop: imageSize.marginTop, marginBottom: imageSize.marginBottom }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </>
    ) : (
          <Grid container justify="flex-start" alignContent="center" direction="row">
            <TextField
              label="Title"
              placeholder="Type here"
              id="filled-hidden-label-small"
              name="page_title"
              variant="outlined"
              size="small"
              fullWidth
              value={page.data.page_title}
              onChange={handleUpdate}
              className={classes.root}
              mb={4}
              mt={4}
          />
          {Array.from({ length: getNumberOfSponsorsToRender(page.page_layout, sponsorsList.length) }, (_, idx) => {
            const sponsor = sponsorsList[idx];
            return (
              <PlaybillSponsorComponent
                key={sponsor ? sponsor.id : `new-${idx}`}
                idx={sponsor ? sponsor.id : `new-${idx}`}
                sponsor={sponsor || newSponsor}
                mode={sponsor ? "update" : "new"}
                gridSize={getGridSize(page.page_layout)}
                handleUploadImage={() => handleUploadImage(sponsor ? "update" : "new", idx)}
                handleSave={handleSave}
                handleRemove={() => handleRemove(sponsor ? "update" : "new", idx)}
                pageLayout={page.page_layout}
              />
            );
          })}
          <Spacer my={6} />
          {!page.page_layout && addAnother && (
            <PlaybillSponsorComponent
              idx="new"
              sponsor={newSponsor}
              mode="new"
              gridSize={getGridSize(6)}
              handleUploadImage={() => handleUploadImage("new", "new")}
              handleSave={handleSave}
              handleRemove={() => handleRemove("new", "new")}
            />
          )}

          {!page.page_layout && (
            <Grid container alignContent="center" alignItems="center" style={{ marginTop: 10 }}>
              <Grid item>
                <AddIcon color="primary" fontSize="small" />
              </Grid>
              <Grid item>
                <Link component="button" variant="body2" underline="none" color="primary" mb={1} onClick={handleAdd}>
                  <b>Add another</b>
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </EditorContainer>
  );
}

function PlaybillSponsors({ production, page, showPreview, classes, handleUpdate }) {
  return <PlaybillSponsorsContent production={production} page={page} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate} />
}
export default PlaybillSponsors;
