import React from "react";

import async from "_components/Async";
import { NavLink } from "react-router-dom";

import {
  Gift,
  Film,
  ArrowLeft,
  Layout,
  Home,
  Monitor,
  Settings as SettingsIcon,
  ShoppingCart,
  PieChart,
  DollarSign,
  Sliders,
  Plus,
  Link as LinkIcon,
  Users,
  UserPlus,
  Send,
  Video,
  Circle,
  ShoppingBag,
  Youtube,
  Calendar as CalendarIcon,
  Award,
  FilePlus,
  Printer
} from "react-feather";
import {
  EventSeatOutlined as EventSeat,
  PaletteOutlined as Palette,
  WallpaperOutlined as Wallpaper,
  LocalPlayOutlined as LocalPlay,
  FeedbackOutlined as Feedback,
  EventOutlined as Event,
  AttachMoneyOutlined as Money,
  Subscriptions as SubscriptionsIcon,
  LocalOffer as SellIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
} from "@material-ui/icons";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Assessment from '@material-ui/icons/Assessment';
import LayersIcon from '@material-ui/icons/Layers';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';

import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import EditPageLayout from "_pages/playbilleditor/PlaybillEditorPageLayout";
import IntegrationsHub from "_pages/settings/IntegrationsHub";
import CustomFields from "_pages/settings/CustomFields";
import Offers from "_pages/pages/Offers/Offers";
import SubscriptionViewPage from "_pages/subscriptions/SubscriptionViewPage";
import ItemCheckInListPage from "_pages/walkup/ItemCheckInList";
// Guards
const AdminGuard = async(() => import("_components/AdminGuard"));

// Auth components
const SignIn = async(() => import("_pages/auth/SignIn"));
const SignUp = async(() => import("_pages/auth/SignUp"));
const ResetPassword = async(() => import("_pages/auth/ResetPassword"));
const CreatePassword = async(() => import("_pages/auth/CreatePassword"));
const Page404 = async(() => import("_pages/auth/Page404"));
const Page500 = async(() => import("_pages/auth/Page500"));
const MagicLink = async(() => import("_pages/auth/MagicLink"));

// Dashboards components
const Default = async(() => {
  const dashboardVariant = localStorage.getItem('defaultDashboard') || 'core';
  console.log('variant', dashboardVariant)
  if (dashboardVariant === 'fund') {
    return import("_pages/dashboards/Fund");
  }
  return import("_pages/dashboards/Default")
});

const FundDefault = async(() => import("_pages/dashboards/Fund"));
const EnterpriseDashboard = async(() => import("_pages/dashboards/Enterprise"));

// Pages components
const CreateNewProduction = async(() => import("_pages/pages/CreateNewProduction"));
const MyLiveProductionList = async(() => import("_pages/myproductions/MyLiveProductionList"));
const MyDraftProductionList = async(() => import("_pages/myproductions/MyDraftProductionList"));
const MyPastProductionList = async(() => import("_pages/myproductions/MyPastProductionList"));
const SubscriptionList = async(() => import("_pages/pages/Subscriptions"));
const PromoCodeList = async(() => import("_pages/pages/PromoCodes"));
const TicketTypes = async(() => import("_pages/pages/TicketTypes"));
const CreatePromoCode = async(() => import("_pages/pages/CreatePromoCode"));
const CreateUser = async(() => import("_pages/pages/CreateUser"));
const AccountAccess = async(() => import("_pages/pages/AccountAccess"));
const Orders = async(() => import("_pages/myproductions/Orders"));
const OrderDetailsPage = async(() => import("_pages/myproductions/OrderDetailsPage"));
const ExchangeTickets = async(() => import("_pages/myproductions/ExchangeTickets"));
const MyPayouts = async(() => import("_pages/pages/MyPayouts"));
const MyReports = async(() => import("_pages/pages/MyReports"));
const CustomFees = async(() => import("_pages/settings/CustomFees"));
const Venues = async(() => import("_pages/settings/Venues"));
const SocialMedia = async(() => import("_pages/settings/SocialMedia"));
const HardTickets = async(() => import("_pages/pages/HardTickets"));
const ContactDetails = async(() => import("_pages/settings/ContactDetails"));
const PasswordPage = async(() => import("_pages/settings/PasswordPage"));
const Billing = async(() => import("_pages/settings/Billing"));
const StripeConnect = async(() => import("_pages/settings/StripeConnect"));
const ClientApiManagement = async(() => import("_pages/settings/ClientApiManagement"));
const CreditCardReader = async(() => import("_pages/settings/CreditCardReader"))
const Notifications = async(() => import("_pages/pages/Notifications"));
const AddChildOrg = async(() => import("_pages/pages/AddChildOrg"));
const TicketAddendum = async(() => import("_pages/pages/TicketAddendum"));
const OTSWidget = async(() => import("_pages/settings/OTSWidget"));
const Productions = async(() => import("_pages/pages/enterprise/Productions"));
const CreateProductions = async(() => import("_pages/pages/enterprise/CreateProductions"));
const EditProductions = async(() => import("_pages/pages/enterprise/EditProductions"));
const AddOns = async(() => import("_pages/pages/AddOns/AddOns"))
const CustomFlows = async(() => import("_pages/pages/CustomFlows/CustomFlows"));
const ReportingDashboard = async(() => import("_pages/pages/ReportingDashboard/ReportingDashboard"))
const Communications = async(() => import("_pages/settings/communications"));

const EmailSettings = async(() => import("_pages/settings/EmailSettings/EmailSettings"));

// Admin Pages
const Calendar = async(() => import("_pages/adminpages/Calendar"));

//Production Routes
const PrintDigitalPackage = async(() => import("_pages/production/PrintDigitalPackage"));
const PromotePage = async(() => import("_pages/production/Promote"));
const PromoteEdit = async(() => import("_pages/production/PromoteEdit"));
const VideoOnDemandPage = async(() => import("_pages/production/VideoOnDemand"));
const LivestreamSettingsPage = async(() => import("_pages/production/LivestreamSettings"));
const ZoomSettingsPage = async(() => import("_pages/production/ZoomSettings"));
const MerchandisePage = async(() => import("_pages/production/Merchandise/index"));
const TicketingPage = async(() => import("_pages/production/TicketingPage"));
const ShowDetailsPage = async(() => import("_pages/production/ShowDetails"));
const SeatingChartPage = async(() => import("_pages/production/SeatingChartPage"));
const EventSeatingChartPage = async(() => import("_pages/events/EventSeatingChartPage"));
const SeatingChartBuilder = async(() => import("_pages/production/SeatingChartBuilderPage"));
const ProductionOrders = async(() => import("_pages/production/ProductionOrders"));
const ProductionOrderDetails = async(() => import("_pages/production/ProductionOrderDetails"));
const ProductionExchangeTickets = async(() => import("_pages/production/ProductionExchangeTickets"));

// Landing
const Landing = async(() => import("_pages/presentation/Landing"));

// Website Editor Pages
const EditorHome = async(() => import("_pages/websiteeditor/EditorHome"));
const EditorTickets = async(() => import("_pages/websiteeditor/EditorTickets"));
const EditorAbout = async(() => import("_pages/websiteeditor/EditorAbout"));
const EditorCast = async(() => import("_pages/websiteeditor/EditorCast"));
const EditorCreative = async(() => import("_pages/websiteeditor/EditorCreative"));
const EditorDonate = async(() => import("_pages/websiteeditor/EditorDonate"));
const EditorShop = async(() => import("_pages/websiteeditor/EditorShop"));



// Website Editor Pages
const OrgEditorHome = async(() => import("_pages/orgsiteeditor/OrgEditorHome"));
const OrgEditorAbout = async(() => import("_pages/orgsiteeditor/OrgEditorAbout"));
const OrgEditorMerchandise = async(() => import("_pages/orgsiteeditor/OrgEditorMerchandise"));
const OrgEditorSeason = async(() => import("_pages/orgsiteeditor/OrgEditorSeason"));
const OrgEditorTickets = async(() => import("_pages/orgsiteeditor/OrgEditorTickets"));
const OrgEditorDonate = async(() => import("_pages/orgsiteeditor/OrgEditorDonate"));


//Fund Pages
const FundContactsPage = async(() => import("_pages/fund/FundContactsPage"));
const ContactDetailPage = async(() => import("_pages/fund/CRM/ContactDetailPage"));
const FundAffiliatesPage = async(() => import("_pages/fund/FundAffiliatesPage"));
const FundReportsPage = async(() => import("_pages/fund/FundReportsPage"));
const CreateAffiliate = async(() => import("_pages/fund/CreateAffiliate"));
const FundDonationsPage = async(() => import("_pages/fund/FundDonationsPage"));
const FundIncentivesPage = async(() => import("_pages/fund/FundIncentivesPage"));
const FundCampaignsPage = async(() => import("_pages/fund/FundCampaignsPage"));
const FundCampaignDetailPage = async(() => import("_pages/fund/FundCampaignDetailPage"));
const FundAccountPage = async(() => import("_pages/fund/FundAccountPage"));
const FundCampaignPromotePage = async(() => import("_pages/fund/MyCampaigns/CampaignPromoteSchedule"));


//SubscriptionsPages
const SubscriptionsListPage = async(() => import("_pages/subscriptions/SubscriptionsListPage"));
const SubscriptionFormPage = async(() => import("_pages/subscriptions/SubscriptionFormPage"));

//New SubscriptionsPages
const SubscriptionsListPageNew = async(() => import("_pages/subscriptions/SubscriptionsListPageNew"));
const SubscriptionFormPageNew = async(() => import("_pages/subscriptions/SubscriptionFormPageNew"));

//WalkupPage
const WalkupPage = async(() => import("_pages/walkup/WalkupPage"));
const WalkupOrders = async(() => import("_pages/walkup/WalkupOrders"));
const WalkupReader = async(() => import("_pages/walkup/WalkupReader"));
const WalkupCheckInList = async(() => import("_pages/walkup/WalkupCheckInList"));

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/",
      name: "Default",
      component: Default,
      enterprise_component: EnterpriseDashboard,
    },
  ],
  component: null,
};

const addChildOrgRoutes = {
  id: "Add Child Org",
  path: "/add-child-org",
  icon: <Plus />,
  component: AddChildOrg,
  children: null,
};

const ticketAddendumRoutes = {
  id: "Ticket Addendum",
  path: "/ticket-addendum",
  icon: <Plus />,
  component: TicketAddendum,
  children: null,
};

const dashboard = {
  id: "Dashboard",
  path: "/",
  icon: <Home />,
  component: Default,
  hideForLegacy: true,
  children: null,
};

const createnewRoutes = {
  id: "Create New",
  path: "/create-new",
  icon: <Plus />,
  component: CreateNewProduction,
  children: null,
  hideForLegacy: true,
  roles: ['manager', 'box_office', 'website_creator'],
};

const myproductionsRoutes = {
  id: "Productions",
  path: "/my-productions",
  icon: <Film />,
  hideForLegacy: true,
  children: [
    {
      path: "/productions/live",
      name: "Live",
      component: MyLiveProductionList,
    },
    {
      path: "/productions/draft",
      name: "Draft",
      component: MyDraftProductionList,
    },
    {
      path: "/productions/past",
      name: "Past",
      component: MyPastProductionList,
    },
  ],
  component: null,
};

const productionsRoutes = {
  id: "Productions",
  path: "/productions",
  icon: <Film />,
  component: Productions,
  hideForLegacy: true,
  children: null,
  roles: ['manager', 'box_office'],
  requires: 'is_enterprise',
};

const createProductionsRoute = {
  id: "Create Productions",
  path: "/productions/create",
  icon: <UserPlus />,
  component: CreateProductions,
  children: null,
  roles: ['manager', 'box_office'],
}

const editProductionsRoute = {
  id: "Edit Productions",
  path: "/productions/edit/:id",
  icon: <UserPlus />,
  component: EditProductions,
  children: null,
  roles: ['manager', 'box_office'],
}

// const subscriptionsRoutes = {
//   id: "Subscriptions",
//   path: "/subscriptions",
//   icon: <Package />,
//   component: SubscriptionList,
//   children: null,
// };

const promocodesRoutes = {
  id: "Promo Codes",
  path: "/promo-codes",
  icon: <Gift />,
  hideForLegacy: true,
  component: PromoCodeList,
  children: null,
  roles: ['manager', 'box_office']
};

const ticketTypesRoutes = {
  id: "Ticket Types",
  path: "/ticket-types",
  icon: <LayersIcon />,
  component: TicketTypes,
  children: null,
  requires: 'is_enterprise'
};


const createpromocodeRoute = {
  id: "Create Promo Codes",
  path: "/promo-codes/create",
  icon: <Gift />,
  component: CreatePromoCode,
  children: null,
  roles: ['manager', 'box_office']
}

const editpromocodeRoute = {
  id: "Edit Promo Codes",
  path: "/promo-codes/edit/:id",
  icon: <Gift />, // You can use a different icon if you wish
  component: CreatePromoCode, // We reuse the CreatePromoCode component for editing
  children: null,
  roles: ['manager', 'box_office']
}
const customFlowsRoutes = {
  id: "Filtered Flows",
  path: "/filtered-flows",
  icon: <AccountTreeOutlinedIcon />,
  component: CustomFlows,
  children: null,
  roles: ['manager', 'box_office'],
  requires: "allow_custom_flows",
};

const promotesRoutes = {
  id: "Promote",
  path: "/productions/promote",
  to: "promote",
  hideStatus: "draft",
  icon: <Send />,
  component: PromotePage,
  roles: ['manager', 'box_office', 'website_creator'],
  hideForLegacy: true,
};

const accountaccessRoutes = {
  id: "Users",
  path: "/account-access",
  icon: <UserPlus />,
  component: AccountAccess,
  children: null,
  roles: ['manager', 'box_office'],
};

const createuserRoute = {
  id: "Create User",
  path: "/account-access/create",
  icon: <UserPlus />,
  component: CreateUser,
  children: null,
  roles: ['manager', 'box_office'],
}

const ordersRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  hideForLegacy: true,
  children: null,
  roles: ['manager', 'box_office', 'ticket_scanner'],
};

const hardTicketsRoutes = {
  id: "Hard Tickets",
  path: "/hard-tickets",
  icon: <ConfirmationNumberIcon />,
  component: HardTickets,
  children: null,
  hideForLegacy: true,
  requires: "allow_hard_tickets",
};

const orderDetailsRoutes = {
  path: "/orders/:orderId",
  id: "Order Details",
  icon: <Users />,
  component: OrderDetailsPage,
  children: null,
  roles: ['manager', 'box_office'],
}

const exchangeTicketsRoutes = {
  path: "/orders/:orderId/exchange",
  id: "Exchange Tickets",
  icon: <Users />,
  component: ExchangeTickets,
  children: null,
}

const myorgsiteRoutes = {
  id: "Org Site",
  icon: <Layout />,
  path: `/orgsite/home`,
  external: true,
  hideForLegacy: true,
  roles: ['manager', 'box_office', 'website_creator'],
};

const subscriptionRoutes = {
  id: "Subscriptions",
  icon: <SubscriptionsIcon />,
  path: `/subscriptions`,
  component: SubscriptionsListPage,
  children: null, 
  hideForLegacy: true, 
  roles: ['manager', 'box_office'],
};

const subscriptionNewRoutes = {
  id: "Subscriptions-new",
  icon: <SubscriptionsIcon />,
  path: `/subscriptions-new`,
  component: SubscriptionsListPageNew,
  children: null, 
  hideForLegacy: true, 
  roles: ['manager', 'box_office'],
  requires: "allow_new_subscriptions_page",
};


export const walkupRoutes = [  {
  id: "Back to Dashboard",
  link: "/",
  path: "/",
  name: "Default",
  icon: <ArrowLeft />,
  component: NavLink,
},{
  id: "Walk Up Sales",
  icon: <Layout />,
  path: `/walkup`,
  roles: ['manager', 'box_office', 'ticket_seller'],
  children: [
    {
      path: "/walkup/:eventId",
      name: "Purchase",
      to: "",
      component: WalkupPage,
    },
    {
      path: "/walkup/:eventId/orders",
      name: "All Orders",
      to: "orders",
      component: WalkupOrders,
    },
    // {
    //   path: "/walkup/:eventId/check-in-report",
    //   name: "Check-In Report",
    //   to: "check-in-report",
    //   component: '<div>todo</div>',
    // },
    {
      path: "/walkup/:eventId/check-in-list",
      name: "Check-In List",
      to: "check-in-list",
      component: WalkupCheckInList,
    },
    {
      path: "/walkup/:eventId/item-list",
      name: "Item List",
      to: "item-list",
      component: ItemCheckInListPage,
      requires: "allow_addons",    
    },    
    {
      path: "/walkup/:eventId/orders/:orderId",
      name: "",
      to: "",
      component: OrderDetailsPage,
    },
    {
      path: "/walkup/:eventId/orders/:orderId/exchange",
      name: "",
      to: "",
      component: ExchangeTickets,
    }
  ],
  component: null,  
}];


const subscriptionCreateRoute = {
  id: "Subscription",
  path: `/subscriptions/create`,
  icon: <ShoppingCart />,
  component: SubscriptionFormPage,
  roles: ['manager'],
  children: null,  
};

const subscriptionNewCreateRoute = {
  id: "Subscription-new",
  path: `/subscriptions-new/create`,
  icon: <ShoppingCart />,
  component: SubscriptionFormPageNew,
  roles: ['manager'],
  children: null,  
  requires: "allow_new_subscriptions_page",
};

const subscriptionEditRoute = {
  id: "Subscription",
  path: `/subscriptions/:id`,
  icon: <ShoppingCart />,
  component: SubscriptionFormPage,
  roles: ['manager'],
  children: null,  
};

const subscriptionNewEditRoute = {
  id: "Subscription-new",
  path: `/subscriptions-new/:id`,
  icon: <ShoppingCart />,
  component: SubscriptionFormPageNew,
  roles: ['manager'],
  children: null,
  requires: "allow_new_subscriptions_page",
};

const subscriptionViewRoute = {
  id: "Subscription",
  path: `/subscriptions/:id/subscribers`,
  icon: <ShoppingCart />,
  component: SubscriptionViewPage,
  roles: ["manager"],
  children: null,
};

const subscriptionNewViewRoute = {
  id: "Subscription-new",
  path: `/subscriptions-new/:id/subscribers`,
  icon: <ShoppingCart />,
  component: SubscriptionViewPage,
  roles: ["manager"],
  children: null,
  requires: "allow_new_subscriptions_page",
};


const mypayoutsRoutes = {
  id: "Payouts",
  path: "/payouts",
  icon: <DollarSign />,
  component: MyPayouts,
  children: null,
  hideForLegacy: true,
  roles: ['manager', 'box_office'],
};

const addonsRoute = {
  id: "Add-Ons and Merch",
  path: "/add-ons",
  icon: <AddShoppingCartIcon />,
  component: AddOns,
  children: null,
  hideForLegacy: true,
  roles: ['manager', 'box_office', 'website_creator'],
  requires: "allow_addons",
};

const offersRoute = {
  id: "Offers",
  path: "/offers",
  icon: <SellIcon />,
  component: Offers,
  children: null,
  roles: ['manager'],
  requires: 'allow_offers',
};

const myreportsRoutes = {
  id: "Reports",
  path: "/reports",
  icon: <PieChart />,
  component: MyReports,
  hideForLegacy: true,
  children: null,
  roles: ['manager', 'box_office'],
};

const reportingDashboardRoute = {
  id: "Reporting Dashboard",
  path: "/reporting-dashboard",
  icon: <Assessment />,
  component: ReportingDashboard,
  children: null,
  roles: ['manager', 'box_office'],
  hideForLegacy: true,
  requires: 'allow_reporting',
};

const notificationsRoutes = {
  id: "Notificaitons",
  path: "/notifications",
  icon: <Users />,
  component: Notifications,
  children: null,
};

const mycontactsRoutes = {
  id: "Contacts",
  path: "/contacts",
  icon: <Users />,
  component: FundContactsPage,
  hideForLegacy: true,
  children: null,
  roles: ['manager', 'box_office'],
};

const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: <SettingsIcon />,
  component: null,
  roles: ['manager', 'box_office'],
  children: [
    {
      path: "/settings/email-settings",
      name: "Email Settings",
      component: EmailSettings,
      hideForLegacy: true,
    },
    {
      path: "/settings/widget",
      name: "Widget Configuration",
      component: OTSWidget,
      roles: ['manager'],
      plans: ['platinum'],
      requires: 'allow_ots_widget',
    },
    {
      path: "/settings/surveys",
      name: "Surveys",
      component: Communications,
      plans: ['platinum'],
      requires: 'allow_surveys',
      hideForLegacy: true,
    },
    {
      path: "/settings/billing",
      name: "Plan & Billing",
      component: Billing,
      roles: ['manager'],
      hideForLegacy: true,
    },
    {
      path: "/settings/custom-fees",
      name: "Custom Fees",
      component: CustomFees,
      plans: ['platinum'],
      requires: 'allow_custom_fees',
      hideForLegacy: true,
    },
    {
      path: "/settings/venues",
      name: "Venues",
      component: Venues,
      hideForLegacy: true,
    },
    {
      path: "/settings/donations",
      id: "Donations",
      name: "Donations",
      component: FundDonationsPage,
    },
    {
      path: "/settings/social-media",
      name: "Social Media",
      component: SocialMedia,
      hideForLegacy: true,
    },
    {
      path: "/settings/integrations-hub",
      name: "Integrations Hub",
      component: IntegrationsHub,
      requires: 'allow_integrations_hub',
      hideForLegacy: false,
    },
    {
      path: "/settings/contact-details",
      name: "Contact Details",
      component: ContactDetails,
      hideForLegacy: true,
    },
    {
      path: "/settings/custom-fields",
      name: "Custom Fields",
      component: CustomFields,
      hideForLegacy: true,
    },
    {
      path: "/settings/password",
      name: "Password",
      component: PasswordPage,
      hideForLegacy: true,
    },
    {
      path: "/settings/stripe-connect",
      name: "Stripe Connect",
      hideSidebar: true,
      component: StripeConnect,
      roles: ['manager'],
      hideForLegacy: true,
    },
    {
      path: "/settings/credit-card-reader",
      name: "Credit Card Reader",
      hideSidebar: true,
      component: CreditCardReader,
      requires: 'allow_stripe_terminal_reader',
      hideForLegacy: true,
    },

    {
      path: "/settings/client-api-management",
      name: "Client Api Management",
      hideSidebar: true,
      component: ClientApiManagement,
      roles: ['manager'],
      requires: 'allow_partner_api',
      hideForLegacy: true,
    },
  ],
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/magic-link",
      name: "Magic Link",
      component: MagicLink,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
    {
      path: "/auth/create-password",
      name: "CreatePassword",
      component: CreatePassword,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/landing",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const calendarRoutes = {
  id: "Shows Calendar",
  path: "/admin/calendar",
  component: Calendar,
  children: null,
  guard: AdminGuard,
};

export const orgSiteEditorRoutes = [
  {
    id: "Back to Dashboard",
    link: "/",
    path: "/",
    name: "Default",
    icon: <ArrowLeft />,
    component: NavLink,
  },
  // {
  //   path: "/orgsite/design",
  //   to: "design",
  //   name: "Design",
  //   icon: <Wallpaper />, 
  //   id: "Design",
  //   component: OrgEditorHome,
  // },
  {
    path: "/orgsite/colors",
    to: "colors",
    name: "Colors",
    icon: <Palette />,
    id: "Colors",
    component: OrgEditorHome,
  },
  {
    path: "/orgsite/home",
    to: "home",
    name: "Home",
    id: "Home",
    icon: <Home />,
    component: OrgEditorHome,
  },
  {
    path: "/orgsite/about",
    to: "about",
    name: "About",
    id: "About",
    icon: <Feedback />,
    component: OrgEditorAbout,
  },
  {
    path: "/orgsite/donate",
    to: "donate",
    name: "Donate",
    id: "Donate",
    icon: <Money />,
    component: OrgEditorDonate,
  },
  {
    path: "/orgsite/merchandise",
    to: "merchandise",
    name: "Merchandise",
    id: "Merchandise",
    icon: <ShoppingBag />,
    component: OrgEditorMerchandise,
    requires: "allow_addons",
  },
  {
    path: "/orgsite/season",
    to: "season",
    name: "Season",
    id: "Season",
    icon: <Event />,
    component: OrgEditorSeason,
  },
  {
    path: "/orgsite/tickets",
    to: "tickets",
    name: "Tickets",
    id: "Tickets",
    icon: <LocalPlay />,
    component: OrgEditorTickets,
  }
];

export const websiteEditorRoutes = [
  {
    id: "Back to Dashboard",
    link: "/",
    path: "/",
    name: "Default",
    icon: <ArrowLeft />,
    component: NavLink,
  },
  {
    path: "/productions/:id",
    to: "",
    id: "Show Details",
    icon: <Film />,
    component: ShowDetailsPage,
  },
  {
    path: "/productions/:id/seating-chart",
    to: "seating-chart",
    id: "Seating Chart",
    productionType: 'reserved',
    icon: <EventSeat />, 
    component: SeatingChartPage,
    roles: ['manager', 'box_office', 'website_creator'],
  },
  {
    path: "/productions/:id/seating-chart/:chartId",
    to: "seating-chart",
    id: "Seating Chart",
    productionType: 'reserved',
    icon: <EventSeat />,
    hideSidebar: true,
    component: SeatingChartBuilder,
    roles: ['manager', 'box_office', 'website_creator'],
  },  
  {
    id: "Video On Demand",
    path: "/productions/:id/video-on-demand",
    to: "video-on-demand",
    productionType: 'vod',
    icon: <Youtube />,
    component: VideoOnDemandPage,
    roles: ['manager', 'box_office', 'website_creator'],
  }, {
    id: "Livestream Settings",
    path: "/productions/:id/livestream-settings",
    to: "livestream-settings",
    livestream_only: true,
    hideStatus: "draft",
    icon: <Video />,
    component: LivestreamSettingsPage,
    roles: ['manager', 'box_office', 'website_creator'],
  }, {
    id: "Zoom Settings",
    path: "/productions/:id/virtual-settings",
    to: "virtual-settings",
    productionType: 'virtual',
    icon: <Video />,
    component: ZoomSettingsPage,
    roles: ['manager', 'box_office', 'website_creator'],
  },
  {
    path: "/productions/:id/ticketing",
    to: "ticketing",
    id: "Ticketing",
    hideSidebar: true,
    icon: <DollarSign />,
    component: TicketingPage,
    roles: ['manager', 'box_office', 'website_creator'],
  },
  {
    path: "/productions/:id/orders",
    to: "orders",
    hideStatus: "draft",
    id: "Orders",
    icon: <ShoppingCart />,
    component: ProductionOrders,
    roles: ['manager', 'box_office'],
  },
  {
    path: "/productions/:id/orders/:orderId",
    to: "orders",
    hideStatus: "draft",
    hideSidebar: true,
    id: "Orders",
    icon: <ShoppingCart />,
    component: ProductionOrderDetails,
    roles: ['manager', 'box_office'],
  },
  {
    path: "/productions/:id/orders/:orderId/exchange",
    to: "orders",
    hideSidebar: true,
    hideStatus: "draft",
    id: "Orders",
    icon: <ShoppingCart />,
    component: ProductionExchangeTickets,
    roles: ['manager', 'box_office'],
  },
  {
    id: "Website",
    path: "/productions/:id/website-editor",
    to: "website-editor",
    icon: <Layout />,
    roles: ['manager', 'box_office', 'website_creator'],
    children: [
      {
        path: "/productions/:id/website-editor/design",
        to: "design",
        name: "Design",
        component: EditorHome,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/colors",
        to: "colors",
        name: "Colors",
        component: EditorHome,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/home",
        to: "home",
        name: "Home",
        component: EditorHome,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/tickets",
        to: "tickets",
        name: "Tickets",
        component: EditorTickets,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/donate",
        to: "donate",
        name: "Donate",
        component: EditorDonate,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/about",
        to: "about",
        name: "About",
        component: EditorAbout,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/cast",
        to: "cast",
        name: "Cast",
        component: EditorCast,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/crew",
        to: "crew",
        name: "Creative",
        component: EditorCreative,
        roles: ['manager', 'box_office', 'website_creator'],
      },
      {
        path: "/productions/:id/website-editor/shop",
        to: "shop",
        name: "Shop",
        component: EditorShop,
        roles: ['manager', 'box_office', 'website_creator'],
      },
    ],
    component: null,
  },
  // ,{
  //   id: "Website Address",
  //   path: "/production/:id/website-address",
  //   to: "website-address",
  //   icon: <Film />, 
  //   component: null,
  // },
  // {
  //   id: "Social Media",
  //   path: "/social-media/productions/:id",
  //   icon: <Film />, 
  //   component: null,
  // },
  {
    id: "Showgram",
    path: "/productions/:id/showgram",
    to: "showgram",
    name: "Showgram",
    icon: <FilePlus />,
    component: EditPageLayout,
    requires: 'allow_program_generator',
    roles: ['manager', 'box_office', 'website_creator'],
  },  
  // {
  //   id: "Merchandise",
  //   path: "/productions/:id/merchandise",
  //   to: "merchandise",
  //   icon: <ShoppingBag />,
  //   component: MerchandisePage,
  //   roles: ['manager', 'box_office', 'website_creator'],
  //   requires: "allow_legacy_merchandise",
  // }, 
  {
    id: "Add-Ons and Merch",
    path: "/add-ons",
    name: 'Add-Ons',
    link: '/add-ons',
    icon: <AddShoppingCartIcon />,
    component: AddOns,
    roles: ['manager', 'box_office', 'website_creator'],
    requires: "allow_addons",
  }, 
  {
    id: "Print/Digital Package",
    path: "/productions/:id/promote",
    to: "promote",
    hideStatus: "draft",
    icon: <Send />,
    component: PrintDigitalPackage,
    roles: ['manager', 'box_office', 'website_creator'],
  },
  {
    path: "/productions/:id/event-seating-chart/:eventId",
    to: "event-seating-chart",
    id: "Seating Chart",
    icon: <Circle />,
    hideSidebar: true,
    component: EventSeatingChartPage,
    roles: ['manager', 'box_office', 'website_creator'],
  },
];

const ContactDetailRoute = {
  path: "/contacts/:contactId",
  id: "Contact Detail",
  to: 'contact',
  icon: null,
  hideSidebar: true,
  component: ContactDetailPage,
  children: null,
};

const promoteEditRoute = {
  path: "/productions/:productionId/promote/:id",
  icon: null,
  component: PromoteEdit,
  children: null,
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  createnewRoutes,
  myproductionsRoutes,
  // subscriptionsRoutes,
  promocodesRoutes,
  createpromocodeRoute,
  accountaccessRoutes,
  createuserRoute,
  ordersRoutes,
  hardTicketsRoutes,
  mycontactsRoutes,
  orderDetailsRoutes,
  subscriptionRoutes,
  subscriptionCreateRoute,
  subscriptionEditRoute,
  subscriptionViewRoute,
  subscriptionNewRoutes,
  subscriptionNewCreateRoute,
  subscriptionNewEditRoute,
  subscriptionNewViewRoute,
  exchangeTicketsRoutes,
  myorgsiteRoutes,
  mypayoutsRoutes,
  calendarRoutes,
  myreportsRoutes,
  settingsRoutes,
  notificationsRoutes,
  addChildOrgRoutes,
  ticketAddendumRoutes,
  ContactDetailRoute,
  productionsRoutes,
  createProductionsRoute,
  editProductionsRoute,
  ticketTypesRoutes,
  addonsRoute,
  offersRoute,
  reportingDashboardRoute,
  editpromocodeRoute,
  customFlowsRoutes,
  promotesRoutes,
  promoteEditRoute
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in OTS Core sidebar
export const sidebarRoutes = [
  dashboard,
  createnewRoutes,
  productionsRoutes,
  myproductionsRoutes,
  // subscriptionsRoutes,
  promocodesRoutes,
  accountaccessRoutes,
  ordersRoutes,
  hardTicketsRoutes,
  myorgsiteRoutes,
  addonsRoute,
  customFlowsRoutes,
  promotesRoutes,
  subscriptionRoutes,
  subscriptionNewRoutes,
  mypayoutsRoutes,
  ticketTypesRoutes,
  offersRoute,
  myreportsRoutes,
  reportingDashboardRoute,
  mycontactsRoutes,
  settingsRoutes,
  promoteEditRoute
];


const fundDashboard = {
  id: "Fund Dashboard",
  path: "/fund",
  icon: <Home />,
  component: FundDefault,
  children: null,
};

const createaffiliateRoute = {
  id: "Create Affiliate Route",
  path: "/fund/affiliates/create",
  icon: <UserPlus />,
  component: CreateAffiliate,
  children: null,
}

const fundContactsRoutes = {
  path: "/fund/contacts",
  id: "Contacts",
  icon: <Users />,
  component: FundContactsPage,
  children: null,
}

const fundDonationsRoutes = {
  path: "/fund/donations",
  id: "Donations",
  icon: <LoyaltyOutlinedIcon />,
  component: FundDonationsPage,
  children: null,
}

const fundAffiliatesRoutes = {
  path: "/fund/affiliates",
  id: "Team",
  icon: <LinkIcon />,
  component: FundAffiliatesPage,
  children: null
}

const fundReportsRoutes = {
  path: "/fund/reports",
  id: "Reports",
  icon: <PieChart />,
  component: FundReportsPage,
  children: null
}

const fundIncentivesRoutes = {
  path: "/fund/incentives",
  id: "Inventory",
  icon: <Award />,
  component: FundIncentivesPage,
  children: null,
}

const fundCampaignsRoutes = {
  path: "/fund/campaigns",
  id: "Campaigns",
  to: 'campaigns',
  icon: <CalendarIcon />,
  component: FundCampaignsPage,
  children: null,
}

const fundPromotesRoutes = {
  path: "/fund/campaigns/promote",
  id: "Promote",
  to: 'Promote',
  icon: <Send />,
  component: FundCampaignPromotePage,
  children: null,
  hideForLegacy: true,
}

const fundPromoteEditRoute = {
  path: "/fund/campaigns/:productionId/promote/:id",
  icon: null,
  component: PromoteEdit,
  children: null,
}


const fundAccountRoute = {
  path: "/fund/account",
  id: "Account",
  icon: <SettingsIcon />,
  // component: FundAccountPage,
  component: Billing, //can we just reuse billing?,
  children: null,
};

const fundCampaignRoute = {
  path: "/fund/campaigns/:campaignId",
  id: "Campaign Detail",
  to: 'campaign',
  icon: null,
  hideSidebar: true,
  component: FundCampaignDetailPage,
  children: null,
};

const fundContactDetailRoute = {
  path: "/fund/contacts/:contactId",
  id: "Contact Detail",
  to: 'contact',
  icon: null,
  hideSidebar: true,
  component: ContactDetailPage,
  children: null,
};

const fundCampaignPromoteRoute = {
    id: "Promote",
    path: "/fund/campaigns/:campaignId/promote",
    to: "promote",
    hideSidebar: true,
    icon: null,
    children: null,
    component: FundCampaignPromotePage,
    hideForLegacy: true,
  }

// Fund sidebar routes
export const fundSidebarRoutes = [
  fundDashboard,
  fundContactsRoutes,
  fundDonationsRoutes,
  fundAffiliatesRoutes,
  fundPromotesRoutes,
  fundReportsRoutes,
  // fundIncentivesRoutes,
  fundCampaignsRoutes,
  fundAccountRoute,
  myorgsiteRoutes,
];

export const fundLayoutRoutes = [
  fundDashboard,
  fundContactsRoutes,
  fundContactDetailRoute,
  fundDonationsRoutes,
  fundAffiliatesRoutes,
  // fundIncentivesRoutes,
  fundCampaignsRoutes,
  fundPromotesRoutes,
  fundPromoteEditRoute,
  fundAccountRoute,
  fundReportsRoutes,
  // fundBackToOtsCoreRoute,
  // ...fundSidebarRoutes,
  createaffiliateRoute,
  fundCampaignRoute,
  fundCampaignPromoteRoute
];
