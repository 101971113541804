import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components/macro";
import { important, rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import { darken, lighten } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "_vendor/perfect-scrollbar.css";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpCenterButton from "_components/common/HelpCenterButton";

import { ExpandLess, ExpandMore, HelpOutline } from "@material-ui/icons";

import { red, green, deepPurple } from "@material-ui/core/colors";

import { websiteEditorRoutes as routes } from "_routes/index";

import { setSelectedProductionForAddons } from '_redux/actions/addonsActions'

import {
  Badge,
  Chip,
  Grid,
  Avatar,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";
import { isDraftPublishable } from "_utils/productionUtils";
import { OrganizationPlanChip } from "./common/OrganizationPlanChip";
import ShowRoles from "./helpers/ShowRoles";


const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;


const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: start;
  cursor: pointer;
  

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const LogoImg = styled.img`
  max-height: 50px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 4px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  & > svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      lighten(0.20, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      lighten(0.2, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const Note = styled.div`
  height: 20px;
  position: absolute;
  right: 10px;
  top: 8px;
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 1rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;
const REACT_APP_ONSTAGE_MAIN = process.env.REACT_APP_ONSTAGE_MAIN;

function getShowDetailsErrors(production) {
  const errors = [];
  if (production) {
    if (production.tiers?.length === 0) {
      errors.push('Add ticket prices');
    }
    if ((production.is_virtual || production.is_livestream) && production.event_type !== 'vod' && 
      production.tiers?.filter ((f) => f.admission_type === 'virtual').length === 0) {
      errors.push("Add Virtual ticket type")
    }
    if (production.dates?.length === 0 && production.event_type !== 'vod') {
      errors.push('Add performance dates');
    }
    if (!production.fee_style) {
      errors.push('Set your fee style');
    }
    if ((production.is_livestream || production.is_virtual) && production.event_type !== 'vod' && !production.livestream_capacity) {
      errors.push('Set livestream capacity');
    }
  }
  return errors;
}

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  const dispatch = useDispatch();
  const production = useSelector(state => state.productionReducer.production);

  const saveProductionForAddons = () => {
    if (name !== 'Add-Ons and Merch') return;
    dispatch(setSelectedProductionForAddons(production.artix_series_id))
  }
  return (
    <Category {...rest} onClick={saveProductionForAddons}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}

      {name === "Show Details" && (getShowDetailsErrors(production).length > 0) && (
        <Note>
          <Tooltip title={`Please correct the following errors: ${getShowDetailsErrors(production).join(', ')}`}>
            <ErrorOutlineIcon style={{ color: red[500] }} />
          </Tooltip>
        </Note>
      )}

      {name === "Seating Chart" && !production?.seating_chart_id && (
        <Note>
          <Tooltip title="You need to choose a seating chart before publishing">
            <ErrorOutlineIcon style={{ color: red[500] }} />
          </Tooltip>
        </Note>
      )}
    </Category>
  );
};


const SidebarLink = ({ name, to, badge, icon }) => {
  const production = useSelector(state => state.productionReducer.production);
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {/* {badge ? <LinkBadge label={badge} /> : ""} */}

      {name === "Design" && (production?.style?.homeBackgroundImage == null) && (
        <Note>
          <Tooltip title="You need to choose or upload artwork before publishing">
            <ErrorOutlineIcon style={{ color: red[500] }} />
          </Tooltip>
        </Note>
      )}
    </Link>
  );
};

const HomeBackImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

const WebsiteEditorSidebar = ({ user, production, classes, staticContext, location, ...rest }) => {
  const isMerchandiseAllowed = useSelector(state => state.organizationReducer.organization.allow_addons);
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.includes(route.to);
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <LogoImg alt="otslogo" src="/static/img/brands/OTS-logo-white-red.svg"/>{" "}
        <OrganizationPlanChip user={user} />  
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {production?.style?.homeBackgroundImage && (
              <HomeBackImage src={`//res.cloudinary.com/onthestage/image/upload/f_auto,w_1000,h_695/${production.style.homeBackgroundImage}.png`} />
            )}
            {production && routes.filter(r => {
                if (r.requires && !production.organization[r.requires]) {
                  return false;
                }
                if (r.roles) {
                  return user?.roles.some(role => r.roles.indexOf(role) > -1)
                } else {
                  return true;
                }
            }).filter((r) => !r.hideSidebar)
              .filter((r) => r.livestream_only === undefined || (r.livestream_only && production.is_livestream))
              .filter((r) => r.productionType == null || production.event_type === r.productionType)
              .filter(r => {
                if (production.show_type === 'registration' && r.name === 'Showgram') return false 
                else return true
              }).map((category, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      isOpen={true}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />

            
                      {category.children.filter(route => {
                         if ( (route.name === "Shop" && !isMerchandiseAllowed)) return false
                         else
                          if (production.show_type === 'registration' && (route.name === 'Cast' || route.name === 'Creative')) return false
                          else return true;
                        }).map((route, index) => (
                        <SidebarLink
                          key={index}
                          name={route.name}
                          to={`/productions/${production.id}/website-editor/${route.to}`}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                    
                  </React.Fragment>
                ) : category.icon && (!category.hideStatus || category.hideStatus !== production.status)? (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.link ? category.link : `/productions/${production.id}/${category.to}`}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    button
                    badge={category.badge}
                  />
                ) : null}
              </React.Fragment>
            ))}
            <HelpCenterButton showhover={false} />
            
          </Items>
        </List>
        {production?.status === "draft" && (
          <Grid container justify="space-around" alignItems="center" style={{marginBottom: '30px'}}>
            <Grid item>
            <ShowRoles roles={['manager', 'box_office']}>
              <Button href={`${REACT_APP_ONSTAGE_MAIN}/show/${production.organization.slug}/${production.slug}?new-dashboard=1?openPublish=1`} color="primary" variant="contained">
                  Review & Publish
              </Button>
            </ShowRoles>
            </Grid>
            {
              isDraftPublishable(production).publishable === false && (
                <Grid item>
                  <Tooltip title={`Please correct the following errors: ${isDraftPublishable(production).errors.join(', ')}`}>
                    <ErrorOutlineIcon style={{ color: red[500] }} />
                  </Tooltip> 
                </Grid>
              )
            }

          </Grid>
        )}
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <PurpleAvatar>{user && user.userOrg && user.userOrg.name[0]}</PurpleAvatar>
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">{user && user.name}</SidebarFooterText>
              <SidebarFooterSubText variant="caption">
            {user?.userOrg?.name}
            </SidebarFooterSubText>
            {user?.userOrg && (
              <SidebarFooterSubText>
                OTSID: {user?.userOrg.artix_id}
              </SidebarFooterSubText>
            )}
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(WebsiteEditorSidebar);
