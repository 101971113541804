import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Helmet from "react-helmet";
import {
  Card,
  Spacer
} from "_components/common"
import {
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Grid,
  Fab,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";
import { makeStyles } from '@material-ui/core/styles';
import { SHOW_NOTIFICATION } from '_constants'
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import UpgradeMessage, { useStylesUpgradeMessage, FeatureContainer } from '../../_components/helpers/upgradeMessage';

import BookIcon from './icons/BookIcon.svg';


import {
  RemoveRedEye as RemoveRedEyeIcon
} from "@material-ui/icons";
import { EnhancedTable, SaveButtonComponent } from "_components/common";
import { getComparator, stableSort } from "_utils/tableUtils";
import AddCustomFieldDialog from "./components/AddCustomFieldDialog";
import EditCustomFieldDialog from "./components/EditCustomFieldDialog";
import { getContactAttributesService } from "_services/contactService";
import { deleteContactAttributeService } from "_services/contactService";
import * as types from "_constants";


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  capitalize: {
    textTransform: "capitalize",
  },
  nameCell: {
    minWidth: '400px',
    maxWidth: '600px',
    flex: '1 1 0',
  }
}));

const useStylesIcon = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '8px',
    marginTop: '4px'
  },
  text: {
    marginLeft: '7px',
  },
});


const useDialogStyles = makeStyles((theme) => ({
  dialogPaper: {
    margin: theme.spacing(20),
  },
}));

const Alert = styled(MuiAlert)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const isPlatinumPlan = (per_ticket_fee) => per_ticket_fee === 1.85;

const DeleteConfirmationDialog = ({ openDeleteDialog, setOpenDeleteDialog, field, orgId}) => {
  const classes = useDialogStyles();
  const [isLoading, setIsLoading] = useState(false);

   async function handleDelete() {
    setIsLoading(true);
    try {
      const res = await deleteContactAttributeService(orgId, field.id);
      console.log('res', res);
      setOpenDeleteDialog(false, true, field);
    } catch (error) {
      console.error('Error deleting contact attribute:', error);
    }
    setIsLoading(false);
  }

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xs"}
      >
       <Box my={5} mx={2}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h4" gutterBottom>
              {`Delete "${field.attributeName}" field?`}
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box mb={5}>
            <Typography gutterBottom  style={{ fontSize: '14px' }}>
              {`Note that this action will delete data from all patron profiles and cannot be recovered.`}
            </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary" variant="outlined">
              Cancel
            </Button>
            <SaveButtonComponent isLoading={isLoading} disabled={isLoading} label={'Delete'}  onClick={() => handleDelete()}/>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
};

function CustomFields() {
  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const organization = useSelector(state => state.organizationReducer.organization);
  const isPlatinumAccount = isPlatinumPlan(organization.per_ticket_fee) || organization.is_enterprise;
  const dispatch = useDispatch();
  const [customFields, setCustomFields] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const disableFeature = !isPlatinumAccount && !organization.is_enterprise;

  const [showAddDialog, setShowAddDialog] = useState({
    isOpen: false,
    field: null
  });
  const [showDeleteDialog, setShowDeleteDialog] = useState({
    isOpen: false,
    field: null
  });
  const [showEditDialog, setShowEditDialog] = useState({
    isOpen: false,
    field: null
  });

  // table details
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState('attributeName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const headCells = [
    { id: "attributeName", numeric: false, label: "Name of field", alignment: "left" },
    { id: "dataType", numeric: false, label: "Type of Field", alignment: "left" },
    { id: "action", numeric: false, label: "Actions", alignment: "left" },
  ];

  const onShowAddDialog = () => {
    setShowAddDialog({
      isOpen: true,
      field: null
    })
  }

  const onAddSuccess = (isOpen, isSuccess, res) => {
    if (isSuccess) {
      setCustomFields([...customFields, ...res].sort((x, y) => {
        return x.attributeName.localeCompare(y.attributeName)
      }) );
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'New custom field(s) added successfully!'
        }
      })
    }
    setShowAddDialog(isOpen)
  };

  const onShowEditDialog = (field) => {
    setShowEditDialog({
      isOpen: true,
      field: field
    })
  }

  const onEditSuccess = (isOpen, isSuccess, field) => {
    if (isSuccess) {
      const editingField = customFields.find(t => t.id === field.id);
      editingField.attributeName = field.attributeName;
      setCustomFields([...customFields].sort((x, y) => {
        return x.attributeName.localeCompare(y.attributeName)
      }) );
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'Edit successfully made!'
        }
      })
    }
    setShowEditDialog(isOpen)
  };



  const onShowDeleteDialog = (field) => {
    setShowDeleteDialog({
      isOpen: true,
      field: field
    })
  }
  const onDeleteSuccess = (isOpen, isSuccess, field) => {
    if (isSuccess) {
      const newCustomFields = customFields.filter(t => t.id !== field.id);
      setCustomFields(newCustomFields);
      dispatch({
        type: types.SHOW_NOTIFICATION,
        payload: {
          message: 'Custom field deleted successfully!'
        }
      });
    }
    setShowDeleteDialog(isOpen);
  };
  

  useEffect(() => {
    const load = async () => {
      const attributes = await getContactAttributesService(organization._id);
      setCustomFields(attributes);
      setIsLoaded(true);
    };
    organization._id && load();
  }, [organization]);

  return (
    <>
      <Helmet title="Custom Fields" />
      <Typography variant="h3" gutterBottom display="inline">
        Custom Fields
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Dashboard
        </Link>
        <Typography>Custom Fields</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {!isPlatinumAccount && 
          <UpgradeMessage 
          message="Custom Fields allow you to add custom data to your Patron’s profiles. This feature is only available on the Platinum plan. To upgrade and access this feature, please contact your "
          contactEmail="accountmanager@onthestage.com"
        />
        }

      {isLoaded && isPlatinumAccount &&
        <Alert mb={4} pl={8} severity="info">
        {"Custom Fields allow you to add custom data to your Patron’s profiles, which can be viewed within the Custom Fields tab. If you would like to view all of the default fields currently available, "}
        <a href="https://intercom.help/on-the-stage/en/articles/7919675-uploading-patron-contact-info">please click here.</a>
        </Alert>
      }

    <FeatureContainer disableFeature={disableFeature}>

      {isLoaded && 
      <Card>
        <CardContent>

          <Spacer my={4} />

          <Grid justify="space-between" container spacing={10}>
            <Grid item   style={{ marginLeft: '16'}}>
              <Typography variant="h4" id="tableTitle" gutterBottom display="inline" style={{ fontWeight: 700 }}>
                All Custom Fields
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Grid container justify="flex-end">
                <Fab mx={2} size="small" color="primary" aria-label="Add" style={{ margin: '2px' }} onClick={() => { onShowAddDialog() }} >
                  <AddIcon/>
                </Fab>
              </Grid>
            </Grid>
          </Grid>

          <Spacer my={4} />
          {isLoaded && customFields.length === 0 && <Alert severity="info" icon={false} variant="outlined">
            <Box className={classesIcon.root}>
              <Box className={classesIcon.icon}>
                <img src={BookIcon} alt="Book Icon" />
              </Box>
              <Box className={classesIcon.text} paddingLeft={0}>
                No Custom Fields
              </Box>
            </Box>
          </Alert>}
          {isLoaded && customFields.length > 0 && <EnhancedTable
            order={order}
            orderBy={orderBy}
            page={page}
            rows={customFields}
            rowsPerPage={rowsPerPage}
            selected={[]}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            headCells={headCells}
            title='Custom Fields'
          >
            <TableBody>
              {stableSort(customFields, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((field, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${field.id}-${index}`}
                    >
                      <TableCell align="left"  className={classes.nameCell} style={{ flexBasis: '0' }}>{`${field.attributeName}`}</TableCell>
                      <TableCell align="left" className={`${classes.capitalize} ${classes.nameCell}`} style={{ flexBasis: '0' }}>{field.dataType}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="edit custom fields" onClick={(e) => onShowEditDialog(field)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete custom fields" onClick={(e) => onShowDeleteDialog(field)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </EnhancedTable>}

          {showAddDialog.isOpen && <AddCustomFieldDialog openAddContactDialog={showAddDialog} setOpenAddContactDialog={onAddSuccess} field={showAddDialog.field} orgId={organization._id}/>}
          {showEditDialog.isOpen && <EditCustomFieldDialog openEditContactDialog={showEditDialog} setOpenEditContactDialog={onEditSuccess} field={showEditDialog.field} orgId={organization._id} />}
          {showDeleteDialog.isOpen && <DeleteConfirmationDialog openDeleteDialog={showDeleteDialog} setOpenDeleteDialog={onDeleteSuccess}  field={showDeleteDialog.field} orgId={organization._id} />}
        </CardContent>
      </Card>}
    </FeatureContainer>
    </>
  )
}

export default CustomFields;
