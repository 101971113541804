import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, updatePlaybill, getPlaybillData, importPlaybill, updatePlaybillValue } from '_redux/actions/playbillActions';
import { closeWaiting, openNotification, showWaiting, openDialog } from '_redux/actions/appActions';
import SharePdfDialog from "./PlaybillEditorDialogs/SharePdfDialog";
import styled from "styled-components/macro";
import Helmet from "react-helmet";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import PlaybillRightColComponent from './PlaybillRightColumnComponent';
import overrides from "_theme/overrides";
import variants from "_theme/variants";
import { useLocation } from 'react-router-dom'
import {
  Dialog as DialogComponent,
} from "_components/common";
import * as Yup from "yup";
import { Formik } from "formik";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Button,
  Menu,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  DialogContentText,
  Select,
  InputLabel,
} from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  DragIndicator as DragIcon,
  Delete,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { downloadPlaybillService } from "_services/playbillService";
// Showgram Editor Routes
import PlaybillCover from "./PlaybillEditorCover";
import PlaybillAbout from "./PlaybillEditorAbout";
import PlaybillEditorScenes from './PlaybillEditorScenes';
import PlaybillEditorCast from './PlaybillEditorCast';
import PlaybillEditorCreative from './PlaybillEditorCreative';
import PlaybillEditorShop from './PlaybillEditorShop';
import PlaybillEditorSponsors from './PlaybillEditorSponsors';
import PlaybillEditorCustom from './PlaybillEditorCustom';
import { Alert as MuiAlert } from "@material-ui/lab";
import { OPEN_DIALOG } from "_constants";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#ffffff"
  },
  showgramOrgTitle: {
    fontFamily: 'Gilroy-Black, sans-serif',
    fontSize: '36px',
    fontWeight: 800,
    letterSpacing: '-0.5px',
    color: '#FFFFFF',
  },
  showgramOrgSubtitle: {
    fontFamily: 'Gilroy-Medium, sans-serif',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0px',
    color: '#FFFFFF',
  },
  showgramOrgAddress: {
    fontFamily: 'Gilroy-Medium, sans-serif',
    fontSize: '12px',
    lineHeight: '17px',
    color: '#F6F6F6',
  }
}));
const Alert = styled(MuiAlert)(spacing);

const Card = styled(MuiCard)`
  font-family: "DM Sans",serif;
`;

const Typography = styled(MuiTypography)`
  font-family: "DM Sans",serif;
`;

const Spacer = styled.div(spacing);

const CardContentSpacing = styled(MuiCardContent)(spacing);

const CardContent = styled(CardContentSpacing)`  
  &:last-child {
    padding: 0;
  }
  background: ${(props) => props.backgroundcolor};
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const OTSList = styled.ul`
  display: flex;
  text-align: center;
  padding-left: 0;
  margin: 0;
  list-style: none;
  justify-content: space-evenly;
`;

const OTSListItem = styled.li`
  position: relative;
  display: inline-block;
  float: left;
  padding: 15px;
  font-family: DM Sans, "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  color: ${(props) => props.show_page ? '#808080' : '#eee'};
  border-bottom: 2px solid ${(props) => props.active ? "#BD1D1E" : "transparent"};
`;

const PageOrderModalList = styled.ul`
  display: flex;
  flex-direction: column;
  /* text-align: center; */
  padding-left: 0;
  margin: 0;
  list-style: none;
  justify-content: space-evenly;
`;

const PageOrderModalListItem = styled.li`
  display: flex;
  position: relative;
  float: left;
  padding: 15px;
  font-family: DM Sans, "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
  color: '#000000';
`;

const NavBar = styled.div`
  background: ${(props) => props.backgroundcolor};
  min-height: 50px;
  line-height: 20px;
`;

const NavBarContent = styled.div`
`;


const pageStructure = {
  sort_order: null,
  page_type: '',
  is_custom: true,
  slug: '',
  show_page: true,
  data: {
      section: ''
  },
  page_layout: ''
}

function SortPageNavRow ({ moveRow, sort_order_id, index, page, goTo, production, history }) {
  const ItemTypes = {
    ROW: 'row',
  };
  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const destinationIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (destinationIndex === hoverIndex) {
        return;
      }
      // Time to actually perform the action
      moveRow(destinationIndex, hoverIndex, sort_order_id);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ROW,
    item: () => {
      return { sort_order_id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <PageOrderModalListItem 
      key={page.sort_order}
      ref={ref}
      style={{ opacity, cursor: 'move' }} 
      data-handler-id={handlerId}
    >
    <Box mr={3}>
      <DragIcon />
    </Box>
      {page.slug}
    </PageOrderModalListItem>
  )
}

function EditPageLayout() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openAddNewSectionModal, setOpenAddNewSectionModal] = useState(false);
  const [openSharePdfModal, setOpenSharePdfModal] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openSortPageModal, setOpenSortPageModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [showgramCurrentFont, setShowgramCurrentFont] = useState(font_family);
  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const userOrg = useSelector((state) => state.authReducer.user.userOrg);
  const production = useSelector(state => state.productionReducer.production);
  const playbillPages = useSelector(state => state.playbillReducer.pages);
  const pdfUrl = useSelector(state => state.playbillReducer.pdf_url);
  const isEdited = useSelector(state => state.playbillReducer.isEdited);
  const font_family = useSelector(state => state.playbillReducer.font_family);
  const isMerchandiseAllowed = useSelector(state => state.organizationReducer.organization.allow_addons);
  const [showPreview, setShowPreview] = useState(false);
  const currentPageIndex = useSelector(state => state.playbillReducer.currentPageIndex);
  const playbillPage = playbillPages[currentPageIndex];
  const playbillPagesCopyArr = [...playbillPages];
  const [pageOrderList, setPageOrderList] = useState([]);

  useEffect(() => {
    const load = async () => {
      dispatch(getPlaybillData(production.id));
    }
    load();
  }, []);

  useEffect(() => {
    setShowgramCurrentFont(font_family)
  }, [font_family]);

  const theme = createMuiTheme({
    palette: variants[variants.length-1].palette,
    overrides: {
      // Style sheet name ⚛️
      MuiTypography: {
        body1: {
          fontFamily: `"${showgramCurrentFont}", "DM Sans", sans-serif`,
          lineHeight: '140%',
          fontSize: '15px',
        },               
        h1: {
          fontFamily: `'${showgramCurrentFont}', "DM Sans", serif`,
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '140%',
          letterSpacing: '-0.5px'
        },
        h2: {
          fontFamily: `'${showgramCurrentFont}', "DM Sans", serif`,
          fontSize: '18px',
          fontWeight: 400,
          lineHiehgt: 1.1,
          marginTop: '18px',
          marginBottom: '10px',
        },
        h3: {
          fontSize: '16px',
          fontFamily: `'${showgramCurrentFont}', "DM Sans", serif`,
        },
        h4: {
          fontFamily: `'${showgramCurrentFont}', "DM Sans", serif`,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '13px',
          marginBottom: '10px'
        },
        h5: {
          fontFamily: `"${showgramCurrentFont}", "DM Sans", serif`,
          fontWeight: 500,
          fontSize: '14px',
        },      
        h6: {
          fontFamily: `"${showgramCurrentFont}", "DM Sans", serif`,
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '13px',
          color: '#595959',
          marginTop: '5px'
        }  
      },
      MuiButton: {
        // Name of the rule
        text: {
          // Some CSS
          color: 'red',
        },
      },
      MuiSwitch: overrides.MuiSwitch
    },
  });
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = async (openWindow = false, useBlanks = true) => {
    try {
      if (true) {
        dispatch(showWaiting({
          message: `Preparing PDF, please wait. ${openWindow ? 'Your download will begin in a few seconds.': ''}`,
        }));
        handleClose();

        const result = await downloadPlaybillService(production.id, playbillPages, useBlanks, showgramCurrentFont);
        dispatch(updatePlaybill(production.id, { pdf_url: result.Location, pdf_key: result.key }));
        if (openWindow) {
          window.open(result.Location + '?v=' + new Date().getTime());
        }  
      } else {
        window.open(pdfUrl);
      }
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data?.error || error.response?.data || error.message || "Something went wrong";
      dispatch({
        type: OPEN_DIALOG,
        payload: {
          message,
        }
      })
    } finally {
      dispatch(closeWaiting());
    }
  };

  const handleImportModal = async () => {
    setOpenImportModal(true);
  };

  const handleCloseAddNewSectionModal = () => {
    setOpenAddNewSectionModal(false);
  };

  const handleOpenAddNewSectionModal = () => {
    setOpenAddNewSectionModal(true);
  };

  const handleCloseSharePdfModal = () => {
    setOpenSharePdfModal(false);
  };

  const handleOpenSharePdfModal = async () => {
    await handleDownload(false, false);
    setOpenSharePdfModal(true);
  };

  const handleCloseSortPageOrderModal = async () => {
    setOpenSortPageModal(false)
  }

  const handleOpenSortPageOrderModal = async () => {
    setPageOrderList(playbillPagesCopyArr);
    setOpenSortPageModal(true)
  }

  const handleOpenSettingsModal = async () => {
    setOpenSettingsModal(true)
  }

  const handleCloseSettingsModal = async () => {
    setOpenSettingsModal(false)
  }

  const moveRow = React.useCallback(async (destinationIndex, hoverIndex, sort_order_id) => {
    const dragPage = pageOrderList[destinationIndex];
    const updatedList = update(pageOrderList, {
      $splice: [[destinationIndex, 1], [hoverIndex, 0, dragPage]],
    });
    // eslint-disable-next-line array-callback-return
    updatedList.map((currElement, index) => {
      currElement.sort_order = index + 1
      })
    setPageOrderList(updatedList);
  }, [pageOrderList]);


  const handleSaveNewPageOrder = async () => {
    try {
      dispatch(updatePlaybill(production.id, { pages: pageOrderList }));
      dispatch(openNotification({
        message: "Page order updated!",
      }));
      handleCloseSortPageOrderModal();
      handleClose();
    } catch (error) {
      const message = error.response?.data?.message || error.response?.data || error.message || "Unknown error";
      dispatch(openDialog({
        message: `There was an error reordering the products: ${message}`,
      }));
    }
  }

  const handleDeleteCustomPage = async (page) => {
    const newPageOrderList = pageOrderList.filter(p => {
      return p.slug !== page.slug
    })
    setPageOrderList(newPageOrderList)
  }

  const goTo = (to) => {
    history.push(to);
  };

  const SortPageOrderModal = () => (
    <Dialog 
      open={openSortPageModal} 
      onClose={handleCloseSortPageOrderModal} 
      aria-labelledby="form-dialog-title" 
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Sort Showgram Page Order</DialogTitle>
      <DialogContent>
        <DndProvider backend={HTML5Backend}>
          <NavBar>
            <NavBarContent>
              <PageOrderModalList>
                {pageOrderList.map((page, index) => ( // moveRow, id, index, page, goTo, production, history
                  <>
                    { page.sort_order !== 1 && (
                       <Grid container justify="flex-end" direction="column" spacing={10}>
                        <Grid item>
                          <SortPageNavRow 
                            moveRow={moveRow} 
                            sort_order_id={page.sort_order} 
                            index={index} 
                            page={page} 
                            production={production} 
                            history={history} 
                          />

                          {page.is_custom && (
                             <Delete fontSize="small" style={{marginTop: "15px", cursor: 'pointer'}} onClick={() => handleDeleteCustomPage(page)}/>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </>
                ))}
              </PageOrderModalList>
            </NavBarContent>
          </NavBar>
        </DndProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSortPageOrderModal} color="primary">
          Close
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSaveNewPageOrder}
          // disabled={!isValid || (isSubmitting || (status && status.success))}
        >
          Sort order
        </Button>
      </DialogActions>
    </Dialog>
  );

  const SettingsModal = () => {
    const [localFont, setLocalFont] = useState(showgramCurrentFont ? showgramCurrentFont : 'DM Sans');

    const handleLocalFontChange = (event) => {
      setLocalFont(event.target.value);
    };
  
    const handleSaveSettings = () => {
      dispatch(updatePlaybill(production.id, { font_family: localFont }));
      setShowgramCurrentFont(localFont);
      handleCloseSettingsModal();
    };

    return ( 
    <Dialog 
      open={openSettingsModal} 
      onClose={handleCloseSettingsModal} 
      aria-labelledby="form-dialog-title" 
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Showgram Settings</DialogTitle>
      <DialogContent>
        <DndProvider backend={HTML5Backend}>
          <NavBar>
            <NavBarContent>
            <InputLabel id="showgram-font">
              Font
            </InputLabel>
            <Select
              labelId="showgram-font"
              id="demo-simple-select"
              value={localFont}
              label="font"
              onChange={handleLocalFontChange}
            >
              <MenuItem style={{fontFamily: 'DM Sans' }} value={'DM Sans'}>DM Sans</MenuItem>
              <MenuItem style={{fontFamily: 'Times New Roman' }} value={'Times New Roman'}>Times New Roman</MenuItem>
              <MenuItem style={{fontFamily: 'Arial' }} value={'Arial'}>Arial</MenuItem>
              <MenuItem style={{fontFamily: 'Roboto' }} value={'Roboto'}>Roboto</MenuItem>
              <MenuItem style={{fontFamily: 'Verdana Pro Cond' }} value={'Verdana Pro Cond'}>Verdana</MenuItem>
              <MenuItem style={{fontFamily: 'Roboto Slab' }} value={'Roboto Slab'}>Roboto Slab</MenuItem>
              <MenuItem style={{fontFamily: 'Carlito' }} value={'Carlito'}>Carlito</MenuItem>
              <MenuItem style={{fontFamily: 'Open Sans' }} value={'Open Sans'}>Open Sans</MenuItem>
            </Select>
            </NavBarContent>
          </NavBar>
        </DndProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseSettingsModal} color="primary">
          Close
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSaveSettings}
        >
          Save Settings
        </Button>
      </DialogActions>
    </Dialog>
  )};

  const ImportModal = () => {
      return <DialogComponent
      title="Are you sure"
      handleOK={() => { setOpenImportModal(false);  handleClose(); dispatch(importPlaybill(production.id)); }}
      handleCancel={() => { handleClose(); setOpenImportModal(false) }}
      open={openImportModal}
      cancelText="Cancel"
    >
      <DialogContentText id="alert-dialog-description">
        This action will import information from your production site. Importing this will overwrite any edits you have made in the following program sections: Cover, Cast, About Us, Creative and Merchandise. Do you wish to continue?
      </DialogContentText>
    </DialogComponent>       
  }

  const ImageOptions = [
    { label: 'Only Text', value: 'text' },
    { label: 'Full Image', value: 'full_image' },
    { label: '2 Images', value: '2_images' },
    { label: '4 Images', value: '4_images' },
    { label: '8 Images', value: '8_images' },
  ];
  
  const AddNewSectionModal = () => (
    <Dialog open={openAddNewSectionModal} onClose={handleCloseAddNewSectionModal} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Add New Page</DialogTitle>
        <Formik initialValues={pageStructure}
          validationSchema={Yup.object().shape({
            page_type: Yup.string().required("Page name is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setStatus({success: false, message : ''});
              let updatedValues;

              if(values.page_layout === 'text') {
                updatedValues = {
                  ...values,
                  slug: values.page_type,
                  is_custom: true,
                  sort_order: playbillPages.length + 1
                }
              } else {
                updatedValues = {
                  ...values,
                  slug: values.page_type,
                  is_custom: true,
                  sort_order: playbillPages.length + 1,
                  page_layout: values.page_layout,
                  page_type: 'Sponsors',
                  data : {
                    sponsors : [
                    ]
                  },
                }
              }             

              let updatedPages
              const slugNamefound = playbillPages.some(page => page.slug === values.page_type)
              if (!slugNamefound) {
                updatedPages = [...playbillPages, updatedValues];
                dispatch(updatePlaybill(production.id, { pages: updatedPages }));
                handleCloseAddNewSectionModal();
                handleClose();
                dispatch(openNotification({
                  message: 'New page added!'
                }))
              } else {
                setErrors({ submit:  'Page names must be unique!'  });
                updatedPages = playbillPages
              }
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false, message: '' });
              setErrors({ submit: message });
              setSubmitting(false);
              dispatch(openNotification({
                message: error.response?.data || error.message || "Something went wrong!",
              }))
            }
          }}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            touched,
            values,
            setFieldValue
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
              {errors.submit && (
                <Alert mt={2} mb={6} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              {status && status.success && (
                <Alert mt={2} mb={6} severity="success">
                  {status.message}
                </Alert>
              )}  
              <Typography variant="h5">Select a Layout</Typography> 
              <Grid container spacing={2} my={3} style={{margin: '15px 0px'}}>
                {ImageOptions.map((option) => (
                  <Grid item xs={2} key={option.value}>
                    <Button
                      variant={values.page_layout === option.value ? 'contained' : 'outlined'}
                      color={values.page_layout === option.value ? 'primary' : 'default'}
                      onClick={() => setFieldValue('page_layout', option.value)}
                      style={{ backgroundColor: values.page_layout === option.value ? 'red' : 'initial' }}
                    >
                      {option.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <TextField
                id="outlined-hidden-label-small"
                variant="outlined"
                name="page_type"
                type="text"
                fullWidth
                size="small"
                value={values.page_type}
                error={Boolean(touched.page_type && errors.page_type)}
                helperText={touched.page_type && errors.page_type}
                onBlur={handleBlur}
                onChange={handleChange}
                label="Page Name"
                placeholder="Enter section name"
                autoFocus
                mb={2}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddNewSectionModal} color="primary">
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid || (isSubmitting || (status && status.success))}
            >
              Create Page
            </Button>
          </DialogActions>
        </form>
        )}
        </Formik>
      </Dialog>
  );

  const location = useLocation();

  const handleUpdate = async (e) => {
    dispatch(updatePlaybillValue({
      key: e.target.name,
      value: e.target.value,
      page: playbillPage,
    }))
  }

  const getActivePage = (slug) => {
    switch (slug) {
      case 'Cover':
        return <PlaybillCover production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillCover>
      case 'About Us':
        return <PlaybillAbout production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillAbout>     
      case 'Scenes':
        return <PlaybillEditorScenes production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillEditorScenes>     
      case 'Creative':
        return <PlaybillEditorCreative production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillEditorCreative>
      case 'Cast':
        return <PlaybillEditorCast production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillEditorCast>  
      case 'Shop':
        return <PlaybillEditorShop production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillEditorShop>
      case 'Sponsors':
        return <PlaybillEditorSponsors production={production}  page={playbillPage} showPreview={showPreview} classes={classes} handleUpdate={handleUpdate}></PlaybillEditorSponsors>
      default:
        return <PlaybillEditorCustom production={production}  page={playbillPage} showPreview={showPreview} fontFamily={showgramCurrentFont}></PlaybillEditorCustom>
    }
  };  


  return (
    <React.Fragment>
      <Helmet title="Showgram" />
      <Grid container justify="space-between" spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Showgram
          </Typography>
         
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Dashboard
            </Link>
            <Link component={NavLink} exact to={`/productions/${production.id}`}>
                {production.name}
            </Link>
            <Typography>Showgram</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button 
            id="action-button"
            align="right" 
            variant="contained" 
            color="primary" 
            endIcon={<ArrowDropDownIcon fontSize="small"/>}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            disabled={userOrg.plan !== 'Pro' && userOrg.plan !== 'Empower' && userOrg.plan !== 'Platinum'}
          >
            Actions
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'action-button',
            }}
          >
            <MenuItem onClick={handleDownload}>Download PDF</MenuItem>
            <MenuItem onClick={handleImportModal}>Import From Website</MenuItem>
            <MenuItem onClick={handleOpenSortPageOrderModal}>Re-order Sections</MenuItem>
            <MenuItem onClick={handleOpenSharePdfModal}>Share PDF</MenuItem>
            <MenuItem onClick={handleOpenAddNewSectionModal}>Add New Section</MenuItem>
          </Menu>
          <Button 
            id="action-button"
            align="right" 
            variant="contained" 
            color="primary" 
            endIcon={<SettingsIcon fontSize="small"/>}
            onClick={handleOpenSettingsModal}
            disabled={userOrg.plan !== 'Pro' && userOrg.plan !== 'Empower' && userOrg.plan !== 'Platinum'}
            style={{ marginLeft: '10px'}}
          >
            Settings
          </Button>
        </Grid>
        <AddNewSectionModal />
        <SharePdfDialog 
          production={production} 
          open={openSharePdfModal} 
          handleOpen={handleOpenSharePdfModal} 
          handleClose={handleCloseSharePdfModal} 
        />
        <ImportModal />
        <SortPageOrderModal />
        <SettingsModal />
      </Grid>
      {
        production.organization.per_tick
      }



      <Divider my={6} />


      {userOrg.plan === "Pro" || userOrg.plan === "Empower" || userOrg.plan === "Platinum" ? (
        null
      ) : <Alert severity="warning">
      Upgrade to a Empower/Platinum Fee plan to use the Showgram feature. Contact your account manager to upgrade.
    </Alert> }      

      <Spacer mb={4} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          {production && (
            <ThemeProvider theme={theme}>
            <Card mb={6}>
              <CardContent>
                  <NavBar>
                    <NavBarContent>
                      <OTSList>
                        {playbillPages.map((page, index) => {
                          {if (!isMerchandiseAllowed && page.slug === "shop" ) {return null}}
                          return <OTSListItem 
                              key={page.slug}
                              onClick={async () => { await dispatch(setCurrentPage(index)); }}
                              active={playbillPage?.slug === page.slug}
                              show_page={page.show_page}
                            >
                              {page.slug}
                            </OTSListItem>
                        }
                        )}
                      </OTSList>
                    </NavBarContent>
                  </NavBar>
                  
                  {playbillPage && 
                    <>
                      <div style={{display: 'flex'}}>
                        <div item  style={{ border: '1px solid #eee', borderRadius: 4, margin: 20, padding: 20  }}>
                          <div container style={{ fontFamily: `${showgramCurrentFont}`, border: '10px solid black',  margin: '20px', padding: '20px', overflow: 'scroll', width: 603, height: 959, backgroundColor: showPreview ? '#fff' : '#F6F6F6'}}>      
                            { getActivePage(playbillPage.page_type)}
                          </div>
                        </div>

                        <div item style={{ border: '1px solid #eee', borderRadius: 4, margin: '20px', padding: '20px', }}>
                          <PlaybillRightColComponent 
                          production={production} 
                          playbillPages={playbillPages}  
                          playbillPage={playbillPage} 
                          showPreview={showPreview} 
                          setShowPreview={setShowPreview} 
                          />    
                        </div>          
                      </div>                    
                    
                    </>
                  }
              </CardContent>
            </Card>
            </ThemeProvider>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EditPageLayout;



